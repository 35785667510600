import PeopleCount from '@/domain/models/PeopleCount'

import { Storage } from 'aws-amplify'

import axios from 'axios'
import csv from 'csvtojson'
import moment from 'moment'
import JSZip from 'jszip'
// import saveAs from 'filesaver'

import DateUtil from '@/utils/DateUtil'

import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'CloudStorageRepository'

const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time))

const getDataFileList = async (storagePath, { start, end, type }) => {
  let subDir = '/'
  let datePrefix = ''

  if (type == 'Monthly') {
    subDir = '/'
    // const isoDate = new Date(date).toISOString()
    // datePrefix = moment(isoDate).format('YYYY')
  } else if (type == 'Daily') {
    // subDir = '/daily/'
    // const isoDate = new Date(date).toISOString()
    // datePrefix = moment(isoDate).format('YYYYMM')
  } else if (type == 'Weekly') {
    // subDir = '/weekly/'
    // const isoDate = new Date(date).toISOString()
    // datePrefix = moment(isoDate).format('YYYYMM')
  } else if (type == 'Hourly') {
    subDir = '/hourly/'
    // const isoDate = new Date(date).toISOString()
    // datePrefix = moment(isoDate).format('YYYYMMDD')
  }

  const basePath = storagePath + subDir
  const key = basePath + 'Pms' + type + datePrefix
  // const key = basePath + 'Pms' + type + datePrefix
  // const key = 'jp.co.enazeal/nagoya-office/area1/data' + '/'
  logger.verbose(MODULE_TAG, 'key: ' + key)
  logger.verbose(MODULE_TAG, 'storagePath: ' + storagePath)
  logger.verbose(MODULE_TAG, 'start: ' + start)
  logger.verbose(MODULE_TAG, 'end: ' + end)
  logger.verbose(MODULE_TAG, 'type: ' + type)
  const dataExpireSeconds = 10 * 60

  const listResult = await Storage.list(key)
    .then((result) => {
      logger.verbose('=== Storage.list result ===')
      logger.verbose(result)
      const data = JSON.parse(JSON.stringify(result))

      // TODO: hasNextToken
      logger.debug(data.hasNextToken)
      // console.log(data.results)

      let results = []
      data.results.forEach((object) => {
        const path = object.key.replace(basePath, '')
        const match = path.match(/[^/]+$/)
        const fileName = path.match(/([^/]*)\./)[1]
        // const fileName = path
        const extend = path.match(/[^.]+$/)
        // console.log(fileName)

        if (fileName.match(/Daily/)) {
          type = 'Daily'
          // start = dateStr.substr(0, 7) + '/01'
        } else if (fileName.match(/Weekly/)) {
          type = 'Weekly'
          // TODO: 対応するか未決
        } else if (fileName.match(/Monthly/)) {
          type = 'Monthly'
          // start = dateStr.substr(0, 4) + '/01/01'
        }

        if (type == 'Hourly') {
          const prefix = 'Pms' + type
          const dateStr = fileName.replace(prefix, '')
          let date = dateStr.substr(0, 4)
          if (4 < dateStr.length) {
            date += '/' + dateStr.substr(4, 2)
          } else {
            date += '/01'
          }
          if (6 < dateStr.length) {
            date += '/' + dateStr.substr(6, 2)
          } else {
            date += '/01'
          }
          if (DateUtil.isWithinDate(date, start, end)) {
            // console.log(date)
            // console.log(start)
            // console.log(end)
            results.push(fileName)
          }
        } else if (type == 'Daily' || type == 'Weekly' || type == 'Monthly') {
          // TODO: 日時と月次の日付の範囲判定
          results.push(fileName)
        }
      })

      return {
        response: results,
        error: null,
      }
    })
    .catch((error) => {
      // handle error
      logger.error('=== Storage.list error ===')
      logger.error(error)
      return {
        response: null,
        error: error,
      }
    })

  if (listResult.error) {
    return {
      data: null,
      error: listResult.error,
    }
  }
  return {
    data: listResult.response,
    error: null,
  }
}

// zip ファイルでダウンロード
function generateFilesZip(contents, folderName) {
  let zip = new JSZip()
  // フォルダ作成
  let folder = zip.folder(folderName)
  // フォルダ下にデータを格納
  contents.forEach((content) => {
    if (content.data && content.fileName) {
      folder.file(content.fileName, content.data)
    }
  })
  // zip を生成
  zip.generateAsync({ type: 'blob' }).then((blob) => {
    // ダウンロードリンクを 生成
    let dlLink = document.createElement('a')

    // blob から URL を生成
    const dataUrl = URL.createObjectURL(blob)
    dlLink.href = dataUrl
    dlLink.download = `${folderName}.zip`

    // 設置/クリック/削除
    document.body.insertAdjacentElement('beforeEnd', dlLink)
    dlLink.click()
    dlLink.remove()

    // オブジェクト URL の開放
    setTimeout(function () {
      window.URL.revokeObjectURL(dataUrl)
    }, 1000)
  })

  zip = null
}

// データファイルをダウンロードする
const getDataFiles = async (identityId, files, folderName) => {
  logger.verbose(MODULE_TAG, 'identityId: ' + identityId)
  logger.verbose(MODULE_TAG, 'files: ' + files)

  let contents = []
  for (const file of files) {
    const key = file.key
    const fileName = file.name
    logger.verbose('=== Storage.get start ===', key)

    const data = await Storage.get(key, { download: true })
      .then(async (result) => {
        logger.verbose('=== Storage.get result ===')
        logger.verbose(result)
        // https://docs.amplify.aws/lib/storage/download/q/platform/js/#get
        //   If download is true, Storage.get returns an object with a Body field of type Blob.
        //   data.Body is a Blob
        return result.Body
      })
      .catch((error) => {
        // handle error
        logger.error('=== Storage.get error ===')
        logger.error(error)
        return null
      })

    if (data != null) {
      contents.push({
        data: data,
        fileName: fileName,
      })
    }
  }

  generateFilesZip(contents, folderName)

  return {
    data: {},
    error: null,
  }
}

// CSVデータを取得してデータセットに変換する
const _tenantCountingDatasetsByDates = async (
  identityId,
  { storagePath, begin, end, type, areaName, tenantName, gender, ageGroup },
) => {
  // console.log(storagePath, begin, end, type)
  logger.debug(begin)
  logger.debug(end)
  logger.debug(type)
  const beginYear = DateUtil.yearNumberFromString(begin)
  const beginMonth = DateUtil.monthNumberFromString(begin)
  const endYear = DateUtil.yearNumberFromString(end)
  const endMonth = DateUtil.monthNumberFromString(end)
  // console.log(beginYear, beginMonth, endYear, endMonth)

  let dateStrs = []
  let endDate = end
  let type_ = type

  let dates = []
  let subDir = '/'

  const now = DateUtil.nowDateString()
  // const now = '2023/04/17'
  if (DateUtil.isWithinJustDate(now, begin, end)) {
    endDate = now
  }
  if (type == 'Hourly') {
    subDir = '/hourly/'
    for (let lc = 0; lc < 31; lc++) {
      const addDate = DateUtil.addDateString(begin, lc)
      if (DateUtil.isWithinJustDate(addDate, begin, end)) {
        const isoDate = new Date(addDate).toISOString()
        const str = moment(isoDate).format('YYYYMMDD')
        dateStrs.push(str)
      } else {
        break
      }
    }
    // console.log(dateStrs)
  } else if (type == 'Daily') {
    // subDir = '/daily/'

    let mc = beginMonth
    let month_ = 0
    do {
      month_ = (mc % 12) + Math.floor(mc / 12) * 12
      const num_year = beginYear + (mc <= 12 ? 0 : Math.floor(mc / 12))
      const num_month = 12 < month_ ? month_ % 12 : month_
      // console.log(num_year, num_month, mc % 12, month_)

      const dateString = num_year + '-' + num_month + '-01'
      // console.log(dateString)
      const isoDate = new Date(dateString).toISOString()
      dateStrs.push(moment(isoDate).format('YYYYMM'))

      mc++
      // console.log(month_, (endYear - beginYear) * 12 + endMonth, mc)
    } while (month_ < (endYear - beginYear) * 12 + endMonth)

    // const isoDate = new Date(begin).toISOString()
    // dateStrs.push(moment(isoDate).format('YYYYMM'))
    // if (beginMonth != endMonth) {
    //   // 月をまたいで取得するために複数の日付をセットする
    //   const isoDate = new Date(end).toISOString()
    //   dateStrs.push(moment(isoDate).format('YYYYMM'))
    // }
    // for (let lc = 0; lc < 12; lc++) {
    //   const addDate = DateUtil.addDateStringByMonth(begin, lc)
    //   if (DateUtil.isWithinDate(addDate, begin, end)) {
    //     const isoDate = new Date(addDate).toISOString()
    //     dateStrs.push(moment(isoDate).format('YYYYMM'))
    //   } else {
    //     break
    //   }
    // }
  } else if (type == 'Weekly') {
    // subDir = '/weekly/'
    type_ = 'Daily'
    const isoDate = new Date(begin).toISOString()
    dateStrs.push(moment(isoDate).format('YYYYMM'))
    if (beginMonth != endMonth) {
      // 月をまたいで取得するために複数の日付をセットする
      const isoDate = new Date(end).toISOString()
      dateStrs.push(moment(isoDate).format('YYYYMM'))
    }
  } else if (type == 'Monthly') {
    subDir = '/'
    const bIsoDate = new Date(begin).toISOString()
    const beginStr = moment(bIsoDate).format('YYYY')
    dateStrs.push(beginStr)
    const eIsoDate = new Date(end).toISOString()
    const endStr = moment(eIsoDate).format('YYYY')
    if (beginStr != endStr) {
      dateStrs.push(endStr)
    }
  }
  // console.log(dateStrs)

  let datasets = []

  for (const dateStr of dateStrs) {
    const basePath = storagePath + subDir
    const key = basePath + 'Pms' + type_ + dateStr + '.csv'
    // const key =
    //   domain + '/' + tenant + '/' + area + '/data/Pms' + type + dateStr + '.csv'
    // const key =
    //   domain + '/' + tenant + '/' + area + '/data/PmsEvent' + dateStr + '.csv'
    logger.verbose(MODULE_TAG, 'identityId: ' + identityId)
    logger.verbose(MODULE_TAG, 'key: ' + key)
    const dataExpireSeconds = 30 * 60

    // protected(他者のファイルにアクセス)
    const csv_url = await Storage.get(key, {
      level: 'public',
      // level: 'protected',
      // level: 'private',
      identityId: identityId, // cognitoで管理されるユーザーIDに紐づく一意なID
      expires: dataExpireSeconds,
      // download: true,
    })
    logger.verbose(csv_url)

    // GET request
    const cvsResult = await axios
      .get(csv_url)
      .then(async (result) => {
        logger.verbose('=== axios.get result ===')
        logger.verbose(result)
        //// 改行で分割して、空要素を削除する。
        // const data = result.data.split(/\n/).filter(Boolean)
        // logger.verbose(result)
        const data = await csv()
          .fromString(result.data)
          .then((rows) => {
            rows = rows.filter((row) => {
              // row.date = moment(row.date).format('YYYY年MM月DD日')
              if (DateUtil.isWithinDate(row.date, begin, endDate)) {
                // console.log(row.date)
                dates.push(row.date)

                row.in_count = new Number(row.in_count)
                // if (row.in_count < 200) {  // for Debug
                //   row.in_count += 200
                // }
                row.out_count = new Number(row.out_count)
                row['in_attribute'] =
                  row.in_attribute !== undefined
                    ? new Number(row.in_attribute)
                    : new Number(row.in_count - row.no_attribute)
                row.no_attribute = new Number(row.no_attribute)
                row.male_age10 = new Number(row.male_age10)
                row.male_age20 = new Number(row.male_age20)
                row.male_age30 = new Number(row.male_age30)
                row.male_age40 = new Number(row.male_age40)
                row.male_age50 = new Number(row.male_age50)
                row.male_noage = new Number(row.male_noage)
                row.female_age10 = new Number(row.female_age10)
                row.female_age20 = new Number(row.female_age20)
                row.female_age30 = new Number(row.female_age30)
                row.female_age40 = new Number(row.female_age40)
                row.female_age50 = new Number(row.female_age50)
                row.female_noage = new Number(row.female_noage)
                row.in01 = new Number(row.in01)
                row.in02 = new Number(row.in02)
                row.in03 = new Number(row.in03)
                row.in04 = new Number(row.in04)
                row.in05 = new Number(row.in05)
                row.in06 = new Number(row.in06)
                row.in07 = new Number(row.in07)
                row.in08 = new Number(row.in08)
                row.in09 = new Number(row.in09)
                row.in10 = new Number(row.in10)
                row.out01 = new Number(row.out01)
                row.out02 = new Number(row.out02)
                row.out03 = new Number(row.out03)
                row.out04 = new Number(row.out04)
                row.out05 = new Number(row.out05)
                row.out06 = new Number(row.out06)
                row.out07 = new Number(row.out07)
                row.out08 = new Number(row.out08)
                row.out09 = new Number(row.out09)
                row.out10 = new Number(row.out10)
                row['inattr01'] =
                  row.inattr01 !== undefined
                    ? new Number(row.inattr01)
                    : new Number(0)
                row['inattr02'] =
                  row.inattr02 !== undefined
                    ? new Number(row.inattr02)
                    : new Number(0)
                row['inattr03'] =
                  row.inattr03 !== undefined
                    ? new Number(row.inattr03)
                    : new Number(0)
                row['inattr04'] =
                  row.inattr04 !== undefined
                    ? new Number(row.inattr04)
                    : new Number(0)
                row['inattr05'] =
                  row.inattr05 !== undefined
                    ? new Number(row.inattr05)
                    : new Number(0)
                row['inattr06'] =
                  row.inattr06 !== undefined
                    ? new Number(row.inattr06)
                    : new Number(0)
                row['inattr07'] =
                  row.inattr07 !== undefined
                    ? new Number(row.inattr07)
                    : new Number(0)
                row['inattr08'] =
                  row.inattr08 !== undefined
                    ? new Number(row.inattr08)
                    : new Number(0)
                row['inattr09'] =
                  row.inattr09 !== undefined
                    ? new Number(row.inattr09)
                    : new Number(0)
                row['inattr10'] =
                  row.inattr10 !== undefined
                    ? new Number(row.inattr10)
                    : new Number(0)

                // row.staying_ave = new Number(row.staying_ave)
                // row.traffic = new Number(row.traffic)

                // console.log(row)
                return row
              }
            })
            return rows
          })
        // console.log(data)
        logger.verbose(data)
        // logger.verbose(data[0]['A'])

        let retarr = []
        for (let lc = 0; lc < data.length; lc++) {
          const record = data[lc]
          if (gender == '' || ageGroup == '') {
            const newData = PeopleCount.createPeopleCount(
              record,
              areaName,
              tenantName,
            )
            retarr.push(newData)
            // retarr.push({ a: record.date })
          } else {
            const newData = PeopleCount.createPeopleCountByAttribute(
              record,
              areaName,
              tenantName,
              gender,
              ageGroup,
            )
            retarr.push(newData)
          }
        }

        return {
          response: retarr.concat(),
          // response: data.concat(),
          error: null,
        }
      })
      .catch(function (error) {
        // handle error
        logger.debug('=== axios.get error ===')
        logger.debug(key)
        // logger.error(error)
        return {
          response: null,
          error: error,
        }
      })
      .finally(function () {
        // always executed
      })
    if (!cvsResult.error) {
      datasets = datasets.concat(cvsResult.response)
    }
  } // end of for (const dateStr of dateStrs)
  // console.log('---')
  // console.log(datasets)

  return {
    indices: dates.slice(),
    datasets: datasets.slice(),
  }
}

const fetchDataFromStorage = async (
  identityId,
  {
    storagePath,
    begin,
    end,
    type,
    mode,
    areaName,
    tenantName,
    gender,
    ageGroup,
  },
) => {
  let offset = 7

  if (mode) {
    // Monthly だが、Daily で収集する場合
    offset = DateUtil.getDateCount(DateUtil.addDateStringByMonth(begin, -1))
  } else if (type == 'Hourly') {
    // TODO:
  } else if (type == 'Daily') {
    offset = 1
  } else if (type == 'Weekly') {
    offset = 7
  } else if (type == 'Monthly') {
    offset = 1
  }
  // console.log(begin)
  // console.log(end)
  // console.log(type)

  const isoDate = new Date(begin).toISOString()

  const start0 = DateUtil.addDateString(isoDate, -offset)
  const end0 = DateUtil.addDateString(isoDate, -1)
  // console.log(start0, end0)
  // const start2 = DateUtil.addDateString(isoDate, offset)
  // const end2 = DateUtil.addDateString(isoDate, offset + 6)

  const emptyDataset = { indices: [], datasets: [] }

  // GET request
  const data = [
    type == 'Monthly' || type == 'Hourly'
      ? emptyDataset
      : await _tenantCountingDatasetsByDates(identityId, {
          storagePath: storagePath,
          begin: start0,
          end: end0,
          type: type,
          areaName: areaName,
          tenantName: tenantName,
          gender: gender,
          ageGroup: ageGroup,
        }),
    await _tenantCountingDatasetsByDates(identityId, {
      storagePath: storagePath,
      begin: begin,
      end: end,
      type: type,
      areaName: areaName,
      tenantName: tenantName,
      gender: gender,
      ageGroup: ageGroup,
    }),
    // type == 'Monthly' || type == 'Hourly'
    //   ? emptyDataset
    //   : await _tenantCountingDatasetsByDates(identityId, {
    //       storagePath: storagePath,
    //       begin: start2,
    //       end: end2,
    //       type: type,
    //       areaName: areaName,
    //       tenantName: tenantName,
    //       gender: gender,
    //       ageGroup: ageGroup,
    //     }),
    {
      indices: [],
      datasets: [],
    },
  ]

  // console.log(data[0])
  // console.log(data[1])
  // console.log(data[2])

  return {
    data: data,
    error: null,
  }
}

export default {
  getDataFileList,
  getDataFiles,
  fetchDataFromStorage,
}
