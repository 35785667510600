/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      idaasUser {
        username
        sub
        email
        registered
      }
      name
      corporate
      email
      description
      activity
      contracts {
        items {
          id
          accountID
          corporate
          owner
          description
          email
          createdAt
          updatedAt
          accountContractsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      idaasUser {
        username
        sub
        email
        registered
      }
      name
      corporate
      email
      description
      activity
      contracts {
        items {
          id
          accountID
          corporate
          owner
          description
          email
          createdAt
          updatedAt
          accountContractsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      idaasUser {
        username
        sub
        email
        registered
      }
      name
      corporate
      email
      description
      activity
      contracts {
        items {
          id
          accountID
          corporate
          owner
          description
          email
          createdAt
          updatedAt
          accountContractsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createContract = /* GraphQL */ `
  mutation CreateContract(
    $input: CreateContractInput!
    $condition: ModelContractConditionInput
  ) {
    createContract(input: $input, condition: $condition) {
      id
      accountID
      corporate
      owner
      description
      email
      period {
        start
        end
      }
      users {
        items {
          id
          contractID
          name
          description
          department
          tenant
          email
          activity
          createdAt
          updatedAt
          contractUsersId
        }
        nextToken
      }
      devices {
        items {
          id
          contractID
          host
          description
          category
          location
          os
          storagePath
          topic
          clientCertFile
          activity
          createdAt
          updatedAt
          contractDevicesId
          iotDeviceTenantInfoId
        }
        nextToken
      }
      options {
        items {
          id
          key
          value
          name
          description
          contractID
          createdAt
          updatedAt
          contractOptionsId
        }
        nextToken
      }
      createdAt
      updatedAt
      accountContractsId
    }
  }
`;
export const updateContract = /* GraphQL */ `
  mutation UpdateContract(
    $input: UpdateContractInput!
    $condition: ModelContractConditionInput
  ) {
    updateContract(input: $input, condition: $condition) {
      id
      accountID
      corporate
      owner
      description
      email
      period {
        start
        end
      }
      users {
        items {
          id
          contractID
          name
          description
          department
          tenant
          email
          activity
          createdAt
          updatedAt
          contractUsersId
        }
        nextToken
      }
      devices {
        items {
          id
          contractID
          host
          description
          category
          location
          os
          storagePath
          topic
          clientCertFile
          activity
          createdAt
          updatedAt
          contractDevicesId
          iotDeviceTenantInfoId
        }
        nextToken
      }
      options {
        items {
          id
          key
          value
          name
          description
          contractID
          createdAt
          updatedAt
          contractOptionsId
        }
        nextToken
      }
      createdAt
      updatedAt
      accountContractsId
    }
  }
`;
export const deleteContract = /* GraphQL */ `
  mutation DeleteContract(
    $input: DeleteContractInput!
    $condition: ModelContractConditionInput
  ) {
    deleteContract(input: $input, condition: $condition) {
      id
      accountID
      corporate
      owner
      description
      email
      period {
        start
        end
      }
      users {
        items {
          id
          contractID
          name
          description
          department
          tenant
          email
          activity
          createdAt
          updatedAt
          contractUsersId
        }
        nextToken
      }
      devices {
        items {
          id
          contractID
          host
          description
          category
          location
          os
          storagePath
          topic
          clientCertFile
          activity
          createdAt
          updatedAt
          contractDevicesId
          iotDeviceTenantInfoId
        }
        nextToken
      }
      options {
        items {
          id
          key
          value
          name
          description
          contractID
          createdAt
          updatedAt
          contractOptionsId
        }
        nextToken
      }
      createdAt
      updatedAt
      accountContractsId
    }
  }
`;
export const createAppUser = /* GraphQL */ `
  mutation CreateAppUser(
    $input: CreateAppUserInput!
    $condition: ModelAppUserConditionInput
  ) {
    createAppUser(input: $input, condition: $condition) {
      id
      contractID
      name
      idaasUser {
        username
        sub
        email
        registered
      }
      description
      department
      tenant
      email
      period {
        start
        end
      }
      activity
      contract {
        id
        accountID
        corporate
        owner
        description
        email
        period {
          start
          end
        }
        users {
          nextToken
        }
        devices {
          nextToken
        }
        options {
          nextToken
        }
        createdAt
        updatedAt
        accountContractsId
      }
      createdAt
      updatedAt
      contractUsersId
    }
  }
`;
export const updateAppUser = /* GraphQL */ `
  mutation UpdateAppUser(
    $input: UpdateAppUserInput!
    $condition: ModelAppUserConditionInput
  ) {
    updateAppUser(input: $input, condition: $condition) {
      id
      contractID
      name
      idaasUser {
        username
        sub
        email
        registered
      }
      description
      department
      tenant
      email
      period {
        start
        end
      }
      activity
      contract {
        id
        accountID
        corporate
        owner
        description
        email
        period {
          start
          end
        }
        users {
          nextToken
        }
        devices {
          nextToken
        }
        options {
          nextToken
        }
        createdAt
        updatedAt
        accountContractsId
      }
      createdAt
      updatedAt
      contractUsersId
    }
  }
`;
export const deleteAppUser = /* GraphQL */ `
  mutation DeleteAppUser(
    $input: DeleteAppUserInput!
    $condition: ModelAppUserConditionInput
  ) {
    deleteAppUser(input: $input, condition: $condition) {
      id
      contractID
      name
      idaasUser {
        username
        sub
        email
        registered
      }
      description
      department
      tenant
      email
      period {
        start
        end
      }
      activity
      contract {
        id
        accountID
        corporate
        owner
        description
        email
        period {
          start
          end
        }
        users {
          nextToken
        }
        devices {
          nextToken
        }
        options {
          nextToken
        }
        createdAt
        updatedAt
        accountContractsId
      }
      createdAt
      updatedAt
      contractUsersId
    }
  }
`;
export const createIotDevice = /* GraphQL */ `
  mutation CreateIotDevice(
    $input: CreateIotDeviceInput!
    $condition: ModelIotDeviceConditionInput
  ) {
    createIotDevice(input: $input, condition: $condition) {
      id
      contractID
      host
      thing {
        thingName
        thingArn
        attributes {
          model
        }
        version
        thingId
        defaultClientId
        thingTypeName
      }
      description
      category
      location
      os
      storagePath
      topic
      clientCertFile
      period {
        start
        end
      }
      activity
      tenantInfo {
        id
        name
        category
        location
        description
        businessHours {
          begin
          open
          close
        }
        businessDay {
          begin
          days
        }
        holiday
        iotDeviceID
        iotDevice {
          id
          contractID
          host
          description
          category
          location
          os
          storagePath
          topic
          clientCertFile
          activity
          createdAt
          updatedAt
          contractDevicesId
          iotDeviceTenantInfoId
        }
        createdAt
        updatedAt
        tenantInformationIotDeviceId
      }
      promotions {
        items {
          id
          name
          category
          location
          description
          iotDeviceID
          createdAt
          updatedAt
          iotDevicePromotionsId
        }
        nextToken
      }
      contract {
        id
        accountID
        corporate
        owner
        description
        email
        period {
          start
          end
        }
        users {
          nextToken
        }
        devices {
          nextToken
        }
        options {
          nextToken
        }
        createdAt
        updatedAt
        accountContractsId
      }
      createdAt
      updatedAt
      contractDevicesId
      iotDeviceTenantInfoId
    }
  }
`;
export const updateIotDevice = /* GraphQL */ `
  mutation UpdateIotDevice(
    $input: UpdateIotDeviceInput!
    $condition: ModelIotDeviceConditionInput
  ) {
    updateIotDevice(input: $input, condition: $condition) {
      id
      contractID
      host
      thing {
        thingName
        thingArn
        attributes {
          model
        }
        version
        thingId
        defaultClientId
        thingTypeName
      }
      description
      category
      location
      os
      storagePath
      topic
      clientCertFile
      period {
        start
        end
      }
      activity
      tenantInfo {
        id
        name
        category
        location
        description
        businessHours {
          begin
          open
          close
        }
        businessDay {
          begin
          days
        }
        holiday
        iotDeviceID
        iotDevice {
          id
          contractID
          host
          description
          category
          location
          os
          storagePath
          topic
          clientCertFile
          activity
          createdAt
          updatedAt
          contractDevicesId
          iotDeviceTenantInfoId
        }
        createdAt
        updatedAt
        tenantInformationIotDeviceId
      }
      promotions {
        items {
          id
          name
          category
          location
          description
          iotDeviceID
          createdAt
          updatedAt
          iotDevicePromotionsId
        }
        nextToken
      }
      contract {
        id
        accountID
        corporate
        owner
        description
        email
        period {
          start
          end
        }
        users {
          nextToken
        }
        devices {
          nextToken
        }
        options {
          nextToken
        }
        createdAt
        updatedAt
        accountContractsId
      }
      createdAt
      updatedAt
      contractDevicesId
      iotDeviceTenantInfoId
    }
  }
`;
export const deleteIotDevice = /* GraphQL */ `
  mutation DeleteIotDevice(
    $input: DeleteIotDeviceInput!
    $condition: ModelIotDeviceConditionInput
  ) {
    deleteIotDevice(input: $input, condition: $condition) {
      id
      contractID
      host
      thing {
        thingName
        thingArn
        attributes {
          model
        }
        version
        thingId
        defaultClientId
        thingTypeName
      }
      description
      category
      location
      os
      storagePath
      topic
      clientCertFile
      period {
        start
        end
      }
      activity
      tenantInfo {
        id
        name
        category
        location
        description
        businessHours {
          begin
          open
          close
        }
        businessDay {
          begin
          days
        }
        holiday
        iotDeviceID
        iotDevice {
          id
          contractID
          host
          description
          category
          location
          os
          storagePath
          topic
          clientCertFile
          activity
          createdAt
          updatedAt
          contractDevicesId
          iotDeviceTenantInfoId
        }
        createdAt
        updatedAt
        tenantInformationIotDeviceId
      }
      promotions {
        items {
          id
          name
          category
          location
          description
          iotDeviceID
          createdAt
          updatedAt
          iotDevicePromotionsId
        }
        nextToken
      }
      contract {
        id
        accountID
        corporate
        owner
        description
        email
        period {
          start
          end
        }
        users {
          nextToken
        }
        devices {
          nextToken
        }
        options {
          nextToken
        }
        createdAt
        updatedAt
        accountContractsId
      }
      createdAt
      updatedAt
      contractDevicesId
      iotDeviceTenantInfoId
    }
  }
`;
export const createServiceOption = /* GraphQL */ `
  mutation CreateServiceOption(
    $input: CreateServiceOptionInput!
    $condition: ModelServiceOptionConditionInput
  ) {
    createServiceOption(input: $input, condition: $condition) {
      id
      key
      value
      name
      description
      contractID
      contract {
        id
        accountID
        corporate
        owner
        description
        email
        period {
          start
          end
        }
        users {
          nextToken
        }
        devices {
          nextToken
        }
        options {
          nextToken
        }
        createdAt
        updatedAt
        accountContractsId
      }
      createdAt
      updatedAt
      contractOptionsId
    }
  }
`;
export const updateServiceOption = /* GraphQL */ `
  mutation UpdateServiceOption(
    $input: UpdateServiceOptionInput!
    $condition: ModelServiceOptionConditionInput
  ) {
    updateServiceOption(input: $input, condition: $condition) {
      id
      key
      value
      name
      description
      contractID
      contract {
        id
        accountID
        corporate
        owner
        description
        email
        period {
          start
          end
        }
        users {
          nextToken
        }
        devices {
          nextToken
        }
        options {
          nextToken
        }
        createdAt
        updatedAt
        accountContractsId
      }
      createdAt
      updatedAt
      contractOptionsId
    }
  }
`;
export const deleteServiceOption = /* GraphQL */ `
  mutation DeleteServiceOption(
    $input: DeleteServiceOptionInput!
    $condition: ModelServiceOptionConditionInput
  ) {
    deleteServiceOption(input: $input, condition: $condition) {
      id
      key
      value
      name
      description
      contractID
      contract {
        id
        accountID
        corporate
        owner
        description
        email
        period {
          start
          end
        }
        users {
          nextToken
        }
        devices {
          nextToken
        }
        options {
          nextToken
        }
        createdAt
        updatedAt
        accountContractsId
      }
      createdAt
      updatedAt
      contractOptionsId
    }
  }
`;
export const createTenantInformation = /* GraphQL */ `
  mutation CreateTenantInformation(
    $input: CreateTenantInformationInput!
    $condition: ModelTenantInformationConditionInput
  ) {
    createTenantInformation(input: $input, condition: $condition) {
      id
      name
      category
      location
      description
      businessHours {
        begin
        open
        close
      }
      businessDay {
        begin
        days
      }
      holiday
      iotDeviceID
      iotDevice {
        id
        contractID
        host
        thing {
          thingName
          thingArn
          version
          thingId
          defaultClientId
          thingTypeName
        }
        description
        category
        location
        os
        storagePath
        topic
        clientCertFile
        period {
          start
          end
        }
        activity
        tenantInfo {
          id
          name
          category
          location
          description
          holiday
          iotDeviceID
          createdAt
          updatedAt
          tenantInformationIotDeviceId
        }
        promotions {
          nextToken
        }
        contract {
          id
          accountID
          corporate
          owner
          description
          email
          createdAt
          updatedAt
          accountContractsId
        }
        createdAt
        updatedAt
        contractDevicesId
        iotDeviceTenantInfoId
      }
      createdAt
      updatedAt
      tenantInformationIotDeviceId
    }
  }
`;
export const updateTenantInformation = /* GraphQL */ `
  mutation UpdateTenantInformation(
    $input: UpdateTenantInformationInput!
    $condition: ModelTenantInformationConditionInput
  ) {
    updateTenantInformation(input: $input, condition: $condition) {
      id
      name
      category
      location
      description
      businessHours {
        begin
        open
        close
      }
      businessDay {
        begin
        days
      }
      holiday
      iotDeviceID
      iotDevice {
        id
        contractID
        host
        thing {
          thingName
          thingArn
          version
          thingId
          defaultClientId
          thingTypeName
        }
        description
        category
        location
        os
        storagePath
        topic
        clientCertFile
        period {
          start
          end
        }
        activity
        tenantInfo {
          id
          name
          category
          location
          description
          holiday
          iotDeviceID
          createdAt
          updatedAt
          tenantInformationIotDeviceId
        }
        promotions {
          nextToken
        }
        contract {
          id
          accountID
          corporate
          owner
          description
          email
          createdAt
          updatedAt
          accountContractsId
        }
        createdAt
        updatedAt
        contractDevicesId
        iotDeviceTenantInfoId
      }
      createdAt
      updatedAt
      tenantInformationIotDeviceId
    }
  }
`;
export const deleteTenantInformation = /* GraphQL */ `
  mutation DeleteTenantInformation(
    $input: DeleteTenantInformationInput!
    $condition: ModelTenantInformationConditionInput
  ) {
    deleteTenantInformation(input: $input, condition: $condition) {
      id
      name
      category
      location
      description
      businessHours {
        begin
        open
        close
      }
      businessDay {
        begin
        days
      }
      holiday
      iotDeviceID
      iotDevice {
        id
        contractID
        host
        thing {
          thingName
          thingArn
          version
          thingId
          defaultClientId
          thingTypeName
        }
        description
        category
        location
        os
        storagePath
        topic
        clientCertFile
        period {
          start
          end
        }
        activity
        tenantInfo {
          id
          name
          category
          location
          description
          holiday
          iotDeviceID
          createdAt
          updatedAt
          tenantInformationIotDeviceId
        }
        promotions {
          nextToken
        }
        contract {
          id
          accountID
          corporate
          owner
          description
          email
          createdAt
          updatedAt
          accountContractsId
        }
        createdAt
        updatedAt
        contractDevicesId
        iotDeviceTenantInfoId
      }
      createdAt
      updatedAt
      tenantInformationIotDeviceId
    }
  }
`;
export const createPromotion = /* GraphQL */ `
  mutation CreatePromotion(
    $input: CreatePromotionInput!
    $condition: ModelPromotionConditionInput
  ) {
    createPromotion(input: $input, condition: $condition) {
      id
      name
      period {
        start
        end
      }
      category
      location
      description
      iotDeviceID
      iotDevice {
        id
        contractID
        host
        thing {
          thingName
          thingArn
          version
          thingId
          defaultClientId
          thingTypeName
        }
        description
        category
        location
        os
        storagePath
        topic
        clientCertFile
        period {
          start
          end
        }
        activity
        tenantInfo {
          id
          name
          category
          location
          description
          holiday
          iotDeviceID
          createdAt
          updatedAt
          tenantInformationIotDeviceId
        }
        promotions {
          nextToken
        }
        contract {
          id
          accountID
          corporate
          owner
          description
          email
          createdAt
          updatedAt
          accountContractsId
        }
        createdAt
        updatedAt
        contractDevicesId
        iotDeviceTenantInfoId
      }
      createdAt
      updatedAt
      iotDevicePromotionsId
    }
  }
`;
export const updatePromotion = /* GraphQL */ `
  mutation UpdatePromotion(
    $input: UpdatePromotionInput!
    $condition: ModelPromotionConditionInput
  ) {
    updatePromotion(input: $input, condition: $condition) {
      id
      name
      period {
        start
        end
      }
      category
      location
      description
      iotDeviceID
      iotDevice {
        id
        contractID
        host
        thing {
          thingName
          thingArn
          version
          thingId
          defaultClientId
          thingTypeName
        }
        description
        category
        location
        os
        storagePath
        topic
        clientCertFile
        period {
          start
          end
        }
        activity
        tenantInfo {
          id
          name
          category
          location
          description
          holiday
          iotDeviceID
          createdAt
          updatedAt
          tenantInformationIotDeviceId
        }
        promotions {
          nextToken
        }
        contract {
          id
          accountID
          corporate
          owner
          description
          email
          createdAt
          updatedAt
          accountContractsId
        }
        createdAt
        updatedAt
        contractDevicesId
        iotDeviceTenantInfoId
      }
      createdAt
      updatedAt
      iotDevicePromotionsId
    }
  }
`;
export const deletePromotion = /* GraphQL */ `
  mutation DeletePromotion(
    $input: DeletePromotionInput!
    $condition: ModelPromotionConditionInput
  ) {
    deletePromotion(input: $input, condition: $condition) {
      id
      name
      period {
        start
        end
      }
      category
      location
      description
      iotDeviceID
      iotDevice {
        id
        contractID
        host
        thing {
          thingName
          thingArn
          version
          thingId
          defaultClientId
          thingTypeName
        }
        description
        category
        location
        os
        storagePath
        topic
        clientCertFile
        period {
          start
          end
        }
        activity
        tenantInfo {
          id
          name
          category
          location
          description
          holiday
          iotDeviceID
          createdAt
          updatedAt
          tenantInformationIotDeviceId
        }
        promotions {
          nextToken
        }
        contract {
          id
          accountID
          corporate
          owner
          description
          email
          createdAt
          updatedAt
          accountContractsId
        }
        createdAt
        updatedAt
        contractDevicesId
        iotDeviceTenantInfoId
      }
      createdAt
      updatedAt
      iotDevicePromotionsId
    }
  }
`;
