import { API } from 'aws-amplify'

import { listServiceOptions } from '@/graphql/queries'
import { createServiceOption } from '@/graphql/mutations'
import { updateServiceOption } from '@/graphql/mutations'
import { deleteServiceOption } from '@/graphql/mutations'

// import ServiceOption from '@/domain/models/ServiceOption'

import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'ServiceOptionRepository'

const createNewServiceOption = async ({ contractId, optionProfile }) => {
  const TAG = `${MODULE_TAG}.${createNewServiceOption.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  let inputData = {
    key: optionProfile.key,
    value: optionProfile.limit,
    name: optionProfile.name,
    description: optionProfile.description,
    contractID: contractId,
  }
  logger.verbose(Logger.formatter(TAG, `inputData:`))
  logger.verbose(inputData)

  const response = await API.graphql({
    query: createServiceOption,
    variables: { input: inputData },
  })
    .then(async (result) => {
      logger.verbose('=== createServiceOption result ===')
      logger.verbose(result)
      return {
        data: result.data.createServiceOption,
        error: null,
      }
    })
    .catch((error) => {
      logger.error('=== createServiceOption error ===')
      logger.error(error)
      return {
        data: null,
        error: error,
      }
    })
  if (!response) {
    return {
      data: null,
      error: new Error('no response!'),
    }
  }
  logger.verbose(Logger.formatter(TAG, `response:`))
  logger.verbose(response)

  return response
}

const updateServiceOptionProfile = async ({ contractId, optionProfile }) => {
  const TAG = `${MODULE_TAG}.${updateServiceOptionProfile.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  let inputData = {
    id: optionProfile.id,
    key: optionProfile.key,
    value: Number(optionProfile.limit),
    name: optionProfile.name,
    description: optionProfile.description,
    contractID: contractId,
  }
  logger.verbose(Logger.formatter(TAG, `inputData:`))
  logger.verbose(inputData)

  const response = await API.graphql({
    query: updateServiceOption,
    variables: { input: inputData },
  })
    .then(async (result) => {
      logger.verbose('=== updateServiceOption result ===')
      logger.verbose(result)
      return {
        data: result.data.updateServiceOption,
        error: null,
      }
    })
    .catch((error) => {
      logger.error('=== updateServiceOption error ===')
      logger.error(error)
      return {
        data: null,
        error: error,
      }
    })
  if (!response) {
    return {
      data: null,
      error: new Error('no response!'),
    }
  }

  return response
}

const fetchServiceOptionsList = async (contractId) => {
  const TAG = `${MODULE_TAG}.${fetchServiceOptionsList.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const response = await API.graphql({
    query: listServiceOptions,
    // limit: 50,
  })
    .then((result) => {
      logger.verbose('=== listServiceOptions result ===')
      logger.verbose(result)
      return {
        data: result.data.listServiceOptions.items,
        error: null,
      }
    })
    .catch((error) => {
      logger.error('=== listServiceOptions error ===')
      logger.error(error)
      return {
        data: [],
        error: error,
      }
    })
  if (!response) {
    return {
      data: [],
      error: new Error('no response!'),
    }
  }

  let items = []
  response.data.forEach((fetchItem) => {
    if (fetchItem.contractID == contractId) {
      items.push(fetchItem)
    }
  })
  response.data = items

  return response
}

const deleteResourceById = async (deleteId) => {
  const TAG = `${MODULE_TAG}.${deleteResourceById.name}`
  logger.verbose(Logger.formatter(TAG, `begin with deleteId: ${deleteId}`))

  if (deleteId == null || deleteId == '' || typeof deleteId === undefined)
    return new Error('deleteId is empty!')

  const response = await API.graphql({
    query: deleteServiceOption,
    variables: { input: { id: deleteId } },
  })
    .then((result) => {
      logger.verbose('=== deleteServiceOption result ===')
      logger.verbose(result)
      return {
        data: result.data.deleteServiceOption,
        error: null,
      }
    })
    .catch((error) => {
      logger.error('=== deleteServiceOption error ===')
      logger.error(error)
      return {
        data: null,
        error: error,
      }
    })
  if (!response) {
    return {
      data: null,
      error: new Error('no response!'),
    }
  }

  return response
}

const deleteResourceByKey = async (contractId, keyName) => {
  const TAG = `${MODULE_TAG}.${deleteResourceById.name}`

  if (!contractId)
    return {
      data: null,
      error: new Error('contractId is empty!'),
    }

  if (!keyName)
    return {
      data: null,
      error: new Error('keyName is empty!'),
    }

  logger.verbose(
    Logger.formatter(TAG, `begin with deleteId: ${keyName} in ${contractId}`),
  )

  let deleteId = null

  const res = await fetchServiceOptionsList(contractId)
  if (res.error) {
    return {
      data: null,
      error: res.error,
    }
  }
  res.data.forEach((fetchItem) => {
    if (fetchItem.contractID == contractId) {
      deleteId = fetchItem.id
    }
  })
  if (!deleteId) {
    return {
      data: null,
      error: new Error('not found service option!'),
    }
  }

  const response = await API.graphql({
    query: deleteServiceOption,
    variables: { input: { id: deleteId } },
  })
    .then((result) => {
      logger.verbose('=== deleteServiceOption result ===')
      logger.verbose(result)
      return {
        data: result.data.deleteServiceOption,
        error: null,
      }
    })
    .catch((error) => {
      logger.error('=== deleteServiceOption error ===')
      logger.error(error)
      return {
        data: null,
        error: error,
      }
    })
  if (!response) {
    return {
      data: null,
      error: new Error('no response!'),
    }
  }

  return response
}

export default {
  createNewServiceOption,
  updateServiceOptionProfile,
  fetchServiceOptionsList,
  deleteResourceById,
  deleteResourceByKey,
}
