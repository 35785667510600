import AccountInteractor from '@/domain/usecases/AccountInteractor'

import StoreRepository from '@/adapters/StoreRepository'

import ContractController from './ContractController'

import DateUtil from '@/utils/DateUtil'
import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'AccountController'

const getAccountList = async (context) => {
  const TAG = `${MODULE_TAG}.${getAccountList.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const cwlogger = await Logger.getInstance(context.getters.getCurrentUsername)
  cwlogger?.verbose(Logger.formatter(TAG, `begin`))

  const input = {}

  const output = await AccountInteractor.getAccountList(input)

  // TODO: アカウント一覧のストアが用意された場合は、ここでストアに保存する。
}

const resetPassword = async (context, { username, password }) => {
  const TAG = `${MODULE_TAG}.${resetPassword.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  if (!username || !password) {
    return
  }

  const input = { username, password }

  const output = await AccountInteractor.resetPassword(input)
}

const enableAccount = async (context, { account, enable }) => {
  const TAG = `${MODULE_TAG}.${enableAccount.name}`
  logger.verbose(Logger.formatter(TAG, `begin, enable: ${enable}`))

  if (!account || enable == null || typeof enable === undefined) {
    alert('不正な操作です')
    return
  }
  const input = { username: account.username, enable: enable }

  const output = await AccountInteractor.enableAccount(input)

  // 画面更新
  // TODO: アカウント一覧のストアが用意された場合は、ここでストアに保存する。
  // TODO: output を受けてストアの更新処理のみに置き換える
  ContractController.getContractList(context)
  // ContractRepository.fetchContractListWithIdaasUser(context)
}

const updateOwnActivity = async (context) => {
  const TAG = `${MODULE_TAG}.${enableAccount.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  // 現在ログインしているアカウントの情報を取得する
  const accountId = context.state.user.attributes.sub

  const date = new Date(Date.now())
  const loginDate = DateUtil.formatDate(date, 'YYYY-MM-DD HH:mm:ss')

  const input = { accountId: accountId, loginDate: loginDate }

  const output = await AccountInteractor.updateActivity(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, output.error))
    // alert(output.error)
    return
  }
  const dataResult = output.result
  logger.verbose(
    `id: ${dataResult.idaasUser.sub}, username: ${dataResult.idaasUser.username}, activity:${dataResult.activity}`,
  )

  let item = context.state.contract
  // 既にあるユーザー情報に上書きする
  item.activity = dataResult.activity
  // 画面更新
  context.commit('setContract', item)
}

export default {
  getAccountList,
  resetPassword,
  enableAccount,
  updateOwnActivity,
}
