import AppUserInteractor from '@/domain/usecases/AppUserInteractor'

import AppUserPresenter from '@/adapters/AppUserPresenter'

import StoreRepository from '@/adapters/StoreRepository'

import ServiceController from '@/adapters/ServiceController'

import DateUtil from '@/utils/DateUtil'
import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'AppUserController'

const getAppUsersList = async (context) => {
  const TAG = `${MODULE_TAG}.${getAppUsersList.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const cwlogger = await Logger.getInstance(context.getters.getCurrentUsername)
  cwlogger?.verbose(`begin ${TAG}`)

  const input = {
    contractId: context.state.contract.id,
  }
  logger.verbose('contractId: ' + input.contractId)

  // ユーザー一覧の取得
  const output = await AppUserInteractor.getAppUsersList(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, `getAppUsersList error`))
    logger.error(output.error)
    return
  }

  const fetchUserItems = output.result.userItems
  const contractId = input.contractId
  // ストアの更新
  StoreRepository.updateAppUserItems(context, { contractId, fetchUserItems })

  let userItems = context.state.userItems
  logger.verbose(userItems)
  let match = false
  let already = false

  output.result.idaasUsers.forEach(function (user, index) {
    match = false
    already = false

    userItems.forEach((item) => {
      if (item.user.contractID === input.contractId) {
        // 法人アカウントに属するユーザー情報
        match = true
      }
      if (item.user.username === user.Username) {
        // ストアの状態を更新する
        already = true
        // item.id = ''
        item.user.sub = user.Attributes[0].Value
        // Cognito のアカウントステータスを更新する
        item.user.enabled = user.Enabled
        item.user.status = user.UserStatus
      }
    })
  })
  // userItems = sampledata.userItems
  context.commit('setUserItems', userItems)

  // オプションの更新
  await ServiceController.fetchServiceOptionList(context)
  // アラートグループの更新
  // await ServiceController.fetchAlertTopicList(context)
}

const createLoginUser = async (context, { username, password, email }) => {
  const TAG = `${MODULE_TAG}.${createLoginUser.name}`
  logger.verbose(Logger.formatter(TAG, `begin with `, username, email))
  // logger.verbose('call createLoginUser: ', username, email)

  if (!username || !password) {
    return
  }

  const contractId = context.state.contract.id

  if (!contractId) {
    const error = '契約ＩＤが存在しません'
    logger.error(Logger.formatter(TAG, error))
    alert('error')
    return
  }

  let index = -1
  const userItems = context.state.userItems
  userItems.find((user, idx) => {
    if (user.user.username == username) {
      index = idx
      return true
    } else {
      return false
    }
  })
  if (0 < index) {
    const error = '既に存在するユーザーＩＤです'
    logger.error(Logger.formatter(TAG, 'index:' + index + ', ' + error))
    alert(error)
    return
  }

  const input = { username, password, email, contractId }

  // ログインユーザーの新規作成
  const output = await AppUserInteractor.createUser(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, `createUser error`))
    logger.error(output.error)
    alert(output.error)
    return
  }

  const inputData = output.result
  const appUser = AppUserPresenter.convertNewDbDataToStore(inputData)
  // ストアに新しいユーザー情報として追加する ~ 画面更新
  StoreRepository.addAppUser(context, { appUser })

  return
}

const editUserProfile = async (
  context,
  { username, name, department, tenant, description },
) => {
  const TAG = `${MODULE_TAG}.${editUserProfile.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const userItems = context.state.userItems
  let loginUser = null
  let index = -1
  userItems.forEach((item, idx) => {
    if (item.user.username === username) {
      // 法人アカウントに属するユーザー情報の抽出
      loginUser = item
      index = idx
    }
  })
  if (index < 0) {
    const error = '編集するユーザーは存在しません'
    logger.error(Logger.formatter(TAG, error))
    return
  }

  const input = {
    contractId: context.state.contract.id,
    sub: loginUser.user.sub,
    username: loginUser.user.username,
    registered: loginUser.user.registered,
    name: name,
    department: department,
    tenant: tenant,
    description: description,
  }

  const output = await AppUserInteractor.editUserProfile(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, output.error))
    alert(output.error)
    return
  }
  const dataResult = output.result
  logger.verbose('id: ' + dataResult.id)

  let appUser = context.state.userItems[index]
  // 既にあるユーザー情報に上書きする
  appUser.id = dataResult.id
  appUser.user.username = dataResult.idaasUser.username
  appUser.name = dataResult.name
  appUser.department = dataResult.department
  appUser.tenant = dataResult.tenant
  appUser.description = dataResult.description
  // 画面更新
  StoreRepository.updateAppUser(context, { appUser, index })

  // ユーザー情報更新
  await getAppUsersList(context)
}

const updateUserActivity = async (context, { username }) => {
  const TAG = `${MODULE_TAG}.${updateUserActivity.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const userItems = context.state.userItems
  let loginUser = null
  let index = -1
  userItems.forEach((item, idx) => {
    if (item.user.username === username) {
      // 法人アカウントに属するユーザー情報
      loginUser = item
      index = idx
    }
  })
  if (index < 0) {
    const warn = 'アクティビティーを更新するユーザーは存在しません'
    logger.warn(Logger.formatter(TAG, warn))
    return
  }

  const date = new Date(Date.now())
  const loginDate = DateUtil.formatDate(date, 'YYYY-MM-DD HH:mm:ss')

  const input = {
    userId: loginUser.user.sub,
    loginDate: loginDate,
  }

  const output = await AppUserInteractor.updateUserActivity(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, output.error))
    // alert(output.error)
    return
  }
  const dataResult = output.result
  logger.verbose(
    `id: ${dataResult.id}, username: ${dataResult.username}, activity:${dataResult.activity}`,
  )

  // 画面更新
  let appUser = context.state.userItems[index]
  // 既にあるユーザー情報に上書きする
  appUser.activity = dataResult.activity
  // 画面更新
  StoreRepository.updateAppUser(context, { appUser, index })
}

const enableUser = async (context, { user, enable }) => {
  const TAG = `${MODULE_TAG}.${enableUser.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const input = {
    username: user.username,
    enable: enable,
  }

  const output = await AppUserInteractor.enableUser(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, output.error))
    alert(output.error)
    return
  }
  logger.verbose(output.result)

  // 画面更新
  await getAppUsersList(context)
}

const deleteAppUserController = async (context, { username, deleteId }) => {
  const TAG = `${MODULE_TAG}.${deleteAppUserController.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const input = {
    username: username,
    deleteId: deleteId,
  }

  const output = await AppUserInteractor.deleteAppUser(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, output.error))
    alert(output.error)
    return
  }
  logger.verbose(output.result)

  // ストアからユーザー情報を削除する ~ 画面更新
  StoreRepository.deleteAppUser(context, { username })
}

export default {
  getAppUsersList,
  createLoginUser,
  editUserProfile,
  updateUserActivity,
  enableUser,
  deleteAppUserController,
}
