import IotDeviceInteractor from '@/domain/usecases/IotDeviceInteractor'

import DevicePresenter from '@/adapters/DevicePresenter'
import StoreRepository from '@/adapters/StoreRepository'

// import sampledata from '@/adapters/sampledata'

import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'DeviceController'

// デバイス一覧の取得
const fetchDeviceList = async (context) => {
  const TAG = `${MODULE_TAG}.${fetchDeviceList.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const cwlogger = await Logger.getInstance(context.getters.getCurrentUsername)
  cwlogger?.verbose(`begin ${TAG}`)

  const input = {
    // 現在ログインしているアカウントの契約情報を取得する
    contractId: context.state.contract.id,
  }
  logger.verbose('contractId: ' + input.contractId)

  // デバイス一覧の取得
  const output = await IotDeviceInteractor.getIotDevicesList(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, `getIotDevicesList error`))
    logger.error(output.error)
    return
  }

  // const fetchDeviceItems = sampledata.deviceItems // サンプルデータから本番データに置き換える
  const fetchDeviceItems = DevicePresenter.convertDbDataToStore(output.result)
  const contractId = input.contractId
  // ストアの更新
  StoreRepository.updateDeviceItems(context, {
    contractId,
    devices: fetchDeviceItems,
  })

  // オプションの更新
  // await ServiceController.fetchServiceOptionList(context)
}

// デバイス登録
const createDevice = async (context, { deviceName, storagePath, hostName }) => {
  const TAG = `${MODULE_TAG}.${createDevice.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  // 現在ログインしているアカウントの契約情報を取得する
  const contractId = context.state.contract.id
  if (!contractId) {
    const error = '契約ＩＤが存在しません'
    logger.error(Logger.formatter(TAG, error))
    alert('error')
    return
  }

  let index = -1
  const deviceItems = context.state.deviceItems
  deviceItems.find((item, idx) => {
    if (!item.thing) {
      return false
    } else if (item.thing.name == deviceName) {
      index = idx
      return true
    } else {
      return false
    }
  })
  // 重複登録の抑制
  if (0 < index) {
    const error = '既に存在するモノの名前です'
    logger.error(
      Logger.formatter(
        TAG,
        'index:' + index + ', device name:' + deviceName + ', ' + error,
      ),
    )
    alert(error)
    return
  }

  const os = 'ubuntu'
  // IoTデバイスの新規作成
  const input = { deviceName, storagePath, hostName, os, contractId }

  // AppSyncにカテゴリ作成リクエストを送る
  const output = await IotDeviceInteractor.createIotDevice(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, `createIotDevice error`))
    logger.error(output.error)
    alert(output.error)
    return
  }
  const inputData = output.result
  const newDeviceItem = DevicePresenter.convertNewDbDataToStore(inputData)
  logger.verbose('=== create new device! ===')
  logger.verbose(newDeviceItem)

  // ストアに新しいユーザー情報として追加する ~ 画面更新
  StoreRepository.addDevice(context, { newDeviceItem })
  return
}

// デバイス詳細情報の編集
const editDeviceProfile = async (
  context,
  { deviceName, storagePath, hostName, category, location, description },
) => {
  const TAG = `${MODULE_TAG}.${editDeviceProfile.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const deviceItems = context.state.deviceItems
  let iotDevice = null
  let index = -1
  deviceItems.forEach((item, idx) => {
    if (item.thing.thingName === deviceName) {
      // 法人アカウントに属するデバイス情報の抽出
      iotDevice = item
      index = idx
    }
  })
  if (index < 0) {
    const error = '編集するPC ' + deviceName + ' は存在しません'
    logger.error(Logger.formatter(TAG, error))
    return
  }

  const os = 'ubuntu'
  const input = {
    id: iotDevice.id,
    deviceName: deviceName,
    storagePath: storagePath,
    hostName: hostName,
    os: os,
    category: category,
    location: location,
    description: description,
    contractId: context.state.contract.id,
  }
  logger.verbose(Logger.formatter(TAG, `input`))
  logger.verbose(input)

  const output = await IotDeviceInteractor.editIotDeviceProfile(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, output.error))
    alert(output.error)
    return
  }
  const dataResult = output.result
  logger.verbose('edit id: ' + dataResult.id)

  let device = context.state.deviceItems[index]
  // 既にあるデバイス情報に上書きする
  device.thing.thingName = dataResult.thing.thingName
  device.host = dataResult.host
  device.storagePath = dataResult.storagePath
  device.os = dataResult.os
  device.category = dataResult.category
  device.location = dataResult.location
  device.description = dataResult.description
  /// const newDeviceItem = DevicePresenter.convertDbDataToStore(inputData)
  // 画面更新
  StoreRepository.updateDevice(context, { device, index })

  // デバイス情報更新
  await fetchDeviceList(context)
}

// デバイスの登録解除
const deleteDevice = async (context, { deviceName }) => {
  const TAG = `${MODULE_TAG}.${deleteDevice.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  // 現在ログインしているアカウントの契約IDを取得する
  const contractId = context.state.contract.id
  // 削除するデバイスの名前
  const deleteDeviceName = deviceName

  const deviceItems = context.state.deviceItems
  let iotDevice = null
  let index = -1
  deviceItems.forEach((item, idx) => {
    if (item.thing.thingName === deviceName) {
      // 法人アカウントに属するデバイス情報の抽出
      iotDevice = item
      index = idx
    }
  })
  if (index < 0) {
    const error = '削除するPC ' + deviceName + ' は存在しません'
    logger.error(Logger.formatter(TAG, error))
    return
  }

  const input = {
    deleteId: iotDevice.id,
    deviceName: iotDevice.thing.thingName,
  }
  // AppSyncにデバイス削除のリクエストを送る
  const output = await IotDeviceInteractor.deleteIotDevice(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, output.error))
    alert(output.error)
    return
  }
  const dataResult = output.result
  logger.verbose('delete id: ' + dataResult.id)

  StoreRepository.deleteDevice(context, { contractId, deleteDeviceName })
}

export default {
  fetchDeviceList,
  createDevice,
  editDeviceProfile,
  deleteDevice,
}
