export default [
  {
    component: 'CNavItem',
    name: '契約一覧',
    to: '/contract',
    icon: 'cil-address-book',
    badge: {
      // color: 'primary',
      // text: 'NEW',
    },
  },
]
