const newContract = (
  contractId,
  accountID,
  username,
  registered,
  // enabled,
  // status,
) => {
  return {
    id: contractId,
    accountID: accountID,
    account: {
      sub: accountID,
      username: username,
      email: '',
      registered: registered,
      // enabled: enabled,
      // status: status,
    },
    owner: '',
    corporate: '',
    email: '',
    description: '',
    period: {
      start: '',
      end: '',
    },
    users: [],
  }
}

export default {
  newContract,
}
