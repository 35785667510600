import LocalStorageRepository from '@/adapters/LocalStorageRepository'
import LocalSystemRepository from '@/adapters/LocalSystemRepository'

import SystemToolPresenter from '@/adapters/SystemToolPresenter'

const MODULE_TAG = 'SystemToolController'

const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time))

const fetchSystemInfo = async (context) => {
  const result = await LocalSystemRepository.fetchArchitecture()
  if (result.error) {
    return
  }

  const output = result.response
  // console.log(output)
  const systemToolItems = SystemToolPresenter.convertDbListToStore(output, null)
  context.commit('setSystemToolItems', systemToolItems)

  return
}

const loadSettings = async (context) => {
  const result = await LocalStorageRepository.loadSettings()
  if (result.error) {
    return
  } else {
    const output = result.response
    // console.log(output)
    const settingItems = output
    context.commit('setSettingItems', settingItems)
  }

  return
}

const saveSettings = async (context, { apiHost }) => {
  const input = {
    apiHost,
  }

  const result = await LocalStorageRepository.saveSettings(input)
  if (result.error) {
    return
  } else {
    // const success = result.response
    const settingItems = input
    context.commit('setSettingItems', settingItems)
  }

  return
}

const fetchHealthCheckStatus = async (context) => {
  context.dispatch('activeLoadingPanel')

  const result = await LocalSystemRepository.fetchArchitecture()
  if (result.error) {
    context.dispatch('deactiveLoadingPanel')
    return
  }
  const result2 = await LocalSystemRepository.fetchHealthCheckStatus()
  if (result2.error) {
    context.dispatch('deactiveLoadingPanel')
    return
  }

  context.dispatch('deactiveLoadingPanel')
  await sleep(500)

  const arch = result.response
  const devices = result2.response
  // console.log(output)
  const systemToolItems = SystemToolPresenter.convertDbListToStore(
    arch,
    devices,
  )

  context.commit('setSystemToolItems', systemToolItems)

  return
}

const calcSummaryData = async (context, { start, startTime, end, endTime }) => {
  context.dispatch('activeLoadingPanel')

  // const result0 = await LocalSystemRepository.fetchJobList()
  // console.log(result0)
  // if (result0.error) {
  //   context.dispatch('deactiveLoadingPanel')
  //   return null
  // }

  const startDate = start
  const endDate = end
  // const startDate =
  //   start.slice(0, 4) + ' ' + start.slice(4, 2) + ' ' + start.slice(6, 2)
  // const endDate =
  //   end.slice(0, 4) + ' ' + end.slice(4, 2) + ' ' + end.slice(6, 2)

  const result = await LocalSystemRepository.triggerCalculation({
    start: startDate,
    startTime: startTime,
    // startTime: startTime.slice(0, 2),
    end: endDate,
    endTime: endTime,
    // endTime: endTime.slice(0, 2),
  })
  // console.log(result)
  if (result.error) {
    context.dispatch('deactiveLoadingPanel')
    return null
  }

  // console.log(result.response.jobs)
  // 画面に実行結果を返すメッセージを生成する
  const output = SystemToolPresenter.convertExecResultToMessage(
    result.response.jobs,
  )

  // await sleep(1000)

  // const result2 = await LocalSystemRepository.fetchJobList()
  // console.log(result2)
  // if (result2.error) {
  //   context.dispatch('deactiveLoadingPanel')
  //   return
  // }

  context.dispatch('deactiveLoadingPanel')
  await sleep(500)

  return output
}

const fetchReCalcJobList = async (context) => {
  // ローディングパネルを表示する
  context.dispatch('activeLoadingPanel')

  // 再計算処理のジョブリストを取得する
  const result = await LocalSystemRepository.fetchJobList()
  // console.log(result)
  if (result.error) {
    context.dispatch('deactiveLoadingPanel')
    return
  }

  const jobs = result.response.jobs
  const dataRecoveryItems = SystemToolPresenter.convertJobListToStore(jobs)
  // console.log(dataRecoveryItems)

  // ストアに保存する
  context.commit('setDataRecoveryItems', dataRecoveryItems)

  // ローディングパネルを消す
  context.dispatch('deactiveLoadingPanel')
  await sleep(500)

  return
}

const fetchVersionInfo = async (context) => {
  // ローディングパネルを表示する
  context.dispatch('activeLoadingPanel')

  // Docker イメージのバージョン 一覧を取得する
  const result = await LocalSystemRepository.fetchImageVersionList()
  // console.log(result)
  if (result.error) {
    context.dispatch('deactiveLoadingPanel')
    return
  }

  // Docker イメージのダウンロードプロセスの有無を確認する
  const result2 = await LocalSystemRepository.fetchDownloadProcessStatus()
  // console.log(result2)
  if (result2.error) {
    context.dispatch('deactiveLoadingPanel')
    return
  }

  const images = result.response.images
  const status = result2.response.status
  const softwareVersionItems =
    SystemToolPresenter.convertImageVersionListToStore(images, status)
  // console.log(softwareVersionItems)

  // ストアに保存する
  context.commit('setSoftwareVersionItems', softwareVersionItems)

  // ローディングパネルを消す
  context.dispatch('deactiveLoadingPanel')
  await sleep(500)

  return
}

const downloadSoftware = async (context) => {
  context.dispatch('activeLoadingPanel')

  const result = await LocalSystemRepository.triggerImageDownload()
  // console.log(result)
  if (result.error) {
    context.dispatch('deactiveLoadingPanel')
    return null
  }

  // console.log(result.response.status)
  const status = result.response.status
  // 画面に実行結果を返すメッセージを生成する
  const output = SystemToolPresenter.convertDownloadResultToMessage(status)

  context.dispatch('deactiveLoadingPanel')
  await sleep(500)

  fetchVersionInfo(context)

  return output
}

export default {
  fetchSystemInfo,
  loadSettings,
  saveSettings,
  fetchHealthCheckStatus,
  calcSummaryData,
  fetchReCalcJobList,
  fetchVersionInfo,
  downloadSoftware,
}
