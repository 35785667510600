import TenantViewModel from '@/adapters/TenantViewModel'

import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'TenantPresenter'

const convertNewDbDataToStore = (resultData) => {
  const TAG = `${MODULE_TAG}.${convertNewDbDataToStore.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(resultData)

  const option = TenantViewModel.newTenantItem(resultData)
  logger.verbose(Logger.formatter(TAG, '--- new PromoEvent ---'))
  logger.verbose(option)

  return option
}

// 【未使用】
const convertDbDataToStore = (fetchData) => {
  const TAG = `${MODULE_TAG}.${convertDbDataToStore.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(fetchData)

  let item
  // TODO: 将来に開店時間の履歴を取り扱うようになったときに実装する

  return item
}

export default {
  convertNewDbDataToStore,
  convertDbDataToStore,
}
