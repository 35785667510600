import Account from '@/domain/models/Account'

import presenter from './presenter'

import DateUtil from '@/utils/DateUtil'

import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'AccountPresenter'

const convertIdaasToStore = (fetchUser) => {
  const TAG = `${MODULE_TAG}.${convertIdaasToStore.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(fetchUser)

  const username = fetchUser.Username
  const date = new Date(fetchUser.UserCreateDate)
  const registered = DateUtil.formatDate(date, 'YYYY-MM-DD')
  const sub = fetchUser.Attributes[0].Value
  const name = ''
  const description = ''

  const accountItem = Account.newAccountWithIDaaSparams(
    sub,
    username,
    registered,
    name,
    description,
  )

  return accountItem
}

const convertDbToStore = (fetchData, enable, status) => {
  const TAG = `${MODULE_TAG}.${convertDbToStore.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(fetchData)

  const date = new Date(fetchData.createdAt)
  return {
    id: fetchData.id,
    idaasUser: {
      sub: fetchData.idaasUser.sub,
      username: fetchData.idaasUser.username,
      registered: DateUtil.formatDate(date, 'YYYY-MM-DD'),
      enabled: enable,
      status: status,
    },
    name: fetchData.name,
    corporate: fetchData.corporate,
    email: fetchData.email,
    description: fetchData.description,
    activity: fetchData.activity,
    contracts: [],
  }
}

export default {
  convertIdaasToStore,
  convertDbToStore,
}
