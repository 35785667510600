import PromotionInteractor from '@/domain/usecases/PromotionInteractor'

import PromotionPresenter from '@/adapters/PromotionPresenter'
import StoreRepository from '@/adapters/StoreRepository'

import DateUtil from '@/utils/DateUtil'
import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'PromotionController'

// 施策一覧の取得
const fetchPromotionsList = async (context) => {
  const TAG = `${MODULE_TAG}.${fetchPromotionsList.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const cwlogger = await Logger.getInstance(context.getters.getCurrentUsername)
  cwlogger?.verbose(`begin ${TAG}`)

  const input = {
    // 現在ログインしているアカウントの契約情報を取得する
    contractId: context.state.contract.id,
    deviceId: context.state.tenantDatabaseItem.id,
  }
  logger.verbose('contractId: ' + input.contractId)
  logger.verbose('deviceId: ' + input.deviceId)
  const identityId = context.state.user.attributes.sub

  // 施策一覧の取得
  const output = await PromotionInteractor.getPromotionsList(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, `getPromotionsList error`))
    logger.error(output.error)
    return
  }

  const promoEventItems = PromotionPresenter.convertDbDataToStore(output.result)
  logger.verbose('=== promoEventItems ===')
  logger.verbose(promoEventItems)

  // ストアの更新
  const deviceId = input.deviceId
  // 販売施策
  StoreRepository.updatePromoEventItems(context, {
    deviceId,
    promoEventItems,
  })
}

// 施策の新規登録
const createPromotion = async (
  context,
  { eventName, start, end, description },
) => {
  const TAG = `${MODULE_TAG}.${createPromotion.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  // 現在ログインしているアカウントの契約情報を取得する
  const contractId = context.state.contract.id
  if (!contractId) {
    const error = '契約ＩＤが存在しません'
    logger.error(Logger.formatter(TAG, error))
    alert('error')
    return
  }
  // デバイス情報を取得する
  const deviceId = context.state.tenantDatabaseItem.id
  if (!deviceId) {
    const error = 'デバイスＩＤが存在しません'
    logger.error(Logger.formatter(TAG, error))
    alert('error')
    return
  }

  let index = -1
  const promoEventItems = context.state.promoEventItems
  promoEventItems.find((item, idx) => {
    if (!item) {
      return false
    } else if (item.name == eventName) {
      index = idx
      return true
    } else {
      return false
    }
  })
  // 重複登録の抑制
  if (0 < index) {
    const error = '既に存在する施策です'
    logger.error(
      Logger.formatter(
        TAG,
        'index:' + index + ', name:' + eventName + ', ' + error,
      ),
    )
    alert(error)
    return
  }

  const areaName = context.state.tenantDatabaseItem.category
  const tenantName = context.state.tenantDatabaseItem.location

  // 施策の新規登録
  const input = {
    eventName,
    start,
    end,
    description,
    areaName,
    tenantName,
    deviceId,
  }

  // 施策登録を要求する
  const output = await PromotionInteractor.createPromotion(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, `createPromotion error`))
    logger.error(output.error)
    alert(output.error)
    return
  }
  const resultData = output.result
  const newItem = PromotionPresenter.convertNewDbDataToStore(resultData)
  logger.verbose('=== create new promotion! ===')
  logger.verbose(newItem)

  // ストアに新しい施策情報として追加する ~ 画面更新
  StoreRepository.addPromoEventItem(context, {
    deviceId,
    newItem,
  })
  return
}

// 施策の編集
const editPromotion = async (
  context,
  { id, eventName, start, end, description },
) => {
  const TAG = `${MODULE_TAG}.${editPromotion.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(eventName, start, end, description)

  // 現在ログインしているアカウントの契約IDを取得する
  const contractId = context.state.contract.id
  // 店舗に設置したデバイスIDを取得する
  const deviceId = context.state.tenantDatabaseItem.id
  if (!deviceId) {
    const error = 'デバイスＩＤが存在しません'
    logger.error(Logger.formatter(TAG, error))
    alert('error')
    return
  }

  const promoEventItems = context.state.promoEventItems
  let promotionItem = null
  let promotionId = null
  let index = -1
  promoEventItems.forEach((item, idx) => {
    if (item.id === id) {
      // デバイスに属するサービスオプション情報の抽出
      promotionItem = item
      logger.verbose(item.id, item.eventName)
      logger.verbose(promotionItem)
      promotionId = item.id
      index = idx
    }
  })
  if (index < 0) {
    const error = '編集する施策 ' + eventName + ' は存在しません'
    logger.error(Logger.formatter(TAG, error))
    return
  }

  const areaName = context.state.tenantDatabaseItem.category
  const tenantName = context.state.tenantDatabaseItem.location

  const input = {
    id: promotionId,
    eventName: eventName,
    start: start,
    end: end,
    areaName: areaName,
    tenantName: tenantName,
    description: description,
    deviceId: deviceId,
  }
  logger.verbose(Logger.formatter(TAG, `input`))
  logger.verbose(input)
  // logger.verbose(promotionItem)

  const output = await PromotionInteractor.editPromotionProfile(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, output.error))
    alert(output.error)
    return
  }
  const dataResult = output.result
  logger.verbose('edit id: ' + dataResult.id)

  // ストアの更新
  StoreRepository.editPromoEventItem(context, {
    deviceId,
    id,
    eventName,
    start,
    end,
    description,
  })
  return
}

// 施策の削除
const deletePromotion = async (context, { deleteId }) => {
  const TAG = `${MODULE_TAG}.${deletePromotion.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const deviceId = context.state.tenantDatabaseItem.id

  const input = {
    deviceId: deviceId,
    deleteId: deleteId,
  }
  // AppSync に施策削除のリクエストを送る
  const output = await PromotionInteractor.deletePromotion(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, output.error))
    alert(output.error)
    return
  }
  logger.verbose('delete id: ' + deleteId)

  // ストアの更新
  StoreRepository.deletePromoEventItem(context, {
    deviceId,
    deleteId,
  })
  return
}

export default {
  createPromotion,
  fetchPromotionsList,
  editPromotion,
  deletePromotion,
}
