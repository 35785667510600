const random = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const sum = (array) => {
  if (array && array.length == 0) {
    return 0
  }

  // console.log(array)
  return array.reduce((sum, element) => {
    // console.log(sum, element)
    const value = element ? element : 0
    return sum + value
  })
}

const sum2 = (array, index) => {
  let total = 0
  array.forEach((element) => {
    total += element[index]
  })
  return total
}

const average = (array) => {
  if (array && array.length == 0) {
    return 0
  }

  // データがない場合(null)は個数に勘定しない
  const count = array.reduce((sum, element) => {
    const value = element ? 1 : 0
    return sum + value
  })
  // const count = array.length

  return (
    array.reduce((sum, element) => {
      const value = element ? element : 0
      return sum + value
    }) / count
  )
}

const fullScale = (array) => {
  if (array && array.length == 0) {
    return 1
  }

  const max = Math.max(...array)
  let scale = 1
  if (max < 10) {
    scale = 10
  } else if (max < 20) {
    scale = 20
  } else if (max < 50) {
    scale = 50
  } else if (max < 100) {
    scale = 100
  } else if (max < 200) {
    scale = 200
  } else if (max < 500) {
    scale = 500
  } else if (max < 600) {
    scale = 600
  } else if (max < 800) {
    scale = 800
  } else if (max < 1000) {
    scale = 1000
  } else if (max < 2000) {
    scale = 2000
  } else if (max < 5000) {
    scale = 5000
  } else {
    scale = 10000
  }
  return scale
}

const rate = (sumValue, sumCount, digit = 1) => {
  const rate_ = sumCount == 0 ? 0 : (100 * sumValue) / sumCount
  return rate_

  // let rate = rate_ * Math.pow(10, digit)
  // rate = Math.round(rate)
  // rate /= Math.pow(10, digit)
  // return rate
}

const round = (value, digit = 0) => {
  if (isNaN(value)) {
    // 数値でないならばそのまま返す
    return value
  }

  let num = value * Math.pow(10, digit)
  num = Math.round(num)
  num /= Math.pow(10, digit)
  return num
}
export default {
  random,
  sum,
  sum2,
  average,
  fullScale,
  rate,
  round,
}
