import CountingViewModel from '@/adapters/CountingViewModel'

import DateUtil from '@/utils/DateUtil'
import CalcUtil from '@/utils/CalcUtil'

import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'CountingPresenter'

const convertDbToStore = (fetchData) => {
  const TAG = `${MODULE_TAG}.${convertDbToStore.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(fetchData)

  const labels = []
  const datasets = []
  fetchData.datasets.forEach((record) => {
    let newRecord = CountingViewModel.copyCountingBaseItem(record)
    // logger.verbose('~~~~~~~~~~~~')
    // logger.verbose(newRecord)

    labels.push(record.date)
    datasets.push(newRecord)
  })

  return {
    labels: labels,
    datasets: datasets,
    malesData: fetchData.malesData,
    femalesData: fetchData.femalesData,
  }
}

// ダッシュボードの店舗データ 一覧 テーブル
const convertDbToTableStore = ({ summaryData, before, type, response }) => {
  const TAG = `${MODULE_TAG}.${convertDbToTableStore.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(type)
  logger.verbose(summaryData)

  let startDate = ''
  let endDate = ''

  let label = ' ~ '
  if (0 < summaryData.length) {
    const labels = summaryData[0].labels
    // console.log(labels)

    startDate = labels[0]
    endDate = labels[labels.length - 1]
    if (type == 'Monthly') {
      // label = '2022年4月'
      label = DateUtil.jpJustMonthFromString(startDate)
    } else {
      // label = '2022年4月11日 〜 2022年4月17日'
      label =
        DateUtil.jpFormatDateFromString(startDate) +
        ' 〜 ' +
        DateUtil.jpFormatDateFromString(endDate)
    }
  }

  let datasets = []
  let totalMaleRate = 0
  let totalFemaleRate = 0

  summaryData.forEach((tenant, index) => {
    // console.log('###############')
    // console.log(tenant)
    // console.log(tenant.datasets)

    let record1 = CountingViewModel.newCountingBaseItem()
    tenant.datasets.forEach((record, idx) => {
      if (idx == 0) {
        record1.date = record.date
        record1.tenant = record.tenant
        record1.area = record.area
      }
      record1.inCount += record.inCount
      record1.inAttribute += record.inAttribute
      record1.noAttribute += record.noAttribute
      record1.male += record.male
      record1.female += record.female
      record1.age10 += record.age10
      record1.age20 += record.age20
      record1.age30 += record.age30
      record1.age40 += record.age40
      record1.age50 += record.age50
    })
    totalMaleRate += record1.male
    totalFemaleRate += record1.female

    // const sum = record1.inCount - record1.noAttribute
    // console.log(sum, record1.inCount, record1.noAttribute)
    const sum11 = record1.male + record1.female
    // console.log(sum11, record1.male, record1.female)
    const sum12 =
      record1.age10 +
      record1.age20 +
      record1.age30 +
      record1.age40 +
      record1.age50
    // console.log(sum12)
    record1.male = CalcUtil.rate(record1.male, sum11)
    record1.female = CalcUtil.rate(record1.female, sum11)
    record1.age10 = CalcUtil.rate(record1.age10, sum12)
    record1.age20 = CalcUtil.rate(record1.age20, sum12)
    record1.age30 = CalcUtil.rate(record1.age30, sum12)
    record1.age40 = CalcUtil.rate(record1.age40, sum12)
    record1.age50 = CalcUtil.rate(record1.age50, sum12)
    // record1.staying = Math.round(record1.staying)

    let record2 = CountingViewModel.newCountingBaseItem()
    if (before && index < before.length) {
      before[index].datasets.forEach((record, idx) => {
        if (idx == 0) {
          record2.date = record.date
        }
        record2.inCount += record.inCount
        record2.inAttribute += record.inAttribute
        record2.noAttribute += record.noAttribute
        record2.male += record.male
        record2.female += record.female
        record2.age10 += record.age10
        record2.age20 += record.age20
        record2.age30 += record.age30
        record2.age40 += record.age40
        record2.age50 += record.age50
      })

      // const sum = record2.inCount - record2.noAttribute
      const sum21 = record2.male + record2.female
      const sum22 =
        record2.age10 +
        record2.age20 +
        record2.age30 +
        record2.age40 +
        record2.age50
      record2.male = CalcUtil.rate(record2.male, sum21)
      record2.female = CalcUtil.rate(record2.female, sum21)
      record2.age10 = CalcUtil.rate(record2.age10, sum22)
      record2.age20 = CalcUtil.rate(record2.age20, sum22)
      record2.age30 = CalcUtil.rate(record2.age30, sum22)
      record2.age40 = CalcUtil.rate(record2.age40, sum22)
      record2.age50 = CalcUtil.rate(record2.age50, sum22)

      record2.area = ''
      record2.tenant = ''

      // record2.staying = Math.round(record2.staying)
    }

    if (type == 'Monthly') {
      const tmp1 = record1.date
      record1.date = DateUtil.jpJustMonthFromString(record1.date)
      if (record2 && record2.date) {
        record2.date = DateUtil.jpJustMonthFromString(record2.date)
      } else {
        record2.date = DateUtil.jpJustMonthFromString(
          DateUtil.addDateStringByMonth(tmp1, -1),
        )
      }
    } else if (type == 'Weekly') {
      const tmp1 = record1.date
      record1.date = DateUtil.jpJustDateFromString(record1.date) + 'の週'
      if (record2 && record2.date) {
        record2.date = DateUtil.jpJustDateFromString(record2.date) + 'の週'
      } else {
        record2.date =
          DateUtil.jpJustDateFromString(DateUtil.addDateString(tmp1, -7)) +
          'の週'
      }
    } else {
      record1.date = DateUtil.jpJustDateFromString(record1.date)
      if (record2) {
        record2.date = DateUtil.jpJustDateFromString(record2.date)
      }
    }

    let record = CountingViewModel.newCountingBaseItem()
    if (record1 && record2) {
      // 成長率
      record.date = '成長率'
      record.area = ''
      record.tenant = ''
      // record.staying = CalcUtil.round(CalcUtil.rate((record1.staying - record2.staying), record2.staying)) + ' %'
      record.inCount =
        CalcUtil.round(
          CalcUtil.rate(record1.inCount - record2.inCount, record2.inCount),
        ) + ' %'
      record.male =
        CalcUtil.round(
          CalcUtil.rate(record1.male - record2.male, record2.male),
        ) + ' %'
      record.female =
        CalcUtil.round(
          CalcUtil.rate(record1.female - record2.female, record2.female),
        ) + ' %'
      record.age10 =
        CalcUtil.round(
          CalcUtil.rate(record1.age10 - record2.age10, record2.age10),
        ) + ' %'
      record.age20 =
        CalcUtil.round(
          CalcUtil.rate(record1.age20 - record2.age20, record2.age20),
        ) + ' %'
      record.age30 =
        CalcUtil.round(
          CalcUtil.rate(record1.age30 - record2.age30, record2.age30),
        ) + ' %'
      record.age40 =
        CalcUtil.round(
          CalcUtil.rate(record1.age40 - record2.age40, record2.age40),
        ) + ' %'
      record.age50 =
        CalcUtil.round(
          CalcUtil.rate(record1.age50 - record2.age50, record2.age50),
        ) + ' %'
    }

    if (record1) {
      datasets.push(record1)
      datasets.push(record2)
      datasets.push(record)
    }
  })

  // 来客数と来客属性の全店舗総計
  const summary = response.summary

  const genderAgeRate = response.genderAgeRate

  const genderSum = totalMaleRate + totalFemaleRate
  totalMaleRate = CalcUtil.rate(totalMaleRate, genderSum)
  totalFemaleRate = CalcUtil.rate(totalFemaleRate, genderSum)

  // const ageSum = genderSum
  let ageSum = 0
  genderAgeRate.forEach((item, genderNum) => {
    item.rate.forEach((value, ageGroupNum) => {
      // 母数を算出する
      ageSum += value
    })
  })
  genderAgeRate.forEach((item, genderNum) => {
    item.rate.forEach((value, ageGroupNum) => {
      // パーセントに変換する
      genderAgeRate[genderNum].rate[ageGroupNum] = CalcUtil.rate(value, ageSum)
    })
  })

  return {
    label: label,
    datasets: datasets,
    start: startDate,
    end: endDate,
    countingData: summary,
    attributes: {
      gender: [totalMaleRate, totalFemaleRate],
      genderAge: genderAgeRate,
    },
  }
}

// 店舗間比較
const convertDbToMultiTenantStore = (multiTenantData) => {
  const TAG = `${MODULE_TAG}.${convertDbToMultiTenantStore.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(multiTenantData)

  const multiTenantCountingItems = {
    labels: multiTenantData[0].labels,
    datasets: [],
  }

  multiTenantData.forEach((tenant) => {
    const dat = {
      area: '',
      tenant: '',
      data: [],
    }

    if (0 < tenant.datasets.length) {
      dat.area = tenant.datasets[0].area
      dat.tenant = tenant.datasets[0].tenant
      tenant.datasets.forEach((record) => {
        dat.data.push(record.inCount)
      })
    }
    multiTenantCountingItems.datasets.push(dat)
  })

  return multiTenantCountingItems
}

// 店舗間比較｜サマリー テーブル
const convertDbToMultiTenantTableStore = (multiTenantData) => {
  const TAG = `${MODULE_TAG}.${convertDbToMultiTenantTableStore.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(multiTenantData)

  const multiTenantCountingItems = []

  if (!multiTenantData) {
    return multiTenantCountingItems
  }

  multiTenantData.forEach((tenant) => {
    // const sum = tenant.inCount
    const totalByGender = tenant.male + tenant.female
    const totalByAge =
      tenant.age10 + tenant.age20 + tenant.age30 + tenant.age40 + tenant.age50
    // パーセント表記に変換する
    const dat = {
      tenant: tenant.tenant,
      // sales: 4457400,
      // passerbyCount: 6655,
      // staying: tenant.staying,
      inCount: tenant.inCount,
      // purchaseRate: 19.7,
      // purchaseNum: 397,
      // averageRevenue: 11228,
      male: (tenant.male = CalcUtil.rate(tenant.male, totalByGender)),
      female: (tenant.female = CalcUtil.rate(tenant.female, totalByGender)),
      age10: (tenant.age10 = CalcUtil.rate(tenant.age10, totalByAge)),
      age20: (tenant.age20 = CalcUtil.rate(tenant.age20, totalByAge)),
      age30: (tenant.age30 = CalcUtil.rate(tenant.age30, totalByAge)),
      age40: (tenant.age40 = CalcUtil.rate(tenant.age40, totalByAge)),
      age50: (tenant.age50 = CalcUtil.rate(tenant.age50, totalByAge)),
    }

    multiTenantCountingItems.push(dat)
  })

  return multiTenantCountingItems
}

// 店舗計測
const convertDbToOwnTenantStore = (ownTenantData) => {
  const TAG = `${MODULE_TAG}.${convertDbToOwnTenantStore.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(ownTenantData)

  const items = CountingViewModel.newCountingListItem()
  items.labels = ownTenantData.labels

  // console.log(ownTenantData)
  if (ownTenantData.malesData) {
    items.malesData = ownTenantData.malesData
  }
  if (ownTenantData.femalesData) {
    items.femalesData = ownTenantData.femalesData
  }

  ownTenantData.datasets.forEach((dataset) => {
    // console.log(dataset)
    // items.malesData.push(dataset.malesData)
    // items.femalesData.push(dataset.malesData)
    items.maleCounts.push(dataset.male)
    items.femaleCounts.push(dataset.female)
    items.inCounts.push(dataset.inCount)
    items.attrSamples.push(dataset.inAttribute)
    items.attrBlanks.push(dataset.noAttribute)
    items.inCount += dataset.inCount
    items.inAttribute += dataset.inAttribute
    items.noAttribute += dataset.noAttribute
    // items.stayings.push(dataset.staying)
    items.maleAge10 += dataset.maleAge10
    items.maleAge20 += dataset.maleAge20
    items.maleAge30 += dataset.maleAge30
    items.maleAge40 += dataset.maleAge40
    items.maleAge50 += dataset.maleAge50
    items.maleNoage += dataset.maleNoage
    items.femaleAge10 += dataset.femaleAge10
    items.femaleAge20 += dataset.femaleAge20
    items.femaleAge30 += dataset.femaleAge30
    items.femaleAge40 += dataset.femaleAge40
    items.femaleAge50 += dataset.femaleAge50
    items.femaleNoage += dataset.femaleNoage
    items.male += dataset.male
    items.female += dataset.female
    items.age10 += dataset.age10
    items.age20 += dataset.age20
    items.age30 += dataset.age30
    items.age40 += dataset.age40
    items.age50 += dataset.age50
    items.in01s.push(dataset.in01)
    items.in02s.push(dataset.in02)
    items.in03s.push(dataset.in03)
    items.in04s.push(dataset.in04)
    items.in05s.push(dataset.in05)
    items.in06s.push(dataset.in06)
    items.in07s.push(dataset.in07)
    items.in08s.push(dataset.in08)
    items.in09s.push(dataset.in09)
    items.in10s.push(dataset.in10)
    items.out01s.push(dataset.out01)
    items.out02s.push(dataset.out02)
    items.out03s.push(dataset.out03)
    items.out04s.push(dataset.out04)
    items.out05s.push(dataset.out05)
    items.out06s.push(dataset.out06)
    items.out07s.push(dataset.out07)
    items.out08s.push(dataset.out08)
    items.out09s.push(dataset.out09)
    items.out10s.push(dataset.out10)
    items.validAttribute01s.push(dataset.validAttribute01)
    items.validAttribute02s.push(dataset.validAttribute02)
    items.validAttribute03s.push(dataset.validAttribute03)
    items.validAttribute04s.push(dataset.validAttribute04)
    items.validAttribute05s.push(dataset.validAttribute05)
    items.validAttribute06s.push(dataset.validAttribute06)
    items.validAttribute07s.push(dataset.validAttribute07)
    items.validAttribute08s.push(dataset.validAttribute08)
    items.validAttribute09s.push(dataset.validAttribute09)
    items.validAttribute10s.push(dataset.validAttribute10)
  })

  items.inCount = CalcUtil.sum(items.inCounts) // 店舗の合計来客数

  const totalByGender = items.male + items.female
  const totalByAge =
    items.age10 + items.age20 + items.age30 + items.age40 + items.age50

  items.maleRate = CalcUtil.rate(items.male, totalByGender)
  items.femaleRate = CalcUtil.rate(items.female, totalByGender)

  const rateMale = [
    CalcUtil.rate(items.maleAge10, totalByAge),
    CalcUtil.rate(items.maleAge20, totalByAge),
    CalcUtil.rate(items.maleAge30, totalByAge),
    CalcUtil.rate(items.maleAge40, totalByAge),
    CalcUtil.rate(items.maleAge50, totalByAge),
  ]
  const rateFemale = [
    CalcUtil.rate(items.femaleAge10, totalByAge),
    CalcUtil.rate(items.femaleAge20, totalByAge),
    CalcUtil.rate(items.femaleAge30, totalByAge),
    CalcUtil.rate(items.femaleAge40, totalByAge),
    CalcUtil.rate(items.femaleAge50, totalByAge),
  ]
  // const rateMale = [11, 7, 8, 9, 11]
  // const rateFemale = [10, 9, 10, 15, 10]
  // console.log(rateMale)
  // console.log(rateFemale)

  items.genderAgeRate = [
    {
      gender: '男性',
      rate: rateMale,
    },
    {
      gender: '女性',
      rate: rateFemale,
    },
  ]

  const ageRate = [
    CalcUtil.rate(items.age10, totalByAge),
    CalcUtil.rate(items.age20, totalByAge),
    CalcUtil.rate(items.age30, totalByAge),
    CalcUtil.rate(items.age40, totalByAge),
    CalcUtil.rate(items.age50, totalByAge),
  ]
  // const data = [12, 20, 22, 19, 10]

  items.ageRate = {
    labels: ['10代', '20代', '30代', '40代', '50代 〜'],
    datasets: [
      {
        label: '年代の割合 [%]',
        backgroundColor: 'rgba(75, 192, 192, 0.8)',
        data: ageRate,
        borderColor: 'rgba(75, 192, 192, 1.0)',
        borderWidth: 1,
        // barPercentage: 0.5,
        barThickness: 24,
        maxBarThickness: 32,
        minBarLength: 2,
      },
    ],
  }

  let ageCountArray = [] // [年齢層毎][人数]
  let agesArray = [] // [年齢層毎][%]
  let totalCountsByDate = []

  items.malesData.forEach((array, ageIndex) => {
    array.forEach((maleAgeCount, dateIndex) => {
      const cntByAge = maleAgeCount + items.femalesData[ageIndex][dateIndex]
      if (ageIndex == 0) {
        totalCountsByDate.push(cntByAge)
      } else {
        totalCountsByDate[dateIndex] += cntByAge
      }
    })
  })

  // 年代傾向｜現状把握 の棒グラフ
  items.malesData.forEach((array, ageIndex) => {
    const ageCounts = []
    let dayData = []
    array.forEach((maleAgeCount, dateIndex) => {
      // const total = items.inCounts[timeIndex]
      const total = totalCountsByDate[dateIndex]
      const ageCount = maleAgeCount + items.femalesData[ageIndex][dateIndex]
      ageCounts.push(ageCount)
      const rate = CalcUtil.rate(ageCount, total)
      dayData.push(rate)
    })
    ageCountArray.push(ageCounts)
    agesArray.push(dayData)
  })
  items.ageCountArray = ageCountArray
  items.agesArray = agesArray

  const maleAgeRate = [
    CalcUtil.rate(items.maleAge10, totalByAge),
    CalcUtil.rate(items.maleAge20, totalByAge),
    CalcUtil.rate(items.maleAge30, totalByAge),
    CalcUtil.rate(items.maleAge40, totalByAge),
    CalcUtil.rate(items.maleAge50, totalByAge),
  ]
  // const maleAgeRate = [15, 9, 10, 11, 16]
  const femaleAgeRate = [
    CalcUtil.rate(items.femaleAge10, totalByAge),
    CalcUtil.rate(items.femaleAge20, totalByAge),
    CalcUtil.rate(items.femaleAge30, totalByAge),
    CalcUtil.rate(items.femaleAge40, totalByAge),
    CalcUtil.rate(items.femaleAge50, totalByAge),
  ]
  // const data = [11, 18, 10, 9, 16]
  // console.log(maleAgeRate)
  // console.log(femaleAgeRate)
  items.maleAgeRate = maleAgeRate
  items.femaleAgeRate = femaleAgeRate

  items.attributes.gender = [items.maleRate, items.femaleRate]
  items.attributes.genderAge = [
    {
      gender: '男性',
      rate: rateMale,
    },
    {
      gender: '女性',
      rate: rateFemale,
    },
  ]

  // console.log(items)
  return items
}

// 自店舗の日別データへの変換
const convertDbToOwnTenantDaysStore = (DailyData) => {
  const TAG = `${MODULE_TAG}.${convertDbToOwnTenantDaysStore.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(DailyData)

  let labels = []
  let datasets = []

  DailyData.datasets.forEach((data, index) => {
    // console.log(data)

    const label = DailyData.labels[index]
    labels.push(label)

    const dat = {
      hour: 0,
      data: [data.inCount],
    }
    datasets.push(dat)
  })

  const tenantCountingDaysItems = {
    labels: labels,
    datasets: datasets,
  }
  // console.log(tenantCountingDaysItems)

  return tenantCountingDaysItems
}

// 自店舗の時間別来客数データへの変換
const convertDbToOwnTenantHoursStore = (hourlyData, open, close) => {
  const TAG = `${MODULE_TAG}.${convertDbToOwnTenantHoursStore.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(hourlyData)
  logger.debug('open:', open)
  logger.debug('close:', close)

  let labels = []
  let datasets = []
  let samples = [0, 0, 0, 0, 0, 0, 0]

  let beforeDate = ''
  let is1stDate = true

  const openHour = DateUtil.getHours(open)
  const closeHour = DateUtil.getCloseHours(open, close)
  // console.log('closeHour:', closeHour)
  const openSpan = closeHour - openHour + 1
  // let minHour = openHour
  // let maxHour = closeHour

  // console.log(hourlyData)
  let dayc = -1
  let beforeHousr = null
  hourlyData.datasets.forEach((data, index) => {
    const date = data.date
    const currentDate = DateUtil.jpFormatDateFromString(date)
    const hour = DateUtil.hourNumberFromString(date)
    // const hour = index % 24
    const inCount = data.inCount
    // console.log(date, hour, inCount)

    if (currentDate != beforeDate) {
      dayc++
      if (beforeDate != '') {
        is1stDate = false
      }
    }

    if (
      (closeHour < 24 && openHour <= hour && hour <= closeHour) ||
      (24 <= closeHour && (hour <= closeHour - 24 || openHour <= hour))
    ) {
      if (is1stDate) {
        // console.log(hour)
        // console.log(dat)

        // NOTE: データが欠損していたときのパディング処理
        const paddingCount = beforeHousr == null ? 0 : hour - beforeHousr
        for (let diffc = 1; diffc < paddingCount; diffc++) {
          const _hour = beforeHousr + diffc
          const null_dat = {
            hour: _hour.toString(),
            data: [null, null, null, null, null, null, null],
          }
          datasets.push(null_dat)
          // console.log(
          //   '-- Add ' + date + ' ' + null_dat.hour + ' ' + null_dat.data,
          // )

          if (_hour == openHour) {
            const label = DateUtil.addDateStringFormat0ByHour(
              hourlyData.labels[index],
              _hour - hour,
            )
            labels.push(label)
            // samples.push(0)
            // console.log('--- Add label ' + label)
          }
        }

        const dat = {
          hour: hour.toString(),
          data: [inCount, null, null, null, null, null, null],
        }
        datasets.push(dat)
        // console.log('-- push to datasets ' + dat.hour + ' ' + dat.data)

        if (hour == openHour) {
          const label = hourlyData.labels[index]
          labels.push(label)
          samples[index] = 1
        }
        // console.log(datasets)
      } else {
        // console.log(hour)
        // console.log(datasets)
        datasets.forEach((record, idx) => {
          // console.log(record)
          if (record.hour == hour.toString()) {
            if (dayc < 7) {
              datasets[idx % openSpan].data[dayc % 7] = inCount
              if (hour == openHour) {
                const label = hourlyData.labels[index]
                labels.push(label)
                samples[dayc % 7] = 1
              }
            } else {
              if (isNaN(datasets[idx % openSpan].data[dayc % 7])) {
                datasets[idx % openSpan].data[dayc % 7] = inCount
              } else {
                datasets[idx % openSpan].data[dayc % 7] += inCount
              }
              if (hour == openHour) {
                samples[dayc % 7] += 1
                // console.log(samples.length, samples, samples[dayc % 7])
              }
            }
          }
        })
        // console.log(datasets)
      }
    }

    beforeHousr = hour
    beforeDate = currentDate
  })
  // console.log(labels)
  // console.log(datasets)
  // console.log(samples)

  datasets.forEach((data, idx) => {
    datasets[idx].data.forEach((value, idx2) => {
      // 1日当たりの人数に換算する
      const countByDay =
        samples[idx2] == 0 ? 0 : datasets[idx].data[idx2] / samples[idx2]
      // データが欠損している場合は null とする
      datasets[idx].data[idx2] =
        datasets[idx].data[idx2] == null ? null : CalcUtil.round(countByDay)
    })
  })
  // console.log(datasets)

  const labels_sorted = ['', '', '', '', '', '', '']
  let datasets_sorted = []
  // 月曜日からラベルを並べ直す
  labels.forEach((value, idx) => {
    let weekNumber = DateUtil.weeklyNumberFromString(value)
    weekNumber = weekNumber == 0 ? 6 : weekNumber - 1
    labels_sorted[weekNumber] = labels[idx]
  })
  // 月曜日から来客数を並べ直す
  for (let idx2 = 0; idx2 < datasets.length; idx2++) {
    // console.log(datasets[idx2])

    const dat = {
      hour: datasets[idx2].hour,
      data: [null, null, null, null, null, null, null],
    }
    for (let idx3 = 0; idx3 < datasets[idx2].data.length; idx3++) {
      const value = datasets[idx2].data[idx3]
      // console.log(value)

      let weekNum = DateUtil.weeklyNumberFromString(labels[idx3])
      weekNum = weekNum == 0 ? 6 : weekNum - 1

      dat.data[weekNum] = value
    }

    // console.log(dat)
    datasets_sorted.push(dat)
  }

  // const labels_ = ['', '', '', '', '', '', '']
  // let datasets_ = []
  // datasets.forEach((dataset, idx) => {
  //   const dat = {
  //     hour: dataset.hour,
  //     data: [],
  //   }
  //   dataset.data.forEach((value, idx2) => {
  //     dat.data.push(value)
  //   })
  //   datasets_.push(dat)
  // })
  // // 月曜日から並べ直す
  // labels.forEach((value, idx) => {
  //   let weekNumber = DateUtil.weeklyNumberFromString(value)
  //   weekNumber = weekNumber == 0 ? 6 : weekNumber - 1
  //
  //   labels_[weekNumber] = labels[idx]
  //
  //   datasets.forEach((dataset, idx2) => {
  //     datasets_[idx2].data[weekNumber] = dataset.data[idx]
  //   })
  // })

  const tenantCountingHoursItems = {
    labels: labels_sorted,
    datasets: datasets_sorted,
    // labels: labels_,
    // datasets: datasets_,
  }
  // console.log(tenantCountingHoursItems)

  return tenantCountingHoursItems
}

// 来客数と来客属性の全店舗総計
// const convertDbToAllTenantStore = (response) => {
//   const TAG = `${MODULE_TAG}.${convertDbToAllTenantStore.name}`
//   logger.verbose(Logger.formatter(TAG, `begin`))
//   logger.verbose(response)
//
//   const summary = response.summary
//   const genderAge = response.genderAge
//   // console.log(summary)
//   // console.log(genderAge)
//
//   // パーセントに変換する
//   const sum = CalcUtil.sum(genderAge[0].rate) + CalcUtil.sum(genderAge[1].rate)
//   // TODO: 実データで妥当な合計値がどれかを検証する
//   // const sum = CalcUtil.sum(summary.datasets[0].data)
//   // console.log(sum)
//   // console.log(CalcUtil.sum(summary.datasets[0].data))
//   genderAge.forEach((item, genderNum) => {
//     item.rate.forEach((value, index) => {
//       genderAge[genderNum].rate[index] = CalcUtil.rate(value, sum)
//     })
//   })
//
//   return {
//     summary: summary,
//     genderAge: genderAge,
//   }
// }

export default {
  convertDbToStore,
  convertDbToTableStore,
  convertDbToMultiTenantStore,
  convertDbToMultiTenantTableStore,
  convertDbToOwnTenantStore,
  convertDbToOwnTenantDaysStore,
  convertDbToOwnTenantHoursStore,
  // convertDbToAllTenantStore,
}
