const newAppUserWithIDaaSparams = (
  sub,
  username,
  registered,
  name,
  department,
  tenant,
  description,
  contractId,
) => {
  return {
    id: sub,
    name: name,
    idaasUser: {
      sub: sub,
      username: username,
      email: '',
      registered: registered,
    },
    department: department,
    tenant: tenant,
    description: description,
    email: '',
    // role: role,
    sound: '設定できません',
    period: {
      start: '',
      end: '',
    },
    // usage: { rate: 0, period: '' },
    activity: '',
    contractID: contractId,
  }
}

export default {
  newAppUserWithIDaaSparams,
}
