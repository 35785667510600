import AppUser from '@/domain/models/AppUser'

import AppUserRepository from '@/adapters/AppUserRepository'
import IdaasRepository from '@/adapters/IdaasRepository'

import StoreRepository from '@/adapters/StoreRepository'

import DateUtil from '@/utils/DateUtil'

const getAppUsersList = async (input) => {
  const contractId = input.contractId

  // ユーザー一覧の取得
  const usersResultItems = await AppUserRepository.fetchAppUsersList(contractId)

  // ログインユーザー情報の更新
  // await IdaasRepository.updateLoginUsersByIDaaSUserStatus(context, {
  //   contractId,
  // })
  // IDaaSユーザーの状態取得
  const idaasUsers = await IdaasRepository.fetchLoginUsers()

  return {
    result: {
      userItems: usersResultItems,
      idaasUsers: idaasUsers,
    },
    error: null,
  }
}

const createUser = async (input) => {
  const username = input.username
  const password = input.password
  const email = input.email
  const contractId = input.contractId

  const user = await IdaasRepository.createNewUser({
    username,
    password,
    email,
  })
  if (!user) {
    return {
      result: null,
      error:
        '既にそのユーザーＩＤは使われているかもしれません。 違うユーザーＩＤを入力してください。',
    }
  }

  const sub = user.Attributes[0].Value
  // const username = user.Username
  const date = new Date(user.UserCreateDate)
  const registered = DateUtil.formatDate(date, 'YYYY-MM-DD')
  // ユーザープロファイルを生成して法人アカウントに関連付ける
  const userProfile = AppUser.newAppUserWithIDaaSparams(
    sub,
    username,
    registered,
    '', // name
    '', // department
    '', // tenant
    '', // description
    contractId,
  )
  // ログインユーザーの登録
  const res = await AppUserRepository.createNewAppUser({
    contractId,
    userProfile,
  })
  if (res.error) {
    // ロールバックする
    await IdaasRepository.deleteUser(username)
    return {
      result: null,
      error: 'ログインユーザーの登録に失敗しました',
    }
  }

  return {
    result: res.data,
    error: null,
  }
}

const editUserProfile = async (input) => {
  const contractId = input.contractId
  const sub = input.sub
  const username = input.username
  const registered = input.registered
  const name = input.name
  const department = input.department
  const tenant = input.tenant
  const description = input.description

  // const role = 'オペレーター'
  const userProfile = AppUser.newAppUserWithIDaaSparams(
    sub,
    username,
    registered,
    name,
    department,
    tenant,
    description,
    // role,
  )

  // ユーザーデータ更新
  const editResult = await AppUserRepository.updateUserProfile({
    contractId,
    userProfile,
  })
  if (!editResult) {
    return {
      result: null,
      error: 'ユーザーのDBへの登録に失敗しました',
    }
  }

  return {
    result: editResult.data.updateAppUser,
    error: null,
  }
}

const updateUserActivity = async (input) => {
  const userId = input.userId
  const activity = input.loginDate

  // ユーザーアクティビティの更新
  const editResult = await AppUserRepository.updateUserActivity({
    userId,
    activity,
  })
  if (!editResult) {
    return {
      result: null,
      error: 'ユーザーのDBの更新に失敗しました',
    }
  }

  return {
    result: editResult.data.updateAppUser,
    error: null,
  }
}

const enableUser = async (input) => {
  const username = input.username
  const enable = input.enable

  const result = await IdaasRepository.enableUser(username, enable)
  if (!result) {
    return {
      result: null,
      error: '不正な操作です',
    }
  }

  return {
    result: result,
    error: null,
  }
}

const deleteAppUser = async (input) => {
  const username = input.username
  const deleteId = input.deleteId

  // AppSyncにユーザー削除リクエストを送る
  await AppUserRepository.deleteResourceById(deleteId)

  // Cognito ユーザーを削除する
  const deleteResult = await IdaasRepository.deleteUser(username)
  if (!deleteResult) {
    return {
      result: null,
      error: '不正な操作です',
    }
  }

  return {
    result: deleteResult,
    error: null,
  }
}

export default {
  getAppUsersList,
  createUser,
  editUserProfile,
  updateUserActivity,
  enableUser,
  deleteAppUser,
}
