import AppUserViewModel from '@/adapters/AppUserViewModel'

import DateUtil from '@/utils/DateUtil'

import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'AppUserPresenter'

const convertNewDbDataToStore = (inputData) => {
  const TAG = `${MODULE_TAG}.${convertNewDbDataToStore.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(inputData)

  const date = new Date(inputData.idaasUser.registered)
  const registered = DateUtil.formatDate(date, 'YYYY-MM-DD')
  const enabled = true
  const status = 'CONFIRMED' // 新規作成直後にパスワードの変更は完了しているため
  const appUser = AppUserViewModel.newUserItemWithProfile(
    inputData,
    registered,
    enabled,
    status,
  )
  logger.verbose(Logger.formatter(TAG, '--- new AppUser ---'))
  logger.verbose(appUser)

  return appUser
}

const convertDbToStore = (fetchData) => {
  const TAG = `${MODULE_TAG}.${convertDbToStore.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(fetchData)

  const date = new Date(fetchData.createdAt)
  return {
    id: fetchData.id,
    user: {
      sub: fetchData.idaasUser.sub,
      username: fetchData.idaasUser.username,
      new: true,
      registered: DateUtil.formatDate(date, 'YYYY-MM-DD'),
      // enabled: user.Enabled,
      // status: user.UserStatus,
    },
    name: fetchData.name,
    corporate: '',
    email: fetchData.email,
    department: fetchData.department,
    tenant: fetchData.tenant,
    description: fetchData.description,
    role: 'オペレーター',
    sound: '設定できません',
    usage: { rate: 0, start: fetchData.start, end: fetchData.end, period: '' },
    activity: '',
  }
}

export default {
  convertNewDbDataToStore,
  convertDbToStore,
}
