import axios from 'axios'

// const defaultApiHost = 'localhost:8380'
// const defaultApiHost = 'localhost:8000'
const defaultApiHost = process.env.VUE_APP_API_HOST

const _makeHeaders = () => {
  const PMS_API_KEY = ''
  const headers = {
    'Content-Type': 'application/json',
    // Accept: 'application/json',
  }
  // console.log(headers)
  return headers
}

// Creating instance for Axios
const getApiInstance = (apiHost) => {
  // console.log(defaultApiHost)
  // console.log(apiHost)
  // console.log(!apiHost)
  // if (!apiHost || typeof apiHost === 'undefined') {
  //   console.log('t')
  // }
  const host = defaultApiHost
  // const host = apiHost
  // console.log(host)
  // if (!apiHost || typeof host === 'undefined') {
  //   console.log('tt')
  //   host = defaultApiHost
  //   console.log(host)
  // }
  const baseUrl = !host ? '' : 'http://' + host
  const api = axios.create({
    baseURL: baseUrl,
    timeout: 30000, // [msec.]
    headers: _makeHeaders(),
  })
  return api
}

export default {
  getApiInstance,
}
