import { API } from 'aws-amplify'

import { listAppUsers, getAppUser } from '@/graphql/queries'
import { createAppUser } from '@/graphql/mutations'
import { updateAppUser } from '@/graphql/mutations'
import { deleteAppUser } from '@/graphql/mutations'

import AppUser from '@/domain/models/AppUser'

import DateUtil from '@/utils/DateUtil'
import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'AppUserRepository'

const createNewAppUser = async ({ contractId, userProfile }) => {
  const TAG = `${MODULE_TAG}.${createNewAppUser.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  let inputData = {
    id: userProfile.idaasUser.sub,
    idaasUser: {
      username: userProfile.idaasUser.username,
      sub: userProfile.idaasUser.sub,
      registered: userProfile.idaasUser.registered,
    },
    name: userProfile.name,
    // role: userProfile.role,
    email: userProfile.email,
    department: userProfile.department,
    tenant: userProfile.tenant,
    description: userProfile.description,
    // period: { start: userProfile.usage.start, end: userProfile.usage.end },
    activity: userProfile.activity,
    contractID: contractId,
  }
  logger.verbose(Logger.formatter(TAG, `inputData:`))
  logger.verbose(inputData)

  const createResult = await API.graphql({
    query: createAppUser,
    variables: { input: inputData },
  })
    .then(async (result) => {
      logger.verbose('=== createAppUser result ===')
      logger.verbose(result)
      return {
        response: result,
        error: null,
      }
    })
    .catch((error) => {
      logger.error('=== createAppUser error ===')
      logger.error(error)
      // "Variable 'input' has coerced Null value for NonNull type 'ID!'"
      // "Variable 'input' has coerced Null value for NonNull type 'String!'"
      // "The variables input contains a field name 'user' that is not defined for input object type 'CreateAppUserInput' "
      // "The variables input contains a field name 'corporate' that is not defined for input object type 'CreateAppUserInput' "
      // "The variables input contains a field name 'contract' that is not defined for input object type 'CreateAppUserInput' "
      return {
        response: null,
        error: error,
      }
    })
  if (createResult.error) {
    return {
      data: null,
      error: createResult.error,
    }
  }
  logger.verbose(Logger.formatter(TAG, `createResult:`))
  logger.verbose(createResult)

  return {
    data: inputData,
    error: null,
  }
}

const getUserProfile = async ({ appUserId }) => {
  const TAG = `${MODULE_TAG}.${getUserProfile.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  let inputData = {
    id: appUserId,
  }
  logger.verbose(Logger.formatter(TAG, `inputData:`))
  logger.verbose(inputData)

  const getResult = await API.graphql({
    query: getAppUser,
    variables: inputData,
  })
    .then(async (result) => {
      logger.verbose('=== getAppUser result ===')
      logger.verbose(result)
      return result
    })
    .catch((error) => {
      logger.error('=== getAppUser error ===')
      logger.error(error)
      return null
    })
  if (!getResult) {
    return null
  }

  return getResult.data.getAppUser
}

const updateUserProfile = async ({ contractId, userProfile }) => {
  const TAG = `${MODULE_TAG}.${updateUserProfile.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const id = userProfile.idaasUser.sub

  let inputData = {
    id: id,
    idaasUser: {
      username: userProfile.idaasUser.username,
      sub: userProfile.idaasUser.sub,
      registered: userProfile.idaasUser.registered,
    },
    name: userProfile.name,
    // role: userProfile.role,
    email: userProfile.email,
    department: userProfile.department,
    tenant: userProfile.tenant,
    description: userProfile.description,
    // period: { start: userProfile.usage.start, end: userProfile.usage.end },
    activity: userProfile.activity,
    contractID: contractId,
  }
  logger.verbose(Logger.formatter(TAG, `inputData:`))
  logger.verbose(inputData)

  const updateResult = await API.graphql({
    query: updateAppUser,
    variables: { input: inputData },
  })
    .then(async (result) => {
      logger.verbose('=== updateAppUser result ===')
      logger.verbose(result)
      return result
    })
    .catch((error) => {
      logger.error('=== updateAppUser error ===')
      logger.error(error)
      return null
    })
  if (!updateResult) {
    return null
  }

  return updateResult
}

const updateUserActivity = async ({ userId, activity }) => {
  const TAG = `${MODULE_TAG}.${updateUserActivity.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  let inputData = {
    id: userId,
    activity: activity,
  }
  logger.verbose(Logger.formatter(TAG, `inputData:`))
  logger.verbose(inputData)

  const updateResult = await API.graphql({
    query: updateAppUser,
    variables: { input: inputData },
  })
    .then(async (result) => {
      logger.verbose('=== updateAppUser result ===')
      logger.verbose(result)
      return result
    })
    .catch((error) => {
      logger.error('=== updateAppUser error ===')
      logger.error(error)
      return null
    })
  if (!updateResult) {
    return null
  }

  return updateResult
}

const fetchAppUsersList = async (contractId) => {
  const TAG = `${MODULE_TAG}.${fetchAppUsersList.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const listResult = await API.graphql({
    query: listAppUsers,
    // limit: 50,
  })
    .then((result) => {
      logger.verbose('=== listAppUsers result ===')
      logger.verbose(result)
      return result
    })
    .catch((error) => {
      logger.error('=== listAppUsers error ===')
      logger.error(error)
      return null
    })
  if (!listResult) {
    return []
  }

  let items = []
  listResult.data.listAppUsers.items.forEach((fetchItem) => {
    if (fetchItem.contractID == contractId) {
      items.push(fetchItem)
    }
  })

  return items
}

const deleteResourceById = async (deleteId) => {
  const TAG = `${MODULE_TAG}.${deleteResourceById.name}`
  logger.verbose(Logger.formatter(TAG, `begin with deleteId: ${deleteId}`))

  // logger.verbose(
  //   'call AppUserRepository.deleteResourceById, deleteId: ' + deleteId,
  // )

  if (deleteId == null || deleteId == '' || typeof deleteId === undefined)
    return

  await API.graphql({
    query: deleteAppUser,
    variables: { input: { id: deleteId } },
  })
    .then((result) => {
      logger.verbose('=== deleteAppUser result ===')
      logger.verbose(result)
    })
    .catch((error) => {
      logger.error('=== deleteAppUser error ===')
      logger.error(error)
    })
}

export default {
  createNewAppUser,
  getUserProfile,
  updateUserProfile,
  updateUserActivity,
  fetchAppUsersList,
  deleteResourceById,
}
