import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'LocalStorageRepository'

const loadSettings = async () => {
  const apiHost = localStorage.getItem('apihost')

  return {
    response: {
      apiHost: apiHost,
    },
    error: null,
  }
}

const saveSettings = async ({ apiHost }) => {
  localStorage.setItem('apihost', apiHost)

  return {
    response: true,
    error: null,
  }
}

export default {
  loadSettings,
  saveSettings,
}
