import ContractViewModel from '@/adapters/ContractViewModel'
import presenter from '@/adapters/presenter'

import DateUtil from '@/utils/DateUtil'
import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'ContractPresenter'

const convertDbToStoreWithStatus = (inputData, enabled, status) => {
  const TAG = `${MODULE_TAG}.${convertDbToStoreWithStatus.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(inputData)

  const contractItem = ContractViewModel.newContractItem()
  contractItem.id = inputData.id
  contractItem.accountID = inputData.accountID
  contractItem.account.sub = inputData.account.sub
  contractItem.account.username = inputData.account.username
  contractItem.account.email = inputData.account.email
  contractItem.account.registered = inputData.account.registered
  contractItem.account.enabled = enabled
  contractItem.account.status = status
  contractItem.account.activity = inputData.account.activity
  contractItem.corporate = inputData.corporate
  contractItem.owner = inputData.owner
  contractItem.email = inputData.email
  contractItem.description = inputData.description
  contractItem.usage.start = inputData.period.start
  contractItem.usage.end = inputData.period.end
  contractItem.usage.rate = presenter.calcUsageRate(
    contractItem.usage.start,
    contractItem.usage.end,
  )

  return contractItem
}

const mergeDbToStore = (
  fetchAccountData,
  fetchContractData,
  enabled,
  status,
) => {
  const TAG = `${MODULE_TAG}.${mergeDbToStore.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(fetchAccountData)
  logger.verbose(fetchContractData)

  return {
    id: fetchContractData.id,
    account: {
      sub: fetchAccountData.idaasUser.sub,
      username: fetchAccountData.idaasUser.username,
      name: fetchAccountData.idaasUser.name,
      new: true,
      registered: fetchAccountData.idaasUser.registered,
      enabled: enabled,
      status: status,
      activity: fetchAccountData.activity,
    },
    owner: fetchContractData.owner,
    corporate: fetchContractData.corporate,
    email: fetchContractData.email,
    description: fetchContractData.description,
    usage: {
      rate: presenter.calcUsageRate(
        fetchContractData.period.start,
        fetchContractData.period.end,
      ),
      start: fetchContractData.period.start,
      end: fetchContractData.period.end,
      period: '',
    },
  }
}

export default {
  convertDbToStoreWithStatus,
  mergeDbToStore,
}
