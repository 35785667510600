import { API } from 'aws-amplify'

import { listContracts, getContract } from '@/graphql/queries'
import { createContract } from '@/graphql/mutations'
import { updateContract } from '@/graphql/mutations'
import { deleteContract } from '@/graphql/mutations'

import IdaasRepository from '@/adapters/IdaasRepository'

import Account from '@/domain/models/Account'
import Contract from '@/domain/models/Contract'

import DateUtil from '@/utils/DateUtil'
import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'ContractRepository'

const createNewContract = async ({ contract }) => {
  const TAG = `${MODULE_TAG}.${createNewContract.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const inputData = contract

  const createResult = await API.graphql({
    query: createContract,
    variables: { input: inputData },
  })
    .then((result) => {
      logger.verbose('=== createContract result ===')
      logger.verbose(result)
      return result
    })
    .catch((error) => {
      logger.error('=== createContract error ===')
      logger.error(error)
      return null
    })

  return createResult.data.createContract
}

const editContract = async ({ contract }) => {
  const TAG = `${MODULE_TAG}.${editContract.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const inputData = contract

  const updateResult = await API.graphql({
    query: updateContract,
    variables: { input: inputData },
  })
    .then((result) => {
      logger.verbose('=== updateContract result ===')
      logger.verbose(result)
      return result
    })
    .catch((error) => {
      logger.error('=== updateContract error ===')
      logger.error(error)
      return null
    })

  return updateResult.data.updateContract
}

const fetchContractList = async ({ fetchUsers }) => {
  const TAG = `${MODULE_TAG}.${fetchContractList.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const username = null
  return await fetchContractListWithUsername({ fetchUsers, username })
}

const fetchContractListWithUsername = async ({ fetchUsers, username }) => {
  const TAG = `${MODULE_TAG}.${fetchContractListWithUsername.name}`
  logger.verbose(Logger.formatter(TAG, `begin, username: ${username}`))
  logger.verbose(fetchUsers)

  const inUsername = username

  const contractsResult = await API.graphql({
    query: listContracts,
    // limit: 50,
  })
    .then(async (result) => {
      logger.verbose('=== listContracts result ===')
      logger.verbose(result)
      return result
    })
    .catch((error) => {
      logger.error('=== listContracts error ===')
      logger.error(error)
      return null
    })
  if (!contractsResult) {
    return
  }

  return contractsResult
}

const fetchContractListWithIdaasUser = async (context) => {
  const TAG = `${MODULE_TAG}.${fetchContractListWithIdaasUser.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const groupName = 'Corporates'
  const accounts = null
  const nextToken = null
  // Cognito からユーザーのリストを取り出す
  const listUsers = await IdaasRepository.fetchIdaasUserList(context, {
    groupName,
    accounts,
    nextToken,
  })

  // AppSyncから契約のリストを取り出す
  await fetchContractList(context, { listUsers })
}

const fetchContract = async (accountId) => {
  const TAG = `${MODULE_TAG}.${fetchContract.name}`
  logger.verbose(Logger.formatter(TAG, `begin with accountId: ${accountId}`))

  // const contractResult = await API.graphql({
  //   query: getContract,
  //   variables: { accountID: accountId },
  // })
  const contractResult = await API.graphql({
    query: listContracts,
    filter: { accountID: accountId },
  })
    .then((result) => {
      logger.verbose('=== getContract result ===')
      logger.verbose(result)
      return {
        response: result,
        error: null,
      }
    })
    .catch((error) => {
      logger.error('=== getContract error ===')
      logger.error(error)
      return {
        response: null,
        error: error,
      }
    })
  if (contractResult.error) {
    return {
      data: null,
      error: contractResult.error,
    }
  }

  let items = []
  contractResult.response.data.listContracts.items.forEach(function (
    contract,
    index,
  ) {
    if (contract.accountID == accountId) {
      items.push(contract)
    }
  })

  return {
    // data: contractResult.response.data.listContracts.items,
    data: items,
    error: null,
  }
}

const fetchContractById = async (contractId) => {
  const TAG = `${MODULE_TAG}.${fetchContractById.name}`
  logger.verbose(Logger.formatter(TAG, `begin with contractId: ${contractId}`))

  const contractResult = await API.graphql({
    query: getContract,
    variables: { id: contractId },
  })
    .then((result) => {
      logger.verbose('=== getContract result ===')
      logger.verbose(result)
      return {
        response: result,
        error: null,
      }
    })
    .catch((error) => {
      logger.error('=== getContract error ===')
      logger.error(error)
      return {
        response: null,
        error: error,
      }
    })
  if (contractResult.error) {
    return {
      data: null,
      error: contractResult.error,
    }
  }

  return {
    data: contractResult.response.data.getContract,
    error: null,
  }
}

const deleteResourceById = async (contractId) => {
  const TAG = `${MODULE_TAG}.${deleteResourceById.name}`
  logger.verbose(Logger.formatter(TAG, `begin with contractId: ${contractId}`))

  if (contractId == null || contractId == '' || typeof contractId === undefined)
    return

  await API.graphql({
    query: deleteContract,
    variables: { input: { id: contractId } },
  })
    .then((result) => {
      logger.verbose('=== deleteContract result ===')
      logger.verbose(result)
    })
    .catch((error) => {
      logger.error('=== deleteContract error ===')
      logger.error(error)
    })
}

export default {
  createNewContract,
  editContract,
  fetchContractList,
  fetchContractListWithUsername,
  fetchContractListWithIdaasUser,
  fetchContract,
  fetchContractById,
  deleteResourceById,
}
