import Tenant from '@/domain/models/Tenant'

import TenantRepository from '@/adapters/TenantRepository'

const getTenantInfomation = async (input) => {
  const deviceId = input.deviceId

  // 店舗基本情報の取得
  const response = await TenantRepository.fetchTenantInfo(deviceId)
  // console.log(response)
  if (response.error) {
    return {
      result: null,
      error: new Error('店舗基本情報の取得に失敗しました'),
    }
  }

  return {
    result: response.data,
    error: null,
  }
}

const createTenantInformation = async (input) => {
  const deviceId = input.deviceId
  // 店舗基本情報定義する
  const tenantInfo = {
    name: input.name,
    area: input.area,
    location: input.location,
    description: input.description,
    begin: input.begin,
    open: input.open,
    close: input.close,
    iotDeviceID: deviceId,
  }
  // 店舗基本情報を登録する
  const res = await TenantRepository.createTenantInfo({
    deviceId,
    tenantInfo,
  })
  if (res.error) {
    return {
      result: null,
      error: new Error('店舗基本情報の登録に失敗しました'),
    }
  }

  // 店舗基本情報の取得
  const response = await TenantRepository.fetchTenantInfo(deviceId)
  if (response.error) {
    return {
      result: null,
      error: new Error('店舗基本情報の取得に失敗しました'),
    }
  }

  return {
    result: response.data,
    error: null,
  }
}

const editTenantInformation = async (input) => {
  const deviceId = input.deviceId
  const tenantInfo = {
    id: input.id,
    name: input.name,
    area: input.area,
    location: input.location,
    description: input.description,
    begin: input.begin,
    open: input.open,
    close: input.close,
  }

  // 施策の更新
  const response = await TenantRepository.updateTenantInfo({
    deviceId,
    tenantInfo,
  })
  if (response.error) {
    return {
      result: null,
      error: new Error('店舗基本情報の更新に失敗しました'),
    }
  }

  return {
    result: response.data,
    error: null,
  }
}

const deleteTenantInformation = async (input) => {
  const deviceId = input.deviceId
  const deleteId = input.deleteId

  // 施策の削除
  const res = await TenantRepository.deleteResourceById(deleteId)
  if (res.error) {
    return {
      result: null,
      error: new Error('店舗基本情報の削除処理に失敗しました'),
    }
  }

  // 店舗基本情報の取得
  const response = await TenantRepository.fetchTenantInfo(deviceId)
  if (response.error) {
    return {
      result: null,
      error: new Error('店舗基本情報の取得に失敗しました'),
    }
  }

  return {
    result: response.data,
    error: null,
  }
}

export default {
  getTenantInfomation,
  createTenantInformation,
  editTenantInformation,
  deleteTenantInformation,
}
