// import DownloadData from '@/domain/models/DownloadData'

import DeviceRepository from '@/adapters/DeviceRepository'
import CloudStorageRepository from '@/adapters/CloudStorageRepository'

import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

// ユースケース｜集計データファイルを zip でまとめてダウンロードする
const startDownloadZipfile = async (input) => {
  const contractId = input.contractId
  const identityId = input.identityId
  const tenants = input.tenants
  const start = input.start
  const end = input.end
  const type = input.type

  // デバイス情報の一覧を取得する（storagePath）
  const resdev = await DeviceRepository.fetchDevicesList(contractId)
  const devices = resdev.data
  // const resdev = await MockDeviceRepository.fetchDevicesList(contractId)
  // console.log(resdev.data[0])

  for (const device of devices) {
    const files = []
    let isTenantMath = false
    for (const tenant of tenants) {
      // console.log(device.location, tenant)
      if (device.location == tenant) {
        isTenantMath = true
      }
    }
    if (isTenantMath) {
      const storagePath = device.storagePath
      // console.log(storagePath)

      const output = await CloudStorageRepository.getDataFileList(storagePath, {
        start,
        end,
        type,
      })
      // console.log(output.data)
      if (output.error == null) {
        const subDir = type == 'Hourly' ? '/hourly/' : '/'
        output.data.forEach((fileName) => {
          const name = fileName + '.csv'
          files.push({
            name: name,
            key: storagePath + subDir + name,
          })
        })

        const tenantName = device.location
          .replaceAll(' ', '')
          .replaceAll('/', '_')
        const postFix =
          type == 'Monthly'
            ? tenantName + '_' + type
            : tenantName +
              '_' +
              type +
              '_' +
              start.replaceAll('-', '') +
              '-' +
              end.replaceAll('-', '')

        const folderName = 'pmsdata_' + postFix

        logger.verbose('--- start download files: ' + files + ' ---')
        await CloudStorageRepository.getDataFiles(identityId, files, folderName)
        logger.verbose('--- end download files ---')
      }
    }
  }

  return {
    result: {},
    error: null,
  }
}

export default {
  startDownloadZipfile,
}
