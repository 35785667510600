const newCountingBaseItem = () => {
  return {
    date: '',
    area: '',
    tenant: '',
    sales: 0.0,
    // traffic: 0.0,
    // staying: 0.0,
    inCount: 0,
    inAttribute: 0,
    noAttribute: 0,
    purchaseRate: 0.0,
    transaction: 0.0,
    customerPrice: 0.0,
    maleAge10: 0,
    maleAge20: 0,
    maleAge30: 0,
    maleAge40: 0,
    maleAge50: 0,
    maleNoage: 0,
    femaleAge10: 0,
    femaleAge20: 0,
    femaleAge30: 0,
    femaleAge40: 0,
    femaleAge50: 0,
    femaleNoage: 0,
    male: 0,
    female: 0,
    age10: 0,
    age20: 0,
    age30: 0,
    age40: 0,
    age50: 0,
    // カウントライン
    in01: 0,
    in02: 0,
    in03: 0,
    in04: 0,
    in05: 0,
    in06: 0,
    in07: 0,
    in08: 0,
    in09: 0,
    in10: 0,
    out01: 0,
    out02: 0,
    out03: 0,
    out04: 0,
    out05: 0,
    out06: 0,
    out07: 0,
    out08: 0,
    out09: 0,
    out10: 0,
    validAttribute01: 0,
    validAttribute02: 0,
    validAttribute03: 0,
    validAttribute04: 0,
    validAttribute05: 0,
    validAttribute06: 0,
    validAttribute07: 0,
    validAttribute08: 0,
    validAttribute09: 0,
    validAttribute10: 0,
  }
}

const copyCountingBaseItem = (record) => {
  let newRecord = newCountingBaseItem()

  newRecord.tenant = record.tenant
  newRecord.area = record.area
  newRecord.date = record.date
  // newRecord.traffic = record.traffic
  // newRecord.staying = record.staying
  newRecord.inCount = record.inCount
  newRecord.noAttribute = record.noAttribute
  newRecord.inAttribute = record.inAttribute
  newRecord.maleAge10 = record.maleAge10
  newRecord.maleAge20 = record.maleAge20
  newRecord.maleAge30 = record.maleAge30
  newRecord.maleAge40 = record.maleAge40
  newRecord.maleAge50 = record.maleAge50
  newRecord.maleNoage = record.maleNoage
  newRecord.femaleAge10 = record.femaleAge10
  newRecord.femaleAge20 = record.femaleAge20
  newRecord.femaleAge30 = record.femaleAge30
  newRecord.femaleAge40 = record.femaleAge40
  newRecord.femaleAge50 = record.femaleAge50
  newRecord.femaleNoage = record.femaleNoage
  // 計算値
  newRecord.male = record.male
  newRecord.female = record.female
  newRecord.age10 = record.age10
  newRecord.age20 = record.age20
  newRecord.age30 = record.age30
  newRecord.age40 = record.age40
  newRecord.age50 = record.age50
  // カウントライン
  newRecord.in01 = record.in01
  newRecord.in02 = record.in02
  newRecord.in03 = record.in03
  newRecord.in04 = record.in04
  newRecord.in05 = record.in05
  newRecord.in06 = record.in06
  newRecord.in07 = record.in07
  newRecord.in08 = record.in08
  newRecord.in09 = record.in09
  newRecord.in10 = record.in10
  newRecord.out01 = record.out01
  newRecord.out02 = record.out02
  newRecord.out03 = record.out03
  newRecord.out04 = record.out04
  newRecord.out05 = record.out05
  newRecord.out06 = record.out06
  newRecord.out07 = record.out07
  newRecord.out08 = record.out08
  newRecord.out09 = record.out09
  newRecord.out10 = record.out10
  newRecord.validAttribute01 = record.inattr01
  newRecord.validAttribute02 = record.inattr02
  newRecord.validAttribute03 = record.inattr03
  newRecord.validAttribute04 = record.inattr04
  newRecord.validAttribute05 = record.inattr05
  newRecord.validAttribute06 = record.inattr06
  newRecord.validAttribute07 = record.inattr07
  newRecord.validAttribute08 = record.inattr08
  newRecord.validAttribute09 = record.inattr09
  newRecord.validAttribute10 = record.inattr10

  return newRecord
}

const newCountingListItem = () => {
  const base = newCountingBaseItem()
  return {
    ...base,
    // 時系列データ

    // 総数
    inCounts: [],
    attrSamples: [],
    attrBlanks: [],
    // stayings: [],

    // 属性別
    genderAgeRate: [],
    ageRate: [],
    malesData: [[], [], [], [], []],
    femalesData: [[], [], [], [], []],
    ageCountArray: [],
    agesArray: [],
    maleAgeRate: [],
    femaleAgeRate: [],
    maleCounts: [],
    femaleCounts: [],

    // カウントライン別
    in01s: [],
    in02s: [],
    in03s: [],
    in04s: [],
    in05s: [],
    in06s: [],
    in07s: [],
    in08s: [],
    in09s: [],
    in10s: [],
    out01s: [],
    out02s: [],
    out03s: [],
    out04s: [],
    out05s: [],
    out06s: [],
    out07s: [],
    out08s: [],
    out09s: [],
    out10s: [],
    validAttribute01s: [],
    validAttribute02s: [],
    validAttribute03s: [],
    validAttribute04s: [],
    validAttribute05s: [],
    validAttribute06s: [],
    validAttribute07s: [],
    validAttribute08s: [],
    validAttribute09s: [],
    validAttribute10s: [],
    attributes: {
      gender: [],
      genderAge: {},
    },
  }
}

export default {
  newCountingBaseItem,
  copyCountingBaseItem,
  newCountingListItem,
}
