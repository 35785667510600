import TenantInteractor from '@/domain/usecases/TenantInteractor'

import TenantPresenter from '@/adapters/TenantPresenter'
import StoreRepository from '@/adapters/StoreRepository'

import DateUtil from '@/utils/DateUtil'
import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'TenantController'

// 店舗情報の取得
const fetchTenantInfo = async (context) => {
  const TAG = `${MODULE_TAG}.${fetchTenantInfo.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const cwlogger = await Logger.getInstance(context.getters.getCurrentUsername)
  cwlogger?.verbose(`begin ${TAG}`)

  const input = {
    // 現在ログインしているアカウントの契約情報を取得する
    contractId: context.state.contract.id,
    deviceId: context.state.tenantDatabaseItem.id,
  }
  logger.verbose('contractId: ' + input.contractId)
  logger.verbose('deviceId: ' + input.deviceId)
  const identityId = context.state.user.attributes.sub

  // ユースケース｜店舗基本情報の取得
  const output = await TenantInteractor.getTenantInfomation(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, `getTenantInfomation error`))
    logger.error(output.error)
    return
  }

  const tenantItem = TenantPresenter.convertNewDbDataToStore(output.result)
  logger.verbose('=== tenantItem ===')
  logger.verbose(tenantItem)

  // ストアの更新
  context.commit('setTenantInfoItem', tenantItem)
}

// 店舗基本情報の新規登録
const createTenantInfo = async (
  context,
  { begin, open, close, description },
) => {
  const TAG = `${MODULE_TAG}.${createTenantInfo.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  // 現在ログインしているアカウントの契約情報を取得する
  const contractId = context.state.contract.id
  if (!contractId) {
    const error = '契約ＩＤが存在しません'
    logger.error(Logger.formatter(TAG, error))
    alert('error')
    return
  }
  // デバイス情報を取得する
  const deviceId = context.state.tenantDatabaseItem.id
  if (!deviceId) {
    const error = 'デバイスＩＤが存在しません'
    logger.error(Logger.formatter(TAG, error))
    alert('error')
    return
  }

  const tenantInfoItem = context.state.tenantInfoItem
  // 重複登録の抑制
  if (tenantInfoItem.name != '') {
    const error = '店舗情報は登録済みです'
    logger.error(Logger.formatter(TAG, error))
    alert(error)
    return
  }

  const area = context.state.tenantDatabaseItem.category
  const location = context.state.tenantDatabaseItem.location
  const name = location

  // 店舗基本情報
  const input = {
    name,
    area,
    location,
    description,
    begin,
    open,
    close,
    deviceId,
  }

  // 店舗基本情報の登録を要求する
  const output = await TenantInteractor.createTenantInformation(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, `createTenantInformation error`))
    logger.error(output.error)
    alert(output.error)
    return
  }
  const resultData = output.result
  const newItem = TenantPresenter.convertNewDbDataToStore(resultData)
  logger.verbose('=== create new promotion! ===')
  logger.verbose(newItem)

  // ストアに新しい店舗基本情報として追加する ~ 画面更新
  context.commit('setTenantInfoItem', newItem)
  return
}

// 店舗基本情報の編集
const editTenantInfo = async (
  context,
  { id, name, description, begin, open, close },
) => {
  const TAG = `${MODULE_TAG}.${editTenantInfo.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(name, description, begin, open, close)

  // 現在ログインしているアカウントの契約IDを取得する
  const contractId = context.state.contract.id
  // 店舗に設置したデバイスIDを取得する
  const deviceId = context.state.tenantDatabaseItem.id
  if (!deviceId) {
    const error = 'デバイスＩＤが存在しません'
    logger.error(Logger.formatter(TAG, error))
    alert('error')
    return
  }

  const tenantInfoItem = context.state.tenantInfoItem
  // 更新の抑制
  if (tenantInfoItem.name == '') {
    const error = '店舗情報が未登録です'
    logger.error(Logger.formatter(TAG, error))
    alert(error)
    return
  }

  const areaName = context.state.tenantDatabaseItem.category
  const location = context.state.tenantDatabaseItem.location

  const input = {
    id: id,
    name: name,
    area: areaName,
    location: location,
    description: description,
    begin: begin,
    open: open,
    close: close,
  }
  logger.verbose(Logger.formatter(TAG, `input`))
  logger.verbose(input)

  const output = await TenantInteractor.editTenantInformation(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, output.error))
    alert(output.error)
    return
  }
  const resultData = output.result
  logger.verbose('edit id: ' + resultData.id)

  const editItem = TenantPresenter.convertNewDbDataToStore(resultData)
  // ストアの更新
  context.commit('setTenantInfoItem', editItem)
  return
}

// 店舗基本情報の削除
const deleteTenantInfo = async (context, { deleteId }) => {
  const TAG = `${MODULE_TAG}.${deleteTenantInfo.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const deviceId = context.state.tenantDatabaseItem.id

  const input = {
    deviceId: deviceId,
    deleteId: deleteId,
  }
  // AppSync に施策削除のリクエストを送る
  const output = await TenantInteractor.deleteTenantInformation(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, output.error))
    alert(output.error)
    return
  }
  logger.verbose('delete id: ' + deleteId)

  const resultData = output.result
  const editItem = TenantPresenter.convertNewDbDataToStore(resultData)
  // ストアの更新
  context.commit('setTenantInfoItem', editItem)
  return
}

export default {
  createTenantInfo,
  fetchTenantInfo,
  editTenantInfo,
  deleteTenantInfo,
}
