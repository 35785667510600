import { createStore } from 'vuex'

import AuthController from '@/adapters/AuthController'
import AccountController from '@/adapters/AccountController'
import ContractController from '@/adapters/ContractController'
import AppUserController from '@/adapters/AppUserController'
import DeviceController from '@/adapters/DeviceController'
import ServiceController from '@/adapters/ServiceController'

import ContractViewModel from '@/adapters/ContractViewModel'

import CountingController from '@/adapters/CountingController'
import DownloadDataController from '@/adapters/DownloadDataController'
import PromotionController from '@/adapters/PromotionController'
import TenantController from '@/adapters/TenantController'
import SystemToolController from '@/adapters/SystemToolController'

import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

export default createStore({
  state: {
    user: null,
    group: null,

    accountItems: [],
    contractItems: [],

    // 現在ログインしているアカウントの契約
    contract: null,
    deviceItems: [],
    optionItems: [],

    userItems: [],

    homeDatesLabelItem: ' ~ ',
    homeTenantTableItems: [],
    homeStartDateItem: '',
    homeEndDateItem: '',
    homeSummaryItems: [],
    homeCountingItems: {
      labels: [],
      datasets: [],
    },
    homeGenderAgeItems: {
      gender: [],
      genderAge: [],
    },
    multiTenantCountingItems: {
      labels: [],
      datasets: [],
    },
    multiTenantSummaryItems: [],
    tenantInfoItem: {
      id: '',
      name: '',
      area: '',
      location: '',
      description: '',
      businessHours: [
        {
          begin: '',
          open: 0,
          close: 0,
        },
      ],
    },
    tenantDatabaseItem: null,
    tenantSelectedCalenderDate: {
      selected: false,
      begin: '',
      end: '',
    },
    tenantMeasurementItems: {},
    tenantCountingItems: {},
    tenantCountingDaysItems: {
      labels: [],
      datasets: [],
    },
    tenantCountingHoursItems: {
      labels: [],
      datasets: [],
    },
    tenantSummaryItems: {
      labels: [],
      maleRate: 0,
      femaleRate: 0,
      genderAgeRate: [],
      ageRate: [],
      malesData: [],
      femalesData: [],
      maleCounts: [],
      femaleCounts: [],
      inCounts: [],
      // stayings: [],
      maleAge10: 0,
      maleAge20: 0,
      maleAge30: 0,
      maleAge40: 0,
      maleAge50: 0,
      maleNoage: 0,
      femaleAge10: 0,
      femaleAge20: 0,
      femaleAge30: 0,
      femaleAge40: 0,
      femaleAge50: 0,
      femaleNoage: 0,
      male: 0,
      female: 0,
      age10: 0,
      age20: 0,
      age30: 0,
      age40: 0,
      age50: 0,
    },
    tenantAttribute1DateSwitch: 'Daily1M',
    tenantAttribute2DateSwitch: 'Daily1M',
    tenantAttributeDate: {
      start: '',
      end: '',
      type: '',
    },
    tenantTargetItems: {
      genderLabel: '',
      ageGroupLabel: '',
      holiday: [0, 0], // [weekday, holiday]
      dayOfWeek: [0, 0, 0, 0, 0, 0, 0],
    },
    tenantTargetSummaryDaysItems: {
      labels: [],
      datasets: [],
      malesData: [],
      femalesData: [],
    },
    tenantTargetHoursItems: {
      labels: [],
      datasets: [],
    },
    spotDirectionItems: {
      labels: [],
      datasets: [],
    },
    spotDirectionSummaryItems: [],
    promoEventItems: [],
    downloadPeriodItems: [],
    settingItems: {
      apiHost: '',
    },
    systemToolItems: {
      attrDevices: [],
    },
    dataRecoveryItems: {
      reCalcJobList: [],
    },
    softwareVersionItems: {
      images: [],
      job: {
        status: '',
      },
    },

    nowLoding: false,

    sidebarVisible: '',
    sidebarUnfoldable: false,
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setGroup(state, group) {
      state.group = group
    },
    clearAllItems(state) {
      state.accountItems = []
      state.contractItems = []

      state.contract = null
      state.deviceItems = []
      state.optionItems = []

      state.userItems = []

      state.homeDatesLabelItem = ' ~ '
      state.homeTenantTableItems = []
      state.homeStartDateItem = ''
      state.homeEndDateItem = ''

      state.homeSummaryItems = []
      state.homeCountingItems = {
        labels: [],
        datasets: [],
      }
      state.homeGenderAgeItems = {
        gender: [],
        genderAge: [],
      }
      state.multiTenantCountingItems = {
        labels: [],
        datasets: [],
      }
      state.multiTenantSummaryItems = []
      state.tenantInfoItem = {
        id: '',
        name: '',
        area: '',
        location: '',
        description: '',
        businessHours: [
          {
            begin: '',
            open: 0,
            close: 0,
          },
        ],
      }
      state.tenantDatabaseItem = null
      state.tenantSelectedCalenderDate = {
        selected: false,
        begin: '',
        end: '',
      }
      state.tenantMeasurementItems = {}
      state.tenantCountingItems = {}
      state.tenantCountingDaysItems = {
        labels: [],
        datasets: [],
      }
      state.tenantCountingHoursItems = {
        labels: [],
        datasets: [],
      }
      state.tenantSummaryItems = {
        labels: [],
        maleRate: 0,
        femaleRate: 0,
        genderAgeRate: [],
        ageRate: [],
        agesArray: [],
        maleAgeRate: [],
        femaleAgeRate: [],
        malesData: [],
        femalesData: [],
        maleCounts: [],
        femaleCounts: [],
        inCounts: [],
        // stayings: [],
        maleAge10: 0,
        maleAge20: 0,
        maleAge30: 0,
        maleAge40: 0,
        maleAge50: 0,
        maleNoage: 0,
        femaleAge10: 0,
        femaleAge20: 0,
        femaleAge30: 0,
        femaleAge40: 0,
        femaleAge50: 0,
        femaleNoage: 0,
        male: 0,
        female: 0,
        age10: 0,
        age20: 0,
        age30: 0,
        age40: 0,
        age50: 0,
      }
      state.tenantAttribute1DateSwitch = 'Daily1M'
      state.tenantAttribute2DateSwitch = 'Daily1M'
      state.tenantAttributeDate = {
        start: '',
        end: '',
        type: '',
      }
      state.tenantTargetItems = {
        genderLabel: '',
        ageGroupLabel: '',
        holiday: [0, 0],
        dayOfWeek: [0, 0, 0, 0, 0, 0, 0],
      }
      state.tenantTargetSummaryDaysItems = {
        labels: [],
        datasets: [],
        malesData: [],
        femalesData: [],
      }
      state.tenantTargetHoursItems = {
        labels: [],
        datasets: [],
      }
      state.spotDirectionItems = {
        labels: [],
        datasets: [],
      }
      state.spotDirectionSummaryItems = []
      state.promoEventItems = []
      state.downloadPeriodItems = []
      state.settingItems = {
        apiHost: '',
      }
      state.systemToolItems = {
        attrDevices: [],
      }
      state.dataRecoveryItems = {
        reCalcJobList: [],
      }
      state.softwareVersionItems = {
        images: [],
        job: {
          status: '',
        },
      }
    },

    setAccountItems(state, items) {
      const accountItems = JSON.parse(JSON.stringify(items))
      state.accountItems = accountItems
      logger.verbose('setAccountItems')
      logger.verbose(state.accountItems)
    },
    setAccountItem(state, payload) {
      const index = payload.index
      const item = payload.item
      let items = state.accountItems

      if (0 <= index) {
        items[index] = item
      } else {
        items.push(item)
      }
      state.accountItems = items
      logger.verbose('setAccountItem')
      logger.verbose(items)
    },

    setContract(state, item) {
      state.contract = item
      logger.verbose('setContract')
      logger.verbose(state.contract)
    },
    setContractItems(state, items) {
      const contractItems = JSON.parse(JSON.stringify(items))
      state.contractItems = contractItems
      logger.verbose('setContractItems')
      logger.verbose(state.contractItems)
    },
    setContractItem(state, payload) {
      const index = payload.index
      const item = payload.item
      let items = state.contractItems

      if (0 <= index) {
        items[index] = item
      } else {
        items.push(item)
      }
      state.contractItems = items
      logger.verbose('setContractItem, index: ' + index)
      logger.verbose(state.contractItems)
    },

    setDeviceItems(state, items) {
      if (0 < items.length) {
        const deviceItems = JSON.parse(JSON.stringify(items))
        state.deviceItems = deviceItems
      } else {
        state.deviceItems = []
      }
      logger.verbose('setDeviceItems')
      logger.verbose(state.deviceItems)
    },
    setDeviceItem(state, payload) {
      const index = payload.index
      const item = payload.item
      let items = state.deviceItems

      if (0 <= index) {
        items[index] = item
      } else {
        items.push(item)
      }
      state.deviceItems = items
    },

    setOptionItems(state, items) {
      const optionItems = JSON.parse(JSON.stringify(items))
      state.optionItems = optionItems
      logger.verbose('setOptionItems')
      logger.verbose(state.optionItems)
    },
    setOptionItem(state, payload) {
      const index = payload.index
      const item = payload.item
      let items = state.optionItems

      if (0 <= index) {
        items[index] = item
      } else {
        items.push(item)
      }
      state.optionItems = items
    },

    setUserItems(state, items) {
      const userItems = JSON.parse(JSON.stringify(items))
      state.userItems = userItems
      logger.verbose('setUserItems')
      logger.verbose(state.userItems)
    },
    setUserItem(state, payload) {
      const index = payload.index
      const item = payload.item
      let items = state.userItems

      if (0 <= index) {
        items[index] = item
      } else {
        items.push(item)
      }
      state.userItems = items
      logger.verbose('setUserItem, index: ' + index)
      logger.verbose(state.userItems)
    },

    setHomeDatesLabelItem(state, value) {
      state.homeDatesLabelItem = value
    },
    setHomeTenantTableItems(state, items) {
      const homeTenantTableItems = JSON.parse(JSON.stringify(items))
      state.homeTenantTableItems = homeTenantTableItems
      logger.verbose('setHomeTenantTableItems')
      logger.verbose(state.homeTenantTableItems)
    },
    setHomeStartDateItem(state, value) {
      state.homeStartDateItem = value
    },
    setHomeEndDateItem(state, value) {
      state.homeEndDateItem = value
    },

    setHomeSummaryItems(state, items) {
      const homeSummaryItems = JSON.parse(JSON.stringify(items))
      state.homeSummaryItems = homeSummaryItems
      logger.verbose('setHomeSummaryItems')
      logger.verbose(state.homeSummaryItems)
    },

    setHomeCountingItems(state, item) {
      const homeCountingItems = JSON.parse(JSON.stringify(item))
      state.homeCountingItems = homeCountingItems
      logger.verbose('setHomeCountingItems')
      logger.verbose(state.homeCountingItems)
    },

    setHomeGenderAgeItems(state, items) {
      const homeGenderAgeItems = JSON.parse(JSON.stringify(items))
      state.homeGenderAgeItems = {
        gender: homeGenderAgeItems.gender,
        genderAge: homeGenderAgeItems.genderAge,
      }
      logger.verbose('setHomeGenderAgeItems')
      logger.verbose(state.homeGenderAgeItems)
    },

    setMultiTenantCountingItems(state, items) {
      const multiTenantCountingItems = JSON.parse(JSON.stringify(items))
      state.multiTenantCountingItems = {
        labels: multiTenantCountingItems.labels,
        datasets: multiTenantCountingItems.datasets,
      }
      logger.verbose('setMultiTenantCountingItems')
      logger.verbose(state.multiTenantCountingItems)
    },

    setMultiTenantSummaryItems(state, items) {
      const multiTenantSummaryItems = JSON.parse(JSON.stringify(items))
      state.multiTenantSummaryItems = multiTenantSummaryItems
      logger.verbose('setMultiTenantSummaryItems')
      logger.verbose(state.multiTenantSummaryItems)
    },

    setTenantInfoItem(state, item) {
      const tenant = item
      // const tenant = JSON.parse(JSON.stringify(item))
      state.tenantInfoItem = {
        id: tenant.id,
        name: tenant.name,
        area: tenant.area,
        location: tenant.location,
        description: tenant.description,
        businessHours: [
          {
            begin: tenant.businessHours[0].begin,
            open: tenant.businessHours[0].open,
            close: tenant.businessHours[0].close,
          },
        ],
      }
      logger.verbose('setTenant')
      logger.verbose(state.tenantInfoItem)
    },

    setTenantDatabaseItem(state, value) {
      state.tenantDatabaseItem = value
    },

    setTenantSelectedCalenderDate(state, item) {
      const tenantSelectedCalenderDate = JSON.parse(JSON.stringify(item))
      state.tenantSelectedCalenderDate = tenantSelectedCalenderDate
      logger.verbose('setTenantSelectedCalenderDate')
      logger.verbose(state.tenantSelectedCalenderDate)
    },

    setTenantMeasurementItems(state, items) {
      const tenantMeasurementItems = JSON.parse(JSON.stringify(items))
      state.tenantMeasurementItems = tenantMeasurementItems
      logger.verbose('setTenantMeasurementItems')
      logger.verbose(state.tenantMeasurementItems)
    },

    setTenantCountingItems(state, items) {
      const tenantCountingItems = JSON.parse(JSON.stringify(items))
      state.tenantCountingItems = tenantCountingItems
      logger.verbose('setTenantCountingItems')
      logger.verbose(state.tenantCountingItems)
    },

    setTenantAttribute1DateSwitch(state, type) {
      state.tenantAttribute1DateSwitch = type
      // console.log(type)
      // console.log(state.tenantAttribute1DateSwitch)
    },
    setTenantAttribute2DateSwitch(state, type) {
      state.tenantAttribute2DateSwitch = type
      // console.log(type)
      // console.log(state.tenantAttribute2DateSwitch)
    },
    // setTenantAttributeDateSwitch(state, item) {
    //   console.log(item.tab, item.value, isNaN(item.tab))
    //   if (Number(item.tab) == 1) {
    //     console.log('%')
    //     state.tenantAttribute1DateSwitch = item.value
    //   } else if (Number(item.tab) == 2) {
    //     console.log('$')
    //     state.tenantAttribute2DateSwitch = item.value
    //   }
    //   console.log(state.tenantAttribute1DateSwitch)
    //   console.log(state.tenantAttribute2DateSwitch)
    // },
    setTenantAttributeDate(state, item) {
      const tenantAttributeDate = JSON.parse(JSON.stringify(item))
      state.tenantAttributeDate = {
        start: tenantAttributeDate.start,
        end: tenantAttributeDate.end,
        type: tenantAttributeDate.type,
      }
      logger.verbose('setTenantAttribute1Date')
      logger.verbose(state.tenantAttributeDate)
    },

    setTenantTargetItems(state, items) {
      const tenantTargetItems = JSON.parse(JSON.stringify(items))
      state.tenantTargetItems = {
        genderLabel: tenantTargetItems.genderLabel,
        ageGroupLabel: tenantTargetItems.ageGroupLabel,
        holiday: tenantTargetItems.holiday,
        dayOfWeek: tenantTargetItems.dayOfWeek,
      }
      logger.verbose('setTenantTargetItems')
      logger.verbose(state.tenantTargetItems)
    },

    setTenantCountingDaysItems(state, items) {
      const tenantCountingDaysItems = JSON.parse(JSON.stringify(items))
      state.tenantCountingDaysItems = {
        labels: tenantCountingDaysItems.labels,
        datasets: tenantCountingDaysItems.datasets,
      }
      logger.verbose('setTenantCountingDaysItems')
      logger.verbose(state.tenantCountingDaysItems)
    },

    setTenantCountingHoursItems(state, items) {
      const tenantCountingHoursItems = JSON.parse(JSON.stringify(items))
      state.tenantCountingHoursItems = {
        labels: tenantCountingHoursItems.labels,
        datasets: tenantCountingHoursItems.datasets,
      }
      logger.verbose('setTenantCountingHoursItems')
      logger.verbose(state.tenantCountingHoursItems)
    },

    setTenantSummaryItems(state, items) {
      const tenantSummaryItems = JSON.parse(JSON.stringify(items))
      state.tenantSummaryItems = tenantSummaryItems
      logger.verbose('setTenantSummaryItems')
      logger.verbose(state.tenantSummaryItems)
    },

    setTenantTargetSummaryDaysItems(state, items) {
      const tenantTargetSummaryDaysItems = JSON.parse(JSON.stringify(items))
      // const tenantTargetSummaryDaysItems = items
      state.tenantTargetSummaryDaysItems = {
        labels: tenantTargetSummaryDaysItems.labels,
        datasets: tenantTargetSummaryDaysItems.datasets,
        malesData: tenantTargetSummaryDaysItems.malesData,
        femalesData: tenantTargetSummaryDaysItems.femalesData,
      }
      logger.verbose('setTenantTargetSummaryDaysItems')
      logger.verbose(state.tenantTargetSummaryDaysItems)
    },

    setTenantTargetHoursItems(state, items) {
      const tenantTargetHoursItems = JSON.parse(JSON.stringify(items))
      state.tenantTargetHoursItems = {
        labels: tenantTargetHoursItems.labels,
        datasets: tenantTargetHoursItems.datasets,
      }
      logger.verbose('setTenantTargetHoursItems')
      logger.verbose(state.tenantTargetHoursItems)
    },

    setSpotDirectionItems(state, items) {
      const spotDirectionItems = JSON.parse(JSON.stringify(items))
      state.spotDirectionItems = {
        labels: spotDirectionItems.labels,
        datasets: spotDirectionItems.datasets,
      }
      logger.verbose('setSpotDirectionItems')
      logger.verbose(state.spotDirectionItems)
    },

    setSpotDirectionSummaryItems(state, items) {
      const spotDirectionSummaryItems = JSON.parse(JSON.stringify(items))
      state.spotDirectionSummaryItems = spotDirectionSummaryItems
      logger.verbose('setSpotDirectionSummaryItems')
      logger.verbose(state.spotDirectionSummaryItems)
    },

    setPromoEventItems(state, items) {
      const promoEventItems = JSON.parse(JSON.stringify(items))
      state.promoEventItems = promoEventItems
      logger.verbose('setPromoEventItems')
      logger.verbose(state.promoEventItems)
    },
    setPromoEventItem(state, payload) {
      const index = payload.index
      const item = payload.item
      let items = state.promoEventItems

      if (0 <= index) {
        items[index] = item
      } else {
        items.push(item)
      }
      state.promoEventItems = items
      logger.verbose('setPromoEventItem, index: ' + index)
      logger.verbose(state.promoEventItems)
    },

    setDownloadPeriodItems(state, items) {
      const downloadPeriodItems = JSON.parse(JSON.stringify(items))
      state.downloadPeriodItems = downloadPeriodItems
      logger.verbose('setDownloadPeriodItems')
      logger.verbose(state.downloadPeriodItems)
    },

    setSettingItems(state, value) {
      state.settingItems = value
      logger.verbose('setSettingItems')
      logger.verbose(state.settingItems)
    },

    setSystemToolItems(state, items) {
      const systemToolItems = JSON.parse(JSON.stringify(items))
      state.systemToolItems = systemToolItems
      logger.verbose('setSystemToolItems')
      logger.verbose(state.systemToolItems)
    },

    setDataRecoveryItems(state, items) {
      const dataRecoveryItems = JSON.parse(JSON.stringify(items))
      state.dataRecoveryItems = dataRecoveryItems
      logger.verbose('setDataRecoveryItems')
      logger.verbose(state.dataRecoveryItems)
    },

    setSoftwareVersionItems(state, items) {
      const softwareVersionItems = JSON.parse(JSON.stringify(items))
      state.softwareVersionItems = softwareVersionItems
      logger.verbose('setSoftwareVersionItems')
      logger.verbose(state.softwareVersionItems)
    },

    setNowLoding(state, value) {
      state.nowLoding = value
      logger.verbose('setNowLoding', state.nowLoding)
    },

    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
  },
  getters: {
    isAdmin: (state) => {
      return state.group == 'Admins'
    },
    isCorporate: (state) => {
      return state.group == 'Corporates'
    },
    hasMultiTenant: (state) => {
      return 2 <= state.multiTenantSummaryItems.length
      // return true
    },
    // isSignIn: (state) => {
    //   return state.user !== null
    // },
    getUser: (state) => {
      return state.user
    },
    getCurrentUsername: (state) => {
      if (!state.user || !state.user.username) {
        return 'unknown-user'
      }
      return state.user.username
    },

    getAccountItems: (state) => {
      return state.accountItems
    },

    getContractItem: (state) => {
      return state.contract
    },
    getContractItems: (state) => {
      return state.contractItems
    },

    getDeviceItems: (state) => {
      return state.deviceItems
    },

    getOptionItems: (state) => {
      return state.optionItems
    },

    getUserItems: (state) => {
      return state.userItems
    },

    getHomeDatesLabelItem: (state) => {
      return state.homeDatesLabelItem
    },
    getHomeTenantTableItems: (state) => {
      return state.homeTenantTableItems
    },
    getHomeStartDateItem: (state) => {
      return state.homeStartDateItem
    },
    getHomeEndDateItem: (state) => {
      return state.homeEndDateItem
    },

    getHomeSummaryItems: (state) => {
      return state.homeSummaryItems
    },

    getHomeCountingItems: (state) => {
      return state.homeCountingItems
    },

    getHomeGenderAgeItems: (state) => {
      return state.homeGenderAgeItems
    },

    getMultiTenantCountingItems: (state) => {
      return state.multiTenantCountingItems
    },

    getMultiTenantSummaryItems: (state) => {
      return state.multiTenantSummaryItems
    },

    getTenantInfoItem: (state) => {
      return state.tenantInfoItem
    },

    getTenantDatabaseItem: (state) => {
      return state.tenantDatabaseItem
    },

    getTenantSelectedCalenderDate: (state) => {
      return state.tenantSelectedCalenderDate
    },

    getTenantMeasurementItems: (state) => {
      return state.tenantMeasurementItems
    },

    getTenantCountingItems: (state) => {
      return state.tenantCountingItems
    },

    getTenantCountingDaysItems: (state) => {
      return state.tenantCountingDaysItems
    },

    getTenantCountingHoursItems: (state) => {
      return state.tenantCountingHoursItems
    },

    getTenantSummaryItems: (state) => {
      return state.tenantSummaryItems
    },

    getTenantAttribute1DateSwitch(state) {
      return state.tenantAttribute1DateSwitch
    },
    getTenantAttribute2DateSwitch(state) {
      return state.tenantAttribute2DateSwitch
    },
    getTenantAttributeDate(state) {
      return state.tenantAttributeDate
    },

    getTenantTargetItems: (state) => {
      return state.tenantTargetItems
    },

    getTenantTargetSummaryDaysItems: (state) => {
      return state.tenantTargetSummaryDaysItems
    },

    getTenantTargetHoursItems: (state) => {
      return state.tenantTargetHoursItems
    },

    getSpotDirectionItems: (state) => {
      return state.spotDirectionItems
    },

    getSpotDirectionSummaryItems: (state) => {
      return state.spotDirectionSummaryItems
    },

    getPromoEventItems: (state) => {
      return state.promoEventItems
    },

    getDownloadPeriodItems: (state) => {
      return state.downloadPeriodItems
    },

    getSettingItems: (state) => {
      return state.settingItems
    },

    getSystemToolItems: (state) => {
      return state.systemToolItems
    },

    getDataRecoveryItems: (state) => {
      return state.dataRecoveryItems
    },

    getSoftwareVersionItems: (state) => {
      return state.softwareVersionItems
    },

    getNowLoding(state) {
      return state.nowLoding
    },
  },
  actions: {
    logout: async (context) => {
      AuthController.logout(context)
    },
    activeLoadingPanel: (context) => {
      context.commit('setNowLoding', { value: true })
    },
    deactiveLoadingPanel: (context) => {
      context.commit('setNowLoding', { value: false })
    },
    createAccountAndContract: (context, { username, password, email }) => {
      ContractController.createContractTemplate(context, {
        username,
        password,
        email,
      })
    },
    getContractList: async (context) => {
      await ContractController.getContractList(context)
    },
    getOwnContract: async (context) => {
      if (!context.state.contract) {
        const item = ContractViewModel.newContractItem()
        context.commit('setContract', item)
        // ログインしているアカウントの契約情報の取得
        await ContractController.getOwnContract(context)
      }
    },
    getOwnContractByAppUserId: async (context) => {
      if (!context.state.contract) {
        const item = ContractViewModel.newContractItem()
        context.commit('setContract', item)
        // ログインしているアカウントの契約情報の取得
        await ContractController.getOwnContractByAppUserId(context)
      }
    },
    editContract: (context, { contract, account, isAdmin }) => {
      ContractController.editContract(context, { contract, account, isAdmin })
    },
    deleteContractAndAccount: (
      context,
      { username, accountId, contractId },
    ) => {
      ContractController.deleteContractAndAccount(context, {
        username,
        accountId,
        contractId,
      })
    },

    getAppUsersList: async (context) => {
      // ログインユーザーの取得
      await AppUserController.getAppUsersList(context)
    },
    createLoginUser: async (context, { username, password, email }) => {
      await AppUserController.createLoginUser(context, {
        username,
        password,
        email,
      })
      // サービスオプションの利用率を更新する
      ServiceController.fetchServiceOptionList(context)
    },
    editUserProfile: (
      context,
      { username, name, department, tenant, description },
    ) => {
      AppUserController.editUserProfile(context, {
        username,
        name,
        department,
        tenant,
        description,
      })
    },
    deleteAppUser: async (context, { username, deleteId }) => {
      // ログインユーザーの削除
      await AppUserController.deleteAppUserController(context, {
        username,
        deleteId,
      })
      // サービスオプションの利用率を更新する
      ServiceController.fetchServiceOptionList(context)
    },

    resetPassword: (context, { username, password }) => {
      AccountController.resetPassword(context, { username, password })
    },
    enableAccount: (context, { account, enable }) => {
      AccountController.enableAccount(context, { account, enable })
    },

    createDevice: (context, { deviceName, storagePath, hostName }) => {
      DeviceController.createDevice(context, {
        deviceName,
        storagePath,
        hostName,
      })
    },
    editDevice: (
      context,
      { deviceName, storagePath, hostName, category, location, description },
    ) => {
      DeviceController.editDeviceProfile(context, {
        deviceName,
        storagePath,
        hostName,
        category,
        location,
        description,
      })
    },
    deleteDevice: (context, { deviceName }) => {
      DeviceController.deleteDevice(context, { deviceName })
    },
    fetchDeviceList: (context) => {
      DeviceController.fetchDeviceList(context)
    },

    createServiceOption: (
      context,
      { keyName, defaultValue, optionName, description },
    ) => {
      ServiceController.createServiceOption(context, {
        keyName,
        defaultValue,
        optionName,
        description,
      })
    },
    fetchServiceOptionList: (context) => {
      ServiceController.fetchServiceOptionList(context)
    },
    editServiceOption: (
      context,
      { keyName, limit, optionName, description },
    ) => {
      ServiceController.editServiceOption(context, {
        keyName,
        limit,
        optionName,
        description,
      })
    },
    deleteServiceOption: (context, { keyName }) => {
      ServiceController.deleteServiceOption(context, { keyName })
    },

    fetchCountingAllData: async (context, { start, end, type }) => {
      await SystemToolController.loadSettings(context)
      await CountingController.fetchCountingAllData(context, {
        start,
        end,
        type,
      })
      PromotionController.fetchPromotionsList(context)
    },
    fetchCountingDataComparison: async (context, { start, end, type }) => {
      await CountingController.fetchCountingDataComparison(context, {
        start,
        end,
        type,
      })
      PromotionController.fetchPromotionsList(context)
    },
    fetchCountingDataList: async (context, { start, end, type }) => {
      await CountingController.fetchCountingDataList(context, {
        start,
        end,
        type,
      })
      PromotionController.fetchPromotionsList(context)
    },
    fetchMeasurementDataList: async (context, { start, end, type }) => {
      await CountingController.fetchMeasurementDataList(context, {
        start,
        end,
        type,
      })
    },
    fetchCountingDailyDataList: async (context, { start, end }) => {
      await CountingController.fetchCountingDailyDataList(context, {
        start,
        end,
      })
    },
    fetchCountingHourlyDataList: async (context, { start, end }) => {
      await CountingController.fetchCountingHourlyDataList(context, {
        start,
        end,
      })
    },
    makeTargetSummary: async (
      context,
      { ageId, genderId, start, end, type },
    ) => {
      if (type == 'Daily') {
        await CountingController.fetchCountingHourlyDataList(context, {
          start,
          end,
          ageId,
          genderId,
        })
      } else {
        // Monthly
        await CountingController.fetchCountingDailyDataList(context, {
          start,
          end,
          ageId,
          genderId,
        })
      }

      CountingController.makeTargetSummary(context, { ageId, genderId, type })
    },

    selectCalendrDate: (context, { begin, end }) => {
      let selected = false
      if (begin != '' && end != '') {
        selected = true
      }
      const item = {
        selected: selected,
        begin: begin,
        end: end,
      }
      context.commit('setTenantSelectedCalenderDate', item)
    },

    switchAttributeDateSwitch: (context, { tab, type }) => {
      // const item = { tab: tab, value: type }
      // console.log(tab, type)
      const ttype = type == 'Monthly' ? type : 'Daily'
      if (new Number(tab) == 1) {
        context.commit('setTenantAttribute1DateSwitch', ttype)
      } else if (new Number(tab) == 2) {
        context.commit('setTenantAttribute2DateSwitch', ttype)
      }
    },

    fetchTrackingDataList: async (context, { start, end, type }) => {
      await CountingController.fetchTrackingDataList(context, {
        start,
        end,
        type,
      })
    },

    // fetchDownloadDataList: async (context, { tenants, start, end, type }) => {
    //   await DownloadDataController.fetchDownloadDataList(context, {
    //     tenants,
    //     start,
    //     end,
    //     type,
    //   })
    // },
    fetchDownloadData: async (context, { tenants, start, end, type }) => {
      await DownloadDataController.fetchDownloadData(context, {
        tenants,
        start,
        end,
        type,
      })
    },

    createPromotion: (context, { eventName, start, end, description }) => {
      PromotionController.createPromotion(context, {
        eventName,
        start,
        end,
        description,
      })
    },
    fetchPromotionsList: async (context) => {
      await PromotionController.fetchPromotionsList(context)
    },
    editPromotion: (context, { id, eventName, start, end, description }) => {
      PromotionController.editPromotion(context, {
        id,
        eventName,
        start,
        end,
        description,
      })
    },
    deletePromotion: async (context, { deleteId }) => {
      await PromotionController.deletePromotion(context, { deleteId })
    },

    createTenantInfo: (context, { description, begin, open, close }) => {
      TenantController.createTenantInfo(context, {
        description,
        begin,
        open,
        close,
      })
    },
    fetchTenantInfo: async (context) => {
      await TenantController.fetchTenantInfo(context)
    },
    editTenantInfo: (
      context,
      { id, name, description, begin, open, close },
    ) => {
      TenantController.editTenantInfo(context, {
        id,
        name,
        description,
        begin,
        open,
        close,
      })
    },
    deleteTenantInfo: async (context, { deleteId }) => {
      await TenantController.deleteTenantInfo(context, { deleteId })
    },

    fetchSystemInfo: async (context) => {
      await SystemToolController.loadSettings(context)
      await SystemToolController.fetchSystemInfo(context)
    },
    saveSettings: async (context, { apiHost }) => {
      await SystemToolController.saveSettings(context, { apiHost })
    },
    fetchHealthCheckStatus: async (context) => {
      await SystemToolController.fetchHealthCheckStatus(context)
    },

    calcSummaryData: async (context, { start, startTime, end, endTime }) => {
      return await SystemToolController.calcSummaryData(context, {
        start,
        startTime,
        end,
        endTime,
      })
    },

    fetchReCalcJobList: async (context) => {
      await SystemToolController.fetchReCalcJobList(context)
    },

    fetchVersionInfo: async (context) => {
      await SystemToolController.fetchVersionInfo(context)
    },
    downloadSoftware: async (context) => {
      return await SystemToolController.downloadSoftware(context)
    },
  },
  modules: {},
})
