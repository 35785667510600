/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      idaasUser {
        username
        sub
        email
        registered
      }
      name
      corporate
      email
      description
      activity
      contracts {
        items {
          id
          accountID
          corporate
          owner
          description
          email
          createdAt
          updatedAt
          accountContractsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        idaasUser {
          username
          sub
          email
          registered
        }
        name
        corporate
        email
        description
        activity
        contracts {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContract = /* GraphQL */ `
  query GetContract($id: ID!) {
    getContract(id: $id) {
      id
      accountID
      corporate
      owner
      description
      email
      period {
        start
        end
      }
      users {
        items {
          id
          contractID
          name
          description
          department
          tenant
          email
          activity
          createdAt
          updatedAt
          contractUsersId
        }
        nextToken
      }
      devices {
        items {
          id
          contractID
          host
          description
          category
          location
          os
          storagePath
          topic
          clientCertFile
          activity
          createdAt
          updatedAt
          contractDevicesId
          iotDeviceTenantInfoId
        }
        nextToken
      }
      options {
        items {
          id
          key
          value
          name
          description
          contractID
          createdAt
          updatedAt
          contractOptionsId
        }
        nextToken
      }
      createdAt
      updatedAt
      accountContractsId
    }
  }
`;
export const listContracts = /* GraphQL */ `
  query ListContracts(
    $id: ID
    $filter: ModelContractFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listContracts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        accountID
        corporate
        owner
        description
        email
        period {
          start
          end
        }
        users {
          nextToken
        }
        devices {
          nextToken
        }
        options {
          nextToken
        }
        createdAt
        updatedAt
        accountContractsId
      }
      nextToken
    }
  }
`;
export const getAppUser = /* GraphQL */ `
  query GetAppUser($id: ID!) {
    getAppUser(id: $id) {
      id
      contractID
      name
      idaasUser {
        username
        sub
        email
        registered
      }
      description
      department
      tenant
      email
      period {
        start
        end
      }
      activity
      contract {
        id
        accountID
        corporate
        owner
        description
        email
        period {
          start
          end
        }
        users {
          nextToken
        }
        devices {
          nextToken
        }
        options {
          nextToken
        }
        createdAt
        updatedAt
        accountContractsId
      }
      createdAt
      updatedAt
      contractUsersId
    }
  }
`;
export const listAppUsers = /* GraphQL */ `
  query ListAppUsers(
    $id: ID
    $filter: ModelAppUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAppUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        contractID
        name
        idaasUser {
          username
          sub
          email
          registered
        }
        description
        department
        tenant
        email
        period {
          start
          end
        }
        activity
        contract {
          id
          accountID
          corporate
          owner
          description
          email
          createdAt
          updatedAt
          accountContractsId
        }
        createdAt
        updatedAt
        contractUsersId
      }
      nextToken
    }
  }
`;
export const getIotDevice = /* GraphQL */ `
  query GetIotDevice($id: ID!) {
    getIotDevice(id: $id) {
      id
      contractID
      host
      thing {
        thingName
        thingArn
        attributes {
          model
        }
        version
        thingId
        defaultClientId
        thingTypeName
      }
      description
      category
      location
      os
      storagePath
      topic
      clientCertFile
      period {
        start
        end
      }
      activity
      tenantInfo {
        id
        name
        category
        location
        description
        businessHours {
          begin
          open
          close
        }
        businessDay {
          begin
          days
        }
        holiday
        iotDeviceID
        iotDevice {
          id
          contractID
          host
          description
          category
          location
          os
          storagePath
          topic
          clientCertFile
          activity
          createdAt
          updatedAt
          contractDevicesId
          iotDeviceTenantInfoId
        }
        createdAt
        updatedAt
        tenantInformationIotDeviceId
      }
      promotions {
        items {
          id
          name
          category
          location
          description
          iotDeviceID
          createdAt
          updatedAt
          iotDevicePromotionsId
        }
        nextToken
      }
      contract {
        id
        accountID
        corporate
        owner
        description
        email
        period {
          start
          end
        }
        users {
          nextToken
        }
        devices {
          nextToken
        }
        options {
          nextToken
        }
        createdAt
        updatedAt
        accountContractsId
      }
      createdAt
      updatedAt
      contractDevicesId
      iotDeviceTenantInfoId
    }
  }
`;
export const listIotDevices = /* GraphQL */ `
  query ListIotDevices(
    $id: ID
    $filter: ModelIotDeviceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listIotDevices(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        contractID
        host
        thing {
          thingName
          thingArn
          version
          thingId
          defaultClientId
          thingTypeName
        }
        description
        category
        location
        os
        storagePath
        topic
        clientCertFile
        period {
          start
          end
        }
        activity
        tenantInfo {
          id
          name
          category
          location
          description
          holiday
          iotDeviceID
          createdAt
          updatedAt
          tenantInformationIotDeviceId
        }
        promotions {
          nextToken
        }
        contract {
          id
          accountID
          corporate
          owner
          description
          email
          createdAt
          updatedAt
          accountContractsId
        }
        createdAt
        updatedAt
        contractDevicesId
        iotDeviceTenantInfoId
      }
      nextToken
    }
  }
`;
export const getServiceOption = /* GraphQL */ `
  query GetServiceOption($id: ID!) {
    getServiceOption(id: $id) {
      id
      key
      value
      name
      description
      contractID
      contract {
        id
        accountID
        corporate
        owner
        description
        email
        period {
          start
          end
        }
        users {
          nextToken
        }
        devices {
          nextToken
        }
        options {
          nextToken
        }
        createdAt
        updatedAt
        accountContractsId
      }
      createdAt
      updatedAt
      contractOptionsId
    }
  }
`;
export const listServiceOptions = /* GraphQL */ `
  query ListServiceOptions(
    $id: ID
    $filter: ModelServiceOptionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listServiceOptions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        key
        value
        name
        description
        contractID
        contract {
          id
          accountID
          corporate
          owner
          description
          email
          createdAt
          updatedAt
          accountContractsId
        }
        createdAt
        updatedAt
        contractOptionsId
      }
      nextToken
    }
  }
`;
export const getTenantInformation = /* GraphQL */ `
  query GetTenantInformation($id: ID!) {
    getTenantInformation(id: $id) {
      id
      name
      category
      location
      description
      businessHours {
        begin
        open
        close
      }
      businessDay {
        begin
        days
      }
      holiday
      iotDeviceID
      iotDevice {
        id
        contractID
        host
        thing {
          thingName
          thingArn
          version
          thingId
          defaultClientId
          thingTypeName
        }
        description
        category
        location
        os
        storagePath
        topic
        clientCertFile
        period {
          start
          end
        }
        activity
        tenantInfo {
          id
          name
          category
          location
          description
          holiday
          iotDeviceID
          createdAt
          updatedAt
          tenantInformationIotDeviceId
        }
        promotions {
          nextToken
        }
        contract {
          id
          accountID
          corporate
          owner
          description
          email
          createdAt
          updatedAt
          accountContractsId
        }
        createdAt
        updatedAt
        contractDevicesId
        iotDeviceTenantInfoId
      }
      createdAt
      updatedAt
      tenantInformationIotDeviceId
    }
  }
`;
export const listTenantInformations = /* GraphQL */ `
  query ListTenantInformations(
    $id: ID
    $filter: ModelTenantInformationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTenantInformations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        category
        location
        description
        businessHours {
          begin
          open
          close
        }
        businessDay {
          begin
          days
        }
        holiday
        iotDeviceID
        iotDevice {
          id
          contractID
          host
          description
          category
          location
          os
          storagePath
          topic
          clientCertFile
          activity
          createdAt
          updatedAt
          contractDevicesId
          iotDeviceTenantInfoId
        }
        createdAt
        updatedAt
        tenantInformationIotDeviceId
      }
      nextToken
    }
  }
`;
export const getPromotion = /* GraphQL */ `
  query GetPromotion($id: ID!) {
    getPromotion(id: $id) {
      id
      name
      period {
        start
        end
      }
      category
      location
      description
      iotDeviceID
      iotDevice {
        id
        contractID
        host
        thing {
          thingName
          thingArn
          version
          thingId
          defaultClientId
          thingTypeName
        }
        description
        category
        location
        os
        storagePath
        topic
        clientCertFile
        period {
          start
          end
        }
        activity
        tenantInfo {
          id
          name
          category
          location
          description
          holiday
          iotDeviceID
          createdAt
          updatedAt
          tenantInformationIotDeviceId
        }
        promotions {
          nextToken
        }
        contract {
          id
          accountID
          corporate
          owner
          description
          email
          createdAt
          updatedAt
          accountContractsId
        }
        createdAt
        updatedAt
        contractDevicesId
        iotDeviceTenantInfoId
      }
      createdAt
      updatedAt
      iotDevicePromotionsId
    }
  }
`;
export const listPromotions = /* GraphQL */ `
  query ListPromotions(
    $id: ID
    $filter: ModelPromotionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPromotions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        period {
          start
          end
        }
        category
        location
        description
        iotDeviceID
        iotDevice {
          id
          contractID
          host
          description
          category
          location
          os
          storagePath
          topic
          clientCertFile
          activity
          createdAt
          updatedAt
          contractDevicesId
          iotDeviceTenantInfoId
        }
        createdAt
        updatedAt
        iotDevicePromotionsId
      }
      nextToken
    }
  }
`;
