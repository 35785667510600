import { API } from 'aws-amplify'

import { listPromotions } from '@/graphql/queries'
import { createPromotion } from '@/graphql/mutations'
import { updatePromotion } from '@/graphql/mutations'
import { deletePromotion } from '@/graphql/mutations'

import Promotion from '@/domain/models/Promotion'

import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'PromotionRepository'

const createNewPromotion = async ({ deviceId, promotionProfile }) => {
  const TAG = `${MODULE_TAG}.${createNewPromotion.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  let inputData = {
    name: promotionProfile.eventName,
    period: { start: promotionProfile.start, end: promotionProfile.end },
    category: promotionProfile.area,
    location: promotionProfile.tenant,
    description: promotionProfile.description,
    iotDeviceID: deviceId,
  }
  logger.verbose(Logger.formatter(TAG, `inputData:`))
  logger.verbose(inputData)

  const response = await API.graphql({
    query: createPromotion,
    variables: { input: inputData },
  })
    .then(async (result) => {
      logger.verbose('=== createPromotion result ===')
      logger.verbose(result)
      return {
        data: result.data.createPromotion,
        error: null,
      }
    })
    .catch((error) => {
      logger.error('=== createPromotion error ===')
      logger.error(error)
      return {
        data: null,
        error: error,
      }
    })
  if (!response) {
    return {
      data: null,
      error: new Error('no response!'),
    }
  }
  logger.verbose(Logger.formatter(TAG, `response:`))
  logger.verbose(response)

  return response
}

const updatePromotionProfile = async ({ deviceId, promotionProfile }) => {
  const TAG = `${MODULE_TAG}.${updatePromotionProfile.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  let inputData = {
    id: promotionProfile.id,
    name: promotionProfile.eventName,
    period: { start: promotionProfile.start, end: promotionProfile.end },
    category: promotionProfile.area,
    location: promotionProfile.tenant,
    description: promotionProfile.description,
    iotDeviceID: deviceId,
  }
  const response = await API.graphql({
    query: updatePromotion,
    variables: { input: inputData },
  })
    .then(async (result) => {
      logger.verbose('=== updatePromotion result ===')
      logger.verbose(result)
      return {
        data: result.data.updatePromotion,
        error: null,
      }
    })
    .catch((error) => {
      logger.error('=== updatePromotion error ===')
      logger.error(error)
      return {
        data: null,
        error: error,
      }
    })
  if (response.error) {
    return {
      data: null,
      error: new Error('no response!'),
    }
  }

  return response
}

const fetchPromotionsList = async (deviceId) => {
  const TAG = `${MODULE_TAG}.${fetchPromotionsList.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const response = await API.graphql({
    query: listPromotions,
    // limit: 50,
  })
    .then((result) => {
      logger.verbose('=== listPromotions result ===')
      logger.verbose(result)
      return {
        data: result.data.listPromotions.items,
        error: null,
      }
    })
    .catch((error) => {
      logger.error('=== listPromotions error ===')
      logger.error(error)
      return {
        data: [],
        error: error,
      }
    })
  if (!response) {
    return {
      data: [],
      error: new Error('no response!'),
    }
  }

  let items = []
  response.data.forEach((fetchItem) => {
    if (fetchItem.iotDeviceID == deviceId) {
      // 店舗に設置されたデバイスＩＤと関連付けされたデータを抽出する
      items.push(fetchItem)
    }
  })
  response.data = items

  return response
}

const deleteResourceById = async (deleteId) => {
  const TAG = `${MODULE_TAG}.${deleteResourceById.name}`
  logger.verbose(Logger.formatter(TAG, `begin with deleteId: ${deleteId}`))

  if (deleteId == null || deleteId == '' || typeof deleteId === undefined)
    return new Error('deleteId is empty!')

  const response = await API.graphql({
    query: deletePromotion,
    variables: { input: { id: deleteId } },
  })
    .then((result) => {
      logger.verbose('=== deletePromotion result ===')
      logger.verbose(result)
      return {
        data: result.data.deletePromotion,
        error: null,
      }
    })
    .catch((error) => {
      logger.error('=== deletePromotion error ===')
      logger.error(error)
      return {
        data: null,
        error: error,
      }
    })
  if (response.error) {
    return {
      data: null,
      error: new Error('no response!'),
    }
  }

  return response
}

export default {
  createNewPromotion,
  updatePromotionProfile,
  fetchPromotionsList,
  deleteResourceById,
}
