const rate = (value, max) => {
  return value <= max ? (100 * value) / max : 100
}
const color = (value) => {
  let $color
  if (value <= 25) {
    $color = 'info'
  } else if (25 < value && value <= 50) {
    $color = 'success'
  } else if (50 < value && value <= 75) {
    $color = 'warning'
  } else if (75 < value) {
    $color = 'danger'
  }
  return $color
}
const enableColor = (enable) => {
  let $color
  if (enable) {
    $color = 'light'
  } else {
    $color = 'success'
  }
  return $color
}
const enableFontColor = (enable) => {
  let $color
  if (enable) {
    $color = ' text-black'
  } else {
    $color = ' text-white'
  }
  return $color
}

function calcUsageRate(startStr, endStr) {
  if (startStr == null || startStr == '' || typeof startStr === undefined) {
    return 0
  } else if (endStr == null || endStr == '' || typeof endStr === undefined) {
    return 0
  }
  const startDate = new Date(startStr)
  const endDate = new Date(endStr)
  const spanMilliSec = endDate - startDate
  const spanDays = parseInt(spanMilliSec / 1000 / 60 / 60 / 24)
  // console.log(spanDays)
  const nowDate = new Date()
  // console.log(nowDate)
  const diffMilliSec = nowDate - startDate
  const diffDays = parseInt(diffMilliSec / 1000 / 60 / 60 / 24)
  // console.log(diffDays)
  return Math.round((100 * diffDays) / spanDays)
}

export default {
  rate,
  color,
  enableColor,
  enableFontColor,
  calcUsageRate,
}
