import CalcUtil from '@/utils/CalcUtil'

const homeSummaryItems = [
  {
    date: '2022年4月11日の週',
    area: '東京都',
    tenant: '渋谷店',
    sales: 4832300,
    traffic: 6672,
    staying: 31.0,
    inCount: 2097,
    purchaseRate: 21.51,
    transaction: 451,
    customerPrice: 8161,
    male: 45.2,
    female: 100 - 45.2,
    age10: 13.1,
    age20: 19.6,
    age30: 25.9,
    age40: 19.2,
    age50: 17.2,
  },
  {
    date: '2022年4月4日の週',
    area: '',
    tenant: '',
    sales: 4535400,
    traffic: 6666,
    staying: 30.6,
    inCount: 2039,
    purchaseRate: 19.42,
    transaction: 396,
    customerPrice: 11453,
    male: 50.1,
    female: 100 - 50.1,
    age10: 11.3,
    age20: 19.0,
    age30: 24.7,
    age40: 19.1,
    age50: 20.9,
  },
  {
    date: '成長率',
    area: '',
    tenant: '',
    sales: '6.55 %',
    traffic: '1.44 %',
    // staying: '1.38 %',
    staying: '0.4 %',
    inCount: '2.84 %',
    purchaseRate: '10.74 %',
    transaction: '13.89 %',
    customerPrice: '-6.45 %',
    male: '-4.9 %',
    female: '4.9 %',
    age10: '1.8 %',
    age20: '0.6 %',
    age30: '1.2 %',
    age40: '0.1 %',
    age50: '-3.7 %',
  },
  {
    date: '2022年4月11日の週',
    area: '愛知県',
    tenant: '名古屋店',
    sales: 4413000,
    traffic: 2186,
    staying: 30.4,
    inCount: 664,
    purchaseRate: 59.49,
    transaction: 395,
    customerPrice: 11172,
    male: 55.6,
    female: 100 - 55.6,
    age10: 12.1,
    age20: 18.5,
    age30: 25.3,
    age40: 18.5,
    age50: 20.6,
  },
  {
    date: '2022年4月4日の週',
    area: '',
    tenant: '',
    sales: 4384900,
    traffic: 2209,
    staying: 28.4,
    inCount: 628,
    purchaseRate: 62.26,
    transaction: 391,
    customerPrice: 11215,
    male: 52.2,
    female: 100 - 52.2,
    age10: 10.5,
    age20: 18.1,
    age30: 26.8,
    age40: 20.4,
    age50: 19.2,
  },
  {
    date: '成長率',
    area: '',
    tenant: '',
    sales: '0.64 %',
    traffic: '-1.04 %',
    staying: '2.0 %',
    // inRatio: '6.84 %',
    inCount: '5.73 %',
    purchaseRate: '-4.45 %',
    transaction: '1.02 %',
    customerPrice: '-0.38 %',
    male: '3.4 %',
    female: '-3.4 %',
    age10: '1.6 %',
    age20: '0.4 %',
    age30: '-1.5 %',
    age40: '-1.9 %',
    age50: '1.4 %',
  },
]

const homeCountingItems = {
  labels: [
    '2022/02/28',
    '2022/03/07',
    '2022/03/14',
    '2022/03/21',
    '2022/03/28',
    '2022/04/04',
    '2022/04/11',
    // '2月28日(月)',
    // '3月7日(月)',
    // '3月14日(月)',
    // '3月21日(月)',
    // '3月28日(月)',
    // '4月4日(月)',
    // '4月11日(月)',
  ],
  datasets: [
    {
      label: '入店数',
      data: [
        CalcUtil.random(500, 3000),
        CalcUtil.random(500, 3000),
        CalcUtil.random(500, 3000),
        CalcUtil.random(500, 3000),
        CalcUtil.random(500, 3000),
        CalcUtil.random(500, 3000),
        CalcUtil.random(500, 3000),
      ],
      yAxisID: 'y1',
    },
    {
      label: '表示区間の平均入店数',
      data: [],
      yAxisID: 'y1',
    },
    {
      label: '平均滞在数',
      data: [
        CalcUtil.random(10, 100),
        CalcUtil.random(10, 100),
        CalcUtil.random(10, 100),
        CalcUtil.random(10, 100),
        CalcUtil.random(10, 100),
        CalcUtil.random(10, 100),
        CalcUtil.random(10, 100),
      ],
      yAxisID: 'y2',
    },
  ],
}

const homeGenderAgeItems = [
  {
    gender: '男性',
    rate: [7, 11, 9, 11, 8],
  },
  {
    gender: '女性',
    rate: [10, 9, 15, 10, 10],
  },
]

const multiTenantCountingItems = {
  labels: [
    '2023/04/03',
    '2023/04/04',
    '2023/04/05',
    '2023/04/06',
    '2023/04/07',
    '2023/04/08',
    '2023/04/09',
  ],
  datasets: [
    {
      area: '愛知県',
      tenant: '名古屋店',
      data: [
        CalcUtil.random(500, 3000),
        CalcUtil.random(500, 3000),
        CalcUtil.random(500, 3000),
        CalcUtil.random(500, 3000),
        CalcUtil.random(500, 3000),
        CalcUtil.random(500, 3000),
        CalcUtil.random(500, 3000),
      ],
    },
    {
      area: '東京都',
      tenant: '渋谷店',
      data: [
        CalcUtil.random(500, 3000),
        CalcUtil.random(500, 3000),
        CalcUtil.random(500, 3000),
        CalcUtil.random(500, 3000),
        CalcUtil.random(500, 3000),
        CalcUtil.random(500, 3000),
        CalcUtil.random(500, 3000),
      ],
    },
  ],
}

const multiTenantSummaryItems = [
  {
    tenant: '名古屋店',
    // sales: 4457400,
    // passerbyCount: 6655,
    // staying: 30.3,
    staying: 30.3,
    inCount: 2019,
    // purchaseRate: 19.7,
    // purchaseNum: 397,
    // averageRevenue: 11228,
    male: 45.2,
    female: 100 - 45.2,
    age10: 13.1,
    age20: 19.6,
    age30: 25.9,
    age40: 19.2,
    age50: 17.2,
  },
  {
    tenant: '渋谷店',
    // sales: 4461300,
    // passerbyCount: 2122,
    // entryRate: 31.1,
    staying: 31.1,
    inCount: 660,
    // purchaseRate: 62.7,
    // purchaseNum: 414,
    // averageRevenue: 10776,
    male: 50.1,
    female: 100 - 50.1,
    age10: 11.3,
    age20: 19.0,
    age30: 24.7,
    age40: 19.1,
    age50: 20.9,
  },
]

const tenantCountingWeeklyItems = {
  labels: [
    // '2023/05/01',
    // '2023/05/02',
    // '2023/05/03',
    // '2023/05/04',
    // '2023/05/05',
    // '2023/05/06',
    // '2023/05/07',
    '2022/12/05',
    '2022/12/06',
    '2022/12/07',
    '2022/12/08',
    '2022/12/09',
    '2022/12/10',
    '2022/12/11',
    // '2022/12/12',
    // '12月6日(月)',
    // '12月7日(火)',
    // '12月8日(水)',
    // '12月9日(木)',
    // '12月10日(金)',
    // '12月11日(土)',
    // '12月12日(日)',
  ],
  inCounts: [
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
  ],
  stayings: [
    CalcUtil.random(30, 100),
    CalcUtil.random(30, 100),
    CalcUtil.random(30, 100),
    CalcUtil.random(30, 100),
    CalcUtil.random(30, 100),
    CalcUtil.random(30, 100),
    CalcUtil.random(30, 100),
  ],
}

const tenantCountingHoursItems = {
  // labels: ['月', '火', '水', '木', '金', '土', '日'],
  labels: [
    '2023/05/01',
    '2023/05/02',
    '2023/05/03',
    '2023/05/04',
    '2023/05/05',
    '2023/05/06',
    '2023/05/07',
    // '2022/12/05',
    // '2022/12/06',
    // '2022/12/07',
    // '2022/12/08',
    // '2022/12/09',
    // '2022/12/10',
    // '2022/12/11',
    // '12月5日(月)',
    // '12月6日(火)',
    // '12月7日(水)',
    // '12月8日(木)',
    // '12月9日(金)',
    // '12月10日(土)',
    // '12月11日(日)',
  ],
  datasets: [
    {
      hour: '10',
      data: [
        CalcUtil.random(1, 10),
        CalcUtil.random(1, 20),
        CalcUtil.random(1, 30),
        CalcUtil.random(1, 40),
        CalcUtil.random(1, 50),
        CalcUtil.random(1, 60),
        CalcUtil.random(1, 70),
      ],
    },
    {
      hour: '11',
      data: [
        CalcUtil.random(1, 10),
        CalcUtil.random(1, 20),
        CalcUtil.random(1, 30),
        CalcUtil.random(1, 40),
        CalcUtil.random(1, 50),
        CalcUtil.random(1, 60),
        CalcUtil.random(1, 70),
      ],
    },
    {
      hour: '12',
      data: [
        CalcUtil.random(1, 10),
        CalcUtil.random(1, 20),
        CalcUtil.random(1, 30),
        CalcUtil.random(1, 40),
        CalcUtil.random(1, 50),
        CalcUtil.random(1, 60),
        CalcUtil.random(1, 70),
      ],
    },
    {
      hour: '13',
      data: [
        CalcUtil.random(1, 10),
        CalcUtil.random(1, 20),
        CalcUtil.random(1, 30),
        CalcUtil.random(1, 40),
        CalcUtil.random(1, 50),
        CalcUtil.random(1, 60),
        CalcUtil.random(1, 70),
      ],
    },
    {
      hour: '14',
      data: [
        CalcUtil.random(1, 10),
        CalcUtil.random(1, 20),
        CalcUtil.random(1, 30),
        CalcUtil.random(1, 40),
        CalcUtil.random(1, 50),
        CalcUtil.random(1, 60),
        CalcUtil.random(1, 70),
      ],
    },
    {
      hour: '15',
      data: [
        CalcUtil.random(1, 10),
        CalcUtil.random(1, 20),
        CalcUtil.random(1, 30),
        CalcUtil.random(1, 40),
        CalcUtil.random(1, 50),
        CalcUtil.random(1, 60),
        CalcUtil.random(1, 70),
      ],
    },
    {
      hour: '16',
      data: [
        CalcUtil.random(1, 10),
        CalcUtil.random(1, 20),
        CalcUtil.random(1, 30),
        CalcUtil.random(1, 40),
        CalcUtil.random(1, 50),
        CalcUtil.random(1, 60),
        CalcUtil.random(1, 70),
      ],
    },
    {
      hour: '17',
      data: [
        CalcUtil.random(1, 10),
        CalcUtil.random(1, 20),
        CalcUtil.random(1, 30),
        CalcUtil.random(1, 40),
        CalcUtil.random(1, 50),
        CalcUtil.random(1, 60),
        CalcUtil.random(1, 70),
      ],
    },
    {
      hour: '18',
      data: [
        CalcUtil.random(1, 10),
        CalcUtil.random(1, 20),
        CalcUtil.random(1, 30),
        CalcUtil.random(1, 40),
        CalcUtil.random(1, 50),
        CalcUtil.random(1, 60),
        CalcUtil.random(1, 70),
      ],
    },
    {
      hour: '19',
      data: [
        CalcUtil.random(1, 10),
        CalcUtil.random(1, 20),
        CalcUtil.random(1, 30),
        CalcUtil.random(1, 40),
        CalcUtil.random(1, 50),
        CalcUtil.random(1, 60),
        CalcUtil.random(1, 70),
      ],
    },
  ],
}

const summaryMale = [
  // 10
  [
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
  ],
  // 20
  [
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
  ],
  // 30
  [
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
  ],
  // 40
  [
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
  ],
  // 50
  [
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
  ],
]
const summaryFemale = [
  // 10
  [
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
  ],
  // 20
  [
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
  ],
  // 30
  [
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
  ],
  // 40
  [
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
  ],
  // 50
  [
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
    CalcUtil.random(50, 200),
  ],
]
const totalSummaryMale = [
  CalcUtil.sum2(summaryMale, 0),
  CalcUtil.sum2(summaryMale, 1),
  CalcUtil.sum2(summaryMale, 2),
  CalcUtil.sum2(summaryMale, 3),
  CalcUtil.sum2(summaryMale, 4),
  CalcUtil.sum2(summaryMale, 5),
  CalcUtil.sum2(summaryMale, 6),
]
const totalSummaryFemale = [
  CalcUtil.sum2(summaryFemale, 0),
  CalcUtil.sum2(summaryFemale, 1),
  CalcUtil.sum2(summaryFemale, 2),
  CalcUtil.sum2(summaryFemale, 3),
  CalcUtil.sum2(summaryFemale, 4),
  CalcUtil.sum2(summaryFemale, 5),
  CalcUtil.sum2(summaryFemale, 6),
]
// const summaryMaleRate = [
//   (100 * totalSummaryMale[0]) / (totalSummaryMale[0] + totalSummaryFemale[0]),
//   (100 * totalSummaryMale[1]) / (totalSummaryMale[1] + totalSummaryFemale[1]),
//   (100 * totalSummaryMale[2]) / (totalSummaryMale[2] + totalSummaryFemale[2]),
//   (100 * totalSummaryMale[3]) / (totalSummaryMale[3] + totalSummaryFemale[3]),
//   (100 * totalSummaryMale[4]) / (totalSummaryMale[4] + totalSummaryFemale[4]),
//   (100 * totalSummaryMale[5]) / (totalSummaryMale[5] + totalSummaryFemale[5]),
//   (100 * totalSummaryMale[6]) / (totalSummaryMale[6] + totalSummaryFemale[6]),
// ]
// const summaryFemaleRate = [
//   100 - summaryMaleRate[0],
//   100 - summaryMaleRate[1],
//   100 - summaryMaleRate[2],
//   100 - summaryMaleRate[3],
//   100 - summaryMaleRate[4],
//   100 - summaryMaleRate[5],
//   100 - summaryMaleRate[6],
// ]
const tenantSummaryItems = {
  // labels: ['6月', '7月', '8月', '9月', '10月', '11月', '12月']
  labels: [
    '2023/05/01',
    '2023/05/02',
    '2023/05/03',
    '2023/05/04',
    '2023/05/05',
    '2023/05/06',
    '2023/05/07',
    // '2023/04/03',
    // '2023/04/04',
    // '2023/04/05',
    // '2023/04/06',
    // '2023/04/07',
    // '2023/04/08',
    // '2023/04/09',
  ],
  malesData: summaryMale,
  femalesData: summaryFemale,
  maleCounts: [
    totalSummaryMale[0],
    totalSummaryMale[1],
    totalSummaryMale[2],
    totalSummaryMale[3],
    totalSummaryMale[4],
    totalSummaryMale[5],
    totalSummaryMale[6],
  ],
  femaleCounts: [
    totalSummaryFemale[0],
    totalSummaryFemale[1],
    totalSummaryFemale[2],
    totalSummaryFemale[3],
    totalSummaryFemale[4],
    totalSummaryFemale[5],
    totalSummaryFemale[6],
  ],
  inCounts: [
    totalSummaryMale[0] + totalSummaryFemale[0],
    totalSummaryMale[1] + totalSummaryFemale[1],
    totalSummaryMale[2] + totalSummaryFemale[2],
    totalSummaryMale[3] + totalSummaryFemale[3],
    totalSummaryMale[4] + totalSummaryFemale[4],
    totalSummaryMale[5] + totalSummaryFemale[5],
    totalSummaryMale[6] + totalSummaryFemale[6],
  ],
  stayings: [
    CalcUtil.random(10, 120),
    CalcUtil.random(10, 120),
    CalcUtil.random(10, 120),
    CalcUtil.random(10, 120),
    CalcUtil.random(10, 120),
    CalcUtil.random(10, 120),
    CalcUtil.random(10, 120),
  ],
  maleAge10: CalcUtil.sum(summaryMale[0]),
  maleAge20: CalcUtil.sum(summaryMale[1]),
  maleAge30: CalcUtil.sum(summaryMale[2]),
  maleAge40: CalcUtil.sum(summaryMale[3]),
  maleAge50: CalcUtil.sum(summaryMale[4]),
  maleNoage: 0,
  femaleAge10: CalcUtil.sum(summaryFemale[0]),
  femaleAge20: CalcUtil.sum(summaryFemale[1]),
  femaleAge30: CalcUtil.sum(summaryFemale[2]),
  femaleAge40: CalcUtil.sum(summaryFemale[3]),
  femaleAge50: CalcUtil.sum(summaryFemale[4]),
  femaleNoage: 0,
  males: CalcUtil.sum(totalSummaryMale),
  females: CalcUtil.sum(totalSummaryFemale),
  age10: CalcUtil.sum(summaryMale[0]) + CalcUtil.sum(summaryFemale[0]),
  age20: CalcUtil.sum(summaryMale[1]) + CalcUtil.sum(summaryFemale[1]),
  age30: CalcUtil.sum(summaryMale[2]) + CalcUtil.sum(summaryFemale[2]),
  age40: CalcUtil.sum(summaryMale[3]) + CalcUtil.sum(summaryFemale[3]),
  age50: CalcUtil.sum(summaryMale[4]) + CalcUtil.sum(summaryFemale[4]),
}

const tenantTargetHoursItems = {
  // isHoliday: [false, false, false, false, false, true, true],
  labels: ['月', '火', '水', '木', '金', '土', '日'],
  datasets: [
    {
      hour: '10',
      data: [
        CalcUtil.random(1, 10),
        CalcUtil.random(1, 20),
        CalcUtil.random(1, 30),
        CalcUtil.random(1, 40),
        CalcUtil.random(1, 50),
        CalcUtil.random(1, 60),
        CalcUtil.random(1, 70),
      ],
    },
    {
      hour: '11',
      data: [
        CalcUtil.random(1, 10),
        CalcUtil.random(1, 20),
        CalcUtil.random(1, 30),
        CalcUtil.random(1, 40),
        CalcUtil.random(1, 50),
        CalcUtil.random(1, 60),
        CalcUtil.random(1, 70),
      ],
    },
    {
      hour: '12',
      data: [
        CalcUtil.random(1, 10),
        CalcUtil.random(1, 20),
        CalcUtil.random(1, 30),
        CalcUtil.random(1, 40),
        CalcUtil.random(1, 50),
        CalcUtil.random(1, 60),
        CalcUtil.random(1, 70),
      ],
    },
    {
      hour: '13',
      data: [
        CalcUtil.random(1, 10),
        CalcUtil.random(1, 20),
        CalcUtil.random(1, 30),
        CalcUtil.random(1, 40),
        CalcUtil.random(1, 50),
        CalcUtil.random(1, 60),
        CalcUtil.random(1, 70),
      ],
    },
    {
      hour: '14',
      data: [
        CalcUtil.random(1, 10),
        CalcUtil.random(1, 20),
        CalcUtil.random(1, 30),
        CalcUtil.random(1, 40),
        CalcUtil.random(1, 50),
        CalcUtil.random(1, 60),
        CalcUtil.random(1, 70),
      ],
    },
    {
      hour: '15',
      data: [
        CalcUtil.random(1, 10),
        CalcUtil.random(1, 20),
        CalcUtil.random(1, 30),
        CalcUtil.random(1, 40),
        CalcUtil.random(1, 50),
        CalcUtil.random(1, 60),
        CalcUtil.random(1, 70),
      ],
    },
    {
      hour: '16',
      data: [
        CalcUtil.random(1, 10),
        CalcUtil.random(1, 20),
        CalcUtil.random(1, 30),
        CalcUtil.random(1, 40),
        CalcUtil.random(1, 50),
        CalcUtil.random(1, 60),
        CalcUtil.random(1, 70),
      ],
    },
    {
      hour: '17',
      data: [
        CalcUtil.random(1, 10),
        CalcUtil.random(1, 20),
        CalcUtil.random(1, 30),
        CalcUtil.random(1, 40),
        CalcUtil.random(1, 50),
        CalcUtil.random(1, 60),
        CalcUtil.random(1, 70),
      ],
    },
    {
      hour: '18',
      data: [
        CalcUtil.random(1, 10),
        CalcUtil.random(1, 20),
        CalcUtil.random(1, 30),
        CalcUtil.random(1, 40),
        CalcUtil.random(1, 50),
        CalcUtil.random(1, 60),
        CalcUtil.random(1, 70),
      ],
    },
    {
      hour: '19',
      data: [
        CalcUtil.random(1, 10),
        CalcUtil.random(1, 20),
        CalcUtil.random(1, 30),
        CalcUtil.random(1, 40),
        CalcUtil.random(1, 50),
        CalcUtil.random(1, 60),
        CalcUtil.random(1, 70),
      ],
    },
  ],
}

const promoEventItems = [
  {
    id: '##1',
    name: '春の感謝祭（Webサイトで告知）',
    period: {
      start: '2023-04-01',
      end: '2023-04-15',
    },
    description: '',
    avarage: 0,
    // avarage: (2097 + 664) / 2,
    sumStart: '',
    sumEnd: '',
  },
  {
    id: '##2',
    name: 'ブラックフライデー（Webサイトで告知）',
    period: {
      start: '2022-11-21',
      end: '2022-11-27',
    },
    description: '',
    avarage: 0,
    // avarage: 234.5 + 30,
    sumStart: '',
    sumEnd: '',
  },
  {
    id: '##3',
    name: '年末感謝祭（Webサイトで告知）',
    period: {
      start: '2022-12-10',
      end: '2022-12-25',
    },
    description: '',
    avarage: 0,
    // avarage: 234.5 + 30,
    sumStart: '',
    sumEnd: '',
  },
  {
    id: '##4',
    name: '年末感謝祭（サイネージで告知）',
    period: {
      start: '2022-12-11',
      end: '2022-12-25',
    },
    description: '',
    avarage: 0,
    // avarage: 234.5,
    sumStart: '',
    sumEnd: '',
  },
]

const spotDirectionData = [
  // spot1
  {
    label: '出入り口1',
    inFront: [
      Math.round(tenantSummaryItems.inCounts[0] * 0.6 * 0.5),
      Math.round(tenantSummaryItems.inCounts[1] * 0.6 * 0.5),
      Math.round(tenantSummaryItems.inCounts[2] * 0.6 * 0.5),
      Math.round(tenantSummaryItems.inCounts[3] * 0.6 * 0.5),
      Math.round(tenantSummaryItems.inCounts[4] * 0.6 * 0.5),
      Math.round(tenantSummaryItems.inCounts[5] * 0.6 * 0.5),
      Math.round(tenantSummaryItems.inCounts[6] * 0.6 * 0.5),
    ],
    inRight: [
      Math.round(tenantSummaryItems.inCounts[0] * 0.6 * 0.3),
      Math.round(tenantSummaryItems.inCounts[1] * 0.6 * 0.3),
      Math.round(tenantSummaryItems.inCounts[2] * 0.6 * 0.3),
      Math.round(tenantSummaryItems.inCounts[3] * 0.6 * 0.3),
      Math.round(tenantSummaryItems.inCounts[4] * 0.6 * 0.3),
      Math.round(tenantSummaryItems.inCounts[5] * 0.6 * 0.3),
      Math.round(tenantSummaryItems.inCounts[6] * 0.6 * 0.3),
    ],
    inLeft: [
      Math.round(tenantSummaryItems.inCounts[0] * 0.6 * 0.2),
      Math.round(tenantSummaryItems.inCounts[1] * 0.6 * 0.2),
      Math.round(tenantSummaryItems.inCounts[2] * 0.6 * 0.2),
      Math.round(tenantSummaryItems.inCounts[3] * 0.6 * 0.2),
      Math.round(tenantSummaryItems.inCounts[4] * 0.6 * 0.2),
      Math.round(tenantSummaryItems.inCounts[5] * 0.6 * 0.2),
      Math.round(tenantSummaryItems.inCounts[6] * 0.6 * 0.2),
    ],
    outFront: [
      Math.round(tenantSummaryItems.inCounts[0] * 0.4 * 0.5),
      Math.round(tenantSummaryItems.inCounts[1] * 0.4 * 0.5),
      Math.round(tenantSummaryItems.inCounts[2] * 0.4 * 0.5),
      Math.round(tenantSummaryItems.inCounts[3] * 0.4 * 0.5),
      Math.round(tenantSummaryItems.inCounts[4] * 0.4 * 0.5),
      Math.round(tenantSummaryItems.inCounts[5] * 0.4 * 0.5),
      Math.round(tenantSummaryItems.inCounts[6] * 0.4 * 0.5),
    ],
    outRight: [
      Math.round(tenantSummaryItems.inCounts[0] * 0.4 * 0.2),
      Math.round(tenantSummaryItems.inCounts[1] * 0.4 * 0.2),
      Math.round(tenantSummaryItems.inCounts[2] * 0.4 * 0.2),
      Math.round(tenantSummaryItems.inCounts[3] * 0.4 * 0.2),
      Math.round(tenantSummaryItems.inCounts[4] * 0.4 * 0.2),
      Math.round(tenantSummaryItems.inCounts[5] * 0.4 * 0.2),
      Math.round(tenantSummaryItems.inCounts[6] * 0.4 * 0.2),
    ],
    outLeft: [
      Math.round(tenantSummaryItems.inCounts[0] * 0.4 * 0.3),
      Math.round(tenantSummaryItems.inCounts[1] * 0.4 * 0.3),
      Math.round(tenantSummaryItems.inCounts[2] * 0.4 * 0.3),
      Math.round(tenantSummaryItems.inCounts[3] * 0.4 * 0.3),
      Math.round(tenantSummaryItems.inCounts[4] * 0.4 * 0.3),
      Math.round(tenantSummaryItems.inCounts[5] * 0.4 * 0.3),
      Math.round(tenantSummaryItems.inCounts[6] * 0.4 * 0.3),
    ],
  },
  // spot2
  {
    label: '出入り口2',
    inFront: [
      Math.round(tenantSummaryItems.inCounts[0] * 0.4 * 0.5),
      Math.round(tenantSummaryItems.inCounts[1] * 0.4 * 0.5),
      Math.round(tenantSummaryItems.inCounts[2] * 0.4 * 0.5),
      Math.round(tenantSummaryItems.inCounts[3] * 0.4 * 0.5),
      Math.round(tenantSummaryItems.inCounts[4] * 0.4 * 0.5),
      Math.round(tenantSummaryItems.inCounts[5] * 0.4 * 0.5),
      Math.round(tenantSummaryItems.inCounts[6] * 0.4 * 0.5),
    ],
    inRight: [
      Math.round(tenantSummaryItems.inCounts[1] * 0.4 * 0.3),
      Math.round(tenantSummaryItems.inCounts[2] * 0.4 * 0.3),
      Math.round(tenantSummaryItems.inCounts[3] * 0.4 * 0.3),
      Math.round(tenantSummaryItems.inCounts[0] * 0.4 * 0.3),
      Math.round(tenantSummaryItems.inCounts[4] * 0.4 * 0.3),
      Math.round(tenantSummaryItems.inCounts[5] * 0.4 * 0.3),
      Math.round(tenantSummaryItems.inCounts[6] * 0.4 * 0.3),
    ],
    inLeft: [
      Math.round(tenantSummaryItems.inCounts[0] * 0.4 * 0.2),
      Math.round(tenantSummaryItems.inCounts[1] * 0.4 * 0.2),
      Math.round(tenantSummaryItems.inCounts[2] * 0.4 * 0.2),
      Math.round(tenantSummaryItems.inCounts[3] * 0.4 * 0.2),
      Math.round(tenantSummaryItems.inCounts[4] * 0.4 * 0.2),
      Math.round(tenantSummaryItems.inCounts[5] * 0.4 * 0.2),
      Math.round(tenantSummaryItems.inCounts[6] * 0.4 * 0.2),
    ],
    outFront: [
      Math.round(tenantSummaryItems.inCounts[0] * 0.6 * 0.5),
      Math.round(tenantSummaryItems.inCounts[1] * 0.6 * 0.5),
      Math.round(tenantSummaryItems.inCounts[2] * 0.6 * 0.5),
      Math.round(tenantSummaryItems.inCounts[3] * 0.6 * 0.5),
      Math.round(tenantSummaryItems.inCounts[4] * 0.6 * 0.5),
      Math.round(tenantSummaryItems.inCounts[5] * 0.6 * 0.5),
      Math.round(tenantSummaryItems.inCounts[6] * 0.6 * 0.5),
    ],
    outRight: [
      Math.round(tenantSummaryItems.inCounts[0] * 0.6 * 0.2),
      Math.round(tenantSummaryItems.inCounts[1] * 0.6 * 0.2),
      Math.round(tenantSummaryItems.inCounts[2] * 0.6 * 0.2),
      Math.round(tenantSummaryItems.inCounts[3] * 0.6 * 0.2),
      Math.round(tenantSummaryItems.inCounts[4] * 0.6 * 0.2),
      Math.round(tenantSummaryItems.inCounts[5] * 0.6 * 0.2),
      Math.round(tenantSummaryItems.inCounts[6] * 0.6 * 0.2),
    ],
    outLeft: [
      Math.round(tenantSummaryItems.inCounts[0] * 0.6 * 0.3),
      Math.round(tenantSummaryItems.inCounts[1] * 0.6 * 0.3),
      Math.round(tenantSummaryItems.inCounts[2] * 0.6 * 0.3),
      Math.round(tenantSummaryItems.inCounts[3] * 0.6 * 0.3),
      Math.round(tenantSummaryItems.inCounts[4] * 0.6 * 0.3),
      Math.round(tenantSummaryItems.inCounts[5] * 0.6 * 0.3),
      Math.round(tenantSummaryItems.inCounts[6] * 0.6 * 0.3),
    ],
  },
]
const spot1InCount =
  CalcUtil.sum(spotDirectionData[0].inFront) +
  CalcUtil.sum(spotDirectionData[0].inRight) +
  CalcUtil.sum(spotDirectionData[0].inLeft)
const spot1OutCount =
  CalcUtil.sum(spotDirectionData[0].outFront) +
  CalcUtil.sum(spotDirectionData[0].outRight) +
  CalcUtil.sum(spotDirectionData[0].outLeft)
const spot2InCount =
  CalcUtil.sum(spotDirectionData[1].inFront) +
  CalcUtil.sum(spotDirectionData[1].inRight) +
  CalcUtil.sum(spotDirectionData[1].inLeft)
const spot2OutCount =
  CalcUtil.sum(spotDirectionData[1].outFront) +
  CalcUtil.sum(spotDirectionData[1].outRight) +
  CalcUtil.sum(spotDirectionData[1].outLeft)

const spotDirectionItems = {
  labels: [
    '2023/04/03',
    '2023/04/04',
    '2023/04/05',
    '2023/04/06',
    '2023/04/07',
    '2023/04/08',
    '2023/04/09',
  ],
  datasets: spotDirectionData,
}

const spotDirectionSummaryItems = [
  // spot1In
  {
    label: '出入り口1',
    Sum: spot1InCount,
    Front: Math.round(spot1InCount * 0.5),
    Right: Math.round(spot1InCount * 0.3),
    Left: Math.round(spot1InCount * 0.2),
  },
  // spot1Out
  {
    label: '出入り口1',
    Sum: spot1OutCount,
    Front: Math.round(spot1OutCount * 0.5),
    Right: Math.round(spot1OutCount * 0.2),
    Left: Math.round(spot1OutCount * 0.3),
  },
  // spot2In
  {
    label: '出入り口2',
    Sum: spot2InCount,
    Front: Math.round(spot2InCount * 0.5),
    Right: Math.round(spot2InCount * 0.3),
    Left: Math.round(spot2InCount * 0.2),
  },
  // spot2Out
  {
    label: '出入り口2',
    Sum: spot2OutCount,
    Front: Math.round(spot2OutCount * 0.5),
    Right: Math.round(spot2OutCount * 0.2),
    Left: Math.round(spot2OutCount * 0.3),
  },
]

const downloadPeriodItems = [
  {
    start: '2021/10/01',
    end: '2021/10/31',
  },
  {
    start: '2021/11/01',
    end: '2021/11/30',
  },
  {
    start: '2021/12/01',
    end: '2021/12/31',
  },
]

export default {
  homeSummaryItems,
  homeCountingItems,
  homeGenderAgeItems,
  multiTenantCountingItems,
  multiTenantSummaryItems,
  // tenantCountingWeeklyItems,
  tenantCountingHoursItems,
  tenantSummaryItems,
  tenantTargetHoursItems,
  promoEventItems,
  spotDirectionItems,
  spotDirectionSummaryItems,
  downloadPeriodItems,
}
