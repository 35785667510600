const newTenantItem = (resultData) => {
  return {
    id: resultData.id,
    name: resultData.name,
    area: resultData.area,
    location: resultData.location,
    description: resultData.description,
    businessHours: [
      {
        begin: resultData.businessHours[0].begin,
        open: resultData.businessHours[0].open,
        close: resultData.businessHours[0].close,
      },
    ],
  }
}

// 【未使用】
const tenantItem = (data) => {
  let item = {
    id: data.id,
    name: data.name,
    area: data.area,
    location: data.location,
    description: data.description,
    businessHours: [
      {
        begin: data.businessHours[0].begin,
        open: data.businessHours[0].open,
        close: data.businessHours[0].close,
      },
    ],
  }

  // TODO: 将来に開店時間の履歴を取り扱うようになったときに実装する

  return item
}

export default {
  newTenantItem,
  tenantItem,
}
