const newTenant = (
  tenantId,
  { name, area, location, description, begin, open, close, iotDeviceID },
) => {
  return {
    id: tenantId,
    name: name,
    area: area,
    location: location,
    description: description,
    businessHours: [{ begin: begin, open: open, close: close }],
    deviceID: iotDeviceID,
  }
}

export default {
  newTenant,
}
