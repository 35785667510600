const newPromoEventItem = (resultData) => {
  return {
    id: resultData.id,
    name: resultData.name,
    start: resultData.period.start,
    end: resultData.period.end,
    category: resultData.category,
    location: resultData.location,
    description: resultData.description,
    avarage: 0,
    sumStart: '',
    sumEnd: '',
  }
}

const promoEventItem = (data) => {
  let item = {
    id: data.id,
    name: data.name,
    category: data.category,
    location: data.location,
    description: data.description,
    start: data.period.start,
    end: data.period.end,
    avarage: 0,
    sumStart: '',
    sumEnd: '',
  }

  return item
}

export default {
  newPromoEventItem,
  promoEventItem,
}
