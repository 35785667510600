import ServiceOptionInteractor from '@/domain/usecases/ServiceOptionInteractor'

import ServiceOptionPresenter from '@/adapters/ServiceOptionPresenter'
import StoreRepository from '@/adapters/StoreRepository'

import sampledata from '@/adapters/sampledata'

import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'ServiceController'

// サービスオプション一覧の取得
const fetchServiceOptionList = async (context) => {
  const TAG = `${MODULE_TAG}.${fetchServiceOptionList.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const cwlogger = await Logger.getInstance(context.getters.getCurrentUsername)
  cwlogger?.verbose(`begin ${TAG}`)

  const input = {
    // 現在ログインしているアカウントの契約情報を取得する
    contractId: context.state.contract.id,
  }
  logger.verbose('contractId: ' + input.contractId)

  // サービスオプション一覧の取得
  const output = await ServiceOptionInteractor.getServiceOptionsList(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, `getServiceOptionsList error`))
    logger.error(output.error)
    return
  }

  // const options = sampledata.optionItems // TODO: 本番の処理に置き換える
  const options = ServiceOptionPresenter.convertDbDataToStore(output.result)
  const contractId = input.contractId

  logger.verbose('=== optionItems ===')
  logger.verbose(options)

  // ストアの更新
  StoreRepository.setOptionItems(context, { contractId, options })
}

// サービスオプションの新規登録
const createServiceOption = async (
  context,
  { keyName, defaultValue, optionName, description },
) => {
  const TAG = `${MODULE_TAG}.${createServiceOption.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  // 現在ログインしているアカウントの契約情報を取得する
  const contractId = context.state.contract.id
  if (!contractId) {
    const error = '契約ＩＤが存在しません'
    logger.error(Logger.formatter(TAG, error))
    alert('error')
    return
  }

  let index = -1
  const optionItems = context.state.optionItems
  optionItems.find((item, idx) => {
    if (!item) {
      return false
    } else if (item.key == keyName) {
      index = idx
      return true
    } else {
      return false
    }
  })
  // 重複登録の抑制
  if (0 < index) {
    const error = '既に存在するオプションです'
    logger.error(
      Logger.formatter(
        TAG,
        'index:' + index + ', key name:' + keyName + ', ' + error,
      ),
    )
    alert(error)
    return
  }

  // サービスオプションの新規作成
  const input = { keyName, defaultValue, optionName, description, contractId }

  // オプション作成を要求する
  const output = await ServiceOptionInteractor.createServiceOption(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, `createIotServiceOption error`))
    logger.error(output.error)
    alert(output.error)
    return
  }
  const inputData = output.result
  const newItem = ServiceOptionPresenter.convertNewDbDataToStore(
    inputData,
    optionName,
    description,
  )
  logger.verbose('=== create new option! ===')
  logger.verbose(newItem)

  // ストアに新しいユーザー情報として追加する ~ 画面更新
  StoreRepository.addOptionItems(context, {
    newItem,
  })
  return
}

// サービスオプションの設定変更
const editServiceOption = async (
  context,
  { keyName, limit, optionName, description },
) => {
  const TAG = `${MODULE_TAG}.${editServiceOption.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(keyName, limit, optionName, description)

  // 現在ログインしているアカウントの契約IDを取得する
  const contractId = context.state.contract.id

  const optionItems = context.state.optionItems
  let optionItem = null
  let optionId = null
  let index = -1
  optionItems.forEach((item, idx) => {
    if (item.key === keyName) {
      // 法人アカウントに属するサービスオプション情報の抽出
      optionItem = item
      logger.verbose(item.id, item.key)
      logger.verbose(optionItem)
      optionId = item.id
      index = idx
    }
  })
  if (index < 0) {
    const error = '編集するオプション ' + keyName + ' は存在しません'
    logger.error(Logger.formatter(TAG, error))
    return
  }

  const input = {
    id: optionId,
    keyName: keyName,
    limit: limit,
    optionName: optionName,
    description: description,
    contractId: context.state.contract.id,
  }
  logger.verbose(Logger.formatter(TAG, `input`))
  logger.verbose(input)
  // logger.verbose(optionItem)

  const output = await ServiceOptionInteractor.editServiceOptionProfile(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, output.error))
    alert(output.error)
    return
  }
  const dataResult = output.result
  logger.verbose('edit id: ' + dataResult.id)

  // let option = context.state.optionItems[index]
  // // 既にあるサービスオプション情報に上書きする
  // option.limit = dataResult.value

  // ストアの更新
  StoreRepository.editOptionItems(context, {
    contractId,
    keyName,
    limit,
    optionName,
    description,
  })
}

// サービスオプションの登録解除
const deleteServiceOption = async (context, { keyName }) => {
  const TAG = `${MODULE_TAG}.${deleteServiceOption.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  // 削除するサービスオプションの名前
  const deleteKey = keyName

  const optionItems = context.state.optionItems
  let iotServiceOption = null
  let index = -1
  optionItems.forEach((item, idx) => {
    if (item.key === keyName) {
      // 法人アカウントに属するサービスオプション情報の抽出
      iotServiceOption = item
      index = idx
    }
  })
  if (index < 0) {
    const error = '削除するオプション ' + keyName + ' は存在しません'
    logger.error(Logger.formatter(TAG, error))
    return
  }

  const input = {
    deleteId: iotServiceOption.id,
    keyName: iotServiceOption.key,
  }
  // AppSyncにサービスオプション削除のリクエストを送る
  const output = await ServiceOptionInteractor.deleteServiceOption(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, output.error))
    alert(output.error)
    return
  }
  const dataResult = output.result
  logger.verbose('delete id: ' + dataResult.id)

  // ストアの更新
  StoreRepository.deleteOptionItem(context, {
    deleteKey,
  })
}

export default {
  createServiceOption,
  fetchServiceOptionList,
  editServiceOption,
  deleteServiceOption,
}
