import { API } from 'aws-amplify'

import { listIotDevices } from '@/graphql/queries'
import { createIotDevice } from '@/graphql/mutations'
import { updateIotDevice } from '@/graphql/mutations'
import { deleteIotDevice } from '@/graphql/mutations'

import IotDevice from '@/domain/models/IotDevice'

import DateUtil from '@/utils/DateUtil'
import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'DeviceRepository'

const createNewDevice = async ({ contractId, deviceProfile }) => {
  const TAG = `${MODULE_TAG}.${createNewDevice.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  let inputData = {
    id: deviceProfile.id,
    host: deviceProfile.host,
    thing: {
      thingName: deviceProfile.deviceName,
      thingArn: '',
      attributes: {},
      version: 0,
      thingId: '',
      defaultClientId: deviceProfile.deviceName,
      thingTypeName: '',
      // registered: deviceProfile.registered,
    },
    description: '',
    category: '',
    location: '',
    os: deviceProfile.os,
    storagePath: deviceProfile.storagePath,
    topic: '',
    clientCertFile: '',
    activity: '',
    contractID: contractId,
  }
  logger.verbose(Logger.formatter(TAG, `inputData:`))
  logger.verbose(inputData)

  const response = await API.graphql({
    query: createIotDevice,
    variables: { input: inputData },
  })
    .then(async (result) => {
      logger.verbose('=== createIotDevice result ===')
      logger.verbose(result)
      return {
        data: result.data.createIotDevice,
        error: null,
      }
    })
    .catch((error) => {
      logger.error('=== createIotDevice error ===')
      logger.error(error)
      return {
        data: null,
        error: error,
      }
    })
  if (!response) {
    return {
      data: null,
      error: new Error('no response!'),
    }
  }
  logger.verbose(Logger.formatter(TAG, `response:`))
  logger.verbose(response)

  return response
}

const updateDeviceProfile = async ({ contractId, deviceProfile }) => {
  const TAG = `${MODULE_TAG}.${updateDeviceProfile.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  let inputData = {
    id: deviceProfile.id,
    host: deviceProfile.host,
    thing: {
      thingName: deviceProfile.deviceName,
      thingArn: '',
      attributes: {},
      version: 0,
      thingId: '',
      defaultClientId: deviceProfile.deviceName,
      thingTypeName: '',
    },
    description: deviceProfile.description,
    category: deviceProfile.category,
    location: deviceProfile.location,
    os: deviceProfile.os,
    storagePath: deviceProfile.storagePath,
    // topic: '',
    // clientCertFile: '',
    // activity: '',
    contractID: contractId,
  }
  logger.verbose(Logger.formatter(TAG, `inputData:`))
  logger.verbose(inputData)

  const response = await API.graphql({
    query: updateIotDevice,
    variables: { input: inputData },
  })
    .then(async (result) => {
      logger.verbose('=== updateIotDevice result ===')
      logger.verbose(result)
      return {
        data: result.data.updateIotDevice,
        error: null,
      }
    })
    .catch((error) => {
      logger.error('=== updateIotDevice error ===')
      logger.error(error)
      return {
        data: null,
        error: error,
      }
    })
  if (!response) {
    return {
      data: null,
      error: new Error('no response!'),
    }
  }

  return response
}

const updateDeviceActivity = async ({ deviceId, activity }) => {
  const TAG = `${MODULE_TAG}.${updateDeviceActivity.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  let inputData = {
    id: deviceId,
    activity: activity,
  }
  logger.verbose(Logger.formatter(TAG, `inputData:`))
  logger.verbose(inputData)

  const response = await API.graphql({
    query: updateIotDevice,
    variables: { input: inputData },
  })
    .then(async (result) => {
      logger.verbose('=== updateIotDevice result ===')
      logger.verbose(result)
      return {
        data: result.data.updateIotDevice,
        error: null,
      }
    })
    .catch((error) => {
      logger.error('=== updateIotDevice error ===')
      logger.error(error)
      return {
        data: null,
        error: error,
      }
    })
  if (!response) {
    return {
      data: null,
      error: new Error('no response!'),
    }
  }

  return response
}

const fetchDevicesList = async (contractId) => {
  const TAG = `${MODULE_TAG}.${fetchDevicesList.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const response = await API.graphql({
    query: listIotDevices,
    // limit: 50,
  })
    .then((result) => {
      logger.verbose('=== listIotDevices result ===')
      logger.verbose(result)
      return {
        data: result.data.listIotDevices.items,
        error: null,
      }
    })
    .catch((error) => {
      logger.error('=== listIotDevices error ===')
      logger.error(error)
      return {
        data: [],
        error: error,
      }
    })
  if (!response) {
    return {
      data: [],
      error: new Error('no response!'),
    }
  }

  let items = []
  response.data.forEach((fetchItem) => {
    if (fetchItem.contractID == contractId) {
      items.push(fetchItem)
    }
  })
  response.data = items

  return response
}

const deleteResourceById = async (deleteId) => {
  const TAG = `${MODULE_TAG}.${deleteResourceById.name}`
  logger.verbose(Logger.formatter(TAG, `begin with deleteId: ${deleteId}`))

  if (deleteId == null || deleteId == '' || typeof deleteId === undefined)
    return new Error('deleteId is empty!')

  const response = await API.graphql({
    query: deleteIotDevice,
    variables: { input: { id: deleteId } },
  })
    .then((result) => {
      logger.verbose('=== deleteIotDevice result ===')
      logger.verbose(result)
      return {
        data: result.data.deleteIotDevice,
        error: null,
      }
    })
    .catch((error) => {
      logger.error('=== deleteIotDevice error ===')
      logger.error(error)
      return {
        data: null,
        error: error,
      }
    })
  if (!response) {
    return {
      data: null,
      error: new Error('no response!'),
    }
  }

  return response
}

export default {
  createNewDevice,
  updateDeviceProfile,
  updateDeviceActivity,
  fetchDevicesList,
  deleteResourceById,
}
