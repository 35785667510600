import { Auth } from 'aws-amplify'

import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'AuthController'

const login = async (context) => {
  const TAG = `${MODULE_TAG}.${login.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  // NOTE: ログイン処理は、App.vue の Authenticator コンポーネントに任せているため、アプリケーション側の実装はない。
}

const logout = async (context) => {
  const TAG = `${MODULE_TAG}.${logout.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  try {
    const username = context.getters.getCurrentUsername
    const cwlogger = await Logger.getInstance(username)
    cwlogger?.info(Logger.formatter(TAG, `${username} logout`))

    setTimeout(() => {
      // CloudWatch Logs へのログの転送が終わるまで少し待つ
      Auth.signOut()
    }, 3000)
  } catch (error) {
    logger.error(Logger.formatter(TAG, 'error Auth.signOut()'))
    logger.error(Logger.formatter(error))
  }
}

export default {
  logout,
}
