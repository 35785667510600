import { Auth, API } from 'aws-amplify'

import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'IdaasRepository'

const getCurrentSession = async () => {
  return await Auth.currentSession()
    .then(async (result) => {
      // logger.verbose('--- Auth.currentSession result ---')
      // logger.verbose(result)
      return result
    })
    .catch((error) => {
      logger.error('--- Auth.currentSession error ---')
      logger.error(error)
      return null
    })
}

const createNewAccount = async ({ username, password, email }) => {
  const TAG = `${MODULE_TAG}.${createNewAccount.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  // Cognito とのセッションを取得する
  const sessionResult = await getCurrentSession()
  if (!sessionResult) {
    return
  }

  const apiName = 'AdminQueries'
  const userParams = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${sessionResult.getAccessToken().getJwtToken()}`,
    },
    body: {
      username,
    },
  }
  // Cognito ユーザー新規作成
  const userResult = await API.post(apiName, '/createUser', userParams)
    .then(async (result) => {
      logger.verbose('=== /createUser result ===')
      logger.verbose(result)
      return result
    })
    .catch((error) => {
      logger.error('=== /createUser error ===')
      logger.error(error)
      return null
    })
  if (!userResult) {
    return null
  }

  const groupParams = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${sessionResult.getAccessToken().getJwtToken()}`,
    },
    body: {
      username: username,
      groupname: 'Corporates',
    },
  }
  // 法人グループにユーザーを追加する
  const groupResult = await API.post(apiName, '/addUserToGroup', groupParams)
    .then(async (result) => {
      logger.verbose('=== /addUserToGroup result ===')
      logger.verbose(result)
      return result
    })
    .catch((error) => {
      logger.error('=== /addUserToGroup error ===')
      logger.error(error)
      return null
    })
  if (!groupResult) {
    return null
  }

  const passwordParams = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${sessionResult.getAccessToken().getJwtToken()}`,
    },
    body: {
      username,
      password,
    },
  }
  // パスワード設定
  const passResult = await API.post(apiName, '/setPassword', passwordParams)
    .then(async (result) => {
      logger.verbose('=== /setPassword result ===')
      logger.verbose(result)
      return result
    })
    .catch((error) => {
      logger.error('=== /setPassword error ===')
      logger.error(error)
      return null
    })
  if (!passResult) {
    return null
  }

  const groupAdminParams = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${sessionResult.getAccessToken().getJwtToken()}`,
    },
    body: {
      username: username,
      groupname: 'Admins',
    },
  }
  // 管理者グループにユーザーを追加する
  // （アプリのログインユーザーを作成できる権限を得る）
  const AdminGroupResult = await API.post(
    apiName,
    '/addUserToGroup',
    groupAdminParams,
  )
    .then((result) => {
      logger.verbose('=== /addUserToGroup result ===')
      logger.verbose(result)
      return result
    })
    .catch((error) => {
      logger.error('=== /addUserToGroup error ===')
      logger.error(error)
      return null
    })
  if (!AdminGroupResult) {
    return null
  }

  return userResult.User
}

const createNewUser = async ({ username, password, email }) => {
  const TAG = `${MODULE_TAG}.${createNewUser.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  // Cognito とのセッションを取得する
  const sessionResult = await getCurrentSession()
  if (!sessionResult) {
    return null
  }

  const apiName = 'AdminQueries'
  const groupname = 'Operators'
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${sessionResult.getAccessToken().getJwtToken()}`,
    },
    body: {
      username,
    },
  }
  const groupParams = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${sessionResult.getAccessToken().getJwtToken()}`,
    },
    body: {
      username,
      groupname,
    },
  }
  const passwordParams = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${sessionResult.getAccessToken().getJwtToken()}`,
    },
    body: {
      username,
      password,
    },
  }

  // ユーザー新規作成
  const userResult = await API.post(apiName, '/createUser', params)
    .then(async (result) => {
      logger.verbose('=== /createUser result ===')
      logger.verbose(result)
      return result
    })
    .catch((error) => {
      logger.error('=== /createUser error ===')
      logger.error(error)
      return null
    })
  if (!userResult) {
    return null
  }

  // グループにユーザーを追加する
  const addResult = await API.post(apiName, '/addUserToGroup', groupParams)
    .then(async (result) => {
      // logger.verbose('=== /addUserToGroup result ===')
      // logger.verbose(result)
      return result
    })
    .catch((error) => {
      logger.error('=== /addUserToGroup error ===')
      logger.error(error)
      return null
    })
  if (!addResult) {
    return null
  }

  // パスワード設定
  const passResult = await API.post(apiName, '/setPassword', passwordParams)
    .then(async (result) => {
      // logger.verbose('=== /setPassword result ===')
      // logger.verbose(result)
      return result
    })
    .catch((error) => {
      logger.error('=== /setPassword error ===')
      logger.error(error)
      return null
    })
  if (!passResult) {
    return null
  }

  return userResult.User
}

const fetchIdaasUserList = async ({ groupName, accounts, nextToken }) => {
  const TAG = `${MODULE_TAG}.${fetchIdaasUserList.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const sessionResult = await getCurrentSession()
  // groupName = 'Corporates';
  const LIST_LOOP_LIMIT = 50

  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${sessionResult.getAccessToken().getJwtToken()}`,
    },
    queryStringParameters: {
      groupname: groupName,
      limit: LIST_LOOP_LIMIT,
      token: nextToken,
    },
  }

  const apiName = 'AdminQueries'
  const path = '/listUsersInGroup'

  const listResults = await API.get(apiName, path, params)
    .then((result) => {
      logger.verbose('=== /listUsersInGroup result ===')
      logger.verbose(result)
      return result
    })
    .catch((error) => {
      logger.error('=== /listUsersInGroup error ===')
      logger.error(error)
      return null
    })

  let listUser = []

  if (listResults.NextToken) {
    // 再帰呼び出し
    if (accounts == null) {
      const tmp = listResults.Users
      const accounts = tmp
      const nextToken = listResults.NextToken
      fetchIdaasUserList({
        groupName,
        accounts,
        nextToken,
      })
    } else {
      const accounts = accounts.concat(listResults.Users)
      const nextToken = listResults.NextToken
      fetchIdaasUserList({
        groupName,
        accounts,
        nextToken,
      })
    }
  } else {
    if (accounts == null) {
      listUser = listResults.Users
    } else {
      listUser = accounts.concat(listResults.Users)
    }
  }

  logger.verbose(Logger.formatter(TAG, `end, listUser:`))
  logger.verbose(listUser)
  return listUser
}

const fetchLoginUsers = async () => {
  const TAG = `${MODULE_TAG}.${fetchLoginUsers.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const groupName = 'Operators'
  const accounts = null
  const nextToken = null

  const listUsers = await fetchIdaasUserList({
    groupName,
    accounts,
    nextToken,
  })

  return listUsers
}

const updateLoginUsersByIDaaSUserStatus = async (context, { contractId }) => {
  const TAG = `${MODULE_TAG}.${updateLoginUsersByIDaaSUserStatus.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const groupName = 'Operators'
  const accounts = null
  const nextToken = null

  const listUsers = await fetchIdaasUserList({
    groupName,
    accounts,
    nextToken,
  })

  logger.verbose(Logger.formatter(TAG, `contractId: ${contractId}`))

  let userItems = context.state.userItems
  logger.verbose(Logger.formatter(TAG, `userItems:`))
  logger.verbose(userItems)
  let match = false
  let already = false

  listUsers.forEach(function (user, index) {
    match = false
    already = false

    userItems.forEach((item) => {
      if (item.user.contractID === contractId) {
        // 法人アカウントに属するユーザー情報
        match = true
      }
      if (item.user.username === user.Username) {
        // ストアの状態を更新する
        already = true
        // item.id = ''
        item.user.sub = user.Attributes[0].Value
        // Cognito のアカウントステータスを更新する
        item.user.enabled = user.Enabled
        item.user.status = user.UserStatus
      }
    })

    if (already == false) {
      if (match == true) {
        // const sub = user.Attributes[0].Value
        // const username = user.Username
        // const registered = user.registered
        // const enabled = user.enabled
        // const status = user.UserStatus
        // // ユーザープロファイルを生成して法人アカウントに関連付ける
        // const userProfile = AppUser.newAppUserWithIDaaSparams(
        //   sub,
        //   username,
        //   registered,
        //   enabled,
        //   status,
        // )
        // userItems.push(userProfile)
      }
    }
  })
  // userItems = sampledata.userItems
  context.commit('setUserItems', userItems)
}

const enableAccount = async (username, enable) => {
  const TAG = `${MODULE_TAG}.${enableAccount.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const apiName = 'AdminQueries'
  const path = enable ? '/enableUser' : '/disableUser'

  const sessionResult = await getCurrentSession()
  if (!sessionResult) {
    return
  }

  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${sessionResult.getAccessToken().getJwtToken()}`,
    },
    body: {
      username,
    },
  }
  logger.verbose(Logger.formatter(TAG, path + ' ' + username))
  // Cognitoユーザーの有効／無効
  await API.post(apiName, path, params)
    .then(async (result) => {
      logger.verbose(`=== ${path} result ===`)
      logger.verbose(result)
    })
    .catch((error) => {
      logger.error(`=== ${path} error ===`)
      logger.error(error)
    })
}

const enableUser = async (username, enable) => {
  const TAG = `${MODULE_TAG}.${enableUser.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const apiName = 'AdminQueries'
  const path = enable ? '/enableUser' : '/disableUser'

  const sessionResult = await Auth.currentSession()
    .then((result) => {
      logger.verbose(`=== ${path} result ===`)
      logger.verbose(result)
      return result
    })
    .catch((error) => {
      logger.error(`=== ${path} error ===`)
      logger.error(error)
      return null
    })
  if (!sessionResult) {
    return
  }

  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${sessionResult.getAccessToken().getJwtToken()}`,
    },
    body: {
      username,
    },
  }
  logger.verbose(path + ' ' + username)
  // Cognitoユーザーの有効／無効
  const enableResult = await API.post(apiName, path, params)
    .then(async (result) => {
      logger.verbose(`=== ${path} result ===`)
      logger.verbose(result)
      return result
    })
    .catch((error) => {
      logger.error(`=== ${path} error ===`)
      logger.error(error)
      return null
    })

  return enableResult
}

const deleteAccount = async (username) => {
  const TAG = `${MODULE_TAG}.${deleteAccount.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const apiName = 'AdminQueries'
  const path = '/deleteUser'

  const sessionResult = await getCurrentSession()
  if (!sessionResult) {
    return null
  }

  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${sessionResult.getAccessToken().getJwtToken()}`,
    },
    body: {
      username,
    },
  }

  // Cognitoユーザーの削除
  const deleteResult = await API.post(apiName, path, params)
    .then((result) => {
      logger.verbose(`=== ${path} result ===`)
      logger.verbose(result)
      return result
    })
    .catch((error) => {
      logger.error(`=== ${path} error ===`)
      logger.error(error)
      return null
    })

  return deleteResult
}

const deleteUser = async (username) => {
  const TAG = `${MODULE_TAG}.${deleteUser.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const sessionResult = await getCurrentSession()
  if (!sessionResult) {
    return null
  }

  const apiName = 'AdminQueries'
  const path = '/deleteUser'
  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${sessionResult.getAccessToken().getJwtToken()}`,
    },
    body: {
      username,
    },
  }
  // Cognitoユーザーの削除
  const deleteResult = await API.post(apiName, path, params)
    .then(async (result) => {
      logger.verbose(`=== ${path} result ===`)
      logger.verbose(result)
      return result
    })
    .catch((error) => {
      logger.error(`=== ${path} error ===`)
      logger.error(error)
      return null
    })

  return deleteResult
}

const resetPassword = async (username, password) => {
  const TAG = resetPassword.name
  logger.verbose(Logger.formatter(TAG, `begin`))

  const apiName = 'AdminQueries'

  // Cognito とのセッションを取得する
  const sessionResult = await getCurrentSession()
  if (!sessionResult) {
    return
  }

  const params = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${sessionResult.getAccessToken().getJwtToken()}`,
    },
    body: {
      username,
      password,
    },
  }
  // logger.verbose(params)

  // パスワード設定
  await API.post(apiName, '/setPassword', params)
    .then((result) => {
      logger.verbose('=== /setPassword result ===')
      logger.verbose(result)
    })
    .catch((error) => {
      logger.error('=== /setPassword error ===')
      logger.error(error)
    })
}

export default {
  getCurrentSession,
  createNewAccount,
  createNewUser,
  fetchIdaasUserList,
  fetchLoginUsers,
  updateLoginUsersByIDaaSUserStatus,
  enableAccount,
  enableUser,
  deleteAccount,
  deleteUser,
  resetPassword,
}
