import PeopleCount from '@/domain/models/PeopleCount'

import AppUserRepository from '@/adapters/AppUserRepository'
// import IdaasRepository from '@/adapters/IdaasRepository'
import DeviceRepository from '@/adapters/DeviceRepository'
import TenantRepository from '@/adapters/TenantRepository'
import CloudStorageRepository from '@/adapters/CloudStorageRepository'

// import MockDeviceRepository from '@/mock/DeviceRepository'
// import MockCloudStorageRepository from '@/mock/CloudStorageRepository'

// ダッシュボードのユースケース
const getPeopleCountDataByTotal = async (input) => {
  const contractId = input.contractId
  const userId = input.identityId
  const begin = input.begin
  const end = input.end
  let type = input.type
  if (type == 'Monthly') {
    // 全店舗総計データは月次であっても日次データをベースにする
    type = 'Daily'
  }

  // ユーザー情報の取得
  const ownUserProfile = await AppUserRepository.getUserProfile({
    appUserId: userId,
  })
  // console.log(ownUserProfile)
  // ユーザープロファイルから所属情報を取得する
  const ownDepartmentName = ownUserProfile.department
  const ownTenantName = ownUserProfile.tenant

  // // IDaaSユーザーの状態取得
  // const idaasUsers = await IdaasRepository.fetchLoginUsers()
  // console.log(idaasUsers)

  // デバイス情報の一覧を取得する（storagePath 取得のため）
  const resdev = await DeviceRepository.fetchDevicesList(contractId)
  const devices = resdev.data
  // const resdev = await MockDeviceRepository.fetchDevicesList(contractId)
  // console.log(resdev.data[0])

  const validDevices = []
  const results = []
  for (const device of devices) {
    const storagePath = device.storagePath
    // console.log(storagePath)
    if (storagePath == '-') {
      // ストレージパスが未設定なら、その店舗のデータレイクは未稼働として割り当てない。
      continue
    }
    validDevices.push(device)
    // デバイス情報から所属情報と店舗名を取得する
    const areaName = device.category
    const tenantName = device.location
    // const areaName = '愛知県'
    // const tenantName = '名古屋店'

    const result = {
      before: {},
      current: {},
      after: {},
    }

    // 集計データ一の取得
    const res = await CloudStorageRepository.fetchDataFromStorage(userId, {
      storagePath: storagePath,
      begin: begin,
      end: end,
      type: type,
      mode: input.type == 'Monthly',
      // TODO: 以下、S3 にあるデータに合わせてデバッグする
      // begin: '2023/04/01',
      // end: '2023/04/30',
      // type: 'Daily',
      areaName: areaName,
      tenantName: tenantName,
      gender: '',
      ageGroup: '',
    })
    // console.log(res)
    // mock を除外する
    // const res_ = await MockCloudStorageRepository.fetchDataFromStorage(userId, {
    //   storagePath,
    //   begin,
    //   end,
    //   type,
    // })
    // console.log(res)

    // console.log(res.data.length)

    res.data.forEach((item, index) => {
      // console.log(item)
      const dat = {
        labels: item.indices,
        datasets: [],
        malesData: [[], [], [], [], []],
        femalesData: [[], [], [], [], []],
      }
      for (const record of item.datasets) {
        dat.datasets.push(record)

        setAttributeArray(dat, record)
      }
      // console.log(dat.datasets[0])

      if (index == 0) {
        result.before = Object.create(dat)
      } else if (index == 1) {
        // console.log(dat)
        result.current = Object.create(dat)
      } else if (index == 2) {
        result.after = Object.create(dat)
      }
    })

    results.push(result)
  }

  let ownDevice = null
  validDevices.forEach((device) => {
    // 店舗名から自店舗のデバイス情報を抽出する
    if (device.location == ownTenantName) {
      ownDevice = device
    }
  })

  // 店舗情報を取得する
  const resTenant = await TenantRepository.fetchTenantInfo(ownDevice.id)
  const tenantInfo = resTenant.data

  let summaryInCounts = []
  // let summaryAveStaying = []
  let totalGenderAgeMaleRate = [0, 0, 0, 0, 0]
  let totalGenderAgeFemaleRate = [0, 0, 0, 0, 0]
  let multiSummary = []

  results.forEach((tenant, tenant_idx) => {
    let tenantSummary = {}
    // 店舗毎のループ
    tenant.current.datasets.forEach((record, index) => {
      // レコード毎のループ
      // 来客数｜全店舗総計
      if (tenant_idx == 0) {
        summaryInCounts.push(record.inCount)
        // summaryAveStaying.push(record.staying)
      } else {
        summaryInCounts[index] += record.inCount
        // summaryAveStaying[index] += record.staying
      }

      // console.log(tenant_idx, record.maleAge30)
      // 来客属性｜全店舗総計
      totalGenderAgeMaleRate[0] += record.maleAge10
      totalGenderAgeMaleRate[1] += record.maleAge20
      totalGenderAgeMaleRate[2] += record.maleAge30
      totalGenderAgeMaleRate[3] += record.maleAge40
      totalGenderAgeMaleRate[4] += record.maleAge50
      totalGenderAgeFemaleRate[0] += record.femaleAge10
      totalGenderAgeFemaleRate[1] += record.femaleAge20
      totalGenderAgeFemaleRate[2] += record.femaleAge30
      totalGenderAgeFemaleRate[3] += record.femaleAge40
      totalGenderAgeFemaleRate[4] += record.femaleAge50

      // 店舗間比較のテーブルデータ
      if (index == 0) {
        tenantSummary = Object.assign({}, record)
      } else {
        PeopleCount.addPeopleCount(tenantSummary, record)
      }
    })

    multiSummary.push(tenantSummary)
  })

  let summary = {
    labels: results[0].current.labels,
    // datasets: results[0].current.datasets,
    datasets: [
      {
        label: '入店数',
        data: summaryInCounts,
      },
      {
        label: '表示区間の平均入店数',
        data: [],
      },
      // {
      //   label: '平均滞在数',
      //   data: summaryAveStaying,
      // },
    ],
  }
  const genderAgeRate = [
    {
      gender: '男性',
      rate: totalGenderAgeMaleRate,
    },
    {
      gender: '女性',
      rate: totalGenderAgeFemaleRate,
    },
  ]
  // console.log(genderAge[0].rate)
  // console.log(genderAge[1].rate)

  return {
    results: results,
    summary: summary,
    genderAgeRate: genderAgeRate,
    multiSummary: multiSummary,
    ownTenant: {
      name: ownTenantName,
      info: tenantInfo,
      device: ownDevice,
    },
    user: ownUserProfile,
    error: null,
  }
}

// 店舗間比較のユースケース
const getPeopleCountDataByComparison = async (input) => {
  const contractId = input.contractId
  const userId = input.identityId
  const area = input.area
  const tenant = input.tenant
  const begin = input.begin
  const end = input.end
  let type = input.type
  // if (type == 'Monthly') {
  //   type = 'Daily'
  // }

  // デバイス情報の一覧を取得する（storagePath）
  const resdev = await DeviceRepository.fetchDevicesList(contractId)
  const devices = resdev.data
  // const resdev = await MockDeviceRepository.fetchDevicesList(contractId)
  // console.log(resdev.data[0])

  const validDevices = []
  const results = []
  for (const device of devices) {
    const storagePath = device.storagePath
    // console.log(storagePath)
    if (storagePath == '-') {
      // ストレージパスが未設定なら、その店舗のデータレイクは未稼働として割り当てない。
      continue
    }
    validDevices.push(device)
    // デバイス情報から所属情報と店舗名を取得する
    const areaName = device.category
    const tenantName = device.location
    // const areaName = '愛知県'
    // const tenantName = '名古屋店'

    const result = {
      before: {},
      current: {},
      after: {},
    }

    // 集計データ一の取得
    const res = await CloudStorageRepository.fetchDataFromStorage(userId, {
      storagePath: storagePath,
      begin: begin,
      end: end,
      type: type,
      // TODO: 以下、S3 にあるデータに合わせてデバッグする
      // begin: '2023/04/01',
      // end: '2023/04/30',
      // type: 'Daily',
      areaName: areaName,
      tenantName: tenantName,
      gender: '',
      ageGroup: '',
    })
    // mock を外す
    // const res_ = await MockCloudStorageRepository.fetchDataFromStorage(userId, {
    //   storagePath,
    //   begin,
    //   end,
    //   type,
    // })
    // console.log(res)

    res.data.forEach((item, index) => {
      const dat = {
        labels: item.indices,
        datasets: [],
        malesData: [[], [], [], [], []],
        femalesData: [[], [], [], [], []],
      }
      item.datasets.forEach((record) => {
        dat.datasets.push(record)

        setAttributeArray(dat, record)
      })

      if (index == 0) {
        result.before = dat
      } else if (index == 1) {
        // console.log(dat)
        result.current = dat
      } else if (index == 2) {
        result.after = dat
      }
    })

    results.push(result)
  }

  let multiSummary = []

  results.forEach((tenant, tenant_idx) => {
    let tenantSummary = {}
    // 店舗毎のループ
    tenant.current.datasets.forEach((record, index) => {
      // レコード毎のループ

      // 店舗間比較のテーブルデータ
      if (index == 0) {
        tenantSummary = Object.assign({}, record)
      } else {
        PeopleCount.addPeopleCount(tenantSummary, record)
      }
    })

    multiSummary.push(tenantSummary)
  })

  return {
    results: results,
    multiSummary: multiSummary,
    error: null,
  }
}

// 店舗計測のユースケース
const getPeopleCountDataByTenant = async (input) => {
  const contractId = input.contractId
  const userId = input.identityId
  const begin = input.begin
  const end = input.end
  const areaName = input.area
  const tenantName = input.tenant
  const type = input.type

  // デバイス情報の一覧を取得する（storagePath）
  const resdev = await DeviceRepository.fetchDevicesList(contractId)
  // const resdev = await MockDeviceRepository.fetchDevicesList(contractId)
  // console.log(resdev.data[0])

  let storagePath = ''
  for (const device of resdev.data) {
    // 明示的な地域と店舗名の設定項目を追加する
    if (tenantName == device.location) {
      storagePath = device.storagePath
      break
    }
  }
  // console.log(storagePath)

  const result = {
    before: {},
    current: {},
    after: {},
  }

  // 集計データ一の取得
  const res = await CloudStorageRepository.fetchDataFromStorage(userId, {
    storagePath: storagePath,
    begin: begin,
    end: end,
    type: type,
    // TODO: 以下、S3 にあるデータに合わせてデバッグする
    // begin: '2023/04/10',
    // end: '2023/04/16',
    // type: 'Daily',
    areaName: areaName,
    tenantName: tenantName,
    gender: '',
    ageGroup: '',
  })
  // TODO: mock を外す
  // const res_ = await MockCloudStorageRepository.fetchDataFromStorage(userId, {
  //   storagePath,
  //   begin,
  //   end,
  //   type,
  // })
  // console.log(res)

  res.data.forEach((item, index) => {
    const dat = {
      labels: item.indices,
      datasets: [],
      malesData: [[], [], [], [], []],
      femalesData: [[], [], [], [], []],
    }
    item.datasets.forEach((record) => {
      dat.datasets.push(record)

      setAttributeArray(dat, record)
    })

    if (index == 0) {
      result.before = dat
    } else if (index == 1) {
      result.current = dat
    } else if (index == 2) {
      result.after = dat
    }
  })

  return {
    result: result,
    error: null,
  }
}

// 時間別のユースケース
const getPeopleCountHourlyData = async (input) => {
  const contractId = input.contractId
  const userId = input.identityId
  const begin = input.begin
  const end = input.end
  const areaName = input.area
  const tenantName = input.tenant
  const type = input.type
  const gender = input.gender
  const ageGroup = input.ageGroup
  // console.log(gender, ageGroup)

  // デバイス情報の一覧を取得する（storagePath）
  const resdev = await DeviceRepository.fetchDevicesList(contractId)
  // const resdev = await MockDeviceRepository.fetchDevicesList(contractId)
  // console.log(resdev.data[0])

  let storagePath = ''
  for (const data of resdev.data) {
    // デバイス情報から店舗名を比較する
    if (tenantName == data.location) {
      storagePath = data.storagePath
      break
    }
  }
  // console.log(storagePath)

  const result = {
    before: {},
    current: {},
    after: {},
  }

  // 集計データ一の取得
  const res = await CloudStorageRepository.fetchDataFromStorage(userId, {
    storagePath: storagePath,
    begin: begin,
    end: end,
    type: type,
    // TODO: 以下、S3 にあるデータに合わせてデバッグする
    // begin: '2023/04/10',
    // end: '2023/04/16',
    // type: 'Hourly',
    areaName: areaName,
    tenantName: tenantName,
    gender: gender,
    ageGroup: ageGroup,
  })
  // TODO: mock を外す
  // const res_ = await MockCloudStorageRepository.fetchDataFromStorage(userId, {
  //   storagePath,
  //   begin,
  //   end,
  //   type: type,
  //   // type: 'Hourly',
  // })
  // console.log(res)

  res.data.forEach((item, index) => {
    const dat = {
      labels: item.indices,
      datasets: [],
      malesData: [[], [], [], [], []],
      femalesData: [[], [], [], [], []],
    }
    item.datasets.forEach((record) => {
      dat.datasets.push(record)

      setAttributeArray(dat, record)
    })

    if (index == 0) {
      result.before = dat
    } else if (index == 1) {
      result.current = dat
    } else if (index == 2) {
      result.after = dat
    }
  })

  return {
    result: result,
    error: null,
  }
}

function setAttributeArray(dat, record) {
  dat.malesData[0].push(record.maleAge10)
  dat.malesData[1].push(record.maleAge20)
  dat.malesData[2].push(record.maleAge30)
  dat.malesData[3].push(record.maleAge40)
  dat.malesData[4].push(record.maleAge50)
  dat.femalesData[0].push(record.femaleAge10)
  dat.femalesData[1].push(record.femaleAge20)
  dat.femalesData[2].push(record.femaleAge30)
  dat.femalesData[3].push(record.femaleAge40)
  dat.femalesData[4].push(record.femaleAge50)
}

export default {
  getPeopleCountDataByTotal,
  getPeopleCountDataByComparison,
  getPeopleCountDataByTenant,
  getPeopleCountHourlyData,
}
