import SystemToolViewModel from '@/adapters/SystemToolViewModel'

import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'SystemToolPresenter'

const convertNewDbItemToStore = (resultData) => {
  const TAG = `${MODULE_TAG}.${convertNewDbItemToStore.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(resultData)

  const option = SystemToolViewModel.newAttrDeviceItem(
    resultData.id,
    resultData.host,
    resultData.count_line.id,
    resultData.count_line.tof_id,
    resultData.count_line.camera_id,
  )
  logger.verbose(Logger.formatter(TAG, '--- new SystemTool ---'))
  logger.verbose(option)

  return option
}

const convertDbListToStore = (arch, devices) => {
  const TAG = `${MODULE_TAG}.${convertDbListToStore.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(arch)
  logger.verbose(devices)

  const items = SystemToolViewModel.systemToolItem(arch, devices)
  return items
}

const convertJobListToStore = (jobs) => {
  const TAG = `${MODULE_TAG}.${convertJobListToStore.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const items = []
  jobs.forEach((data) => {
    // console.log(data)
    const id = data.ExecCount
    const date = data.ExecTime
    const status = 'success'
    const item = SystemToolViewModel.newJobItem(id, date, status)
    items.push(item)
  })

  return { reCalcJobList: items }
}

const convertExecResultToMessage = (jobs) => {
  let message = ''
  if (jobs.length < 2) {
    message = '再計算処理番号は ' + jobs[0].id + ' 番です'
  } else {
    message =
      '再計算処理番号は ' +
      jobs[0].id +
      ' 番から ' +
      jobs[jobs.length - 1].id +
      ' 番です'
  }
  return message
}

const convertDownloadResultToMessage = (status) => {
  let message = ''
  if (status == 'success') {
    message = 'ソフトウェアのダウンロードを開始しました'
  } else if (status == 'cancel') {
    message = 'ダウンロード処理中です'
  } else if (status == 'error') {
    message = 'ダウンロード処理の開始に失敗しました'
  } else {
    message = '不明なエラーです'
  }
  return message
}

const imageNameMap = {
  'local-web-ui-nginx-alpine': '施設用 Web アプリ',
  'job-people-count-python-38': '店舗分析データ集計ライブラリ',
  'apache-airflow2-standard': '店舗分析データ集計ジョブ管理サーバー',
  'bionic-cvengine-library': '人物属性推定エンジンライブラリ',
  'ros-melodic-attr-estimator-standard': '人物属性推定サーバー',
  'ros-melodic-data-logger-standard': '人物属性データロガー',
  'ros-noetic-master-standard': '計測ネットワーク ネームサーバー',
  'ros-rtsp-stream-standard': 'ネットワークカメラ映像受信サーバー',
  'ros-melodic-web-standard': 'ネットワークカメラ映像配信サーバー',
  'ros-noetic-cmd-standard': '計測ネットワーク コマンドサーバー',
  'elk-filebeat7-standard': '計測データ収集サーバー',
  'elk-logstash7-standard': '計測データ登録サーバー',
  'elk-elasticsearch7-standard': 'データレイク',
  'local-web-api-python-39': 'Web API サーバー',
  'local-web-ci-jenkins-lts-jdk11': 'ユーザーイベントジョブ管理サーバー',
}

const skipNameMap = {
  'ros-melodic-desktop': '計測ネットワーク デスクトップサーバー',
  'elk-kibana7-standard': 'データレイク ダッシュボード',
  'jupyter-datascience-python-38': '',
  'ros-tcp-socket-standard': '',
  'attr-judge-server': '',
  'elk-elasticsearch-v7tof': '',
  'elk-logstash-v7tof': '',
}

const convertImageVersionListToStore = (images, josStatus) => {
  const TAG = `${MODULE_TAG}.${convertImageVersionListToStore.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const items = []
  let index = 0
  for (var key in imageNameMap) {
    index += 1
    for (var data of images) {
      if (data.name in skipNameMap == false) {
        if (data.name == key) {
          const id = index
          // const name = imageNameMap[data.name]
          const name = data.name_jp
          const installed = data.installed
          const version = data.version
          const latest = data.latest
          const date = data.date
          const size = data.size
          const hash = data.hash
          const item = SystemToolViewModel.newVersionItem(
            id,
            name,
            installed,
            version,
            latest,
            date,
            size,
            hash,
          )
          items.push(item)
          break
        }
      }
    }
  }

  const unknown = []
  for (let image of images) {
    if (
      image.name in skipNameMap == false &&
      image.name in imageNameMap == false &&
      -1 == unknown.indexOf(image.name)
    ) {
      index += 1
      const id = index
      const name = image.name
      const installed = image.installed
      const version = image.version
      const latest = image.latest
      const date = image.date
      const size = image.size
      const hash = image.hash
      const item = SystemToolViewModel.newVersionItem(
        id,
        name,
        installed,
        version,
        latest,
        date,
        size,
        hash,
      )
      items.push(item)
      unknown.push(name)
    }
  }

  const status = josStatus

  return {
    images: items,
    job: {
      status: status,
    },
  }
}

export default {
  convertNewDbItemToStore,
  convertDbListToStore,
  convertJobListToStore,
  convertExecResultToMessage,
  convertImageVersionListToStore,
  convertDownloadResultToMessage,
}
