import DeviceViewModel from '@/adapters/DeviceViewModel'

import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'DevicePresenter'

const convertNewDbDataToStore = (inputData) => {
  const TAG = `${MODULE_TAG}.${convertNewDbDataToStore.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(inputData)

  // const date = new Date(inputData.idaasUser.registered)
  // const registered = DateUtil.formatDate(date, 'YYYY-MM-DD')
  const device = DeviceViewModel.newDeviceItemWithProfile(inputData)
  logger.verbose(Logger.formatter(TAG, '--- new Device ---'))
  logger.verbose(device)

  return device
}

const convertDbDataToStore = (fetchData) => {
  const TAG = `${MODULE_TAG}.${convertDbDataToStore.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(fetchData)

  let items = []

  fetchData.forEach((data) => {
    // console.log(data)
    let item = DeviceViewModel.deviceItem(data)
    items.push(item)
  })

  return items
}

export default {
  convertNewDbDataToStore,
  convertDbDataToStore,
}
