const newOptionItemWithProfile = (id, key, value, name, description) => {
  return {
    id: id,
    key: key,
    name: name,
    limit: value,
    description: description,
    usage: {
      count: 0,
      rate: 0,
      period: '',
    },
    status: true,
    activity: '',
  }
}

const optionItem = (inputData) => {
  let item = {
    id: inputData.id,
    key: inputData.key,
    name: inputData.name,
    limit: inputData.value,
    description: inputData.description,
    usage: {
      count: 0,
      rate: 0,
      period: '',
    },
    status: true,
    activity: '',
  }

  return item
}

export default {
  newOptionItemWithProfile,
  optionItem,
}
