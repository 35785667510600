import DownloadDataViewModel from '@/adapters/DownloadDataViewModel'

import DateUtil from '@/utils/DateUtil'

import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'DownloadDataPresenter'

const convertNewDbDataToStore = (resultData) => {
  const TAG = `${MODULE_TAG}.${convertNewDbDataToStore.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(resultData)

  let items = []

  resultData.forEach((fileName) => {
    let type = 'Hourly'
    if (fileName.match(/Monthly/)) {
      type = 'Monthly'
    } else if (fileName.match(/Weekly/)) {
      type = 'Weekly'
    } else if (fileName.match(/Daily/)) {
      type = 'Daily'
    }
    const prefix = 'Pms' + type
    const dateStr = fileName.replace(prefix, '')
    const start =
      dateStr.substr(0, 4) +
      '/' +
      dateStr.substr(4, 2) +
      '/' +
      dateStr.substr(6, 2)
    const end = DateUtil.addDateString(start, 7)

    const item = DownloadDataViewModel.newDownloadDataItem(
      start,
      end,
      type,
      resultData.fileName,
    )
    items.push(item)
  })

  logger.verbose(Logger.formatter(TAG, `--- end ---`))
  logger.verbose(items)
  return items
}

const convertDbDataToStore = (fetchData) => {
  const TAG = `${MODULE_TAG}.${convertDbDataToStore.name}`
  logger.verbose(Logger.formatter(TAG, `--- begin ---`))
  logger.verbose(fetchData)

  let items = []

  fetchData.forEach((fileName) => {
    // console.log(fileName)
    let type = 'Hourly'
    if (fileName.match(/Monthly/)) {
      type = 'Monthly'
    } else if (fileName.match(/Weekly/)) {
      type = 'Weekly'
    } else if (fileName.match(/Daily/)) {
      type = 'Daily'
    }
    const prefix = 'Pms' + type
    const dateStr = fileName.replace(prefix, '')
    const start =
      dateStr.substr(0, 4) +
      '/' +
      dateStr.substr(4, 2) +
      '/' +
      dateStr.substr(6, 2)
    // console.log(start)
    const data = {
      start: start,
      end: DateUtil.addDateString(start, 7),
      type: type,
      fineName: fileName,
    }

    let item = DownloadDataViewModel.downloadDataItem(data)
    // console.log(item)
    items.push(item)
  })

  logger.verbose(items)
  logger.verbose(Logger.formatter(TAG, `--- end ---`))
  return items
}

export default {
  convertNewDbDataToStore,
  convertDbDataToStore,
}
