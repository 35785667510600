import PeopleCountInteractor from '@/domain/usecases/PeopleCountInteractor'

import CountingPresenter from '@/adapters/CountingPresenter'
import TenantPresenter from '@/adapters/TenantPresenter'

import StoreRepository from '@/adapters/StoreRepository'

import sampledata from '@/adapters/samplecountingdata'

import DateUtil from '@/utils/DateUtil'
import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'CountingController'

const fetchCountingAllData = async (context, { start, end, type }) => {
  const TAG = `${MODULE_TAG}.${fetchCountingAllData.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const cwlogger = await Logger.getInstance(context.getters.getCurrentUsername)
  cwlogger?.verbose(`begin ${TAG}`)

  const contractId = context.state.contract.id
  const identityId = context.state.user.attributes.sub

  const input = {
    contractId: contractId,
    identityId: identityId,
    begin: start,
    end: end,
    type: type,
  }
  // logger.verbose('contractId: ' + input.contractId)
  // logger.verbose('identityId: ' + input.identityId)
  logger.verbose('begin: ' + input.begin)
  logger.verbose('end: ' + input.end)
  logger.verbose('type: ' + input.type)

  // ユースケース｜ダッシュボード
  const res = await PeopleCountInteractor.getPeopleCountDataByTotal(input)

  // TODO: ユースケース｜来客数、来客属性

  // TODO: ユースケース｜動線計測

  // TODO: ユースケース｜店舗間比較

  // TODO: ユースケース｜店舗施策

  // let countingDailyDataItem = []
  // let countingWeeklyDataItem = []
  // let countingMonthlyDataItem = []

  // const homeSummaryData = sampledata.homeSummaryItems
  let homeSummaryData = []
  let currentTableData = []
  let deforeTableData = []
  let ownTenantData = {
    labels: [],
    datasets: [],
  }
  let userItem = null

  // 自店舗に設置されたデータレイクの情報
  const deviceItem = res.ownTenant.device
  context.commit('setTenantDatabaseItem', deviceItem)

  const tenantItem = TenantPresenter.convertNewDbDataToStore(res.ownTenant.info)
  // console.log(tenantItem)
  // 店舗基本情報（開店時間、閉店時間）をストアに保存する
  context.commit('setTenantInfoItem', tenantItem)

  res.results.forEach((tenant) => {
    const currentData = tenant.current
    logger.verbose('currentData: ')
    logger.verbose(currentData)
    // console.log(currentData)
    const newCurrentItem = CountingPresenter.convertDbToStore(currentData)
    // console.log(newCurrentItem)
    homeSummaryData.push(newCurrentItem)

    // 自店舗データの抽出
    if (
      0 < newCurrentItem.datasets.length &&
      newCurrentItem.datasets[0].tenant == res.ownTenant.name
    ) {
      ownTenantData = newCurrentItem
      userItem = res.user
    }

    const beforeData = tenant.before
    logger.verbose('beforeData: ')
    logger.verbose(beforeData)
    const newBeforeItem = CountingPresenter.convertDbToStore(beforeData)

    currentTableData.push(newCurrentItem)
    deforeTableData.push(newBeforeItem)

    // if (input.type == 'Daily') {
    //   countingDailyDataItem = countingDailyDataItem.concat(newCurrentItem)
    // } else if (input.type == 'Weekly') {
    //   countingWeeklyDataItem = countingWeeklyDataItem.concat(newCurrentItem)
    // } else if (input.type == 'Monthly') {
    //   countingMonthlyDataItem = countingMonthlyDataItem.concat(newCurrentItem)
    // }
  })

  logger?.verbose(`-- homeSummaryData ${TAG} --`)
  logger?.verbose(homeSummaryData)
  // logger?.verbose(countingDailyDataItem)

  ///// ストアの更新 /////

  // ログインユーザー情報
  const appUser = userItem
  StoreRepository.addAppUser(context, { appUser })

  // 店舗間比較
  const multiTenantDataItems =
    CountingPresenter.convertDbToMultiTenantStore(currentTableData)
  // const multiTenantDataItems = sampledata.multiTenantCountingItems
  StoreRepository.updateMultiTenantCountingItems(context, {
    contractId: contractId,
    multiTenantDataItems: multiTenantDataItems,
  })

  // 店舗間比較｜サマリー
  const multiTenantTableData =
    CountingPresenter.convertDbToMultiTenantTableStore(res.multiSummary)
  // const multiTenantTableData = res.multiSummary
  // const multiTenantTableData = sampledata.multiTenantSummaryItems
  StoreRepository.updateMultiTenantSummaryItems(context, {
    contractId: contractId,
    multiTenantTableData: multiTenantTableData,
  })

  // 店舗計測｜来客数
  const tenantCountingData =
    CountingPresenter.convertDbToOwnTenantStore(ownTenantData)
  // console.log(tenantCountingData)
  // const tenantCountingData = sampledata.tenantSummaryItems
  // const tenantCountingData = sampledata.tenantCountingWeeklyItems
  StoreRepository.updateTenantCountingItems(context, {
    contractId,
    tenantCountingData,
  })

  // 店舗計測｜来客属性
  const tenantSummaryData = tenantCountingData
  // const tenantSummaryData = sampledata.tenantSummaryItems
  StoreRepository.updateTenantSummaryItems(context, {
    contractId: contractId,
    tenantSummaryData: tenantSummaryData,
  })

  // 動線計測
  // const spotDirectionData = sampledata.spotDirectionItems
  // StoreRepository.updateSpotDirectionItems(context, {
  //   contractId,
  //   spotDirectionData,
  // })

  // 動線計測｜方向別
  // const spotDirectionSummaryData = sampledata.spotDirectionSummaryItems
  // StoreRepository.updateSpotDirectionSummaryItems(context, {
  //   contractId,
  //   spotDirectionSummaryData,
  // })

  // 店舗データ 一覧
  // 選択期間のラベルと比較データ
  const summaryTableItems = CountingPresenter.convertDbToTableStore({
    summaryData: currentTableData,
    before: deforeTableData,
    type: input.type,
    response: res,
  })
  context.commit('setHomeDatesLabelItem', summaryTableItems.label)
  context.commit('setHomeTenantTableItems', summaryTableItems.datasets)
  context.commit('setHomeStartDateItem', summaryTableItems.start)
  context.commit('setHomeEndDateItem', summaryTableItems.end)
  StoreRepository.updateHomeSummaryItems(context, {
    contractId: contractId,
    summaryData: homeSummaryData,
  })

  // const summaryGraphItems = CountingPresenter.convertDbToAllTenantStore(res)
  // 来客数｜全店舗総計
  const countingData = summaryTableItems.countingData
  // const countingData = summaryGraphItems.summary
  StoreRepository.updateHomeCountingItems(context, { contractId, countingData })

  // 来客属性｜全店舗総計
  const genderAgeData = summaryTableItems.attributes
  // const genderAgeData = summaryGraphItems.genderAge
  StoreRepository.updateHomeGenderAgeItems(context, {
    contractId,
    genderAgeData,
  })

  // 来客属性
  context.commit('setTenantAttributeDate', {
    start: summaryTableItems.start,
    end: summaryTableItems.end,
    type: type == 'Monthly' ? type : 'Daily',
  })

  return
}

// ユースケース｜店舗間比較
const fetchCountingDataComparison = async (context, { start, end, type }) => {
  const TAG = `${MODULE_TAG}.${fetchCountingDataComparison.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const cwlogger = await Logger.getInstance(context.getters.getCurrentUsername)
  cwlogger?.verbose(`begin ${TAG}`)

  const contractId = context.state.contract.id
  const identityId = context.state.user.attributes.sub

  // ユーザープロファイルから所属情報を取得する
  const areaName = context.state.userItems[0].department
  const tenantName = context.state.userItems[0].tenant
  // const tenantName = '愛知県 / 名古屋店'

  const input = {
    contractId: contractId,
    identityId: identityId,
    begin: start,
    end: end,
    area: areaName,
    tenant: tenantName,
    type: type,
  }
  logger.verbose('begin: ' + input.begin)
  logger.verbose('end: ' + input.end)
  logger.verbose('type: ' + input.type)

  // ユースケース｜店舗間比較
  const res = await PeopleCountInteractor.getPeopleCountDataByComparison(input)

  // let countingDailyDataItem = []
  // let countingWeeklyDataItem = []
  // let countingMonthlyDataItem = []

  let summaryData = []
  let currentTableData = []
  let deforeTableData = []

  res.results.forEach((tenant) => {
    const currentData = tenant.current
    logger.verbose('currentData: ')
    logger.verbose(currentData)
    const newCurrentItem = CountingPresenter.convertDbToStore(currentData)
    // console.log(newCurrentItem)
    summaryData.push(newCurrentItem)

    const beforeData = tenant.before
    logger.verbose('beforeData: ')
    logger.verbose(beforeData)
    const newBeforeItem = CountingPresenter.convertDbToStore(beforeData)

    currentTableData.push(newCurrentItem)
    deforeTableData.push(newBeforeItem)

    // if (input.type == 'Daily') {
    //   countingDailyDataItem = countingDailyDataItem.concat(newCurrentItem)
    // } else if (input.type == 'Weekly') {
    //   countingWeeklyDataItem = countingWeeklyDataItem.concat(newCurrentItem)
    // } else if (input.type == 'Monthly') {
    //   countingMonthlyDataItem = countingMonthlyDataItem.concat(newCurrentItem)
    // }
  })

  logger?.debug(`-- summaryData ${TAG} --`)
  logger?.debug(summaryData)

  ///// ストアの更新 /////

  // 店舗間比較
  const multiTenantDataItems =
    CountingPresenter.convertDbToMultiTenantStore(currentTableData)
  StoreRepository.updateMultiTenantCountingItems(context, {
    contractId: contractId,
    multiTenantDataItems: multiTenantDataItems,
  })

  // 店舗間比較｜サマリー
  const multiTenantTableData =
    CountingPresenter.convertDbToMultiTenantTableStore(res.multiSummary)
  StoreRepository.updateMultiTenantSummaryItems(context, {
    contractId: contractId,
    multiTenantTableData: multiTenantTableData,
  })

  // 店舗データ 一覧
  // 選択期間のラベルと比較データ
  const summaryTableItems = CountingPresenter.convertDbToTableStore({
    summaryData: currentTableData,
    before: deforeTableData,
    type: input.type,
    response: res,
  })
  context.commit('setHomeDatesLabelItem', summaryTableItems.label)
  context.commit('setHomeTenantTableItems', summaryTableItems.datasets)
  context.commit('setHomeStartDateItem', summaryTableItems.start)
  context.commit('setHomeEndDateItem', summaryTableItems.end)
  StoreRepository.updateHomeSummaryItems(context, {
    contractId: contractId,
    summaryData: summaryData,
  })

  // 来客属性
  context.commit('setTenantAttributeDate', {
    start: summaryTableItems.start,
    end: summaryTableItems.end,
    type: type == 'Monthly' ? type : 'Daily',
  })

  return
}

// 店舗計測｜計測データ
const fetchMeasurementDataList = async (context, { start, end, type }) => {
  const TAG = `${MODULE_TAG}.${fetchMeasurementDataList.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const cwlogger = await Logger.getInstance(context.getters.getCurrentUsername)
  cwlogger?.verbose(`begin ${TAG}`)

  const contractId = context.state.contract.id
  const identityId = context.state.user.attributes.sub

  // ユーザープロファイルから所属情報を取得する
  const areaName = context.state.userItems[0].department
  const tenantName = context.state.userItems[0].tenant
  // const tenantName = '愛知県 / 名古屋店'

  const input = {
    contractId: contractId,
    identityId: identityId,
    begin: start,
    end: end,
    area: areaName,
    tenant: tenantName,
    type: type,
  }
  // logger.verbose('contractId: ' + input.contractId)
  // logger.verbose('identityId: ' + input.identityId)
  logger.verbose('begin: ' + input.begin)
  logger.verbose('end: ' + input.end)
  logger.verbose('type: ' + input.type)

  // ユースケース｜来客数、来客属性
  const res = await PeopleCountInteractor.getPeopleCountDataByTenant(input)
  // console.log(res)

  // const homeSummaryData = sampledata.homeSummaryItems
  let currentTableData = []
  // let ownTenantData = {
  //   labels: [],
  //   datasets: [],
  //   malesData: [],
  //   femalesData: [],
  // }
  const tenant = res.result

  const currentData = tenant.current
  logger.verbose('currentData: ')
  logger.verbose(currentData)
  const newCurrentItem = CountingPresenter.convertDbToStore(currentData)
  // const newCurrentItem = MeasurementPresenter.convertDbToStore(currentData)
  // console.log(newCurrentItem)
  const ownTenantData = newCurrentItem

  currentTableData.push(newCurrentItem)

  logger?.verbose(`-- ownTenantData ${TAG} --`)
  logger?.verbose(ownTenantData)

  const tenantCountingData =
    CountingPresenter.convertDbToOwnTenantStore(ownTenantData)
  // MeasurementPresenter.convertDbToOwnTenantStore(ownTenantData)
  ///// ストアの更新 /////

  // 店舗計測｜来客数
  StoreRepository.updateTenantCountingItems(context, {
    contractId,
    tenantCountingData,
  })
  // StoreRepository.updateTenantMeasurementItems(context, {
  //   contractId,
  //   tenantMeasurementData,
  // })

  // 店舗計測｜来客属性
  const tenantSummaryData = tenantCountingData
  StoreRepository.updateTenantSummaryItems(context, {
    contractId: contractId,
    tenantSummaryData: tenantSummaryData,
  })

  // 来客属性
  context.commit('setTenantAttributeDate', {
    start: tenantSummaryData.start,
    end: tenantSummaryData.end,
    type: type == 'Monthly' ? type : 'Daily',
  })

  return
}

// 店舗計測｜来客数、来客属性
const fetchCountingDataList = async (context, { start, end, type }) => {
  const TAG = `${MODULE_TAG}.${fetchCountingDataList.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const cwlogger = await Logger.getInstance(context.getters.getCurrentUsername)
  cwlogger?.verbose(`begin ${TAG}`)

  const contractId = context.state.contract.id
  const identityId = context.state.user.attributes.sub

  // ユーザープロファイルから所属情報を取得する
  const areaName = context.state.userItems[0].department
  const tenantName = context.state.userItems[0].tenant
  // const tenantName = '愛知県 / 名古屋店'

  const input = {
    contractId: contractId,
    identityId: identityId,
    begin: start,
    end: end,
    area: areaName,
    tenant: tenantName,
    type: type,
  }
  // logger.verbose('contractId: ' + input.contractId)
  // logger.verbose('identityId: ' + input.identityId)
  logger.verbose('begin: ' + input.begin)
  logger.verbose('end: ' + input.end)
  logger.verbose('type: ' + input.type)

  // ユースケース｜来客数、来客属性
  const res = await PeopleCountInteractor.getPeopleCountDataByTenant(input)
  // console.log(res)

  // let countingDailyDataItem = []
  // let countingWeeklyDataItem = []
  // let countingMonthlyDataItem = []

  // const homeSummaryData = sampledata.homeSummaryItems
  let currentTableData = []
  let deforeTableData = []
  // let ownTenantData = {
  //   labels: [],
  //   datasets: [],
  //   malesData: [],
  //   femalesData: [],
  // }
  const tenant = res.result

  const currentData = tenant.current
  logger.verbose('currentData: ')
  logger.verbose(currentData)
  const newCurrentItem = CountingPresenter.convertDbToStore(currentData)
  // console.log(newCurrentItem)
  const ownTenantData = newCurrentItem

  const beforeData = tenant.before
  logger.verbose('beforeData: ')
  logger.verbose(beforeData)
  const newBeforeItem = CountingPresenter.convertDbToStore(beforeData)

  currentTableData.push(newCurrentItem)
  deforeTableData.push(newBeforeItem)

  // if (input.type == 'Daily') {
  //   countingDailyDataItem = countingDailyDataItem.concat(newCurrentItem)
  // } else if (input.type == 'Weekly') {
  //   countingWeeklyDataItem = countingWeeklyDataItem.concat(newCurrentItem)
  // } else if (input.type == 'Monthly') {
  //   countingMonthlyDataItem = countingMonthlyDataItem.concat(newCurrentItem)
  // }

  logger?.verbose(`-- ownTenantData ${TAG} --`)
  logger?.verbose(ownTenantData)
  // logger?.verbose(countingDailyDataItem)

  ///// ストアの更新 /////

  // 店舗間比較
  // const multiTenantDataItems =
  //   CountingPresenter.convertDbToMultiTenantStore(currentTableData)
  // StoreRepository.updateMultiTenantCountingItems(context, {
  //   contractId: contractId,
  //   multiTenantDataItems: multiTenantDataItems,
  // })

  // 店舗間比較｜サマリー
  // const multiTenantTableData =
  //   CountingPresenter.convertDbToMultiTenantTableStore(res.multiSummary)
  // StoreRepository.updateMultiTenantSummaryItems(context, {
  //   contractId: contractId,
  //   multiTenantTableData: multiTenantTableData,
  // })

  // 店舗計測｜来客数
  const tenantCountingData =
    CountingPresenter.convertDbToOwnTenantStore(ownTenantData)
  // const tenantCountingData = sampledata.tenantSummaryItems
  // const tenantCountingData = sampledata.tenantCountingWeeklyItems
  StoreRepository.updateTenantCountingItems(context, {
    contractId,
    tenantCountingData,
  })

  // 店舗計測｜来客属性
  const tenantSummaryData = tenantCountingData
  // const tenantSummaryData = sampledata.tenantSummaryItems
  StoreRepository.updateTenantSummaryItems(context, {
    contractId: contractId,
    tenantSummaryData: tenantSummaryData,
  })

  // 店舗データ 一覧
  // 選択期間のラベルと比較データ
  const summaryTableItems = CountingPresenter.convertDbToTableStore({
    summaryData: currentTableData,
    before: deforeTableData,
    type: input.type,
    response: tenantSummaryData,
  })
  context.commit('setHomeDatesLabelItem', summaryTableItems.label)
  context.commit('setHomeTenantTableItems', summaryTableItems.datasets)
  context.commit('setHomeStartDateItem', summaryTableItems.start)
  context.commit('setHomeEndDateItem', summaryTableItems.end)
  StoreRepository.updateHomeSummaryItems(context, {
    contractId: contractId,
    summaryData: ownTenantData,
  })

  // 来客属性
  context.commit('setTenantAttributeDate', {
    start: summaryTableItems.start,
    end: summaryTableItems.end,
    type: type == 'Monthly' ? type : 'Daily',
  })

  return
}

// 店舗計測の月次集計は Daily のみで集計して Hourly は無視（非表示に）する
const fetchCountingDailyDataList = async (context, { start, end }) => {
  const TAG = `${MODULE_TAG}.${fetchCountingDailyDataList.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const cwlogger = await Logger.getInstance(context.getters.getCurrentUsername)
  cwlogger?.verbose(`begin ${TAG}`)

  const contractId = context.state.contract.id
  const identityId = context.state.user.attributes.sub

  // console.log(context.state.userItems)
  // ユーザープロファイルから所属情報を取得する
  const areaName = context.state.userItems[0].department
  const tenantName = context.state.userItems[0].tenant
  // const tenantName = '愛知県 / 名古屋店'

  const input = {
    contractId: contractId,
    identityId: identityId,
    begin: start,
    end: end,
    area: areaName,
    tenant: tenantName,
    type: 'Daily',
  }
  logger.verbose('input:')
  logger.verbose(input)

  // ユースケース｜[休日と平日の来店客人数／日] [曜日別の来店客人数／日] のデータ集計
  const res = await PeopleCountInteractor.getPeopleCountDataByTenant(input)
  // const res = await PeopleCountInteractor.getPeopleCountHourlyData(input)

  let currentTableData = []
  let dailyData = {
    labels: [],
    datasets: [],
    malesData: [],
    femalesData: [],
  }

  const tenant = res.result
  const currentData = tenant.current
  logger.verbose('currentData: ')
  logger.verbose(currentData)
  const newCurrentItem = CountingPresenter.convertDbToStore(currentData)
  // console.log('~~~~~~~~~~~~~~~~~~~')
  // console.log(newCurrentItem)

  dailyData = newCurrentItem

  currentTableData.push(newCurrentItem)

  logger?.verbose(`-- dailyData ${TAG} --`)
  logger?.verbose(dailyData)
  // logger?.verbose(countingDailyDataItem)

  const tenantCountingDaysData =
    CountingPresenter.convertDbToOwnTenantDaysStore(dailyData)

  const tenantSummaryDaysData = newCurrentItem

  ///// ストアの更新 /////
  // 店舗計測｜来客数｜時間別
  StoreRepository.updateTenantCountingDaysItems(context, {
    contractId,
    tenantCountingDaysData,
  })
  // 店舗計測｜来客属性｜時間別
  StoreRepository.updateTenantTargetSummaryDaysItems(context, {
    contractId,
    tenantSummaryDaysData,
  })

  return
}

// 狙いを定めた客層の傾向
const makeTargetSummary = async (context, { ageId, genderId, type }) => {
  const TAG = `${MODULE_TAG}.${makeTargetSummary.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const cwlogger = await Logger.getInstance(context.getters.getCurrentUsername)
  cwlogger?.verbose(`begin ${TAG}`)

  let items = null
  if (type == 'Daily') {
    items = context.state.tenantSummaryItems
  } else {
    // Monthly
    items = context.state.tenantTargetSummaryDaysItems
  }

  const targetAgeIndex = ageId

  const targetItems = {
    genderLabel: '',
    ageGroupLabel: '',
    holiday: [0, 0], // [weekday, holiday]
    dayOfWeek: [0, 0, 0, 0, 0, 0, 0],
  }
  const holidayCounts = [0, 0]
  const dayOfWeekCounts = [0, 0, 0, 0, 0, 0, 0]

  let targetData = []

  if (genderId == 0) {
    targetData = items.malesData[targetAgeIndex]
  } else {
    targetData = items.femalesData[targetAgeIndex]
  }

  // console.log(targetData)
  // console.log(type)
  let weekday = 0
  let holiday = 0
  items.labels.forEach((dateString, index) => {
    let sumByDayOfWeek = targetData[index] // 日毎（曜日毎）の合計

    const flag = DateUtil.isHoliday(dateString)
    // console.log(dateString)
    if (flag) {
      holiday += sumByDayOfWeek
      holidayCounts[1] += 1
      // targetData.forEach((value) => {
      //   holiday += value
      //   holidayCounts[1] += 1
      // })
      // console.log('holiday', holiday)
    } else {
      weekday += sumByDayOfWeek
      holidayCounts[0] += 1
      // targetData.forEach((value) => {
      //   weekday += value
      //   holidayCounts[0] += 1
      // })
      // console.log('weekday', weekday)
    }

    // 曜日毎に仕分け
    const weeklyNumber = DateUtil.weeklyNumberFromString(dateString)
    switch (weeklyNumber) {
      case 1:
        targetItems.dayOfWeek[0] += sumByDayOfWeek
        dayOfWeekCounts[0] += 1
        break
      case 2:
        targetItems.dayOfWeek[1] += sumByDayOfWeek
        dayOfWeekCounts[1] += 1
        break
      case 3:
        targetItems.dayOfWeek[2] += sumByDayOfWeek
        dayOfWeekCounts[2] += 1
        break
      case 4:
        targetItems.dayOfWeek[3] += sumByDayOfWeek
        dayOfWeekCounts[3] += 1
        break
      case 5:
        targetItems.dayOfWeek[4] += sumByDayOfWeek
        dayOfWeekCounts[4] += 1
        break
      case 6:
        targetItems.dayOfWeek[5] += sumByDayOfWeek
        dayOfWeekCounts[5] += 1
        break
      case 0:
        targetItems.dayOfWeek[6] += sumByDayOfWeek
        dayOfWeekCounts[6] += 1
        break
    }
  })

  // console.log(weekday, holidayCounts[0], holiday, holidayCounts[1])
  targetItems.holiday = [
    holidayCounts[0] == 0 ? 0 : weekday / holidayCounts[0],
    holidayCounts[1] == 0 ? 0 : holiday / holidayCounts[1],
  ]

  for (let idx = 0; idx < targetItems.dayOfWeek.length; idx++) {
    targetItems.dayOfWeek[idx] /= dayOfWeekCounts[idx]
  }

  const genderIndex = genderId
  const genderLabels = ['男性', '女性']
  targetItems.genderLabel = genderLabels[genderIndex]

  const ageGroupIndex = ageId
  const ageGroupLabels = ['10', '20', '30', '40', '50']
  targetItems.ageGroupLabel = ageGroupLabels[ageGroupIndex]

  // console.log(targetItems)

  ///// ストアの更新 /////
  context.commit('setTenantTargetItems', targetItems)
}

// 時間別の来客数／来客属性
const fetchCountingHourlyDataList = async (
  context,
  { start, end, ageId, genderId },
) => {
  const TAG = `${MODULE_TAG}.${fetchCountingHourlyDataList.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const cwlogger = await Logger.getInstance(context.getters.getCurrentUsername)
  cwlogger?.verbose(`begin ${TAG}`)

  const contractId = context.state.contract.id
  const identityId = context.state.user.attributes.sub

  // console.log(context.state.userItems)
  // ユーザープロファイルから所属情報を取得する
  const areaName = context.state.userItems[0].department
  const tenantName = context.state.userItems[0].tenant

  logger.debug(ageId, genderId)

  const ageStrs = ['10', '20', '30', '40', '50']
  let ageStr = ''
  if (ageId) {
    ageStr = ageStrs[ageId]
  }

  const genderStrs = ['Male', 'Female']
  let genderStr = ''
  if (genderId) {
    genderStr = genderStrs[genderId]
  }

  const input = {
    contractId: contractId,
    identityId: identityId,
    begin: start,
    end: end,
    area: areaName,
    tenant: tenantName,
    type: 'Hourly',
    ageGroup: ageStr,
    gender: genderStr,
  }
  logger.verbose('input:')
  logger.verbose(input)

  // ユースケース｜曜日別、時間別のデータ集計
  const res = await PeopleCountInteractor.getPeopleCountHourlyData(input)

  let currentTableData = []
  let hourlyData = {
    labels: [],
    datasets: [],
  }

  const tenant = res.result
  const currentData = tenant.current
  logger.verbose('currentData: ')
  logger.verbose(currentData)

  const newCurrentItem = CountingPresenter.convertDbToStore(currentData)
  // console.log(newCurrentItem)

  hourlyData = newCurrentItem

  // const beforeData = tenant.before
  // logger.verbose('beforeData: ')
  // logger.verbose(beforeData)
  // const newBeforeItem = CountingPresenter.convertDbToStore(beforeData)

  currentTableData.push(newCurrentItem)

  logger?.verbose(`-- hourlyData ${TAG} --`)
  logger?.verbose(hourlyData)
  // logger?.verbose(countingDailyDataItem)

  // console.log(context.state.tenantInfoItem)
  // 開店時間と閉店時間の設定
  const openHour = context.state.tenantInfoItem.businessHours[0].open
  const closeHour = context.state.tenantInfoItem.businessHours[0].close
  // 時間次データの整形
  const tenantCountingHoursData =
    CountingPresenter.convertDbToOwnTenantHoursStore(
      hourlyData,
      openHour,
      closeHour,
    )
  // console.log(tenantCountingHoursData)
  const tenantTargetHoursData = tenantCountingHoursData

  ///// ストアの更新 /////
  // 店舗計測｜来客数｜時間別
  StoreRepository.updateTenantCountingHoursItems(context, {
    contractId,
    tenantCountingHoursData,
  })
  // 店舗計測｜来客属性｜時間別
  StoreRepository.updateTenantTargetHoursItems(context, {
    contractId,
    tenantTargetHoursData,
  })

  return
}

// 動線計測
const fetchTrackingDataList = async (context, { start, end, type }) => {
  const TAG = `${MODULE_TAG}.${fetchTrackingDataList.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const cwlogger = await Logger.getInstance(context.getters.getCurrentUsername)
  cwlogger?.verbose(`begin ${TAG}`)

  const contractId = context.state.contract.id
  const identityId = context.state.user.attributes.sub

  // console.log(start, end)
  const dateBegin = start
  const dateEnd = end

  // console.log(context.state.userItems)
  // ユーザープロファイルから所属情報を取得する
  const areaName = context.state.userItems[0].department
  const tenantName = context.state.userItems[0].tenant
  // const tenantName = '愛知県 / 名古屋店'

  const input = {
    contractId: contractId,
    identityId: identityId,
    begin: dateBegin,
    end: dateEnd,
    area: areaName,
    tenant: tenantName,
    type: type,
  }
  logger.verbose('contractId: ' + input.contractId)
  logger.verbose('identityId: ' + input.identityId)

  // TODO: ユースケース｜動線計測

  // 動線計測
  const spotDirectionData = sampledata.spotDirectionItems
  StoreRepository.updateSpotDirectionItems(context, {
    contractId: contractId,
    spotDirectionData: spotDirectionData,
  })

  // 動線計測｜方向別
  const spotDirectionSummaryData = sampledata.spotDirectionSummaryItems
  StoreRepository.updateSpotDirectionSummaryItems(context, {
    contractId: contractId,
    spotDirectionSummaryData: spotDirectionSummaryData,
  })
}

export default {
  fetchCountingAllData,
  fetchCountingDataComparison,

  fetchMeasurementDataList,
  fetchCountingDataList,
  fetchCountingDailyDataList,
  makeTargetSummary,
  fetchCountingHourlyDataList,

  fetchTrackingDataList,
}
