import ServiceOption from '@/domain/models/ServiceOption'

import ServiceOptionRepository from '@/adapters/ServiceOptionRepository'

const getServiceOptionsList = async (input) => {
  const contractId = input.contractId

  // サービスオプション一覧の取得
  const response = await ServiceOptionRepository.fetchServiceOptionsList(
    contractId,
  )

  return {
    result: response.data,
    error: null,
  }
}

const createServiceOption = async (input) => {
  const contractId = input.contractId
  // オプションプロファイルを作成する
  const optionProfile = {
    key: input.keyName,
    limit: input.defaultValue,
    name: input.optionName,
    description: input.description,
  }
  // サービスオプションの登録
  const response = await ServiceOptionRepository.createNewServiceOption({
    contractId,
    optionProfile,
  })
  if (response.error) {
    return {
      result: null,
      error: new Error('サービスオプションの登録に失敗しました'),
    }
  }

  return {
    result: response.data,
    error: null,
  }
}

const editServiceOptionProfile = async (input) => {
  const contractId = input.contractId
  const optionProfile = {
    id: input.id,
    key: input.keyName,
    limit: input.limit,
    name: input.optionName,
    description: input.description,
  }

  // サービスオプションの更新
  const response = await ServiceOptionRepository.updateServiceOptionProfile({
    contractId,
    optionProfile,
  })

  if (!response) {
    return {
      result: null,
      error: new Error('サービスオプションの更新に失敗しました'),
    }
  }

  return {
    result: response.data,
    error: null,
  }
}

const deleteServiceOption = async (input) => {
  const deleteId = input.deleteId

  // サービスオプションの削除
  const response = await ServiceOptionRepository.deleteResourceById(deleteId)

  if (!response) {
    return {
      result: null,
      error: new Error('サービスオプションの削除処理に失敗しました'),
    }
  }

  return {
    result: response.data,
    error: null,
  }
}

export default {
  getServiceOptionsList,
  createServiceOption,
  editServiceOptionProfile,
  deleteServiceOption,
}
