import Account from '@/domain/models/Account'

import AccountRepository from '@/adapters/AccountRepository'
import IdaasRepository from '@/adapters/IdaasRepository'

import DateUtil from '@/utils/DateUtil'

const createAccount = async (input) => {
  const username = input.username
  const password = input.password
  const email = input.email

  const resultNewUser = await IdaasRepository.createNewAccount({
    username,
    password,
    email,
  })
  if (!resultNewUser) {
    return {
      result: null,
      error:
        '既にそのアカウントＩＤは使われているかもしれません。　違うアカウントＩＤを入力してください。',
    }
  }

  const date = new Date(resultNewUser.UserCreateDate)
  const registered = DateUtil.formatDate(date, 'YYYY-MM-DD')
  const sub = resultNewUser.Attributes[0].Value
  // const accountItem = AccountPresenter.convertIdaasToStore(resultNewUser)
  // const sub = accountItem.idaasUser.sub
  // const registered = accountItem.idaasUser.registered

  const resultAccount = await AccountRepository.createNewAccount({
    username,
    sub,
    email,
    registered,
  })

  const result = {
    newUser: resultNewUser,
    newAccount: resultAccount,
  }

  return {
    result: result,
    error: null,
  }
}

const getAccountList = async (input) => {
  const response = await AccountRepository.fetchAccountList()

  return {
    result: response,
    error: null,
  }
}

const resetPassword = async (input) => {
  const username = input.username
  const password = input.password

  const result = await IdaasRepository.resetPassword(username, password)

  return {
    result: result,
    error: null,
  }
}

const enableAccount = async (input) => {
  const username = input.username
  const enable = input.enable

  const result = await IdaasRepository.enableAccount(username, enable)

  return {
    result: result,
    error: null,
  }
}

const updateActivity = async (input) => {
  const accountId = input.accountId
  const activity = input.loginDate

  // ユーザーアクティビティの更新
  const updateResult = await AccountRepository.updateActivity({
    accountId,
    activity,
  })
  if (!updateResult) {
    return {
      result: null,
      error: 'ユーザーのDBの更新に失敗しました',
    }
  }

  return {
    result: updateResult.data.updateAccount,
    error: null,
  }
}

export default {
  createAccount,
  getAccountList,
  resetPassword,
  enableAccount,
  updateActivity,
}
