// ロガー Amplify Logger
import { Logger } from 'aws-amplify'

const loggerName = 'pms_web'

/// どのレベルのログまでロギングするか
///   NOTE: setLogLevel 関数で再設定する
// let LOG_LEVEL = 'ERROR'
let LOG_LEVEL = 'WARN'
// let LOG_LEVEL = 'INFO'
// let LOG_LEVEL = 'DEBUG'
// let LOG_LEVEL = 'VERBOSE'

/// NOTE: CloudWatch Logs に接続するかどうか
const connectedCloudWatchLogs = true
// const connectedCloudWatchLogs = false

const setLogLevel = () => {
  const env = process.env.NODE_ENV
  /// NOTE: ここで環境毎のログレベルを設定する
  if (env === 'development') {
    // window.LOG_LEVEL = 'DEBUG'
    // LOG_LEVEL = 'INFO'
    // LOG_LEVEL = 'DEBUG'
    LOG_LEVEL = 'VERBOSE'
  } else if (env === 'production') {
    // window.LOG_LEVEL = 'INFO'
    /// TODO: リリース時は適切なログレベルを選択すること
    // LOG_LEVEL = 'WARN'
    LOG_LEVEL = 'INFO'
  }
}

const formatter = (TAG, ...msg) => {
  return `[${TAG}] ${msg}`
}

let localLogger_ = null
const getLocalInstance = () => {
  if (!localLogger_) {
    setLogLevel()
    localLogger_ = new Logger(loggerName, LOG_LEVEL)
    return localLogger_
  }
  return localLogger_
}

// ロガー Amplify Ligger + CloudWatch Logs

import { Auth } from 'aws-amplify'
import { Amplify, AWSCloudWatchProvider } from 'aws-amplify'

const loggerPrefix = 'amplify-logger'
const appName = 'pms_web'
const default_username = 'unknown-user'
// const default_username = 'default-user'
const region = 'ap-northeast-1'
const endpoint = 'https://logs.ap-northeast-1.amazonaws.com'

let logger_ = null
const getInstance = async (username) => {
  if (!connectedCloudWatchLogs) return null

  if (logger_) {
    return logger_
  }

  let logStreamName = username
  if (!logStreamName) {
    logStreamName = default_username
  }

  setLogLevel()
  logger_ = new Logger(loggerName, LOG_LEVEL)
  Amplify.register(logger_)

  logger_.verbose(
    'log group: ' + `/${loggerPrefix}/${appName}/${process.env.NODE_ENV}`,
  )
  const credentials = await Auth.currentCredentials()
  // console.log(credentials)
  // const s = await Auth.currentAuthenticatedUser()
  // console.log(s)

  const provier = new AWSCloudWatchProvider()
  provier.configure({
    logGroupName: `/${loggerPrefix}/${appName}/${process.env.NODE_ENV}`,
    logStreamName: logStreamName,
    region: region,
    credentials: credentials,
    endpoint: endpoint,
  })

  logger_.addPluggable(provier)
  return logger_
}

export default {
  formatter,
  getLocalInstance,
  getInstance,
}
