<template>
  <CFooter>
    <div>
      <a href="https://www.enazeal.co.jp/" target="_blank"
        >来店者分析システム</a
      >
      <span class="ms-1"
        >&copy; {{ new Date().getFullYear() }} Enazeal株式会社.</span
      >
    </div>
    <div class="ms-auto">
      <span class="me-1" target="_blank">Powered by</span>
      <a href="https://www.enazeal.co.jp/face_recognition.php">
        顔認証ソリューション（AI顔認証）
      </a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
