import { API } from 'aws-amplify'

import { listAccounts, getAccount } from '@/graphql/queries'
import { createAccount } from '@/graphql/mutations'
import { updateAccount } from '@/graphql/mutations'
import { deleteAccount } from '@/graphql/mutations'

import IdaasRepository from '@/adapters/IdaasRepository'

import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'AccountRepository'

const createNewAccount = async ({ username, sub, email, registered }) => {
  const TAG = `${MODULE_TAG}.${createNewAccount.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const inputData = {
    id: sub,
    idaasUser: {
      username: username,
      sub: sub,
      email: email,
      registered: registered,
    },
    name: '',
    corporate: '',
    email: '',
    description: '',
    activity: '',
  }
  logger.verbose(Logger.formatter(TAG, `inputData:`))
  logger.verbose(inputData)

  const resultAccount = await API.graphql({
    query: createAccount,
    variables: { input: inputData },
  })
    .then(async (result) => {
      logger.verbose('=== createAccount result ===')
      logger.verbose(result)
      return result
    })
    .catch((error) => {
      logger.error('=== createAccount error ===')
      logger.error(error)
      return null
    })

  return resultAccount
}

const fetchAccountList = async () => {
  const TAG = `${MODULE_TAG}.${fetchAccountList.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const groupName = 'Corporates'
  const accounts = null
  const nextToken = null

  const listUsers = await IdaasRepository.fetchIdaasUserList({
    groupName,
    accounts,
    nextToken,
  })
  logger.verbose(Logger.formatter(TAG, `--- listUsers ---`))
  logger.verbose(listUsers)

  const response = await API.graphql({
    query: listAccounts,
    // limit: 50,
  })
    .then((result) => {
      logger.verbose('=== listAccounts result ===')
      logger.verbose(result)
      return result
    })
    .catch((error) => {
      logger.error('=== listAccounts error ===')
      logger.error(error)
      return null
    })
  if (response == null) {
    return
  }

  return {
    dbAccounts: response.data.listAccounts.items,
    idaasUsers: listUsers,
  }
}

const fetchAccountById = async (accountId) => {
  const TAG = `${MODULE_TAG}.${fetchAccountById.name}`
  logger.verbose(Logger.formatter(TAG, `begin with accountId: ${accountId}`))

  const accountResult = await API.graphql({
    query: getAccount,
    variables: { id: accountId },
  })
    .then((result) => {
      logger.verbose('=== getAccount result ===')
      logger.verbose(result)
      return result
    })
    .catch((error) => {
      logger.error('=== getAccount error ===')
      logger.error(error)
      return null
    })

  return accountResult
}

const updateActivity = async ({ accountId, activity }) => {
  const TAG = `${MODULE_TAG}.${updateActivity.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  let inputData = {
    id: accountId,
    activity: activity,
  }
  logger.verbose(Logger.formatter(TAG, `inputData:`))
  logger.verbose(inputData)

  const updateResult = await API.graphql({
    query: updateAccount,
    variables: { input: inputData },
  })
    .then(async (result) => {
      logger.verbose('=== updateAccount result ===')
      logger.verbose(result)
      return result
    })
    .catch((error) => {
      logger.error('=== updateAccount error ===')
      logger.error(error)
      return null
    })
  if (!updateResult) {
    return null
  }

  return updateResult
}

const deleteResourceById = async (accountId) => {
  const TAG = `${MODULE_TAG}.${deleteResourceById.name}`
  logger.verbose(Logger.formatter(TAG, `begin,  accountId: ${accountId}`))

  if (accountId == null || accountId == '' || typeof accountId === undefined)
    return

  await API.graphql({
    query: deleteAccount,
    variables: { input: { id: accountId } },
  })
    .then((result) => {
      logger.verbose('=== deleteAccount result ===')
      logger.verbose(result)
    })
    .catch((error) => {
      logger.error('=== deleteAccount error ===')
      logger.error(error)
    })
}

export default {
  createNewAccount,
  fetchAccountList,
  fetchAccountById,
  updateActivity,
  deleteResourceById,
}
