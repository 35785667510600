import Promotion from '@/domain/models/Promotion'

import PromotionRepository from '@/adapters/PromotionRepository'
// import MockPromotionRepository from '@/mock/PromotionRepository'

const getPromotionsList = async (input) => {
  // const contractId = input.contractId
  const deviceId = input.deviceId

  // 施策一覧の取得
  const response = await PromotionRepository.fetchPromotionsList(deviceId)
  // NOTE: 本番の処理に置き換える
  // const response = await MockPromotionRepository.fetchPromotionsList(deviceId)
  if (response.error) {
    return {
      result: [],
      error: new Error('施策の取得に失敗しました'),
    }
  }

  return {
    result: response.data,
    error: null,
  }
}

const createPromotion = async (input) => {
  const deviceId = input.deviceId
  // 施策のプロファイルを作成する
  const promotionProfile = {
    eventName: input.eventName,
    start: input.start,
    end: input.end,
    area: input.areaName,
    tenant: input.tenantName,
    description: input.description,
  }
  // 施策の登録
  const response = await PromotionRepository.createNewPromotion({
    deviceId,
    promotionProfile,
  })
  // NOTE: 本番の処理に置き換える
  // const response = await MockPromotionRepository.createNewPromotion({
  //   deviceId,
  //   promotionProfile,
  // })
  if (response.error) {
    return {
      result: null,
      error: new Error('施策の登録に失敗しました'),
    }
  }

  return {
    result: response.data,
    error: null,
  }
}

const editPromotionProfile = async (input) => {
  const deviceId = input.deviceId
  const promotionProfile = {
    id: input.id,
    eventName: input.eventName,
    start: input.start,
    end: input.end,
    area: input.areaName,
    tenant: input.tenantName,
    description: input.description,
  }

  // 施策の更新
  const response = await PromotionRepository.updatePromotionProfile({
    deviceId,
    promotionProfile,
  })
  // NOTE: 本番の処理に置き換える
  // const response = await MockPromotionRepository.updatePromotionProfile({
  //   deviceId,
  //   promotionProfile,
  // })
  if (response.error) {
    return {
      result: null,
      error: new Error('施策の更新に失敗しました'),
    }
  }

  return {
    result: response.data,
    error: null,
  }
}

const deletePromotion = async (input) => {
  // const deviceId = input.deviceId
  const deleteId = input.deleteId

  // 施策の削除
  const response = await PromotionRepository.deleteResourceById(deleteId)
  // NOTE: 本番の処理に置き換える
  // const response = await MockPromotionRepository.deleteResourceById(deleteId)
  if (response.error) {
    return {
      result: null,
      error: new Error('施策の削除処理に失敗しました'),
    }
  }

  return {
    result: response.data,
    error: null,
  }
}

export default {
  getPromotionsList,
  createPromotion,
  editPromotionProfile,
  deletePromotion,
}
