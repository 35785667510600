import { API } from 'aws-amplify'

import { listTenantInformations } from '@/graphql/queries'
import { createTenantInformation } from '@/graphql/mutations'
import { updateTenantInformation } from '@/graphql/mutations'
import { deleteTenantInformation } from '@/graphql/mutations'

import Tenant from '@/domain/models/Tenant'

import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

import DateUtil from '@/utils/DateUtil'

const MODULE_TAG = 'TenantRepository'

// 店舗基本情報を新規作成する
const createTenantInfo = async ({ deviceId, tenantInfo }) => {
  const TAG = `${MODULE_TAG}.${createTenantInfo.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const inputData = {
    name: tenantInfo.name,
    category: tenantInfo.area,
    location: tenantInfo.location,
    description: tenantInfo.description,
    businessHours: [
      {
        begin: tenantInfo.begin,
        open: tenantInfo.open,
        close: tenantInfo.close,
      },
    ],
    iotDeviceID: deviceId,
  }
  logger.verbose(Logger.formatter(TAG, `inputData:`))
  logger.verbose(inputData)

  const response = await API.graphql({
    query: createTenantInformation,
    variables: { input: inputData },
  })
    .then(async (result) => {
      logger.verbose('=== createTenantInformation result ===')
      logger.verbose(result)
      return {
        data: result.data.createTenantInformation,
        error: null,
      }
    })
    .catch((error) => {
      logger.error('=== createTenantInformation error ===')
      logger.error(error)
      return {
        data: null,
        error: error,
      }
    })
  if (!response) {
    return {
      data: null,
      error: new Error('no response!'),
    }
  }
  logger.verbose(Logger.formatter(TAG, `response:`))
  logger.verbose(response)

  return response
}

// 店舗基本情報を取得する
const fetchTenantInfo = async (deviceId) => {
  const TAG = `${MODULE_TAG}.${fetchTenantInfo.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const response = await API.graphql({
    query: listTenantInformations,
    // limit: 50,
  })
    .then((result) => {
      logger.verbose('=== listTenantInformations result ===')
      logger.verbose(result)
      return {
        data: result.data.listTenantInformations.items,
        error: null,
      }
    })
    .catch((error) => {
      logger.error('=== listTenantInformations error ===')
      logger.error(error)
      return {
        data: [],
        error: error,
      }
    })
  if (!response) {
    return {
      data: [],
      error: new Error('no response!'),
    }
  }

  let item
  response.data.forEach((fetchItem) => {
    if (fetchItem.iotDeviceID == deviceId) {
      // 店舗に設置されたデバイスＩＤと関連付けされたデータを抽出する
      item = Tenant.newTenant(fetchItem.id, {
        name: fetchItem.name,
        area: fetchItem.category,
        location: fetchItem.location,
        description: fetchItem.description,
        begin: fetchItem.businessHours[0].begin,
        open: fetchItem.businessHours[0].open,
        close: fetchItem.businessHours[0].close,
        deviceID: '',
      })
    }
  })

  if (item) {
    response.data = item
  } else {
    // データがなかったときの規定値
    response.data = Tenant.newTenant('', {
      name: '',
      area: '',
      location: '',
      description: '',
      begin: DateUtil.systemBeginDate(),
      open: '07:00',
      close: '23:00',
      deviceID: '',
    })
  }
  return response
}

// 店舗基本情報を更新する
const updateTenantInfo = async ({ deviceId, tenantInfo }) => {
  const TAG = `${MODULE_TAG}.${updateTenantInfo.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  let inputData = {
    id: tenantInfo.id,
    name: tenantInfo.name,
    category: tenantInfo.area,
    location: tenantInfo.name,
    description: tenantInfo.description,
    businessHours: [
      {
        begin: tenantInfo.begin,
        open: tenantInfo.open,
        close: tenantInfo.close,
      },
    ],
    iotDeviceID: deviceId,
  }
  const response = await API.graphql({
    query: updateTenantInformation,
    variables: { input: inputData },
  })
    .then(async (result) => {
      logger.verbose('=== updateTenantInformation result ===')
      logger.verbose(result)
      return {
        data: result.data.updateTenantInformation,
        error: null,
      }
    })
    .catch((error) => {
      logger.error('=== updateTenantInformation error ===')
      logger.error(error)
      return {
        data: null,
        error: error,
      }
    })
  if (response.error) {
    return {
      data: null,
      error: new Error('no response!'),
    }
  }

  const fetchItem = response.data
  response.data = Tenant.newTenant(fetchItem.id, {
    id: fetchItem.id,
    name: fetchItem.name,
    area: fetchItem.category,
    location: fetchItem.location,
    description: fetchItem.description,
    begin: fetchItem.businessHours[0].begin,
    open: fetchItem.businessHours[0].open,
    close: fetchItem.businessHours[0].close,
    deviceID: '',
  })
  return response
}

// 店舗基本情報を削除する
const deleteResourceById = async (deleteId) => {
  const TAG = `${MODULE_TAG}.${deleteResourceById.name}`
  logger.verbose(Logger.formatter(TAG, `begin with deleteId: ${deleteId}`))

  if (deleteId == null || deleteId == '' || typeof deleteId === undefined)
    return new Error('deleteId is empty!')

  const response = await API.graphql({
    query: deleteTenantInformation,
    variables: { input: { id: deleteId } },
  })
    .then((result) => {
      logger.verbose('=== deleteTenantInformation result ===')
      logger.verbose(result)
      return {
        data: result.data.deleteTenantInformation,
        error: null,
      }
    })
    .catch((error) => {
      logger.error('=== deleteTenantInformation error ===')
      logger.error(error)
      return {
        data: null,
        error: error,
      }
    })
  if (response.error) {
    return {
      data: null,
      error: new Error('no response!'),
    }
  }

  const fetchItem = response.data
  response.data = Tenant.newTenant(fetchItem.id, {
    name: fetchItem.name,
    area: fetchItem.category,
    location: fetchItem.location,
    description: fetchItem.description,
    begin: fetchItem.businessHours[0].begin,
    open: fetchItem.businessHours[0].open,
    close: fetchItem.businessHours[0].close,
    deviceID: '',
  })

  return response
}

export default {
  createTenantInfo,
  fetchTenantInfo,
  updateTenantInfo,
  deleteResourceById,
}
