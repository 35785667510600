import AccountInteractor from '@/domain/usecases/AccountInteractor'
import ContractInteractor from '@/domain/usecases/ContractInteractor'

import ContractPresenter from '@/adapters/ContractPresenter'
import AccountPresenter from '@/adapters/AccountPresenter'
import presenter from '@/adapters/presenter'

import StoreRepository from '@/adapters/StoreRepository'

import AccountController from '@/adapters/AccountController'
import AppUserController from '@/adapters/AppUserController'
import DeviceController from '@/adapters/DeviceController'
import ServiceController from '@/adapters/ServiceController'

import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'ContractController'

const createContractTemplate = async (
  context,
  { username, password, email },
) => {
  const TAG = `${MODULE_TAG}.${createContractTemplate.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  if (!username || !password) {
    const error = 'no username or no password'
    logger.error(Logger.formatter(TAG, error))
    alert(error)
    return
  }

  const input = {
    username: username,
    password: password,
    email: email,
  }

  const output = await AccountInteractor.createAccount(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, output.error))
    alert(output.error)
    return
  }
  logger.verbose(
    Logger.formatter(TAG, 'newUser id: ' + output.result.newUser.id),
  )

  const accountItem = AccountPresenter.convertIdaasToStore(
    output.result.newUser,
  )
  // ストアに保存する
  StoreRepository.addAcccount(context, { accountItem })

  // アカウントを契約情報の雛形とセットでデータベースに保存する
  const sub = accountItem.idaasUser.sub
  const registered = accountItem.idaasUser.registered
  const enabled = true
  const status = 'CONFIRMED' // 新規作成直後にパスワードの変更は完了しているため
  // 契約のストアとデータベースの項目の新規作成
  await createContract(context, {
    sub,
    username,
    email,
    registered,
    enabled,
    status,
  })

  // サービスオプションの初期設定データを作成して、データベースに保存する。
  const keyName = 'max_login_users'
  const defaultValue = 5
  const optionName = 'ログインユーザーの最大数'
  const description = 'Webアプリにログインできるユーザーの最大登録数'
  await ServiceController.createServiceOption(context, {
    keyName,
    defaultValue,
    optionName,
    description,
  })

  return
}

const createContract = async (
  context,
  { sub, username, email, registered, enabled, status },
) => {
  const TAG = `${MODULE_TAG}.${createContract.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  let index = -1
  context.state.contractItems.find((cont, idx) => {
    if (sub == cont.account.sub) {
      index = idx
      return true
    } else {
      return false
    }
  })
  if (0 <= index) {
    const error = 'limit over of contract'
    logger.error(Logger.formatter(TAG, error))
    alert('新規契約は、１アカウント当たり１契約までです。')
    return
  }

  const corporate = ''
  const owner = ''
  const description = ''
  const start = ''
  const end = ''

  const input = {
    username: username,
    email: email,
    registered: registered,
    // enabled: enabled,
    // status: status,
    contract: {
      /// id: id,
      accountID: sub,
      corporate: corporate,
      owner: owner,
      email: '',
      description: description,
      period: { start: start, end: end },
    },
  }
  logger.verbose(Logger.formatter(TAG, 'input:'))
  logger.verbose(input)

  const output = await ContractInteractor.createContract(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, output.error))
    return
  }
  const contract = output.result
  logger.verbose(Logger.formatter(TAG, 'contractId: ' + contract.id))
  logger.verbose(contract)

  // 契約データ作成直後は空なので sub をセットする
  contract.accountID = sub
  contract.account.sub = sub

  // ストアの更新
  const contractItem = ContractPresenter.convertDbToStoreWithStatus(
    contract,
    enabled,
    status,
  )
  StoreRepository.updateContract(context, { contractItem })
}

const getContractList = async (context) => {
  const TAG = `${MODULE_TAG}.${getContractList.name}`
  logger.verbose(Logger.formatter(TAG, `begin`, 'a', 'b'))

  const cwlogger = await Logger.getInstance(context.getters.getCurrentUsername)
  cwlogger?.verbose(Logger.formatter(TAG, `begin`))

  const input = {}

  const output = await ContractInteractor.getContractList(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, output.error))
    return
  }

  const fetchUsers = output.result.fetchUsers
  const fetchDbUsers = output.result.fetchDbUsers
  const contractsResult = output.result.contracts

  StoreRepository.updateAccountItems(context, {
    fetchDbUsers,
    fetchUsers,
  })

  StoreRepository.updateContractItems(context, {
    fetchUsers,
    contractsResult,
  })
}

const getOwnContract = async (context) => {
  const TAG = `${MODULE_TAG}.${getOwnContract.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const cwlogger = await Logger.getInstance(context.getters.getCurrentUsername)
  cwlogger?.verbose(Logger.formatter(TAG, `call getOwnContract`))

  const input = {
    accountId: context.state.user.attributes.sub,
  }
  logger.debug(Logger.formatter(TAG, '--- input ---'))
  logger.debug(input)

  const output = await ContractInteractor.getOwnContract(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, output.error))
    return
  }
  logger.debug(Logger.formatter(TAG, '--- output ---'))
  logger.debug(output)
  const fetchAccountData = output.result.fetchAccount
  const contractResult = output.result.contractResult

  const fetchContractData = contractResult
  const enabled = fetchAccountData.enabled
  const status = fetchAccountData.status

  const contractItem = ContractPresenter.mergeDbToStore(
    fetchAccountData,
    fetchContractData,
    enabled,
    status,
  )

  // 自身の契約情報をストアに保存する
  StoreRepository.updateContract(context, { contractItem })

  // アプリユーザー情報の取得
  await AppUserController.getAppUsersList(context)
  // デバイス情報の取得
  await DeviceController.fetchDeviceList(context)
  // サービスオプション情報の取得
  await ServiceController.fetchServiceOptionList(context)
  // 自身のアクティビティーの更新
  AccountController.updateOwnActivity(context)

  // NOTE: デバッグ用
  // const username = context.getters.getCurrentUsername
  // const appUsername = 'user01-demo-x'
  // await AppUserController.updateUserActivity(context, { username: appUsername })
}

const getOwnContractByAppUserId = async (context) => {
  const TAG = `${MODULE_TAG}.${getOwnContract.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const cwlogger = await Logger.getInstance(context.getters.getCurrentUsername)
  cwlogger?.verbose(Logger.formatter(TAG, `call getOwnContractByAppUserId`))

  const input = {
    appUserId: context.state.user.attributes.sub,
  }
  logger.debug(Logger.formatter(TAG, '--- input ---'))
  logger.debug(input)

  const output = await ContractInteractor.getOwnContractByAppUserId(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, output.error))
    return
  }
  logger.debug(Logger.formatter(TAG, '--- output ---'))
  logger.debug(output)
  const fetchAccountData = output.result.fetchAccount
  const contractResult = output.result.contractResult

  const fetchContractData = contractResult
  const enabled = fetchAccountData.enabled
  const status = fetchAccountData.status

  const contractItem = ContractPresenter.mergeDbToStore(
    fetchAccountData,
    fetchContractData,
    enabled,
    status,
  )

  // 自身の契約情報をストアに保存する
  StoreRepository.updateContract(context, { contractItem })

  // アプリユーザー情報の取得
  // await AppUserController.getAppUsersList(context)
  // デバイス情報の取得
  await DeviceController.fetchDeviceList(context)
  // サービスオプション情報の取得
  await ServiceController.fetchServiceOptionList(context)
  // 自身のアクティビティーの更新
  // AccountController.updateOwnActivity(context)

  // NOTE: デバッグ用
  // const username = context.getters.getCurrentUsername
  // const appUsername = 'user01-demo-x'
  // await AppUserController.updateUserActivity(context, { username: appUsername })
}

const editContract = async (context, { contract, account, isAdmin }) => {
  const TAG = `${MODULE_TAG}.${editContract.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(account)

  let index = -1
  context.state.contractItems.find((cont, idx) => {
    if (contract.account.sub == cont.account.sub) {
      index = idx
      return true
    } else {
      return false
    }
  })

  let start = ''
  let end = ''

  if (isAdmin) {
    start = contract.usage.start
    end = contract.usage.end
  } else {
    if (
      typeof context.state.contractItems[index].usage !== undefined &&
      context.state.contractItems[index].usage != null
    ) {
      start = context.state.contractItems[index].usage.start
      end = context.state.contractItems[index].usage.end
    }
  }

  if (
    index < 0 ||
    typeof context.state.contractItems[index].id === undefined ||
    context.state.contractItems[index].id == null ||
    context.state.contractItems[index].id == ''
  ) {
    // エラー
    logger.error(Logger.formatter(TAG, 'undefine id or index'))
    return
  }

  const id = context.state.contractItems[index].id
  logger.verbose(Logger.formatter(TAG, `update data, id:` + id))
  const input = {
    contract: {
      id: id,
      accountID: account.sub,
      corporate: contract.corporate,
      owner: contract.owner,
      email: contract.email,
      description: contract.description,
      period: { start: start, end: end },
    },
  }
  logger.verbose(Logger.formatter(TAG, `input:`))
  logger.verbose(input)

  const output = await ContractInteractor.editContract(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, output.error))
    return
  }
  const editDataContract = output.result
  // logger.verbose(editDataContract)
  logger.verbose(Logger.formatter(TAG, 'id: ' + editDataContract.id))

  // ストアの更新
  const contractItem = contract
  contractItem.id = editDataContract.id
  contractItem.accountID = account.sub
  contractItem.usage.start = start
  contractItem.usage.end = end
  contractItem.usage.rate = presenter.calcUsageRate(
    contractItem.usage.start,
    contractItem.usage.end,
  )
  StoreRepository.updateContract(context, { contractItem })
}

const deleteContractAndAccount = async (
  context,
  { username, accountId, contractId },
) => {
  const TAG = `${MODULE_TAG}.${deleteContractAndAccount.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  if (!username || !accountId || !contractId) {
    const error = 'no username or no accountId or no contractId'
    logger.error(Logger.formatter(TAG, error))
    return
  }

  const input = {
    contractId: contractId,
    accountId: accountId,
    username: username,
    optionKey: 'max_login_users',
  }
  const output = await ContractInteractor.deleteContractAndAccount(input)
  if (output.error != null) {
    logger.error(Logger.formatter(TAG, output.error))
    return
  }

  // ストアから削除する
  StoreRepository.deleteAccount(context, { username })

  return
}

export default {
  createContractTemplate,
  getContractList,
  getOwnContract,
  getOwnContractByAppUserId,
  createContract,
  editContract,
  deleteContractAndAccount,
}
