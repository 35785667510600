import ubuntu from '@/assets/images/devices/ubuntu.png'

const contractItems = [
  {
    // avatar: { url: 'images/avatars/profile_pic_1.jpg', status: 'success' },
    account: {
      sub: 'abcdefg',
      username: 'owner-carehouse-a',
      new: false,
      registered: '2020-01-06',
      enabled: false,
      status: 'SAMPLE',
    },
    owner: '理科音 太郎',
    corporate: '（社）ケアハウスＡ',
    email: 'owner@example.com',
    description: '理事',
    usage: {
      rate: Math.round((100 * 10) / 12),
      start: '2021-01-11',
      end: '2022-01-10',
      // period: '',
    },
    activity: '10 秒前',
  },
  {
    // avatar: { url: 'images/avatars/profile_pic_1.jpg', status: 'success' },
    account: {
      sub: 'hijklmn',
      username: 'owner-carehouse-b',
      new: false,
      registered: '2020-02-06',
      enabled: false,
      status: 'SAMPLE',
    },
    owner: '山田 太郎',
    corporate: '（社）ケアハウスＢ',
    email: 'owner@example.com',
    description: '理事',
    usage: {
      rate: Math.round((100 * 8) / 12),
      start: '2021-02-11',
      end: '2022-02-10',
      // period: '',
    },
    activity: '10 秒前',
  },
  {
    // avatar: { url: 'images/avatars/profile_pic_1.jpg', status: 'success' },
    account: {
      sub: 'opqrstu',
      username: 'owner-carehouse-c',
      new: true,
      registered: '2021-03-06',
      enabled: false,
      status: 'SAMPLE',
    },
    owner: '佐藤 太郎',
    corporate: '（社）ケアハウスＣ',
    email: 'owner@example.com',
    description: '代表',
    usage: {
      rate: Math.round((100 * 7) / 12),
      start: '2021-03-11',
      end: '2022-03-10',
      period: '',
    },
    activity: '10 秒前',
  },
  {
    // avatar: { url: 'images/avatars/profile_pic_1.jpg', status: 'success' },
    account: {
      sub: 'vwxyz',
      username: 'owner-carehouse-d',
      new: true,
      registered: '2021-04-06',
      enabled: false,
      status: 'SAMPLE',
    },
    owner: '鈴木 太郎',
    corporate: '（社）ケアハウスＤ',
    email: 'owner@example.com',
    description: '代表',
    usage: {
      rate: Math.round((100 * 6) / 12),
      start: '2021-04-11',
      end: '2022-04-10',
      // period: '',
    },
    activity: '10 秒前',
  },
  {
    // avatar: { url: 'images/avatars/profile_pic_1.jpg', status: 'success' },
    account: {
      sub: 'ABCDEFG',
      username: 'owner-carehouse-e',
      new: true,
      registered: '2021-05-06',
      enabled: false,
      status: 'SAMPLE',
    },
    owner: '未設定',
    corporate: '（社）ケアハウスＥ',
    email: '',
    description: '',
    usage: {
      rate: Math.round((100 * 5) / 12),
      start: '2021-05-11',
      end: '2022-05-10',
      // period: '',
    },
    activity: '10 秒前',
  },
  {
    // avatar: { url: 'images/avatars/profile_pic_1.jpg', status: 'success' },
    account: {
      sub: 'IHJKLMN',
      username: 'owner-shoppingmall-f',
      new: true,
      registered: '2021-06-06',
      enabled: false,
      status: 'SAMPLE',
    },
    owner: '丹羽 肇',
    corporate: '（株）ショッピングモールＦ',
    email: 'niwa@example.com',
    description: '代表',
    usage: {
      rate: Math.round((100 * 4) / 12),
      start: '2021-06-11',
      end: '2022-06-10',
      // period: '',
    },
    activity: '10 秒前',
  },
]

const deviceItems = [
  {
    avatar: { url: ubuntu },
    thing: {
      name: 'datalake-pc1-nagoya-enazeal',
      new: true,
      registered: '2021-01-15',
      status: 'success',
    },
    host: 'datalake-nagoya-pc1',
    category: '愛知県',
    location: '名古屋支店',
    clientCertFile: 'ubuntu-pc1-carehouse-a.pem.crt',
    storagePath: '/jp.co.enazeal/nagoya-office/area1/data',
    description: 'プレステ−ジング環境',
    usage: { rate: 84, mount: '/', period: 'Jun 11, 2021 - Jul 10, 2021' },
    usage2: {
      rate: 57,
      mount: '/mnt/2TBSSD',
      period: 'Jun 11, 2021 - Jul 10, 2021',
    },
    activity: '10 sec ago',
  },
  {
    avatar: { url: ubuntu },
    thing: {
      name: 'datalake-pc2-tokyo-enazeal',
      new: true,
      registered: '2021-01-20',
      status: 'success',
    },
    host: 'datalake-tokyo-pc2',
    category: '東京都',
    location: '東京本社',
    clientCertFile: 'ubuntu-pc2-carehouse-a.pem.crt',
    storagePath: '/jp.co.enazeal/tokyo-office/area1/data',
    description: '未設置',
    usage: { rate: 20, mount: '/', period: 'Jun 11, 2021 - Jul 10, 2021' },
    usage2: {
      rate: 16,
      mount: '/mnt/2TBSSD',
      period: 'Jun 11, 2021 - Jul 10, 2021',
    },
    activity: '10 sec ago',
  },
]

const optionItems = [
  {
    avatar: { url: ubuntu, status: 'success' },
    key: 'max_login_users',
    name: 'ログインユーザーの最大数',
    limit: 5,
    description: 'Webアプリにログインできるユーザーの最大登録数',
    usage: {
      count: 0,
      // rate: (100 * 4) / 5,
      rate: 0,
      period: 'Jun 11, 2021 - Jul 10, 2021',
    },
    activity: '10 sec ago',
  },
  // {
  //   avatar: { url: ubuntu, status: 'success' },
  //   key: `max_log_num`,
  //   name: '検知一覧の最大件数',
  //   limit: 100,
  //   description:
  //     'アラート通知アプリの検知一覧画面に表示する検知ログの最大表示件数',
  //   usage: {
  //     count: 3,
  //     rate: 0,
  //     period: ' - ',
  //   },
  //   activity: '10 sec ago',
  // },
]

const userItems = [
  {
    id: '',
    // avatar: { url: 'images/avatars/profile_pic_1.jpg', status: 'success' },
    user: {
      name: 's-rikaon-carehouse-a',
      new: true,
      registered: '2021-01-21',
      enabled: false,
      status: 'SAMPLE',
    },
    name: '理科音 三郎',
    role: 'オペレーター',
    email: 's-rikaon@example.com',
    department: '',
    tenant: '',
    description: '院長',
    sound: 'call_01 など、個別設定できません。',
    usage: { value: 2, period: '2021-01-11 - 2022-01-10' },
    activity: '10 sec ago',
  },
  {
    id: '',
    // avatar: { url: 'images/avatars/profile_pic_1.jpg', status: 'success' },
    user: {
      name: 'shiro-rikaon-carehouse-a',
      new: true,
      registered: '2021-01-21',
      enabled: false,
      status: 'SAMPLE',
    },
    name: '理科音 四郎',
    role: 'オペレーター',
    email: 's-rikaon@example.com',
    department: '',
    tenant: '',
    description: '看護主任',
    sound: 'call_02 など、個別設定できません。',
    usage: { value: 2, period: '2021-01-11 - 2022-01-10' },
    activity: '10 sec ago',
  },
]

export default {
  contractItems,
  deviceItems,
  optionItems,

  userItems,
}
