import DownloadDataInteractor from '@/domain/usecases/DownloadDataInteractor'

import DownloadDataPresenter from '@/adapters/DownloadDataPresenter'

import DeviceRepository from '@/adapters/DeviceRepository'
import CloudStorageRepository from '@/adapters/CloudStorageRepository'
// import MockCloudStorageRepository from '@/mock/CloudStorageRepository'

import StoreRepository from '@/adapters/StoreRepository'

// import sampledata from '@/adapters/samplecountingdata'

import DateUtil from '@/utils/DateUtil'
import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'DownloadDataController'

const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time))

const fetchDownloadDataList = async (
  context,
  { tenants, start, end, type },
) => {
  const TAG = `${MODULE_TAG}.${fetchDownloadDataList.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const cwlogger = await Logger.getInstance(context.getters.getCurrentUsername)
  cwlogger?.verbose(`begin ${TAG}`)

  const input = {
    // 現在ログインしているアカウントの契約情報を取得する
    contractId: context.state.contract.id,
    deviceId: context.state.tenantDatabaseItem.id,
    start: start,
    end: end,
    // start: '2023/04/01',
    // end: '2023/04/15',
    type: type,
    // type: 'Daily',
  }
  logger.verbose('contractId: ' + input.contractId)
  logger.verbose('deviceId: ' + input.deviceId)

  const contractId = input.contractId

  // デバイス情報の一覧を取得する（storagePath）
  const resdev = await DeviceRepository.fetchDevicesList(contractId)
  const devices = resdev.data
  // const resdev = await MockDeviceRepository.fetchDevicesList(contractId)
  // console.log(resdev.data[0])

  const results = []
  for (const device of devices) {
    let isTenantMath = false
    console
    for (const tenant of tenants) {
      // console.log(device.location, tenant)
      if (device.location == tenant) {
        isTenantMath = true
      }
    }
    if (isTenantMath) {
      const storagePath = device.storagePath
      // console.log(storagePath)

      // const storagePath = context.state.tenantDatabaseItem.storagePath
      // const storagePath = 'jp.co.enazeal/nagoya-office/area1/data'
      // const start = input.start
      // const end = input.end
      // const type = input.type

      // TODO: ユースケース
      const output = await CloudStorageRepository.getDataFileList(storagePath, {
        start,
        end,
        type,
      })
      // console.log(output)
      if (!output.error) {
        results.push(output.data)
      }
    }
  }

  results.forEach((result) => {
    const downloadDataItems = DownloadDataPresenter.convertDbDataToStore(result)
    logger.verbose('=== downloadDataItems ===')
    logger.verbose(downloadDataItems)
    const downloadPeriodData = downloadDataItems

    // const response = await MockCloudStorageRepository.getDataFileList(deviceId, {
    //   storagePath,
    //   date,
    //   type,
    // })
    // const output2 = {
    //   result: response.data,
    //   error: null,
    // }
    // const downloadPeriodData = output2.result
    // const downloadPeriodData = sampledata.downloadPeriodItems

    // データダウンロード可能区間のリスト
    StoreRepository.updateDownloadPeriodItems(context, {
      contractId: input.contractId,
      downloadPeriodList: downloadPeriodData,
    })
  })

  return
}

const fetchDownloadData = async (context, { tenants, start, end, type }) => {
  const TAG = `${MODULE_TAG}.${fetchDownloadData.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const cwlogger = await Logger.getInstance(context.getters.getCurrentUsername)
  cwlogger?.verbose(`begin ${TAG}`)

  const input = {
    // 現在ログインしているアカウントの契約情報を取得する
    contractId: context.state.contract.id,
    identityId: context.state.user.attributes.sub,
    tenants: tenants,
    start: start,
    end: end,
    // start: '2023/04/01',
    // end: '2023/04/15',
    type: type,
    // type: 'Daily',
  }
  logger.verbose('start: ' + input.start)
  logger.verbose('end: ' + input.end)

  // ユースケース: データダウンロード開始を要求する
  DownloadDataInteractor.startDownloadZipfile(input)

  return
}

export default {
  // fetchDownloadDataList,
  fetchDownloadData,
}
