const newPeopleCount = () => {
  return {
    date: '',
    area: '',
    tenant: '',
    // traffic: 0,
    // staying: 0.0,
    inCount: 0,
    inAttribute: 0,
    noAttribute: 0,
    maleAge10: 0,
    maleAge20: 0,
    maleAge30: 0,
    maleAge40: 0,
    maleAge50: 0,
    maleNoage: 0,
    femaleAge10: 0,
    femaleAge20: 0,
    femaleAge30: 0,
    femaleAge40: 0,
    femaleAge50: 0,
    femaleNoage: 0,
    // 計算
    male: 0,
    female: 0,
    age10: 0,
    age20: 0,
    age30: 0,
    age40: 0,
    age50: 0,
    // カウントライン
    in01: 0,
    in02: 0,
    in03: 0,
    in04: 0,
    in05: 0,
    in06: 0,
    in07: 0,
    in08: 0,
    in09: 0,
    in10: 0,
    out01: 0,
    out02: 0,
    out03: 0,
    out04: 0,
    out05: 0,
    out06: 0,
    out07: 0,
    out08: 0,
    out09: 0,
    out10: 0,
    validAttribute01: 0,
    validAttribute02: 0,
    validAttribute03: 0,
    validAttribute04: 0,
    validAttribute05: 0,
    validAttribute06: 0,
    validAttribute07: 0,
    validAttribute08: 0,
    validAttribute09: 0,
    validAttribute10: 0,
  }
}

const addPeopleCount = (obj1, obj2) => {
  obj1.date = obj2.date
  obj1.area = obj2.area
  obj1.tenant = obj2.tenant
  // obj1.traffic += obj2.traffic
  // obj1.staying += obj2.staying
  obj1.inCount += obj2.inCount
  obj1.inAttribute += obj2.inAttribute
  obj1.noAttribute += obj2.noAttribute
  obj1.maleAge10 += obj2.maleAge10
  obj1.maleAge20 += obj2.maleAge20
  obj1.maleAge30 += obj2.maleAge30
  obj1.maleAge40 += obj2.maleAge40
  obj1.maleAge50 += obj2.maleAge50
  obj1.maleNoage += obj2.maleNoage
  obj1.femaleAge10 += obj2.femaleAge10
  obj1.femaleAge20 += obj2.femaleAge20
  obj1.femaleAge30 += obj2.femaleAge30
  obj1.femaleAge40 += obj2.femaleAge40
  obj1.femaleAge50 += obj2.femaleAge50
  obj1.femaleNoage += obj2.femaleNoage
  // 計算
  obj1.male += obj2.male
  obj1.female += obj2.female
  obj1.age10 += obj2.age10
  obj1.age20 += obj2.age20
  obj1.age30 += obj2.age30
  obj1.age40 += obj2.age40
  obj1.age50 += obj2.age50
}

const createPeopleCount = (record, areaName, tenantName) => {
  return {
    date: record.date,
    area: areaName,
    tenant: tenantName,
    // traffic: record.traffic,
    // staying: record.staying_ave,
    inCount: record.in_count,
    inAttribute: record.in_attribute,
    noAttribute: record.no_attribute,
    maleAge10: record.male_age10,
    maleAge20: record.male_age20,
    maleAge30: record.male_age30,
    maleAge40: record.male_age40,
    maleAge50: record.male_age50,
    maleNoage: record.male_noage,
    femaleAge10: record.female_age10,
    femaleAge20: record.female_age20,
    femaleAge30: record.female_age30,
    femaleAge40: record.female_age40,
    femaleAge50: record.female_age50,
    femaleNoage: record.female_noage,
    // 計算
    male:
      record.male_noage +
      record.male_age10 +
      record.male_age20 +
      record.male_age30 +
      record.male_age40 +
      record.male_age50,
    female:
      record.female_noage +
      record.female_age10 +
      record.female_age20 +
      record.female_age30 +
      record.female_age40 +
      record.female_age50,
    age10: record.male_age10 + record.female_age10,
    age20: record.male_age20 + record.female_age20,
    age30: record.male_age30 + record.female_age30,
    age40: record.male_age40 + record.female_age40,
    age50: record.male_age50 + record.female_age50,
    // カウントライン
    in01: record.in01,
    in02: record.in02,
    in03: record.in03,
    in04: record.in04,
    in05: record.in05,
    in06: record.in06,
    in07: record.in07,
    in08: record.in08,
    in09: record.in09,
    in10: record.in10,
    out01: record.out01,
    out02: record.out02,
    out03: record.out03,
    out04: record.out04,
    out05: record.out05,
    out06: record.out06,
    out07: record.out07,
    out08: record.out08,
    out09: record.out09,
    out10: record.out10,
    validAttribute01: record.inattr01,
    validAttribute02: record.inattr02,
    validAttribute03: record.inattr03,
    validAttribute04: record.inattr04,
    validAttribute05: record.inattr05,
    validAttribute06: record.inattr06,
    validAttribute07: record.inattr07,
    validAttribute08: record.inattr08,
    validAttribute09: record.inattr09,
    validAttribute10: record.inattr10,
  }
}

const createPeopleCountByAttribute = (
  record,
  areaName,
  tenantName,
  gender,
  ageGroup,
) => {
  const data = newPeopleCount()

  data.date = record.date
  data.area = areaName
  data.tenant = tenantName
  data.noAttribute = record.no_attribute
  data.inAttribute = record.in_attribute

  // data.traffic = record.traffic
  // data.staying = record.staying_ave
  if (gender == 'Male') {
    if (ageGroup == '10') {
      data.maleAge10 = record.male_age10
    } else if (ageGroup == '20') {
      data.maleAge20 = record.male_age20
    } else if (ageGroup == '30') {
      data.maleAge30 = record.male_age30
    } else if (ageGroup == '40') {
      data.maleAge40 = record.male_age40
    } else if (ageGroup == '50') {
      data.maleAge50 = record.male_age50
    }

    data.maleNoage = record.male_noage
  } else if (gender == 'Female') {
    if (ageGroup == '10') {
      data.femaleAge10 = record.female_age10
    } else if (ageGroup == '20') {
      data.femaleAge20 = record.female_age20
    } else if (ageGroup == '30') {
      data.femaleAge30 = record.female_age30
    } else if (ageGroup == '40') {
      data.femaleAge40 = record.female_age40
    } else if (ageGroup == '50') {
      data.femaleAge50 = record.female_age50
    }

    data.femaleNoage = record.female_noage
  }

  // 計算
  data.male =
    data.maleNoage +
    data.maleAge10 +
    data.maleAge20 +
    data.maleAge30 +
    data.maleAge40 +
    data.maleAge50

  data.female =
    data.femaleNoage +
    data.femaleAge10 +
    data.femaleAge20 +
    data.femaleAge30 +
    data.femaleAge40 +
    data.femaleAge50

  data.inCount = data.male + data.female
  // data.inCount = record.in_count

  data.age10 = data.maleAge10 + data.femaleAge10
  data.age20 = data.maleAge20 + data.femaleAge20
  data.age30 = data.maleAge30 + data.femaleAge30
  data.age40 = data.maleAge40 + data.femaleAge40
  data.age50 = data.maleAge50 + data.femaleAge50

  return data
}

export default {
  newPeopleCount,
  addPeopleCount,
  createPeopleCount,
  createPeopleCountByAttribute,
}
