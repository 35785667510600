import { createApp } from 'vue'
import App from './App.vue'
import LocalApp from './LocalApp.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/components/DocsExample'

// Amplify読み込み
import AmplifyVue from '@aws-amplify/ui-vue'

// Amplify SDK標準
import { Amplify } from 'aws-amplify'
import aws_exports from './aws-exports'

// Amplify 日本語対応
import { I18n } from 'aws-amplify'
import { translations } from '@aws-amplify/ui-vue'
I18n.putVocabularies(translations)
I18n.setLanguage('ja')
// 日本語訳をカスタマイズする
I18n.putVocabularies({
  ja: {
    'Sign in': 'ログイン',
    'Sign In': 'ログイン',
    'Forgot your password?': ' ',
    'Back to Sign In': 'ログインに戻る',
    'Cannot reset password for the user as there is no registered/verified email or phone_number':
      '確認用メールアドレスもしくは、電話番号がアカウントに登録されていないため、この画面からはパスワードをリセットできません。 サービスの管理者にお問い合わせください。',
    'Incorrect username or password.':
      'アカウントＩＤ か パスワードが間違っています',
    'User does not exist.': 'アカウントＩＤ か パスワードが間違っています',
    Username: 'アカウントＩＤ ',
    Password: 'パスワード ',
    'Enter your Username': 'アカウントＩＤを入力してください',
    'Enter your Password': 'パスワードを入力してください',
    'Network error':
      'インターネットに接続できていません。 ネットワーク接続を確認してください。',
  },
})

// Amplify アプリケーションの設定
Amplify.configure(aws_exports)

const runMode = process.env.VUE_APP_RUN_MODE
const appComponent = runMode && runMode === 'localserver' ? LocalApp : App
const app = createApp(appComponent)
// const app = createApp(App)
app.use(AmplifyVue)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsExample', DocsExample)

app.mount('#app')
