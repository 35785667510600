import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import storeAuth from '../store'

// Amplify読み込み
import { Hub } from 'aws-amplify'
import { Auth } from '@aws-amplify/auth'

import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'router'

const runMode = process.env.VUE_APP_RUN_MODE

let user
let group

// ユーザー管理
getUser().then(async (usr) => {
  const TAG = `${MODULE_TAG}.${getUser.name}`

  // 保存する
  user = usr

  logger.verbose(Logger.formatter(TAG, '=== user object ==='))
  logger.verbose(user)

  if (usr) {
    group = getGroup(usr)
    // ログインユーザーのユーザーグループによって、リダイレクト先を選択する。
    if (group == 'Admins') {
      logger.verbose(Logger.formatter(TAG, 'user is administrator'))
      router.push({ path: '/contract' })
    } else if (group == 'Corporates') {
      logger.verbose(Logger.formatter(TAG, 'Corporates group is ' + group))
      router.push({ path: '/account' })
    } else {
      logger.verbose(Logger.formatter(TAG, 'Normal user group is ' + group))
      if (runMode && runMode === 'localserver') {
        router.push({ path: '/systemtools' })
      } else {
        router.push({ path: '/home' })
      }
    }

    const username = user.username
    // logger.info(Logger.formatter(TAG, `${username} login`))
    logger.info(`${username} login`)
    // TODO: 最後に CloudWatch に接続すること。
    const cwlogger = await Logger.getInstance(username)
    // cwlogger?.info(Logger.formatter(TAG, `${username} login`))
    cwlogger?.info(`${username} login`)
  }
})

async function getUser() {
  return await Auth.currentAuthenticatedUser()
    .then((data) => {
      if (data && data.signInUserSession) {
        storeAuth.commit('setUser', data)
        return data
      }
    })
    .catch(() => {
      storeAuth.commit('setUser', null)
      storeAuth.commit('setGroup', null)
      return null
    })
}

function getGroup(user) {
  const TAG = `${MODULE_TAG}.${getGroup.name}`

  if (user && user.signInUserSession) {
    const groups = user.signInUserSession.accessToken.payload['cognito:groups']
    logger.verbose(Logger.formatter(TAG, `payload groups: ${groups}`))
    // storeAuth.commit('setGroup', groups && groups.length === 1 ? groups[0] : '');
    // const gp = groups && 0 < groups.length ? groups[groups.length-1] : '';
    // const gp = groups && 0 < groups.length ? groups[0] : '';
    let gp = ''
    if (groups) {
      const normalGroups = ['Corporates', 'Managers', 'Operators']
      const result = groups.find((group) => {
        return normalGroups.find((n) => n === group)
      })
      if (result) {
        gp = result
      } else {
        const adminGroups = ['Admins']
        const result = groups.find((group) => {
          return adminGroups.find((n) => n === group)
        })
        if (result) {
          gp = result
        }
      }
    }
    logger.verbose(Logger.formatter(TAG, `set groups: ${gp}`))
    storeAuth.commit('setGroup', gp)
    return gp
  } else {
    storeAuth.commit('setGroup', null)
    return null
  }
}

// ログイン状態管理
Hub.listen('auth', async (data) => {
  const TAG = `Hub.listen event auth`

  //   logger.verbose('Listening from channel: ', data.channel)
  logger.verbose(Logger.formatter(TAG, `data:`))
  logger.verbose(data)
  if (data) {
    if (data.payload.event === 'signOut') {
      logger.debug(Logger.formatter(TAG, `logout`))
      storeAuth.commit('clearAllItems')
      user = null
      group = null
      storeAuth.commit('setUser', null)
      storeAuth.commit('setGroup', null)
      //// router.push({path: '/login'});
      router.push({ path: '/' })
      // router.go({ path: '/', force: true }) // Refreshes page
    } else if (data.payload.event === 'signIn') {
      logger.debug(Logger.formatter(TAG, `login`))
      // user = await getUser();
      user = data.payload.data
      group = getGroup(user)
      storeAuth.commit('setUser', user)
      storeAuth.commit('setGroup', group)
      router.go() // Refreshes page
      // ログインユーザーのユーザーグループによって、リダイレクト先を選択する。
      if (group == 'Admins') {
        logger.verbose(Logger.formatter(TAG, 'Administrator group is Admins'))
        router.push({ path: '/contract' })
      } else if (group == 'Corporates') {
        logger.verbose(Logger.formatter(TAG, 'Corporates group is ' + group))
        router.push({ path: '/account' })
      } else {
        logger.verbose(Logger.formatter(TAG, 'Normal user group is ' + group))
        if (runMode && runMode === 'localserver') {
          router.push({ path: '/systemtools' })
        } else {
          router.push({ path: '/home' })
        }
      }
    }
  }
})

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: '',
    component: DefaultLayout,
    // redirect: '/dashboard',
    children: [
      {
        path: '/contract',
        name: '契約一覧',
        // component: () => import('@/views/Contract.vue'),
        component: () =>
          import(/* webpackChunkName: "contract" */ '@/views/Contract.vue'),
      },

      {
        path: '/account',
        name: 'アカウント情報',
        // component: () => import('@/views/Account.vue'),
        component: () =>
          import(/* webpackChunkName: "account" */ '@/views/Account.vue'),
      },
      // {
      //   path: '/system',
      //   name: 'System',
      //   component: () => import('@/views/Lykaoni.vue'),
      //   component: () =>
      //     import(/* webpackChunkName: "system" */ '@/views/Lykaoni.vue'),
      // },
      {
        path: '/users',
        name: 'ログインユーザー',
        // component: () => import('@/views/Users.vue'),
        component: () =>
          import(/* webpackChunkName: "users" */ '@/views/Users.vue'),
      },
      {
        path: '/home',
        name: 'ダッシュボード',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
      },

      {
        path: '/tenant',
        name: '店舗計測',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/tenant/measurement-data',
        children: [
          {
            path: '/tenant/measurement-data',
            name: '計測データ',
            component: () =>
              import(
                /* webpackChunkName: "tenantmeasurementdata" */ '@/views/TenantMeasurementData.vue'
              ),
          },
          {
            path: '/tenant/people-count',
            name: '来客数',
            component: () =>
              import(
                /* webpackChunkName: "tenantpeoplecount" */ '@/views/TenantPeopleCount.vue'
              ),
          },
          {
            path: '/tenant/people-attributes',
            name: '来客属性',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            redirect: '/tenant/people-attributes/gender',
            children: [
              {
                path: '/tenant/people-attributes/gender',
                name: '性別傾向',
                component: () =>
                  import(
                    /* webpackChunkName: "tenantpeopleattribute" */ '@/views/TenantPeopleAttributes.vue'
                  ),
              },
              {
                path: '/tenant/people-attributes/age',
                name: '年代傾向',
                component: () =>
                  import(
                    /* webpackChunkName: "tenantpeopleattribute2" */ '@/views/TenantPeopleAttributes2.vue'
                  ),
              },
            ],
          },
          {
            path: '/tenant/people-tracking',
            name: '動線計測',
            component: () =>
              import(
                /* webpackChunkName: "tenantpeopletracking" */ '@/views/TenantPeopleTracking.vue'
              ),
          },
        ],
      },
      {
        path: '/tenant-comparison',
        name: '店舗間比較',
        component: () =>
          import(
            /* webpackChunkName: "tenantcomparison" */ '@/views/TenantComparison.vue'
          ),
      },

      // {
      //   path: '/tenant',
      //   name: '店舗分析',
      //   component: {
      //     render() {
      //       return h(resolveComponent('router-view'))
      //     },
      //   },
      //   redirect: '/tenant/summary',
      //   children: [
      //     {
      //       path: '/tenant/summary',
      //       name: 'KPIツリー',
      //       component: () =>
      //         import(
      //           /* webpackChunkName: "tenantsummary" */ '@/views/TenantSummary.vue'
      //         ),
      //     },
      //     {
      //       path: '/tenant/people-count-pr1',
      //       name: '来客数分析',
      //       component: () =>
      //         import(
      //           /* webpackChunkName: "tenantpeoplecount" */ '@/views/TenantPeopleCountPr1.vue'
      //         ),
      //     },
      //     {
      //       path: '/tenant/people-attributes-pr1',
      //       name: '来客属性分析',
      //       component: () =>
      //         import(
      //           /* webpackChunkName: "tenantpeopleattribute" */ '@/views/TenantPeopleAttributesPr1.vue'
      //         ),
      //     },
      //     {
      //       path: '/tenant/people-tracking-pr1',
      //       name: '動線分析',
      //       component: () =>
      //         import(
      //           /* webpackChunkName: "tenantpeopletracking" */ '@/views/TenantPeopleTrackingPr1.vue'
      //         ),
      //     },
      //   ],
      // },
      // {
      //   path: '/tenant-comparison-pr1',
      //   name: '店舗間 比較分析',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "tenantcomparison" */ '@/views/TenantComparisonPr1.vue'
      //     ),
      // },
      {
        path: '/download',
        name: 'データ ダウンロード',
        component: () =>
          import(/* webpackChunkName: "download" */ '@/views/DownloadData.vue'),
      },
      {
        path: '/merchandising',
        name: '販売施策',
        component: () =>
          import(
            /* webpackChunkName: "merchandising" */ '@/views/Merchandising.vue'
          ),
      },
      {
        path: '/systemtools',
        name: 'システムツール',
        component: () =>
          import(
            /* webpackChunkName: "systemtools" */ '@/views/SystemTools.vue'
          ),
      },
      // {
      //   path: '/data-mainte',
      //   name: 'データメンテナンス',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "data-mainte" */ '@/views/DataMaintenance.vue'
      //     ),
      // },
      {
        path: '/versions',
        name: 'バージョン',
        component: () =>
          import(/* webpackChunkName: "versions" */ '@/views/Versions.vue'),
      },
      {
        path: '/settings',
        name: '設定',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/settings/tenant',
        children: [
          {
            path: '/settings/tenant',
            name: '店舗設定',
            component: () =>
              import(
                /* webpackChunkName: "settingstenant" */ '@/views/SettingTenant.vue'
              ),
          },
          // {
          //   path: '/settings/people-tracking',
          //   name: '動線分析設定',
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "settingspeopletracking" */ '@/views/PeopleTrackingParams.vue'
          //     ),
          // },
          // {
          //   path: '/settings/users',
          //   name: 'ログインユーザー管理',
          //   component: () =>
          //     import(/* webpackChunkName: "users" */ '@/views/Users.vue'),
          // },
        ],
      },

      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/theme',
        name: 'Theme',
        redirect: '/theme/typography',
      },
      {
        path: '/theme/colors',
        name: 'Colors',
        component: () => import('@/views/theme/Colors.vue'),
      },
      {
        path: '/theme/typography',
        name: 'Typography',
        component: () => import('@/views/theme/Typography.vue'),
      },
      {
        path: '/base',
        name: 'Base',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/base/breadcrumbs',
        children: [
          {
            path: '/base/accordion',
            name: 'Accordion',
            component: () => import('@/views/base/Accordion.vue'),
          },
          {
            path: '/base/breadcrumbs',
            name: 'Breadcrumbs',
            component: () => import('@/views/base/Breadcrumbs.vue'),
          },
          {
            path: '/base/cards',
            name: 'Cards',
            component: () => import('@/views/base/Cards.vue'),
          },
          {
            path: '/base/carousels',
            name: 'Carousels',
            component: () => import('@/views/base/Carousels.vue'),
          },
          {
            path: '/base/collapses',
            name: 'Collapses',
            component: () => import('@/views/base/Collapses.vue'),
          },
          {
            path: '/base/list-groups',
            name: 'List Groups',
            component: () => import('@/views/base/ListGroups.vue'),
          },
          {
            path: '/base/navs',
            name: 'Navs',
            component: () => import('@/views/base/Navs.vue'),
          },
          {
            path: '/base/paginations',
            name: 'Paginations',
            component: () => import('@/views/base/Paginations.vue'),
          },
          {
            path: '/base/placeholders',
            name: 'Placeholders',
            component: () => import('@/views/base/Placeholders.vue'),
          },
          {
            path: '/base/popovers',
            name: 'Popovers',
            component: () => import('@/views/base/Popovers.vue'),
          },
          {
            path: '/base/progress',
            name: 'Progress',
            component: () => import('@/views/base/Progress.vue'),
          },
          {
            path: '/base/spinners',
            name: 'Spinners',
            component: () => import('@/views/base/Spinners.vue'),
          },
          {
            path: '/base/tables',
            name: 'Tables',
            component: () => import('@/views/base/Tables.vue'),
          },
          {
            path: '/base/tooltips',
            name: 'Tooltips',
            component: () => import('@/views/base/Tooltips.vue'),
          },
        ],
      },
      {
        path: '/buttons',
        name: 'Buttons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/buttons/standard-buttons',
        children: [
          {
            path: '/buttons/standard-buttons',
            name: 'Buttons',
            component: () => import('@/views/buttons/Buttons.vue'),
          },
          {
            path: '/buttons/dropdowns',
            name: 'Dropdowns',
            component: () => import('@/views/buttons/Dropdowns.vue'),
          },
          {
            path: '/buttons/button-groups',
            name: 'Button Groups',
            component: () => import('@/views/buttons/ButtonGroups.vue'),
          },
        ],
      },
      {
        path: '/forms',
        name: 'Forms',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/forms/form-control',
        children: [
          {
            path: '/forms/form-control',
            name: 'Form Control',
            component: () => import('@/views/forms/FormControl.vue'),
          },
          {
            path: '/forms/select',
            name: 'Select',
            component: () => import('@/views/forms/Select.vue'),
          },
          {
            path: '/forms/checks-radios',
            name: 'Checks & Radios',
            component: () => import('@/views/forms/ChecksRadios.vue'),
          },
          {
            path: '/forms/range',
            name: 'Range',
            component: () => import('@/views/forms/Range.vue'),
          },
          {
            path: '/forms/input-group',
            name: 'Input Group',
            component: () => import('@/views/forms/InputGroup.vue'),
          },
          {
            path: '/forms/floating-labels',
            name: 'Floating Labels',
            component: () => import('@/views/forms/FloatingLabels.vue'),
          },
          {
            path: '/forms/layout',
            name: 'Layout',
            component: () => import('@/views/forms/Layout.vue'),
          },
          {
            path: '/forms/validation',
            name: 'Validation',
            component: () => import('@/views/forms/Validation.vue'),
          },
        ],
      },
      {
        path: '/charts',
        name: 'Charts',
        component: () => import('@/views/charts/Charts.vue'),
      },
      {
        path: '/icons',
        name: 'Icons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/icons/coreui-icons',
        children: [
          {
            path: '/icons/coreui-icons',
            name: 'CoreUI Icons',
            component: () => import('@/views/icons/CoreUIIcons.vue'),
          },
          {
            path: '/icons/brands',
            name: 'Brands',
            component: () => import('@/views/icons/Brands.vue'),
          },
          {
            path: '/icons/flags',
            name: 'Flags',
            component: () => import('@/views/icons/Flags.vue'),
          },
        ],
      },
      {
        path: '/notifications',
        name: 'Notifications',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/notifications/alerts',
        children: [
          {
            path: '/notifications/alerts',
            name: 'Alerts',
            component: () => import('@/views/notifications/Alerts.vue'),
          },
          {
            path: '/notifications/badges',
            name: 'Badges',
            component: () => import('@/views/notifications/Badges.vue'),
          },
          {
            path: '/notifications/modals',
            name: 'Modals',
            component: () => import('@/views/notifications/Modals.vue'),
          },
        ],
      },
      {
        path: '/widgets',
        name: 'Widgets',
        component: () => import('@/views/widgets/Widgets.vue'),
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
