import PromotionViewModel from '@/adapters/PromotionViewModel'

import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'PromotionPresenter'

const convertNewDbDataToStore = (resultData) => {
  const TAG = `${MODULE_TAG}.${convertNewDbDataToStore.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(resultData)

  const option = PromotionViewModel.newPromoEventItem(resultData)
  logger.verbose(Logger.formatter(TAG, '--- new PromoEvent ---'))
  logger.verbose(option)

  return option
}

const convertDbDataToStore = (fetchData) => {
  const TAG = `${MODULE_TAG}.${convertDbDataToStore.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(fetchData)

  let items = []

  fetchData.forEach((data) => {
    // console.log(data)
    let item = PromotionViewModel.promoEventItem(data)
    items.push(item)
  })

  return items
}

export default {
  convertNewDbDataToStore,
  convertDbDataToStore,
}
