const newAccount = () => {
  return {
    id: '',
    idaasUser: {
      sub: '',
      username: '',
      email: '',
      registered: '',
      enabled: true,
      status: '',
    },
    name: '',
    corporate: '',
    email: '',
    description: '',
    activity: '',
    contracts: [],
  }
}

const newAccountWithIDaaSparams = (
  sub,
  username,
  registered,
  name,
  description,
) => {
  return {
    id: sub,
    idaasUser: {
      sub: sub,
      username: username,
      email: '',
      registered: registered,
      enabled: true,
      status: '未同期',
    },
    name: name,
    corporate: '',
    email: '',
    description: description,
    activity: '',
    contracts: [],
  }
}

const newAccountItemWithProfile = (
  inputData,
  id,
  registered,
  enabled,
  status,
) => {
  return {
    id: id,
    idaasUser: {
      sub: inputData.idaasUser.sub,
      username: inputData.idaasUser.username,
      email: '',
      registered: registered,
      enabled: enabled,
      status: status,
    },
    name: inputData.name,
    corporate: '',
    email: inputData.email,
    description: inputData.description,
    activity: inputData.activity,
    contracts: [],
  }
}

export default {
  newAccount,
  newAccountWithIDaaSparams,
  newAccountItemWithProfile,
}
