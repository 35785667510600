import Account from '@/domain/models/Account'
import Contract from '@/domain/models/Contract'

import ServiceOptionRepository from '@/adapters/ServiceOptionRepository'
import AccountRepository from '@/adapters/AccountRepository'
import ContractRepository from '@/adapters/ContractRepository'
import IdaasRepository from '@/adapters/IdaasRepository'
import AppUserRepository from '@/adapters/AppUserRepository'

const getContractList = async (input) => {
  const response = await AccountRepository.fetchAccountList()

  const fetchDbUsers = response.dbAccounts
  const fetchUsers = response.idaasUsers

  // データベースから契約一覧を取得する
  const contractsResponse = await ContractRepository.fetchContractList({
    fetchUsers,
  })
  return {
    result: {
      fetchDbUsers: fetchDbUsers,
      fetchUsers: fetchUsers,
      contracts: contractsResponse,
    },
    error: null,
  }
}

const getOwnContract = async (input) => {
  const accountId = input.accountId

  const accountResult = await AccountRepository.fetchAccountById(accountId)
  if (!accountResult) {
    const error = 'アカウント情報の取得に失敗しました'
    return {
      result: {
        fetchAccount: null,
        contractResult: null,
      },
      error: error,
    }
  }

  const fetchAccount = accountResult.data.getAccount

  const contractResult = await ContractRepository.fetchContract(accountId)

  return {
    result: {
      fetchAccount: fetchAccount,
      contractResult: contractResult.data[0],
    },
    error: null,
  }
}

const getOwnContractByAppUserId = async (input) => {
  const appUserId = input.appUserId

  const usersResultItem = await AppUserRepository.getUserProfile({
    appUserId: appUserId,
  })
  // console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%')
  // console.log(usersResultItem)
  const contractId = usersResultItem.contractID

  const contractResult = await ContractRepository.fetchContractById(contractId)
  // console.log(contractResult)
  const accountId = contractResult.data.accountID

  const accountResult = await AccountRepository.fetchAccountById(accountId)
  if (!accountResult) {
    const error = 'アカウント情報の取得に失敗しました'
    return {
      result: {
        fetchAccount: null,
        contractResult: null,
      },
      error: error,
    }
  }

  const fetchAccount = accountResult.data.getAccount

  return {
    result: {
      fetchAccount: fetchAccount,
      contractResult: contractResult.data,
    },
    error: null,
  }
}

const createContract = async (input) => {
  const contract = input.contract

  // 契約データの雛形を作成する
  const resultDataContract = await ContractRepository.createNewContract({
    contract,
  })

  const contractId = resultDataContract.id
  const sub = contract.sub
  const username = input.username
  const registered = input.registered
  // const enabled = input.enabled
  // const status = input.status
  const newContract = Contract.newContract(
    contractId,
    sub,
    username,
    registered,
    // enabled,
    // status,
  )

  return {
    result: newContract,
    error: null,
  }
}

const editContract = async (input) => {
  const contract = input.contract

  const editDataContract = await ContractRepository.editContract({ contract })
  return {
    result: editDataContract,
    error: null,
  }
}

const deleteContractAndAccount = async (input) => {
  const contractId = input.contractId
  const accountId = input.accountId
  const username = input.username
  const keyName = input.optionKey

  // サービスオプション情報を削除する
  await ServiceOptionRepository.deleteResourceByKey(contractId, keyName)
  // 契約情報の詳細を削除する
  await ContractRepository.deleteResourceById(contractId)
  // アカウント情報の詳細を削除する
  await AccountRepository.deleteResourceById(accountId)
  // Cognito ユーザーを削除する
  const deleteResult = await IdaasRepository.deleteAccount(username)
  if (!deleteResult) {
    return {
      result: deleteResult,
      error: '不正な操作です',
    }
  }
  return {
    result: deleteResult,
    error: null,
  }
}

export default {
  getContractList,
  getOwnContract,
  getOwnContractByAppUserId,
  createContract,
  editContract,
  deleteContractAndAccount,
}
