import LocalSystem from '@/api/local-system'

import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'LocalSystemRepository'

const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time))

const _get_query = async (path, apiHost) => {
  const url = path

  const api = LocalSystem.getApiInstance(apiHost)
  const result = await api
    .get(url)
    .then(async (result) => {
      logger.verbose('=== axios.get result ===')
      logger.verbose(result)
      // console.log(result)

      return {
        response: result.data,
        error: null,
      }
    })
    .catch(function (error) {
      // handle error
      logger.error('=== axios.get error ===')
      logger.error(error)
      // console.log(error)

      return {
        response: [],
        error: error,
      }
    })
    .finally(function () {
      // always executed
    })

  return result
}

const _post_query = async (path, apiHost, payload) => {
  const url = path

  const api = LocalSystem.getApiInstance(apiHost)
  const result = await api
    .post(url, payload)
    .then(async (result) => {
      logger.verbose('=== axios.post result ===')
      logger.verbose(result)
      // console.log(result)

      return {
        response: result.data,
        error: null,
      }
    })
    .catch(function (error) {
      // handle error
      logger.error('=== axios.post error ===')
      logger.error(error)
      // console.log(error)

      return {
        response: [],
        error: error,
      }
    })
    .finally(function () {
      // always executed
    })

  return result
}

const _architecture = {
  attrDevices: [
    {
      id: 1,
      host: '192.168.100.215',
      count_line: {
        id: 1,
        tof_id: 1,
        camera_id: 1,
      },
    },
    {
      id: 2,
      host: '192.168.100.215',
      count_line: {
        id: 2,
        tof_id: 2,
        camera_id: 2,
      },
    },
    {
      id: 3,
      host: '192.168.100.215',
      count_line: {
        id: 3,
        tof_id: 3,
        camera_id: 3,
      },
    },
    {
      id: 4,
      host: '192.168.100.215',
      count_line: {
        id: 4,
        tof_id: 4,
        camera_id: 4,
      },
    },
  ],
}

const fetchArchitecture = async () => {
  const apiHost = localStorage.getItem('apihost')
  // console.log(apiHost)
  const result = await _get_query('/api/v1/architecture', apiHost)
  // console.log(result)
  return {
    response: result.response,
    error: result.error,
  }
  // const result = {
  //   response: _architecture,
  //   error: null,
  // }
}

const fetchHealthCheckStatus = async () => {
  const apiHost = localStorage.getItem('apihost')
  const result = await _get_query('/api/v1/health-check', apiHost)
  // console.log(result)
  return {
    response: result.response,
    error: result.error,
  }
}

const triggerCalculation = async ({ start, startTime, end, endTime }) => {
  const apiHost = localStorage.getItem('apihost')
  const payload = { begin: start + ' ' + startTime, end: end + ' ' + endTime }

  const result = await _post_query('/api/v1/run-recal/hourly', apiHost, payload)
  // console.log(result)
  return {
    response: result.response,
    error: result.error,
  }
}

const fetchJobList = async () => {
  const apiHost = localStorage.getItem('apihost')
  const result = await _get_query('/api/v1/jobs', apiHost)
  // console.log(result)
  return {
    response: result.response,
    error: result.error,
  }
}

const demoResult = {
  images: [
    {
      name: 'local-web-api-python-39',
      name_jp: 'Web API サーバー',
      version: 'v0.4.1',
      installed: 'v0.4.0',
      hash: '9e25df2136f4',
      date: '2024-04-23T19:48:35+09:00',
      size: '482MB',
      latest: 'v0.4.1',
    },
    {
      name: 'apache-airflow2-standard',
      name_jp: '店舗分析データ集計ジョブ管理サーバー',
      version: 'v0.8.0',
      installed: 'v0.8.0',
      hash: '791de5df5456',
      date: '2024-04-23T17:33:12+09:00',
      size: '1.2GB',
      latest: 'v0.8.1',
    },
    {
      name: 'local-web-ui-nginx-alpine',
      name_jp: '施設用 Web アプリ',
      version: 'v0.5.0',
      installed: 'v0.5.0',
      hash: 'a4339bce4300',
      date: '2024-04-17T17:47:02+09:00',
      size: '67.4MB',
      latest: 'v0.5.0',
    },
    {
      name: 'local-web-ci-jenkins-lts-jdk11',
      name_jp: 'ユーザーイベントジョブ管理サーバー',
      version: 'v0.3.0',
      installed: 'v0.3.0',
      hash: '4b168e392327',
      date: '2024-04-17T16:15:39+09:00',
      size: '1.03GB',
      latest: 'v0.3.0',
    },
    {
      name: 'job-people-count-python-38',
      name_jp: '店舗分析データ集計ライブラリ',
      version: 'v0.3.2',
      installed: 'v0.3.2',
      hash: '340721395f9d',
      date: '2024-03-18T11:45:08+09:00',
      size: '4.02GB',
      latest: 'v0.3.2',
    },
    {
      name: 'ros-noetic-cmd-standard',
      name_jp: '計測ネットワーク コマンドサーバー',
      version: 'v0.3.0',
      installed: 'v0.3.0',
      hash: '281efe99756e',
      date: '2023-08-17T20:08:25+09:00',
      size: '1.31GB',
      latest: 'v0.3.0',
    },
    {
      name: 'ros-melodic-attr-estimator-standard',
      name_jp: '人物属性推定サーバー',
      version: 'sdk6.11.0-v0.3.1',
      installed: 'sdk6.11.0-v0.3.1',
      hash: '69a5376886c0',
      date: '2023-06-03T18:25:18+09:00',
      size: '4.98GB',
      latest: 'sdk6.11.0-v0.3.1',
    },
    {
      name: 'bionic-cvengine-library',
      name_jp: '人物属性推定エンジンライブラリ',
      version: 'sdk6.11.0-v0.3.1',
      installed: 'sdk6.11.0-v0.3.1',
      hash: 'c1a886813a96',
      date: '2023-06-03T18:19:52+09:00',
      size: '2.04GB',
      latest: 'sdk6.11.0-v0.3.1',
    },
    {
      name: 'ros-melodic-data-logger-standard',
      name_jp: '人物属性データロガー',
      version: 'v0.4.0',
      installed: 'v0.4.0',
      hash: '18ac842d9caa',
      date: '2023-06-03T13:40:15+09:00',
      size: '4.17GB',
      latest: 'v0.4.0',
    },
    {
      name: 'elk-logstash7-standard',
      name_jp: '計測データ登録サーバー',
      version: 'v0.3.0',
      installed: 'v0.3.0',
      hash: '89943cbaa958',
      date: '2023-06-03T11:48:25+09:00',
      size: '1.01GB',
      latest: 'v0.3.0',
    },
    {
      name: 'elk-kibana7-standard',
      name_jp: 'データレイク ダッシュボード',
      version: 'v0.2.0',
      installed: 'v0.2.0',
      hash: '32c459623468',
      date: '2023-06-03T11:19:44+09:00',
      size: '674MB',
      latest: 'v0.2.0',
    },
    {
      name: 'jupyter-datascience-python-38',
      name_jp: '',
      version: 'v0.0.2',
      installed: 'unknown',
      hash: '5a1e103d062f',
      date: '2022-10-21T13:16:30+09:00',
      size: '4.02GB',
      latest: 'unknown',
    },
    {
      name: 'elk-elasticsearch7-standard',
      name_jp: 'データレイクサーバー',
      version: 'v0.1.0',
      installed: 'v0.1.0',
      hash: 'ca7c5c8d4a90',
      date: '2022-09-30T15:44:43+09:00',
      size: '716MB',
      latest: 'v0.1.0',
    },
    {
      name: 'ros-rtsp-stream-standard',
      name_jp: 'ネットワークカメラ映像受信サーバー',
      version: 'v0.2.2',
      installed: 'v0.2.2',
      hash: 'e5771abceb4c',
      date: '2022-09-21T16:17:53+09:00',
      size: '1.81GB',
      latest: 'v0.2.2',
    },
    {
      name: 'ros-noetic-master-standard',
      name_jp: '計測ネットワーク ネームサーバー',
      version: 'v0.2.0',
      installed: 'v0.2.0',
      hash: '1f56a158a649',
      date: '2022-09-14T17:34:56+09:00',
      size: '1.66GB',
      latest: 'v0.2.0',
    },
    {
      name: 'attr-judge-server',
      name_jp: '',
      version: '0.3.1',
      installed: 'unknown',
      hash: '51fea81089b2',
      date: '2021-12-21T15:51:18+09:00',
      size: '13.2GB',
      latest: 'unknown',
    },
    {
      name: 'elk-logstash-v7tof',
      name_jp: '',
      version: 'v0.1.0',
      installed: 'unknown',
      hash: '17d17012ae49',
      date: '2021-12-07T17:03:11+09:00',
      size: '954MB',
      latest: 'unknown',
    },
    {
      name: 'elk-elasticsearch-v7tof',
      name_jp: '',
      version: 'v0.1.0',
      installed: 'unknown',
      hash: '96aae7043a4c',
      date: '2021-12-03T13:57:31+09:00',
      size: '664MB',
      latest: 'unknown',
    },
    {
      name: 'ros-melodic-web-standard',
      name_jp: 'ネットワークカメラ映像配信サーバー',
      version: 'v0.2.0',
      installed: 'v0.2.0',
      hash: '953bbe94ef80',
      date: '2021-11-02T13:25:09+09:00',
      size: '1.9GB',
      latest: 'v0.2.0',
    },
    {
      name: 'ros-tcp-socket-standard',
      name_jp: '',
      version: 'v0.2.1',
      installed: 'unknown',
      hash: '03e3ba1b0ba6',
      date: '2021-11-01T13:13:13+09:00',
      size: '18.7MB',
      latest: 'unknown',
    },
    {
      name: 'ros-melodic-desktop',
      name_jp: '計測ネットワーク デスクトップサーバー',
      version: 'v0.1.0',
      installed: 'v0.1.0',
      hash: 'cf7ae48cd6f6',
      date: '2021-06-18T17:28:55+09:00',
      size: '4.35GB',
      latest: 'v0.1.0',
    },
    {
      name: 'elk-filebeat7-standard',
      name_jp: '計測データ収集サーバー',
      version: 'v0.1.0',
      installed: 'v0.1.0',
      hash: '1abcbc6f1e25',
      date: '2021-01-13T07:13:39+09:00',
      size: '381MB',
      latest: 'v0.1.0',
    },
  ],
}

const fetchImageVersionList = async () => {
  const apiHost = localStorage.getItem('apihost')
  const result = await _get_query('/api/v1/download-images', apiHost)
  // console.log(result)

  // for Debug
  // const result = {
  //   response: demoResult,
  //   error: null,
  // }

  return {
    response: result.response,
    error: result.error,
  }
}

const triggerImageDownload = async () => {
  const apiHost = localStorage.getItem('apihost')
  const payload = {}

  const result = await _post_query(
    '/api/v1/run-download-images',
    apiHost,
    payload,
  )
  // console.log(result)
  return {
    response: result.response,
    error: result.error,
  }
}

const fetchDownloadProcessStatus = async () => {
  const apiHost = localStorage.getItem('apihost')
  const result = await _get_query('/api/v1/download-status', apiHost)
  // console.log(result)

  // for Debug
  // const result = {
  //   response: { status: 'none' },
  //   // response: { status: 'exist' },
  //   error: null,
  // }

  return {
    response: result.response,
    error: result.error,
  }
}

export default {
  fetchArchitecture,
  fetchHealthCheckStatus,
  triggerCalculation,
  fetchJobList,
  fetchImageVersionList,
  triggerImageDownload,
  fetchDownloadProcessStatus,
}
