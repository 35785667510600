import IotDevice from '@/domain/models/IotDevice'

import DeviceRepository from '@/adapters/DeviceRepository'

const getIotDevicesList = async (input) => {
  const contractId = input.contractId

  // デバイス一覧の取得
  const response = await DeviceRepository.fetchDevicesList(contractId)

  return {
    result: response.data,
    error: null,
  }
}

const createIotDevice = async (input) => {
  const contractId = input.contractId
  // デバイスプロファイルを作成する
  const deviceProfile = {
    deviceName: input.deviceName,
    storagePath: input.storagePath,
    host: input.hostName,
    os: input.os,
  }
  // デバイスの登録
  const response = await DeviceRepository.createNewDevice({
    contractId,
    deviceProfile,
  })
  if (response.error) {
    return {
      result: null,
      error: new Error('デバイスの登録に失敗しました'),
    }
  }

  return {
    result: response.data,
    error: null,
  }
}

const editIotDeviceProfile = async (input) => {
  const contractId = input.contractId
  const deviceProfile = {
    id: input.id,
    deviceName: input.deviceName,
    storagePath: input.storagePath,
    host: input.hostName,
    os: input.os,
    category: input.category,
    location: input.location,
    description: input.description,
  }

  // デバイスデータの更新
  const response = await DeviceRepository.updateDeviceProfile({
    contractId,
    deviceProfile,
  })

  if (!response) {
    return {
      result: null,
      error: new Error('デバイスの更新に失敗しました'),
    }
  }

  return {
    result: response.data,
    error: null,
  }
}

const updateIotDeviceActivity = async (input) => {}

const deleteIotDevice = async (input) => {
  const deviceName = input.deviceName
  const deleteId = input.deleteId

  // デバイスの削除
  const response = await DeviceRepository.deleteResourceById(deleteId)

  if (!response) {
    return {
      result: null,
      error: new Error('デバイスの登録解除に失敗しました'),
    }
  }

  return {
    result: response.data,
    error: null,
  }
}

export default {
  getIotDevicesList,
  createIotDevice,
  editIotDeviceProfile,
  updateIotDeviceActivity,
  deleteIotDevice,
}
