import ubuntu from '@/assets/images/devices/ubuntu.png'

import DateUtil from '@/utils/DateUtil'

const newDeviceItemWithProfile = (inputData) => {
  let avatar_url = ubuntu
  if (inputData.os === 'ubuntu') {
    avatar_url = ubuntu
  }
  // else if (os === 'RaspberryPiOS') {
  // }

  return {
    id: inputData.id,
    avatar: { url: avatar_url },
    host: inputData.host,
    thing: {
      thingName: inputData.thing.thingName,
      new: true,
      registered: '',
      status: 'secondary', // success danger warning secondary
    },
    description: '',
    category: '',
    location: '',
    storagePath: inputData.storagePath,
    os: inputData.os,
    topic: '',
    clientCertFile: '',
    period: {
      start: '',
      end: '',
    },
    usage: { rate: 0, mount: '/', period: ' - ' },
    usage2: { rate: 0, mount: '', period: '' },
    activity: '',
  }
}

const deviceItem = (inputData) => {
  let avatar_url = ubuntu
  if (inputData.os === 'ubuntu') {
    avatar_url = ubuntu
  }
  // else if (os === 'RaspberryPiOS') {
  // }
  const date = new Date(inputData.createdAt)
  const registered = DateUtil.formatDate(date, 'YYYY-MM-DD')

  let item = inputData
  item['avatar'] = { url: avatar_url }
  item.thing['registered'] = registered

  return item
}

export default {
  newDeviceItemWithProfile,
  deviceItem,
}
