// import DateUtil from '@/utils/DateUtil'

const newAttrDeviceItem = (
  id,
  host,
  status,
  line_id,
  tof_id,
  tof_status,
  camera_id,
) => {
  return {
    id: id,
    host: host,
    status: status,
    count_line: {
      id: line_id,
      tof_id: tof_id,
      tof_status: tof_status,
      camera_id: camera_id,
    },
  }
}

const systemToolItem = (arch, devices) => {
  // console.log(devices)
  const items = {
    attrDevices: [],
  }

  arch.attr_devices.forEach((device, idx) => {
    const id = device.id
    const host = device.host
    // const status = 0
    let status = 0
    if (devices) {
      status = devices.attributes[idx].status
      if (status == 0) {
        status = 1
      }
    }
    const line_id = device.count_line.id
    const tof_id = device.count_line.tof_id
    let tof_status = 0
    if (devices) {
      tof_status = devices.tofs[idx].status
      if (tof_status == 0) {
        tof_status = 1
      }
    }
    const camera_id = device.count_line.camera_id

    const deviceItem = newAttrDeviceItem(
      id,
      host,
      status,
      line_id,
      tof_id,
      tof_status,
      camera_id,
    )
    items.attrDevices.push(deviceItem)
  })

  // console.log(items)
  return items
}
// {
//   attrDevices: [
//     {
//       id: 1,
//       host: '192.168.100.215',
//       count_line: {
//         id: 1,
//         tof_id: 1,
//         camera_id: 1,
//       },
//       name: 'IPカメラ（カウントライン1）',
//     },
//     {
//       id: 2,
//       host: '192.168.100.215',
//       count_line: {
//         id: 2,
//         tof_id: 2,
//         camera_id: 2,
//       },
//       name: 'IPカメラ（カウントライン2）',
//     },
//     {
//       id: 3,
//       host: '192.168.100.215',
//       count_line: {
//         id: 3,
//         tof_id: 3,
//         camera_id: 3,
//       },
//       name: 'IPカメラ（カウントライン3）',
//     },
//     {
//       id: 4,
//       host: '192.168.100.215',
//       count_line: {
//         id: 4,
//         tof_id: 4,
//         camera_id: 4,
//       },
//       name: 'IPカメラ（カウントライン4）',
//     },
//   ],
// }

const newJobItem = (id, date, status) => {
  return {
    id: id,
    date: date,
    status: status,
  }
}

const newVersionItem = (
  id,
  name,
  installed,
  version,
  latest,
  date,
  size,
  hash,
) => {
  return {
    id: id,
    name: name,
    installed: installed,
    version: version,
    latest: latest,
    date: date,
    size: size,
    hash: hash,
  }
}

export default {
  newAttrDeviceItem,
  systemToolItem,
  newJobItem,
  newVersionItem,
}
