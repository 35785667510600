import moment from 'moment'
import holiday_jp from '@holiday-jp/holiday_jp'

const formatDate = (date, format) => {
  format = format.replace(/YYYY/g, date.getFullYear())
  format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2))
  format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2))
  format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2))
  format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2))
  format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2))
  format = format.replace(/SSS/g, ('00' + date.getMilliseconds()).slice(-3))
  return format
}

const systemBeginDate = (contract) => {
  if (contract) {
    return contract.usage.start
  }
  // return ''
  return '2023/06/05'
  // return '2022/01/01'
}

const nowDateString = () => {
  const isoDate = new Date().toISOString()
  const now = moment(isoDate).format('YYYY/MM/DD')
  return now
  // return addDateString(now, 1)
}

const dateString = (date, separator = '-') => {
  const isoDate = new Date(date).toISOString()
  return moment(isoDate).format('YYYY' + separator + 'MM' + separator + 'DD')
}

const justDayString = (date, separator = '-') => {
  const isoDate = new Date(date).toISOString()
  return moment(isoDate).format('DD')
}

const nowBeginOfWeekDateString = () => {
  const isoDate = new Date().toISOString()
  let dayOfWeek = Number(moment(isoDate).format('d'))
  if (dayOfWeek == 0) {
    dayOfWeek = 6
  } else {
    dayOfWeek -= 1
  }
  return moment(isoDate).add(-dayOfWeek, 'd').format('YYYY/MM/DD')
}

const beginOfWeekDateFromString = (date) => {
  let isoDate = new Date().toISOString()
  if (date) {
    isoDate = new Date(date).toISOString()
  }
  let dayOfWeek = Number(moment(isoDate).format('d'))
  if (dayOfWeek == 0) {
    dayOfWeek = 6
  } else {
    dayOfWeek -= 1
  }
  return moment(isoDate).add(-dayOfWeek, 'd').format('YYYY/MM/DD')
}

const addDateStringFormat0ByHour = (date, value) => {
  let isoDate = new Date().toISOString()
  if (date) {
    isoDate = new Date(date).toISOString()
  }
  return moment(isoDate).add(value, 'h').format('YYYY-MM-DD hh:00:00')
}

const addDateString = (date, value) => {
  let isoDate = new Date().toISOString()
  if (date) {
    isoDate = new Date(date).toISOString()
  }
  return moment(isoDate).add(value, 'd').format('YYYY/MM/DD')
}

const addDateStringByMonth = (date, value) => {
  let isoDate = new Date().toISOString()
  if (date) {
    isoDate = new Date(date).toISOString()
  }
  return moment(isoDate).add(value, 'months').format('YYYY/MM/DD')
}

const beginOfMonthFromString = (date) => {
  let isoDate = new Date().toISOString()
  if (date) {
    isoDate = new Date(date).toISOString()
  } else {
    return '-/-/01'
  }
  return moment(isoDate).format('YYYY/MM/01')
}

const endOfMonthFromString = (date) => {
  let isoDate = new Date().toISOString()
  if (date) {
    isoDate = new Date(date).toISOString()
  } else {
    return '-/-/-'
  }
  return moment(isoDate).endOf('month').format('YYYY/MM/DD')
}

const jpFormatDateFromString = (date) => {
  let isoDate = new Date().toISOString()
  if (date) {
    isoDate = new Date(date).toISOString()
  } else {
    return '-年-月-日'
  }
  return moment(isoDate).format('YYYY年M月D日')
}

const jpYearMonthFromString = (date) => {
  moment.locale(navigator.language)
  let isoDate = new Date().toISOString()
  if (date) {
    isoDate = new Date(date).toISOString()
  } else {
    return '-年-月'
  }
  return moment(isoDate).format('YYYY年M月')
}

const jpJustMonthFromString = (date) => {
  moment.locale(navigator.language)
  let isoDate = new Date().toISOString()
  if (date) {
    isoDate = new Date(date).toISOString()
  } else {
    return '-月'
  }
  return moment(isoDate).format('M月')
}

const jpJustDateFromString = (date) => {
  moment.locale(navigator.language)
  let isoDate = new Date().toISOString()
  if (date) {
    isoDate = new Date(date).toISOString()
  } else {
    return '-月-日'
  }
  return moment(isoDate).format('M月D日')
}
const jpShortDateFromString = (date) => {
  moment.locale(navigator.language)
  let isoDate = new Date().toISOString()
  if (date) {
    isoDate = new Date(date).toISOString()
  } else {
    return '-月-日(-)'
  }
  return moment(isoDate).format('M月D日(dd)')
}
const jpShortDateWithHourFromString = (date) => {
  moment.locale(navigator.language)
  let isoDate = new Date().toISOString()
  if (date) {
    isoDate = new Date(date).toISOString()
  } else {
    return '-月-日(-) -時'
  }
  return moment(isoDate).format('M月D日(dd) HH時')
}
const jpShortMonthFromString = (date) => {
  moment.locale(navigator.language)
  let isoDate = new Date().toISOString()
  if (date) {
    isoDate = new Date(date).toISOString()
  } else {
    return '-月'
  }
  return moment(isoDate).format('M月')
}

const yearNumberFromString = (date) => {
  moment.locale(navigator.language)
  let isoDate = new Date().toISOString()
  if (date) {
    isoDate = new Date(date).toISOString()
  } else {
    return 0
  }
  return Number(moment(isoDate).format('Y'))
}

const monthNumberFromString = (date) => {
  moment.locale(navigator.language)
  let isoDate = new Date().toISOString()
  if (date) {
    isoDate = new Date(date).toISOString()
  } else {
    return 0
  }
  return Number(moment(isoDate).format('M'))
}

const hourNumberFromString = (date) => {
  let isoDate = new Date().toISOString()
  if (date) {
    isoDate = new Date(date).toISOString()
  }
  return Number(moment(isoDate).format('HH'))
}

const weeklyNumberFromString = (date) => {
  let isoDate = new Date().toISOString()
  if (date) {
    isoDate = new Date(date).toISOString()
  }
  return Number(moment(isoDate).format('d'))
}

const weeklyString = (date) => {
  let isoDate = new Date().toISOString()
  if (date) {
    isoDate = new Date(date).toISOString()
  }
  return moment(isoDate).format('dd')
}

// 休日判定
const isHoliday = (date) => {
  let flag = false
  // console.log(date)
  if (date) {
    const isoDate = new Date(date).toISOString()
    const dateString = moment(isoDate).format('YYYY-MM-DD')
    const dd = Number(moment(isoDate).format('d'))
    if (holiday_jp.isHoliday(new Date(dateString))) {
      flag = true // 祝日
    } else if (dd == 0 || dd == 6) {
      flag = true // 土日
    }
    // console.log(dateString, flag)
  }
  return flag
}

const getDayOfWeek = (date) => {
  if (date) {
    const isoDate = new Date(date).toISOString()
    return moment(isoDate).format('dd')
  } else {
    return ''
  }
}

const getDateCount = (date) => {
  const isoDate = new Date(date).toISOString()
  const year = new Number(moment(isoDate).format('YYYY'))
  const month = new Number(moment(isoDate).format('M'))
  return new Date(year, month, 0).getDate()
}

const getMonthsArray = (start, end) => {
  const result = []

  const dateStart = moment(new Date(start).toISOString())
  const dateEnd = moment(new Date(end).toISOString())
  const diff = dateEnd.diff(dateStart, 'months')
  for (let lc = 0; lc <= diff; lc++) {
    const date = moment(dateStart).add(lc, 'months').format('YYYY/MM/DD')
    result.push(date)
  }
  return result
}

const getDatesArray = (start, end) => {
  const result = []

  const dateStart = moment(new Date(start).toISOString())
  const dateEnd = moment(new Date(end).toISOString())
  const diff = dateEnd.diff(dateStart, 'days')
  for (let lc = 0; lc <= diff; lc++) {
    const date = moment(dateStart).add(lc, 'd').format('YYYY/MM/DD')
    result.push(date)
  }
  return result
}

const getHoursArray = (start, end) => {
  const result = []

  const dateStart = moment(new Date(start).toISOString())
  const dateEnd = moment(new Date(end).toISOString())
  const diff = dateEnd.diff(dateStart, 'hours')
  for (let lc = 0; lc <= diff + 23; lc++) {
    const date = moment(dateStart).add(lc, 'h').format('YYYY/MM/DD HH:00:00')
    result.push(date)
  }
  return result
}

const isWithinDate = (date, start, end) => {
  const dateIso = moment(new Date(date).toISOString())
  const dateStart = moment(new Date(start).toISOString())
  const dateEnd = moment(new Date(end).toISOString())
  // console.log(dateIso)
  // console.log(dateStart)
  // console.log(dateEnd)

  const diff1 = dateIso.diff(dateStart, 'hours')
  const diff2 = dateEnd.diff(dateIso, 'hours')
  // console.log(diff1)
  // console.log(diff2)

  if (-14 <= diff1 && -14 - 9 <= diff2) {
    return true
  } else {
    return false
  }
}

const isWithinJustDate = (date, start, end) => {
  const dateIso = moment(new Date(date).toISOString())
  const dateStart = moment(new Date(start).toISOString())
  const dateEnd = moment(new Date(end).toISOString())
  // console.log(dateIso)
  // console.log(dateStart)
  // console.log(dateEnd)

  const diff1 = dateIso.diff(dateStart, 'hours')
  const diff2 = dateEnd.diff(dateIso, 'hours')
  // console.log(diff1)
  // console.log(diff2)

  if (-9 <= diff1 && -9 <= diff2) {
    return true
  } else {
    return false
  }
}

const ltDateSpan = (span, start, end) => {
  const dateStart = moment(new Date(start).toISOString())
  const dateEnd = moment(new Date(end).toISOString())
  // console.log(dateStart)
  // console.log(dateEnd)

  const diff = dateEnd.diff(dateStart, 'hours')
  // console.log(diff + 24)

  if (0 <= diff && 0 <= diff + 24 && diff + 24 <= span * 24) {
    return true
  } else {
    return false
  }
}

const getPeriod = (start, end) => {
  const dateStart = moment(new Date(start).toISOString())
  const dateEnd = moment(new Date(end).toISOString())

  const diff = dateStart.diff(dateEnd, 'days')
  return diff
}

const getHours = (hour) => {
  const date = new Date('2023-01-01 ' + hour)

  return date.getHours()
}

const getCloseHours = (open, close) => {
  const openHour = new Date('2023-01-01 ' + open).getHours()
  const closeHour = new Date('2023-01-01 ' + close).getHours()
  const closeMinuites = new Date('2023-01-01 ' + close).getMinutes()

  if (openHour < closeHour) {
    if (0 == closeMinuites) {
      return closeHour - 1
    } else {
      return closeHour
    }
  } else {
    if (0 == closeMinuites) {
      return 24 + closeHour - 1
    } else {
      return 24 + closeHour
    }
  }
}

export default {
  formatDate,
  systemBeginDate,
  nowDateString,
  dateString,
  justDayString,
  nowBeginOfWeekDateString,
  beginOfWeekDateFromString,
  addDateStringFormat0ByHour,
  addDateString,
  addDateStringByMonth,
  beginOfMonthFromString,
  endOfMonthFromString,
  jpFormatDateFromString,
  jpYearMonthFromString,
  jpJustMonthFromString,
  jpJustDateFromString,
  jpShortDateFromString,
  jpShortDateWithHourFromString,
  jpShortMonthFromString,
  yearNumberFromString,
  monthNumberFromString,
  hourNumberFromString,
  weeklyNumberFromString,
  weeklyString,
  isHoliday,
  getDayOfWeek,
  getDateCount,
  getMonthsArray,
  getDatesArray,
  getHoursArray,
  isWithinDate,
  isWithinJustDate,
  ltDateSpan,
  getPeriod,
  getHours,
  getCloseHours,
}
