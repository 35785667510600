const newContractItem = () => {
  const sub = ''
  return {
    id: '',
    accountID: sub,
    account: {
      sub: sub,
      username: '',
      new: true,
      registered: '',
      enabled: false,
      status: '未同期',
      activity: '',
    },
    owner: '',
    corporate: '',
    email: '',
    description: '',
    usage: {
      rate: 0,
      start: '',
      end: '',
      // period: '',
    },
  }
}

export default {
  newContractItem,
}
