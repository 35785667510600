import ServiceOptionViewModel from '@/adapters/ServiceOptionViewModel'

import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'ServiceOptionPresenter'

const convertNewDbDataToStore = (inputData, optionName, description) => {
  const TAG = `${MODULE_TAG}.${convertNewDbDataToStore.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(inputData)

  const option = ServiceOptionViewModel.newOptionItemWithProfile(
    inputData.id,
    inputData.key,
    inputData.value,
    optionName,
    description,
  )
  logger.verbose(Logger.formatter(TAG, '--- new Option ---'))
  logger.verbose(option)

  return option
}

const convertDbDataToStore = (fetchData) => {
  const TAG = `${MODULE_TAG}.${convertDbDataToStore.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(fetchData)

  let items = []

  fetchData.forEach((data) => {
    // console.log(data)
    let item = ServiceOptionViewModel.optionItem(data)
    items.push(item)
  })

  return items
}

export default {
  convertNewDbDataToStore,
  convertDbDataToStore,
}
