import AccountPresenter from '@/adapters/AccountPresenter'
import ContractPresenter from '@/adapters/ContractPresenter'
import AppUserPresenter from '@/adapters/AppUserPresenter'

import CalcUtil from '@/utils/CalcUtil'

import Logger from '@/utils/Logger'
const logger = Logger.getLocalInstance()

const MODULE_TAG = 'StoreRepository'

const addAcccount = (context, { accountItem }) => {
  // ストアに保存する
  context.commit('setAccountItem', {
    item: accountItem,
    index: -1,
  })
}

const updateAccountItems = (context, { fetchDbUsers, fetchUsers }) => {
  const TAG = `${MODULE_TAG}.${updateAccountItems.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))
  logger.verbose(fetchUsers)

  let items = context.state.accountItems
  fetchDbUsers.forEach((dbItem) => {
    let accountIndex = -1
    let idaasUserIndex = -1

    items.forEach((account, index) => {
      if (account.id == dbItem.idaasUser.sub) {
        accountIndex = index
      }
    })

    fetchUsers.forEach((user, index) => {
      if (user.Attributes[0].Value == dbItem.idaasUser.sub) {
        idaasUserIndex = index
      }
    })

    logger.verbose(Logger.formatter(TAG, 'idaasUserIndex: ', idaasUserIndex))

    if (0 <= idaasUserIndex) {
      const fetchData = dbItem
      const enable = fetchUsers[idaasUserIndex].Enabled
      const status = fetchUsers[idaasUserIndex].UserStatus
      const userItem = AccountPresenter.convertDbToStore(
        fetchData,
        enable,
        status,
      )
      if (0 <= accountIndex) {
        items[accountIndex] = userItem
      } else {
        items.push(userItem)
      }
    }
  })
  // ストアのデータ更新
  context.commit('setAccountItems', items)
}

const deleteAccount = (context, { username }) => {
  const TAG = `${MODULE_TAG}.${deleteAccount.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  let contractItems = context.state.contractItems
  let index = -1
  contractItems.find((contract, idx) => {
    if (contract.account.username == username) {
      index = idx
      return true
    } else {
      return false
    }
  })
  if (0 <= index) {
    // ストアの要素の削除
    contractItems.splice(index, 1)
  }
  context.commit('setContractItems', contractItems)
}

const updateContractItems = (context, { fetchUsers, contractsResult }) => {
  const TAG = `${MODULE_TAG}.${updateContractItems.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  let contractItems = context.state.contractItems
  logger.verbose(Logger.formatter(TAG, `=== store contractItems ===`))
  logger.verbose(contractItems)

  const accountItems = context.state.accountItems
  logger.verbose(Logger.formatter(TAG, `=== store accountItems ===`))
  logger.verbose(accountItems)

  contractsResult.data.listContracts.items.forEach((fetchItem) => {
    let matchIndex = -1
    contractItems.forEach(function (contract, index) {
      logger.verbose(fetchItem)
      if (fetchItem.id == contract.id) {
        matchIndex = index
      }
    })
    let matchUserIndex = -1
    fetchUsers.forEach(function (user, index) {
      if (fetchItem.accountID == user.Attributes[0].Value) {
        matchUserIndex = index
      }
    })
    const user = fetchUsers[matchUserIndex]

    logger.verbose(Logger.formatter(TAG, 'matchIndex = ' + matchIndex))
    logger.verbose(Logger.formatter(TAG, 'matchUserIndex = ' + matchUserIndex))
    logger.verbose(accountItems)

    accountItems.forEach((accountItem) => {
      logger.verbose(Logger.formatter(TAG, 'accountItem.id ' + accountItem.id))
      logger.verbose(
        Logger.formatter(TAG, 'fetchItem.accountID ' + fetchItem.accountID),
      )
      if (accountItem.id == fetchItem.accountID) {
        logger.verbose(
          Logger.formatter(
            TAG,
            '=== match account id: ' + accountItem.id + ' ===',
          ),
        )

        const fetchContractData = fetchItem
        const idaasUser = accountItem.idaasUser
        const name = accountItem.name
        const enabled = user.Enabled
        const status = user.UserStatus
        const activity = accountItem.activity
        const fetchAccountData = {
          idaasUser,
          name,
          activity,
        }
        const item = ContractPresenter.mergeDbToStore(
          fetchAccountData,
          fetchContractData,
          enabled,
          status,
        )
        if (0 <= matchIndex) {
          // データ上書き
          contractItems[matchIndex] = item
        } else {
          // データ追加
          contractItems.push(item)
        }
      }
    })
  })

  context.commit('setContractItems', contractItems)
}

const updateContract = (context, { contractItem }) => {
  const TAG = `${MODULE_TAG}.${updateContract.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const accountId = contractItem.account.sub
  const item = contractItem
  let index = -1
  context.state.contractItems.find((cont, idx) => {
    if (accountId == cont.account.sub) {
      index = idx
      return true
    } else {
      return false
    }
  })

  // 自身の契約情報をストアに保存する
  context.commit('setContract', item)

  // 契約一覧のストアにも保存しておく
  context.commit('setContractItem', { item, index })
}

const addAppUser = (context, { appUser }) => {
  const TAG = `${MODULE_TAG}.${addAppUser.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const item = appUser
  const index = -1
  // ストアのデータ更新
  context.commit('setUserItem', { item, index })
}

const updateAppUser = (context, { appUser, index }) => {
  const TAG = `${MODULE_TAG}.${updateAppUser.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const item = appUser
  // ストアのデータ更新
  context.commit('setUserItem', { item, index })
}

const updateAppUserItems = (context, { contractId, fetchUserItems }) => {
  const TAG = `${MODULE_TAG}.${updateAppUserItems.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  let items = context.state.userItems
  logger.verbose(Logger.formatter(TAG, `store userItems:`))
  logger.verbose(items)

  fetchUserItems.forEach((fetchItem) => {
    if (fetchItem.contractID == contractId) {
      let already = false
      items.forEach((item) => {
        if (item.user.username == fetchItem.idaasUser.username) {
          already = true
        }
      })
      if (already == false) {
        const fetchData = fetchItem
        const userItem = AppUserPresenter.convertDbToStore(fetchData)
        items.push(userItem)
      }
    }
  })

  // ストアのデータ更新
  context.commit('setUserItems', items)
}

const deleteAppUser = (context, { username }) => {
  const TAG = `${MODULE_TAG}.${deleteAppUser.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  let userItems = context.state.userItems

  let index = -1
  userItems.find((contract, idx) => {
    if (contract.user.username == username) {
      index = idx
      return true
    } else {
      return false
    }
  })
  if (0 <= index) {
    userItems.splice(index, 1)
  }

  const items = userItems
  context.commit('setUserItems', items)
}

const addOptionItems = (context, { newItem }) => {
  const TAG = `${MODULE_TAG}.${addOptionItems.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const item = newItem
  const index = -1
  // ストアのデータ更新
  context.commit('setOptionItem', { item, index })
}

const setOptionItems = (context, { contractId, options }) => {
  const TAG = `${MODULE_TAG}.${setOptionItems.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  let optionItems = options

  optionItems.find((item, idx) => {
    if (item.key == 'max_login_users') {
      item.usage.count = context.state.userItems.length
    }
    // else if (item.key == 'alert_topic_num') {
    //   item.usage.count = context.state.alertTopicItems.length
    // }
    const count = item.usage.count
    const limit = item.limit
    const rate = CalcUtil.rate(count, limit)
    item.usage.rate = rate
  })

  const items = optionItems
  context.commit('setOptionItems', items)
}

const editOptionItems = (
  context,
  { contractId, keyName, limit, optionName, description },
) => {
  const TAG = `${MODULE_TAG}.${editOptionItems.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  let optionItems = context.state.optionItems
  // 要素の検索
  let index = -1
  optionItems.find((item, idx) => {
    if (item.key == keyName) {
      index = idx
      if (item.key == 'max_login_users') {
        item.usage.count = context.state.userItems.length
      }
      return true
    } else {
      return false
    }
  })

  if (0 <= index) {
    optionItems[index].optionName = optionName
    optionItems[index].description = description

    const count = optionItems[index].usage.count
    const rate = CalcUtil.rate(count, limit)
    optionItems[index].limit = limit
    optionItems[index].usage.rate = rate
  }

  const items = optionItems
  context.commit('setOptionItems', items)
}

const deleteOptionItem = (context, { deleteKey }) => {
  const TAG = `${MODULE_TAG}.${deleteOptionItem.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  let optionItems = context.state.optionItems
  // 要素の削除
  let index = -1
  optionItems.find((item, idx) => {
    if (item.key == deleteKey) {
      index = idx
      return true
    } else {
      return false
    }
  })
  if (0 <= index) {
    optionItems.splice(index, 1)
  }

  const items = optionItems

  context.commit('setOptionItems', items)
}

const addDevice = (context, { newDeviceItem }) => {
  const TAG = `${MODULE_TAG}.${addDevice.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  // let deviceItems = context.state.deviceItems

  // deviceItems.push(newDeviceItem)

  const item = newDeviceItem
  const index = -1
  context.commit('setDeviceItem', { item, index })
}

const deleteDevice = (context, { contractId, deleteDeviceName }) => {
  const TAG = `${MODULE_TAG}.${deleteDevice.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  let deviceItems = context.state.deviceItems
  // 要素の削除
  let index = -1
  deviceItems.find((item, idx) => {
    if (item.thing.thingName == deleteDeviceName) {
      index = idx
      return true
    } else {
      return false
    }
  })
  if (0 <= index) {
    deviceItems.splice(index, 1)
  }

  const items = deviceItems

  context.commit('setDeviceItems', items)
}

const updateDevice = (context, { device, index }) => {
  const TAG = `${MODULE_TAG}.${updateDevice.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const item = device
  // ストアのデータ更新
  context.commit('setDeviceItem', { item, index })
}

const updateDeviceItems = (context, { contractId, devices }) => {
  const TAG = `${MODULE_TAG}.${updateDeviceItems.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const items = devices

  context.commit('setDeviceItems', items)
}

const updateHomeSummaryItems = (context, { contractId, summaryData }) => {
  const TAG = `${MODULE_TAG}.${updateHomeSummaryItems.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const items = summaryData
  context.commit('setHomeSummaryItems', items)
}

const updateHomeCountingItems = (context, { contractId, countingData }) => {
  const TAG = `${MODULE_TAG}.${updateHomeCountingItems.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const item = countingData
  context.commit('setHomeCountingItems', item)
}

const updateHomeGenderAgeItems = (context, { contractId, genderAgeData }) => {
  const TAG = `${MODULE_TAG}.${updateHomeGenderAgeItems.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const items = genderAgeData
  context.commit('setHomeGenderAgeItems', items)
}

const updateMultiTenantCountingItems = (
  context,
  { contractId, multiTenantDataItems },
) => {
  const TAG = `${MODULE_TAG}.${updateMultiTenantCountingItems.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const items = multiTenantDataItems
  context.commit('setMultiTenantCountingItems', items)
}

const updateMultiTenantSummaryItems = (
  context,
  { contractId, multiTenantTableData },
) => {
  const TAG = `${MODULE_TAG}.${updateMultiTenantSummaryItems.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const items = multiTenantTableData
  context.commit('setMultiTenantSummaryItems', items)
}

const updateTenantMeasurementItems = (
  context,
  { contractId, tenantMeasurementData },
) => {
  const TAG = `${MODULE_TAG}.${updateTenantMeasurementItems.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const items = tenantMeasurementData
  context.commit('setTenantMeasurementItems', items)
}

const updateTenantCountingItems = (
  context,
  { contractId, tenantCountingData },
) => {
  const TAG = `${MODULE_TAG}.${updateTenantCountingItems.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const items = tenantCountingData
  context.commit('setTenantCountingItems', items)
}

const updateTenantCountingDaysItems = (
  context,
  { contractId, tenantCountingDaysData },
) => {
  const TAG = `${MODULE_TAG}.${updateTenantCountingDaysItems.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const items = tenantCountingDaysData
  context.commit('setTenantCountingDaysItems', items)
}

const updateTenantCountingHoursItems = (
  context,
  { contractId, tenantCountingHoursData },
) => {
  const TAG = `${MODULE_TAG}.${updateTenantCountingHoursItems.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const items = tenantCountingHoursData
  context.commit('setTenantCountingHoursItems', items)
}

const updateTenantSummaryItems = (
  context,
  { contractId, tenantSummaryData },
) => {
  const TAG = `${MODULE_TAG}.${updateTenantSummaryItems.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const items = tenantSummaryData
  context.commit('setTenantSummaryItems', items)
}

const updateTenantTargetSummaryDaysItems = (
  context,
  { contractId, tenantSummaryDaysData },
) => {
  const TAG = `${MODULE_TAG}.${updateTenantTargetSummaryDaysItems.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const items = tenantSummaryDaysData
  context.commit('setTenantTargetSummaryDaysItems', items)
}

const updateTenantTargetDaysItems = (
  context,
  { contractId, tenantTargetDaysData },
) => {
  const TAG = `${MODULE_TAG}.${updateTenantTargetDaysItems.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const items = tenantTargetDaysData
  context.commit('setTenantTargetDaysItems', items)
}

const updateTenantTargetHoursItems = (
  context,
  { contractId, tenantTargetHoursData },
) => {
  const TAG = `${MODULE_TAG}.${updateTenantTargetHoursItems.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const items = tenantTargetHoursData
  context.commit('setTenantTargetHoursItems', items)
}

const updateSpotDirectionItems = (
  context,
  { contractId, spotDirectionData },
) => {
  const TAG = `${MODULE_TAG}.${updateSpotDirectionItems.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const items = spotDirectionData
  context.commit('setSpotDirectionItems', items)
}

const updateSpotDirectionSummaryItems = (
  context,
  { deviceId, spotDirectionSummaryData },
) => {
  const TAG = `${MODULE_TAG}.${updateSpotDirectionSummaryItems.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const items = spotDirectionSummaryData
  context.commit('setSpotDirectionSummaryItems', items)
}

const addPromoEventItem = (context, { deviceId, newItem }) => {
  const TAG = `${MODULE_TAG}.${addPromoEventItem.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const item = newItem
  const index = -1
  // ストアのデータ更新
  context.commit('setPromoEventItem', { item, index })
}

const updatePromoEventItems = (context, { deviceId, promoEventItems }) => {
  const TAG = `${MODULE_TAG}.${updatePromoEventItems.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const items = promoEventItems
  context.commit('setPromoEventItems', items)
}

const editPromoEventItem = (
  context,
  { deviceId, id, eventName, start, end, description },
) => {
  const TAG = `${MODULE_TAG}.${editOptionItems.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  let promoEventItems = context.state.promoEventItems
  // 要素の検索
  let index = -1
  promoEventItems.find((item, idx) => {
    if (item.id == id) {
      index = idx
      return true
    } else {
      return false
    }
  })

  if (0 <= index) {
    promoEventItems[index].name = eventName
    promoEventItems[index].start = start
    promoEventItems[index].end = end
    promoEventItems[index].description = description
  }

  const items = promoEventItems
  context.commit('setPromoEventItems', items)
}

const deletePromoEventItem = (context, { deviceId, deleteId }) => {
  const TAG = `${MODULE_TAG}.${deletePromoEventItem.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  let promoEventItems = context.state.promoEventItems
  // 要素の削除
  let index = -1
  promoEventItems.find((item, idx) => {
    if (item.id == deleteId) {
      index = idx
      return true
    } else {
      return false
    }
  })
  if (0 <= index) {
    promoEventItems.splice(index, 1)
  }

  const items = promoEventItems

  context.commit('setPromoEventItems', items)
}

const updateDownloadPeriodItems = (
  context,
  { deviceId, downloadPeriodList },
) => {
  const TAG = `${MODULE_TAG}.${updateDownloadPeriodItems.name}`
  logger.verbose(Logger.formatter(TAG, `begin`))

  const items = downloadPeriodList
  context.commit('setDownloadPeriodItems', items)
}

export default {
  addAcccount,
  updateAccountItems,
  deleteAccount,

  updateContractItems,
  updateContract,

  addAppUser,
  updateAppUser,
  updateAppUserItems,
  deleteAppUser,

  addDevice,
  deleteDevice,
  updateDevice,
  updateDeviceItems,

  addOptionItems,
  setOptionItems,
  editOptionItems,
  deleteOptionItem,

  updateHomeSummaryItems,
  updateHomeCountingItems,
  updateHomeGenderAgeItems,
  updateMultiTenantCountingItems,
  updateMultiTenantSummaryItems,
  updateTenantMeasurementItems,
  updateTenantCountingItems,
  updateTenantCountingDaysItems,
  updateTenantCountingHoursItems,
  updateTenantSummaryItems,
  updateTenantTargetSummaryDaysItems,
  updateTenantTargetDaysItems,
  updateTenantTargetHoursItems,

  updateSpotDirectionItems,
  updateSpotDirectionSummaryItems,

  addPromoEventItem,
  updatePromoEventItems,
  editPromoEventItem,
  deletePromoEventItem,

  updateDownloadPeriodItems,
}
