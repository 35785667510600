const newUserItemWithProfile = (inputData, registered, enabled, status) => {
  return {
    id: inputData.idaasUser.sub,
    user: {
      sub: inputData.idaasUser.sub,
      username: inputData.idaasUser.username,
      new: true,
      registered: registered,
      enabled: enabled,
      status: status,
    },
    name: inputData.name,
    corporate: '',
    email: inputData.email,
    department: inputData.department,
    tenant: inputData.tenant,
    description: inputData.description,
    role: 'オペレーター',
    sound: '設定できません',
    usage: { rate: 0, period: '' },
    activity: inputData.activity,
  }
}

export default {
  newUserItemWithProfile,
}
